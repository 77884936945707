import { mdiGreaterThan, mdiLessThan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useRef } from "react";
import Slider from "react-slick";
import { IconButton } from "ui-neumorphism";
import pic_1 from '../../assets/projectPortfolio/pic-1.png'
import pic_2 from '../../assets/projectPortfolio/pic-2.png'
import pic_3 from '../../assets/projectPortfolio/pic-3.png'
import pic_4 from '../../assets/projectPortfolio/pic-4.png'

export default function ProjectPortfolio() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    className: "slider variable-width flex flex-row w-full gap-5",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const portfolios=[
    {
      image:pic_1,
      titleName:"Groww up",
      subTitle:"groww up"
    },
    {
        image:pic_2,
        titleName:"Fashion site",
        subTitle:"Fashion site"
      },
      {
        image:pic_3,
        titleName:"Logo redesign",
        subTitle:"Logo redesign"
      },
      {
        image:pic_4,
        titleName:"AI website",
        subTitle:"AI website"
      },
      {
        image:pic_1,
        titleName:"Groww up",
        subTitle:"groww up"
      },
      {
          image:pic_2,
          titleName:"Fashion site",
          subTitle:"Fashion site"
        },
        {
          image:pic_3,
          titleName:"Logo redesign",
          subTitle:"Logo redesign"
        },
        {
          image:pic_4,
          titleName:"AI website",
          subTitle:"AI website"
        }
  ]
  return (
    <div className="flex flex-col gap-4 w-full h-fit">
      <div className="text-[#051118]  text-[22px] font-[700] leading-[51.3px] text-left">
      Project portfolio
      </div>
      <div className="slider-container">
        <Slider  ref={slider => {
          sliderRef = slider;
        }}
        {...settings}>
      
      {
        portfolios.map((folio,_)=>(
            <div className="p-2">
           
                <div className="[box-shadow:4.84px_4.84px_7.26px_0px_#BDC2C740,-4.84px_-4.84px_7.26px_0px_#FFFFFF40]  w-[300px] h-[211px] rounded-[26px] flex flex-col">
                  <div className="w-full h-fit">
                    <img className="rounded-t-[26px]" src={folio.image} alt="ss" />
                  </div>
                  <div className="px-4 h-fit py-2">
                  <div className=" text-[23px] font-[700] text-[#352F36] text-left">{folio.titleName}</div>
                  <div className=" text-[#051118] text-[10.89px] font-medium opacity-[50%] ">{folio.subTitle}</div>
                  </div>
                </div>
            
            </div>
        ))
      
      }
        
        </Slider>
       <div className="text-right w-full flex flex-row justify-end pt-4">
       <div className="w-fit flex flex-row gap-4">
       <IconButton rounded text={false} color='#4448B3' onClick={previous}>
  <Icon path={mdiLessThan} size={0.8} />
</IconButton>
<IconButton rounded text={false} color='#4448B3' onClick={next}>
  <Icon path={mdiGreaterThan} size={0.8} />
</IconButton>
       </div>
       </div>
      </div>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}