import React, { useState, useEffect } from "react";
import BgButton from "../Button/BgButton";
import BackButton from "../Button/BackButton";
import axios from "axios";
import Spinner, {
  apiUrl,
  token,
  handleBase64Change,
} from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
export default function TopProjects() {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch projects data from your API
    axios
      .get(apiUrl + "/project/getAll")
      .then((response) => {
        // Sort projects by budget in descending order
        const sortedProjects = response.data.sort(
          (a, b) => b.projectBudeget - a.budget
        );
        setProjects(sortedProjects.slice(0, 6));

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setLoading(false);
      });
  }, []);

  const handleSeeMoreClick = () => {
    // Navigate to the all projects page
    navigate("/all-projects");
  };
  const handleProjectClick = (projectId) => {
    navigate(`/default-project/${projectId}`);
  };
  const hasImage = projects.projectFile && projects.projectFile.trim() !== "";
  if (loading) return <Spinner titleName={"Loading topproject details..."} />;
  return (
    <div className="flex flex-col gap-1 px-10 p-4 items-center">
      <div className="font-[500] text-[40px] text-[#352F36]">Top Projects </div>
      <div className="text-[#414B5A] small:text-center opacity-[50%] font-[400] text-[30px]">
        Discover the Leading Innovators and Industry Giants
      </div>
      <div className="p-4 w-fit h-fit grid grid-cols-[repeat(3,1fr)] gap-6">
        {projects.map((project) => (
          <div
            onClick={() => handleProjectClick(project.projectId)}
            key={project.projectId}
            className="relative w-[248px] items-center flex justify-center h-[248px] rounded-[30px] [box-shadow:2.83px_2.83px_5.65px_0px_#A6ABBD_inset,_-2.83px_-2.83px_5.65px_0px_#FAFBFF_inset] p-4"
          >
            {hasImage ? (
              <img
                className="absolute w-[100%] h-full rounded-[15px] object-cover"
                src={`data:image/png;base64,${project.projectFile}`}
                alt={project.projectName}
              />
            ) : (
              <div className="absolute w-[100%] h-full rounded-[15px] object-cover items-center justify-center flex">
                <div className="w-32 h-32 bg-blue-500 rounded-[50%] flex items-center justify-center text-white font-bold text-xl rounded">
                  {project.projectName.slice(0, 2)}
                </div>
              </div>
            )}

            <div className="relative h-full w-full p-4 text-[#FFFFFF] flex flex-col justify-end">
              <span className="font-[500] text-[23px]">
                {project.projectName}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div>
        <BgButton buttonName="See more" onClickHandler={handleSeeMoreClick} />
      </div>
    </div>
  );
}
