import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { IconButton } from 'ui-neumorphism';
import { useNavigate} from "react-router-dom";
import PostNavHeader from "../components/Post-Jobs/util/PostNavHeader";
import SearchIcon from '@mui/icons-material/Search';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function Messages() {
    const { id } = useParams();
      const [searchTerm, setSearchTerm] = useState('');
      const navigate=useNavigate();
      const messages = [
        { id: 1, name: 'John Adams', time: '7 mins ago', text: 'I have completed mod 1, can you please review...', image: require('../assets/noticationimage.png') },
        { id: 2, name: 'Jane Doe', time: '15 mins ago', text: 'Can we schedule a meeting?', image: require('../assets/noticationimage.png') },
        { id: 3, name: 'Perry', time: '15 mins ago', text: 'Can we schedule a meeting?', image: require('../assets/noticationimage.png') },
        { id: 4, name: 'Alysha ', time: '15 mins ago', text: 'I have completed with mod 1, can you please review...?', image: require('../assets/noticationimage.png') },
        { id: 5, name: 'Sarah', time: '15 mins ago', text: 'Can we schedule a meeting?', image: require('../assets/noticationimage.png') },
        { id: 6, name: 'Lionel', time: '15 mins ago', text: 'I have completed with mod 1, can you please review...', image: require('../assets/noticationimage.png') },
        { id: 7, name: 'Cristiano', time: '15 mins ago', text: 'Can we schedule a meeting?', image: require('../assets/noticationimage.png') },
        { id: 8, name: 'Micheal', time: '15 mins ago', text: 'I have completed with mod 1, can you please review...', image: require('../assets/noticationimage.png') },
    
        // Add more messages as needed
      ];
    
      const filteredMessages = messages.filter(message =>
        message.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.text.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
      // const message = messages.find(msg => msg.id === parseInt(id));
    
      // if (!message) {
      //   return <p>Message not found</p>;
      // }
    
      return (
        <div className="w-screen h-screen  bg-[#E3EDF7] font-Satoshi overflow-hidden items-center justify-center flex flex-col gap-5 ">
 <div className="w-full h-full">
 <PostNavHeader
        isBack={true}
        titleName="Messages"
      />
      </div>
      <div className="w-[100%] p-5 h-[85%] flex flex-row gap-10 ">
      <div className="w-[30%] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] max-h-[95%] overflow-y-auto block scrollbar bg-[#E3EDF7] rounded-[15px] p-5 flex flex-col gap-5 items-center ">
      
            
            <div className="w-[100%]  flex flex-row justify-between p-3 rounded-[15px] items-center gap-10">
         <div className="w-fit flex flex-row gap-5">
         <div className='w-fit h-fit rounded-[50%]  p-3'>
                <img className='w-[48px] h-[48px]'  src={require("../assets/rohit.png")} alt="Notification" />
              </div>
              <div className="w-fit h-fit flex flex-col">
              <p className='text-[20px] font-[700]'>Rohit</p>
              <p className="text-[16px] fnt-[500] text-[#20BF55] flex flex-row gap-1">online <img className="w-[10px] h-[10px]"  src={require("../assets/dot.png")} /></p>
              </div>
              </div>
             <div className="w-fit h-fit items-center text-[13px] font-[700] p-2 bg-[#089949] rounded-[15px] shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]  flex flex-row gap-2 text-white">
             <img className='w-[10px] h-[10px]'  src={require("../assets/messageschat.png")} alt="Notification" />
             Chats 60  </div>
            </div>
            <div className="w-[100%] flex flex-row p-3 rounded-[15px] items-center gap-10 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
          <SearchIcon className="text-[#051118]" />
          <input
            className="w-full h-fit focus:outline-none bg-[#E3EDF7] text-[18px] font-[500]"
            placeholder="Search for messages"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      {filteredMessages.map(message => (
            <div key={message.id} 
            className="w-[100%] flex flex-row rounded-[15px] items-center gap-1">
              <div className='w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3'>
                <img className='w-[48px] h-[48px]' src={message.image} alt="Notification" />
              </div>
              <div className="flex w-full h-fit flex-col gap-1">
                <div className="w-full h-fit items-center flex flex-row justify-between">
                  <p className='text-[20px] font-[700]'>{message.name}</p>
                  <p className="text-[14px] font-[500] text-[#414B5A]">{message.time}</p>
                </div>
                <p className="text-[14px] font-[500]">{message.text}</p>
              </div>
             
            </div>
          ))}
      </div>
          <div className="w-[60%]  h-full bg-[#E3EDF7] rounded-[15px] p-5 flex flex-col gap-5 items-center ">
            
          <div className="w-[100%] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] flex flex-row justify-between p-3 rounded-[15px] items-center gap-10">
         <div className="w-fit flex flex-row gap-5">
         <div className='w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3'>
         <img className='w-[48px] h-[48px]' src={require("../assets/Loginpage.png")} alt="Notification" />
              </div>
                
              <div className="w-fit h-fit flex flex-col">
              <p className='text-[20px] font-[700]'>John Adams</p>
              <p className="text-[16px] fnt-[500] text-[#20BF55] flex flex-row gap-1">online <img className="w-[10px] h-[10px]"  src={require("../assets/dot.png")} /></p>
              </div>
              </div>
              <IconButton text={false} style={{ width: "24px", height: "24px", borderRadius: "15px" }} size="large" className="px-4 py-2">
            <ArrowForwardIosIcon />
          </IconButton>
            </div>
            
            <div className="w-[100%] max-h-70 h-fit  text-[18px] font-[500] overflow-hidden shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] flex flex-col p-3 gap-5 rounded-[15px] items-center gap-10">
            <div className="w-[100%] max-h-60 overflow-y-auto block scrollbar">
             <p className="w-[50%] text-[16px] font-[500] text-[#352F36]">You started a chat with @john. we use information from this chat to improve your experience.</p>
              <div className='w-[100%] h-fit flex flex-row gap-2 items-center justify-start rounded-[50%] p-3'>
              <div className='w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3'>
         <img className='w-[48px] h-[48px]' src={require("../assets/rohit.png")} alt="Notification" />
              </div>
                <div className="w-fit p-2 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">Hello! How are you today?</div>
              </div>
              <div className='w-[100%] h-fit flex flex-row gap-2 items-center justify-end rounded-[50%] p-3'>
             
                <div className="w-fit p-2 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">Hello! How are you today?</div>
                <div className='w-fit h-fit rounded-[50%]  p-3'>
                <img className='w-[48px] h-[48px]'  src={require("../assets/rohit.png")} alt="Notification" />
              </div>
              </div>
              <div className='w-[100%] h-fit flex flex-row gap-2 items-center justify-start rounded-[50%] p-3'>
              <div className='w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3'>
         <img className='w-[48px] h-[48px]' src={require("../assets/rohit.png")} alt="Notification" />
              </div>
                <div className="w-fit p-2 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">Hello! How are you today?</div>
              </div>
              <div className='w-[100%] h-fit flex flex-row gap-2 items-center justify-end rounded-[50%] p-3'>
             
                <div className="w-fit p-2 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">Hello! How are you today?</div>
                <div className='w-fit h-fit rounded-[50%]  p-3'>
                <img className='w-[48px] h-[48px]'  src={require("../assets/rohit.png")} alt="Notification" />
              </div>
              </div>
              </div>
              <div className="w-[100%] h-fit items-center rounded-[15px] flex flex-row gap-10 p-5 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
              <IconButton text={false} style={{ width: "24px", height: "24px", borderRadius: "15px" }} size="large" className="px-4 py-2">
              <img className='w-[24px] h-[24px]'  src={require("../assets/document.png")} alt="Notification" />
          </IconButton>
<input className="w-[70%] bg-[#E3EDF7] rounded-[15px] h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]" placeholder="message"/>
<IconButton text={false} style={{ width: "24px", height: "24px", borderRadius: "15px" }} size="large" className="px-4 py-2">
<img className='w-[24px] h-[24px]'  src={require("../assets/send.png")} alt="Notification" />
          </IconButton>
              </div>
            </div>
          </div>
          </div>
          </div>
      );
    };
    
 
    
export default Messages