import React, { useState } from "react";

export default function TextInput({
  type,
  inputValue,
  setInputValue,
  labelName,
  placeholder
}) {
  const [error, setError] = useState("");
  if (inputValue ===null){
    inputValue="";
  }
  return (
    <div className={`w-full h-full flex flex-col gap-3`}>
      <div className=" text-[18px] font-[500] leading-[29.7px] text-left">
        {labelName}
      </div>
      <input
        type={type}
        className={` w-[100%] ${
          inputValue !== ""
            ? "shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff]"
            : "shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
        } focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] outline-none  rounded-[5px] p-3 font-[500] placeholder:text-[14px] border-1 leading-24 bg-[#E3EDF7] text-[#414B5A] text-[22px]  focus:outline-none" ${
          error && "border border-red-500"
        }`}
        style={{ borderRadius: "10px" }}
        placeholder={placeholder?placeholder:labelName}
        name={labelName}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
}
