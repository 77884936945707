import React, { useState } from 'react'
import { Card } from 'ui-neumorphism'
import RadioInput from '../../inputs/RadioInput'

export default function IsContractToHire({isContract,setIsContract}) {
    return (
      <div className="flex flex-col w-full gap-4 py-4">
        <div className="font-[500] text-[22px] ">Is this job a contract to hire opportunity</div>
        <div className="flex flex-row medium:flex-wrap medium:gap-4 gap-10">
          <Card width={303} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={isContract}
              setRadioValue={setIsContract}
              labelValue={"Yes, it will become full time"}
            />
          </Card>
          <Card width={240} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={isContract}
              setRadioValue={setIsContract}
              labelValue={"No, Not at this time"}
            />
          </Card>
        
        </div>
        </div>
    )
}
