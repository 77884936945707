import React from 'react'
 export default function ClientRadioInput({ radioValue, setRadioValue, labelValue }) {
      const radioValueHandler = () => {
        if (radioValue === labelValue) {
          setRadioValue(null);
        } else {
          setRadioValue(labelValue);
        }
        
      };
      return (
        <div className="p-2 h-full  w-full flex flex-row items-center gap-2">
          <div
            className={`w-[24px] h-[24px] rounded-full grid place-items-center cursor-pointer ${
              radioValue === labelValue
                ? "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF]"
                : "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset]"
            }`}
            onClick={radioValueHandler}
          >
            <div
              className={`w-[15px] h-[15px]   ${
                radioValue === labelValue ? "border-[#0624A6] border-4" : "border-2 border-[#414B5A] opacity-[50%] "
              } rounded-full [box-shadow:2.5px_2.5px_7px_0px_#A6ABBD,_-2.5px_-2.5px_7px_0px_#FAFBFF]`}
            ></div>
          </div>
          <div className=" text-[22px] font-[500] leading-[24.3px] text-center capitalize text-[#352F36]">
            {" "}
            {labelValue}
          </div>
        </div>
      );
    }
    
