import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hoverBg } from "../../utils/constants";

export default function DefaultHeader({
  scrollToSection,
  howItWorksRef,
  categoriesRef,
  findFreelancerRef,
  findCompaniesRef,
  startupsAndIncubatorsRef,
  activeSection,
}) {
  const [headerActive, setHeaderActive] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Update headerActive based on the activeSection prop
    switch (activeSection) {
      case "howItWorks":
        setHeaderActive(0);
        break;
      case "categories":
        setHeaderActive(1);
        break;
      case "findFreelancer":
        setHeaderActive(2);
        break;
      case "findCompanies":
        setHeaderActive(3);
        break;
      case "startupsAndIncubators":
        setHeaderActive(4);
        break;
      default:
        setHeaderActive(-1); // No section is active
    }
  }, [activeSection]);
  return (
    <div className="w-full h-[10vh] px-10 py-4 flex flex-row justify-between items-center">
      <div className="h-full w-fit text-[42px] font-normal leading-[51.66px] text-left cursor-pointer hover:scale-[1.01]">
        P2H
      </div>
      <div className="w-fit h-full grid place-items-center">
        <div className="h-fit flex font-[400] text-[18px]  text-[#414B5A] flex-row items-center mt-2  w-fit gap-10">
          {headerList.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer hover:text-[#2485FF]  medium:hidden flex flex-row justify-center items-center font-[400] gap-2 ${
                headerActive === index &&
                "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] p-2 px-4 transition-all text-[#2485FF] font-[500] rounded-[8px]"
              }`}
              onClick={() => {
                setHeaderActive(index);
                if (item === "How it works") {
                  scrollToSection(howItWorksRef);
                } else if (item === "Categories") {
                  scrollToSection(categoriesRef);
                } else if (item === "Find freelancers") {
                  scrollToSection(findFreelancerRef);
                } else if (item === "Find companies") {
                  scrollToSection(findCompaniesRef);
                } else if (item === "Startups & Incubators") {
                  scrollToSection(startupsAndIncubatorsRef);
                }
              }}
            >
              <span>{item}</span>{" "}
              {headerActive === index && (
                <div>
                  <img
                    className="mt-1"
                    src={require("../../assets/navbar-logos/yellow-dots.png")}
                    alt=""
                  />
                </div>
              )}
            </div>
          ))}
          <div>
            <button
              className={`${hoverBg} [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] p-2 px-4 rounded-[14px] small:hidden text-[#ffffff]`}
              onClick={() => navigate("/login")}
            >
              Signup / Login
            </button>
          </div>
          <div
            onClick={() => setShowMenu(!showMenu)}
            className="medium:flex hidden flex-col gap-1 cursor-pointer hover:scale-[1.01]"
          >
            <img
              src={require("../../assets/defaultScreen/menu-alt.png")}
              alt=""
            />
          </div>
          {showMenu && (
            <div className="relative z-50 hidden medium:block">
              <div className="absolute z-100 -right-10 top-12 bg-slate-100 w-[300px] p-4 px-6 flex flex-col gap-6">
                {headerList.map((item, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer w-fit flex flex-row justify-center items-center font-[400] gap-2 ${
                      headerActive === index &&
                      "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] p-2 px-4 transition-all text-[#2485FF] font-[500] rounded-[8px]"
                    }`}
                    onClick={() => {
                      setHeaderActive(index);
                      if (item === "How it works") {
                        scrollToSection(howItWorksRef);
                      } else if (item === "Categories") {
                        scrollToSection(categoriesRef);
                      } else if (item === "Find freelancers") {
                        scrollToSection(findFreelancerRef);
                      } else if (item === "Find companies") {
                        scrollToSection(findCompaniesRef);
                      } else if (item === "Startups & Incubators") {
                        scrollToSection(startupsAndIncubatorsRef);
                      }
                    }}
                  >
                    <span>{item}</span>{" "}
                    {headerActive === index && (
                      <div>
                        <img
                          className="mt-1"
                          src={require("../../assets/navbar-logos/yellow-dots.png")}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const headerList = [
  "How it works",
  "Categories",
  "Find freelancers",
  "Find companies",
  "Startups & Incubators",
];
