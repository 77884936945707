import React, { useContext, useEffect, useState } from "react";
import PostNavHeader from "../util/PostNavHeader";
import ProgressBar from "../util/ProgressBar";
import { PostAJobContext } from "../../../App";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../util/ScrollToTop";
import TypesOfPayments from "./TypesOfPayments";
import NextButton from "../../Button/NextButton";

export default function PaymentType() {
  const {listOfPaymentType,projectBudget, setProjectBudget,costOfWork, setCostOfWork,paymentType, setPaymentType,progressStatus, setprogressStatus}= useContext(PostAJobContext);


  const location = useLocation();
  const redirectUrl=location.state?location.state.pathName:"/project-estimation-time"

  useEffect(() => {
    if (location.pathname === "/project-payment") {
      setprogressStatus(3);
    }
  }, []);
  const navigate=useNavigate()
const nextHandler=()=>{
navigate("/project-description",{state:{pathName:location.pathname}})
}
useEffect(()=>{
  if (progressStatus<2) {
    navigate("/project-duration")
  }
})
  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} redirectUrl={redirectUrl}/>
      <div className="px-40 py-8 small:px-10 mobile:px-4 flex flex-col items-center gap-5 overflow-auto">
        <ProgressBar status={progressStatus} />
        <TypesOfPayments
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          costOfWork={costOfWork}
          setCostOfWork={setCostOfWork}
          projectBudget={projectBudget}
          setProjectBudget={setProjectBudget}
          listOfPaymentType={listOfPaymentType}
        />
        <div className="w-full grid place-items-center h-fit">
        <NextButton
    bgActive={paymentType && (costOfWork || projectBudget) ? true : false}
    buttonName={"next"}
    nextHandler={nextHandler}
  />
        </div>
      </div>
    </div>
  );
}
