import React, { useState } from "react";
import RadioInput from "../../inputs/RadioInput";
import { Card } from "ui-neumorphism";

export default function Estimatetime({
  estimateTime,
  setEstimateTime,
  timeValue,
  setTimeValue,
}) {
  return (
    <div className="flex flex-col w-full items-start gap-4">
      <div className="font-[500] text-[22px] w-full text-left ">Estimated time</div>
      <div className="flex flex-row small:flex-wrap small:gap-4 gap-10">
        <div className="card w-[150px] h-[58px] rounded-[20px]">
          <RadioInput
            radioValue={estimateTime}
            setRadioValue={setEstimateTime}
            labelValue={"Year"}
          />
        </div>
        <div className="card w-[150px] h-[58px] rounded-[20px]">
          {" "}
          <RadioInput
            radioValue={estimateTime}
            setRadioValue={setEstimateTime}
            labelValue={"Month"}
          />
        </div>
        <div className="card w-[150px] h-[58px] rounded-[20px]">
          {" "}
          <RadioInput
            radioValue={estimateTime}
            setRadioValue={setEstimateTime}
            labelValue={"Week"}
          />
        </div>
      </div>
      {
        estimateTime && <div className="font-[500] text-[22px] w-full text-left ">Select time Period</div>
      }
      {estimateTime === "Year" ? (
        <div className="flex flex-row small:flex-wrap small:gap-4  gap-10">
          <Card width={185} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={timeValue}
              setRadioValue={setTimeValue}
              labelValue={"Below 1 year"}
            />
          </Card>
          <Card width={185} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={timeValue}
              setRadioValue={setTimeValue}
              labelValue={"above 1 year"}
            />
          </Card>
        </div>
      ) : estimateTime === "Month" ? (
        <div className="flex flex-row small:flex-wrap small:gap-4 gap-10">
          <Card width={186} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={timeValue}
              setRadioValue={setTimeValue}
              labelValue={"Below 1 month"}
            />
          </Card>
          <Card width={185} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={timeValue}
              setRadioValue={setTimeValue}
              labelValue={"above 1 month"}
            />
          </Card>
        </div>
      ) : (
        estimateTime === "Week" && (
          <div className="flex flex-row small:flex-wrap small:gap-4 gap-10">
            <Card width={185} height={58} style={{borderRadius:"20px"}} >
              <RadioInput
                radioValue={timeValue}
                setRadioValue={setTimeValue}
                labelValue={"Below 1 week"}
              />
            </Card>
            <Card width={185} height={58} style={{borderRadius:"20px"}} >
              <RadioInput
                radioValue={timeValue}
                setRadioValue={setTimeValue}
                labelValue={"above 1 week"}
              />
            </Card>
          </div>
        )
      )}
    </div>
  );
}
