import React, { useState, useEffect, useContext } from "react";
import { ProposalsFiltered } from "./ProposalFilters";
import { IoIosSearch } from "react-icons/io";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AcceptButton from "../Button/AcceptButton";
import DeclineButton from "../Button/DeclineButton";
import InviteSentButton from "../Button/InviteSentButton";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import { apiUrl } from "../../utils/constants";
import { Avatar, IconButton } from "ui-neumorphism";
import { FaEye } from "react-icons/fa";
import { ClientIdContext, TokenContext } from "../../App";

export default function BrowseExpertsFilters({
  filterSkills = [],
  setFilterSkills = () => {},
  filterBadges = [],
  setFilterBadges = () => {},
  filterPricing = [],
  setFilterPricing = () => {},
  filterMoneyEarned = [],
  setFilterMoneyEarned = () => {},
}) {
  const { token, updateToken } = useContext(TokenContext);
  const [browseProfiles, setBrowseProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("Individual");

  useEffect(() => {
    axios
      .get(`${apiUrl}/freelancer/getAll`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setBrowseProfiles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching freelancers!", error);
      });
  }, []);

  return (
    <div className="w-full h-full grid grid-cols-[27%_auto] medium:grid-cols-1 gap-10">
      <div className="medium:hidden">
        <ProposalsFiltered
          filterSkills={filterSkills}
          setFilterSkills={setFilterSkills}
        />
      </div>
      <BrowseUsersView
        browseProfiles={browseProfiles}
        skillFilterList={filterSkills}
        setSkillFilterList={setFilterSkills}
        badgeFilterList={filterBadges}
        setBadgeFilterList={setFilterBadges}
        pricingFilterList={filterPricing}
        setPricingFilterList={setFilterPricing}
        moneyEarnedFilterList={filterMoneyEarned}
        setMoneyEarnedFilterList={setFilterMoneyEarned}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
    </div>
  );
}

export const BrowseUsersView = ({
  browseProfiles,
  skillFilterList,
  setSkillFilterList,
  badgeFilterList,
  setBadgeFilterList,
  pricingFilterList,
  setPricingFilterList,
  moneyEarnedFilterList,
  setMoneyEarnedFilterList,
  searchTerm,
  setSearchTerm,
  selectedRole,
  setSelectedRole,
}) => {
  const filteredProfiles = browseProfiles.filter((profile) => {
    const matchesSearch = profile.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesRole =
      selectedRole === "" || profile.freelancerRole === selectedRole;
    const matchesSkills =
      skillFilterList.length === 0 ||
      skillFilterList.some((skill) => profile.skills.includes(skill));
    const matchesBadges =
      badgeFilterList.length === 0 ||
      badgeFilterList.some((badge) => profile.badges.includes(badge));
    const matchesPricing =
      pricingFilterList.length === 0 ||
      pricingFilterList.some((price) => profile.income === price);
    const matchesMoneyEarned =
      moneyEarnedFilterList.length === 0 ||
      moneyEarnedFilterList.some((money) => profile.earned >= money);

    return (
      matchesSearch &&
      matchesRole &&
      matchesSkills &&
      matchesBadges &&
      matchesPricing &&
      matchesMoneyEarned
    );
  });

  return (
    <div className="w-full flex flex-col gap-4 pb-4">
      <div className="grid grid-cols-3 small:grid-cols-2 justify-end w-full gap-10 gap-y-4">
        <div className="col-span-2 w-full h-[70px] rounded-[20px] p-4 flex flex-row gap-2 items-center shadow-md">
          <IoIosSearch className="opacity-[50%] text-[#414B5A]" />
          <input
            type="text"
            className="h-full w-full bg-transparent outline-none focus:outline-none"
            placeholder="Search by name, skill, location"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-span-1 small:hidden w-full flex flex-row justify-end h-[70px] rounded-[20px] p-4 shadow-md">
          <select
            name="role"
            id="role"
            className="w-full bg-transparent h-full outline-none focus:outline-none"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <option value="Individual">Individual</option>
            <option value="Company">Company</option>
          </select>
        </div>
      </div>
      <AppliedFilters
        skillFilterList={skillFilterList}
        setSkillFilterList={setSkillFilterList}
        badgeFilterList={badgeFilterList}
        setBadgeFilterList={setBadgeFilterList}
        pricingFilterList={pricingFilterList}
        setPricingFilterList={setPricingFilterList}
        moneyEarnedFilterList={moneyEarnedFilterList}
        setMoneyEarnedFilterList={setMoneyEarnedFilterList}
      />
      <div className="flex flex-col gap-5">
        {filteredProfiles.length > 0 ? (
          filteredProfiles.map((profile, index) => (
            <BrowseExpertsProfiles key={index} profile={profile} />
          ))
        ) : (
          <div>No profiles found</div>
        )}
      </div>
    </div>
  );
};

const AppliedFilters = ({
  skillFilterList = [],
  setSkillFilterList,
  badgeFilterList = [],
  setBadgeFilterList,
  pricingFilterList = [],
  setPricingFilterList,
  moneyEarnedFilterList = [],
  setMoneyEarnedFilterList,
}) => {
  const removeSkillHandler = (skill) => {
    setSkillFilterList(skillFilterList.filter((value) => value !== skill));
  };

  const removeBadgeHandler = (badge) => {
    setBadgeFilterList(badgeFilterList.filter((value) => value !== badge));
  };

  const removePricingHandler = (price) => {
    setPricingFilterList(pricingFilterList.filter((value) => value !== price));
  };

  const removeMoneyEarnedHandler = (money) => {
    setMoneyEarnedFilterList(
      moneyEarnedFilterList.filter((value) => value !== money)
    );
  };

  return (
    <div className="w-full flex flex-col gap-4 h-fit">
      <div className="font-[700] text-[20px] text-[#352F36]">
        Applied filters
      </div>
      <div className="flex flex-row w-full h-fit gap-6 capitalize">
        {skillFilterList.map((skill) => (
          <div
            className="text-[#2485FF] border border-[#2485FF] rounded-[40px] p-1 flex flex-row gap-2 items-center w-fit h-[44px] px-4"
            key={skill}
          >
            <span>{skill}</span>
            <span
              className="text-[24px] cursor-pointer"
              onClick={() => removeSkillHandler(skill)}
            >
              &times;
            </span>
          </div>
        ))}
        {badgeFilterList.map((badge) => (
          <div
            className="text-[#2485FF] border border-[#2485FF] rounded-[40px] p-1 flex flex-row gap-2 items-center w-fit h-[44px] px-4"
            key={badge}
          >
            <span>{badge}</span>
            <span
              className="text-[24px] cursor-pointer"
              onClick={() => removeBadgeHandler(badge)}
            >
              &times;
            </span>
          </div>
        ))}
        {/* Add similar rendering for pricingFilterList and moneyEarnedFilterList if needed */}
      </div>
    </div>
  );
};

function BrowseExpertsProfiles({ profile }) {
  const sentInvitationHandler = () => {
    profile.invite = true; // Handle sending invitation logic
  };

  const declinedInvitationHandler = () => {
    profile.decline = true; // Handle declining invitation logic
  };

  const location = useLocation();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const [isSaved, setIsSaved] = useState(false); // State to track if profile is saved

  const saveProfileUserIds = async () => {
    try {
      const response = await axios.put(
        apiUrl + `/saveUser/update/${clientId}`,
        {
          userIds: [{ userId: profile.userId }],
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Response:", response.data);
      setIsSaved(true); // Update state or UI to indicate profile is saved
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };
  return (
    <div className="grid grid-cols-4 small:grid-cols-2 w-full h-fit gap-4">
      <div
        className={`${
          location.pathname === "/browse-experts-view"
            ? "col-span-3"
            : "col-span-4"
        } [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] flex flex-col gap-3 justify-center w-full p-4`}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-10">
            <InnerShadowAvatar imgSrc={profile.image} size={40} />
            <div className="flex flex-col">
              <span className="font-[700] text-[20px] text-[#352F36] capitalize">
                {profile.name}
              </span>
              <span className="text-[#414B5A] font-[500] text-[14px] opacity-[50%]">
                {profile.designation}
              </span>
            </div>
          </div>
          <div className="cursor-pointer">
            {!isSaved && (
              <img
                className="w-[17px] h-[20px]"
                src={require("../../assets/praposals/browse-view.png")}
                alt=""
                onClick={saveProfileUserIds}
              />
            )}
            {isSaved && (
              <img
                className="w-[22px] h-[25px]"
                src={require("../../assets/praposals/svaed.png")}
                alt=""
              />
            )}
          </div>
        </div>
        <div
          className={`flex flex-row gap-4 w-full ${
            location.pathname === "/browse-experts-view"
              ? "justify-between"
              : "justify-center"
          } text-[#352F36] font-[500] text-[18px]`}
        >
          <div className="flex flex-row small:flex-wrap gap-4 w-fit">
            <span>${profile.hourlyRate}.00 / hr</span>
            <span>{profile.totalEarnings}K + earned</span>
            <span className="flex flex-row text-[#089949] ">
              <img
                className="w-[24px] h-[24px]"
                src={require("../../assets/praposals/prize-award-icon.png")}
                alt=""
              />
              100% Success rate
            </span>
          </div>

          {location.pathname !== "/browse-experts-view" && (
            <div className="w-fit text-[#ffffff] p-1 px-3 flex flex-row gap-1 items-center text-[16px] cursor-pointer hover:scale-[1.02] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] rounded-[20px]">
              <FaEye /> View
            </div>
          )}
        </div>
      </div>

      {location.pathname === "/browse-experts-view" && (
        <div className="col-span-1 small:col-span-2 flex large:flex-col flex-col ipadMin:flex-row gap-4 small:justify-start justify-center">
          {profile.invite ? (
            <>
              <div className="w-fit min-w-[180px] h-[40px]">
                <InviteSentButton buttonName="Invite Sent" />
              </div>
              <div className="w-fit min-w-[180px] h-[40px]">
                <DeclineButton
                  buttonName="Decline Invite"
                  onClick={declinedInvitationHandler}
                />
              </div>
            </>
          ) : (
            <div className="w-fit min-w-[180px] h-[40px]">
              <AcceptButton onClick={sentInvitationHandler} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
