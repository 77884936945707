import React, { useContext, useEffect } from "react";
import PostNavHeader from "../util/PostNavHeader";
import { ClientIdContext, PostAJobContext, TokenContext } from "../../../App";
import { BiSolidFilePdf } from "react-icons/bi";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RadioInput from "../../inputs/RadioInput";
import NextButton from "../../Button/NextButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../utils/constants";
import InnerShadowAvatar from "../../../utils/InnerShadowAvatar";
export default function PreviewPostMain() {
  const navigate = useNavigate();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const {
    projectDuration,
    setProjectDuration,
    progressStatus,
    projectTitle,
    setProjectTitle,
    selectedSKills,
    setSelectedSkills,
    setEstimateTime,
    timeValue,
    setTimeValue,
    experienceLevel,
    setExperienceLevel,
    isContract,
    setIsContract,
    needAcompanyToWork,
    setNeedAcompanyToWork,
    teamSize,
    setTeamSize,
    projectDescription,
    setProjectDescription,
    projectDocument,
    setProjectDocument,
    projectDocumentName,
    setProjectDocumentName,
    projectBudget,
    setProjectBudget,
    costOfWork,
    setCostOfWork,
    paymentType,
    setPaymentType,
    setprogressStatus,
  } = useContext(PostAJobContext);
  useEffect(() => {
    if (progressStatus < 2) {
      navigate("/project-duration");
    }
  });
  const successfullySubmittedClearData = () => {
    setProjectDuration(null);
    setProjectTitle(null);
    setEstimateTime(null);
    setSelectedSkills([]);
    setTimeValue(null);
    setExperienceLevel(null);
    setIsContract(null);
    setNeedAcompanyToWork(null);
    setTeamSize(null);
    setProjectBudget(null);
    setProjectDescription(null);
    setProjectDocument(null);
    setProjectDocumentName(null);
    setCostOfWork(null);
    setPaymentType(null);
    setprogressStatus(1);
    navigate("/project-duration");
  };

  useEffect(() => {
    if (projectTitle === null) {
      navigate("/project-duration");
    }
  }, []);

  const postAJObApi = () => {
    const formData = new FormData();
    formData.append("file", projectDocument);
    formData.append("userId", clientId);
    formData.append("projectType", projectDuration);
    formData.append("projectName", projectTitle);
    formData.append("projectDescription", projectDescription);
    // formData.append("companyName", projectDescription);
    formData.append(
      "isThisJobAContractToHire",
      isContract === "Yes" ? true : false
    );
    formData.append("skills", selectedSKills);
    formData.append("estimatedTime", timeValue);
    formData.append(
      "needACompanyToWork",
      needAcompanyToWork === "Yes" ? true : false
    );
    formData.append("companySize", teamSize);
    formData.append("experience", experienceLevel);

    if (paymentType === "Hourly") {
      formData.append("paymentType", "hourly");
      formData.append("costOfWorking", costOfWork);
    } else {
      formData.append("paymentType", "fixed");
      formData.append("projectBudget", projectBudget);
    }

    axios
      .post(`${apiUrl}/project/addProject`, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        successfullySubmittedClearData();
        navigate("/post-Success-message");
      })
      .catch((error) => {
        alert("Show error notification!");
      });
  };

  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} titleName="Project Preview" />
      <div className="px-40 py-8 medium:px-10 mobile:px-4 flex flex-col  gap-5 overflow-auto">
        <div className="flex flex-row w-full justify-between items-center h-fit flex-wrap gap-10 ">
          <div
            title={projectTitle}
            className="font-[700] text-[28px]  text-[#352F36] flex flex-row gap-2 items-center h-full"
          >
            <InnerShadowAvatar
              imgSrc={require("../../../assets/navbar-logos/project-name-logo.png")}
              size={40}
            />
            <div className="h-fit -mt-2 capitalize"> {projectTitle}</div>
          </div>
          <div
            onClick={() => navigate("/project-duration")}
            className="cursor-pointer border-dashed border-2 border-gray-300 hover:border-gray-400 hover:scale-[1.01] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-[280px] small:w-fit h-[64px] rounded-[20px] px-[30px] py-[20px] flex flex-row gap-2 items-center justify-center"
          >
            <div>
              {" "}
              <img
                className=" "
                src={require("../../../assets/navbar-logos/edit-icon.png")}
                alt=""
                srcSet=""
              />
            </div>
            <div className="font-[500] text-[18px] small:hidden ">
              Edit project
            </div>
          </div>
        </div>

        <div className="w-full h-[101px] rounded-[20px] text-[#FFFFFF]  p-2 bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] flex flex-row gap-4 justify-center items-center">
          <div className="flex flex-row items-end h-full mt-3 overflow-hidden w-fit">
            <img
              className="w-[86px] h-[76px] mobile:w-[40px] mobile:h-[30px] small:w-[60px] small:h-[40px] "
              src={require("../../../assets/navbar-logos/hurry-project-preview.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="font-[700] text-[30px] small:text-[24px] mobile:text-[20px] normal-case	">
            Hurray..! This is going to be a {projectDuration}
          </div>
        </div>

        <div className="flex flex-col gap-1 large:w-[864px] w-full h-fit">
          <div className="font-[700] text-[22px] text-[#352F36]">
            Project description{" "}
          </div>
          <div className="font-[400] text-[18px] text-[#352F36] normal-case">
            {projectDescription?.charAt(0).toUpperCase() +
              projectDescription?.slice(1)}
          </div>
        </div>

        <div className="flex flex-col gap-2 large:w-[864px] w-full h-fit">
          <div className="font-[700] text-[22px] text-[#352F36]">
            Attached document{" "}
          </div>
          <div className="flex flex-row gap-5 pt-2">
            <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center mobile:hidden">
              <BiSolidFilePdf size={24} color="white" />
            </div>
            <div className="min-w-[296px] w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-3 items-center justify-start">
              <div className="w-[16.17px] h-[20px]">
                <img
                  src={require("../../../assets/navbar-logos/document-icon.png")}
                  alt=""
                  srcSet=""
                />
              </div>
              <div>{projectDocumentName}</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 large:w-[864px] w-full h-fit">
          <div className="font-[700] text-[22px] text-[#352F36]">
            Skills required{" "}
          </div>
          <div className="flex flex-row small:w-full flex-wrap gap-5 pt-2">
            {selectedSKills &&
              selectedSKills.map((skill, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-center items-center gap-2 [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] min-w-[170px] h-[48px] rounded-[30px] px-[12px] py-[30px]"
                >
                  <div className="w-[10px] h-[10px] bg-[#2485FF] rounded-full"></div>
                  <div className="font-[500] text-[18px] text-[#352F36] ">
                    {skill.charAt(0).toUpperCase() + skill?.slice(1)}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 large:w-[864px] w-full h-fit">
          <div className="font-[700] text-[22px] text-[#352F36]">
            Estimated time
          </div>
          <div className="flex w-fit min-w-[100px]  flex-row gap-5 rounded-[20px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF]">
            <RadioInput
              radioValue={timeValue}
              labelValue={timeValue}
              disabled={true}
            />
          </div>
        </div>

        {needAcompanyToWork === "Yes" && (
          <div className="flex flex-col gap-2 large:w-[864px] w-full medium:w-full h-fit">
            <div className="font-[700] text-[22px] text-[#352F36]">Add on</div>
            <div className="flex min-h-[67px] max-h-fit large:min-w-[920px] medium:w-full break-words  w-fit px-4 text-[18px] flex-row flex-wrap p-2  items-center  gap-2 rounded-[20px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF]">
              <span>We need a company to work with</span>{" "}
              <span className="w-fit font-[700] text-[20px] text-[#2485FF]">
                {teamSize}
              </span>{" "}
              <span>size of employees, with </span>
              <span className="w-fit font-[700] text-[20px] text-[#2485FF] lowercase">
                {experienceLevel} of work experience
              </span>
            </div>
          </div>
        )}

        <div className="w-full h-[101px] rounded-[20px] text-[#FFFFFF] p-2  bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] flex flex-row gap-4 justify-center items-center">
          <div className="flex flex-row items-end h-full mt-3 overflow-hidden w-fit">
            <img
              className="w-[86px] h-[76px] mobile:w-[50px] mobile:h-[30px] small:w-[60px] small:h-[40px]  "
              src={require("../../../assets/navbar-logos/oreview-fulltime-offer-logo.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="font-[700] text-[30px] small:text-[24px] mobile:text-[20px] normal-case	">
            You can have an oppurtunity to work{" "}
            {isContract === "Yes, it will become full time"
              ? "full-time"
              : "part-time"}
          </div>
        </div>

        <div className="flex flex-row small:flex-wrap small:gap-4 w-fit gap-10 py-4">
          <div className="flex flex-col gap-2  h-fit">
            <div className="font-[700] text-[22px] text-[#352F36]">
              COW {paymentType === "Hourly" ? "Per hour" : paymentType}
            </div>
            <div className="flex flex-row gap-5 pt-2">
              <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center">
                <AttachMoneyIcon size={24} style={{ color: "white" }} />
              </div>
              <div className="min-w-[150px] w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-3 items-center justify-start">
                <div className="flex flex-row gap-1 items-center justify-center w-full">
                  {costOfWork}{" "}
                  <img
                    className="w-[9px] h-[18px]"
                    src={require("../../../assets/navbar-logos/doller-icon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2  h-fit">
            <div className="font-[700] text-[22px] text-[#352F36]">
              Fixed project budget
            </div>
            <div className="flex flex-row gap-5 pt-2">
              <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center">
                <AttachMoneyIcon size={24} style={{ color: "white" }} />
              </div>
              <div className="min-w-[150px] w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-3 items-center justify-start">
                <div className="flex flex-row gap-1 items-center justify-center w-full">
                  {projectBudget}{" "}
                  <img
                    className="w-[9px] h-[18px]"
                    src={require("../../../assets/navbar-logos/doller-icon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid place-items-center h-fit">
          <NextButton
            bgActive={true}
            buttonName={"post"}
            nextHandler={postAJObApi}
          />
        </div>
      </div>
    </div>
  );
}
