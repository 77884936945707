import React, { useState } from 'react'
import TuneIcon from "@mui/icons-material/Tune";

export default function FilterStatus() {
  return (
    <div className='card min-h-[100px] rounded-[14px]  px-4'>
          <div className="flex flex-row items-center gap-2 py-4 border-b border-[#414B5A] border-opacity-[20%] ">
              <TuneIcon style={{fontSize:"18px"}} />
              <span className="font-[700] text-[18px] text-[#352F36]">
                Filters
              </span>
            </div>
            <Skills />
    </div>
  )
}
const Skills = () => {
    const options=[
      "open",
      "closed",
      "in-bid",
      "in discussion",
     
    ];
  const [filterSkills, setFilterSkill]=useState([])
    return (
      <div className="flex flex-col">
        <div className="font-[700] text-[20px] text-[#352F36]">Status</div>
        <div className="flex flex-col gap-4 py-4">
          {options.map((option, index) => (
            <div key={index} className="flex flex-row gap-2 items-center">
              <input
                className="w-[16px] input-bg h-[22px] rounded-[12px]"
                type="checkbox"
                value={option}
                checked={filterSkills && filterSkills.includes(option)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterSkill([...filterSkills, e.target.value]);
                  } else {
                    const arr = filterSkills.filter(
                      (item) => item !== e.target.value
                    );
                    setFilterSkill(arr);
                  }
                }}
              />
              <label className="font-[400] text-[20px] text-[#352F36] capitalize">
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };