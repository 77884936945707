import React from "react";

export default function SelectInput({
  list,
  selectedValue,
  setSelectedValue,
  labelName,
}) {
  const inputHandler = (e) => {
    if (e.target.value !== "select") {
      setSelectedValue(e.target.value);
    } else {
      setSelectedValue(null);
    }
  };
  return (
    <div className=" rounded-[20px] w-full flex flex-col gap-3">
      <div className="text-[22px] font-medium leading-[29.7px]">
        {labelName}
      </div>
      <select
        value={selectedValue}
        onChange={(e) => inputHandler(e)}
        className={`w-full ${
          selectedValue === "" ||
          (selectedValue === "Select" && "w-full text-gray-400 opacity-[50%]")
        }focus:text-black capitalize dropdown-arrow text-[18px]   leading-[29.7px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-[500px] h-[64px] outline-none focus:outline-none rounded-[20px] px-4 bg-normal`}
      >
        <option value="select" className="text-gray-400 opacity-[50%] w-full">
          select
        </option>
        {list &&
          list.map((key, index) => (
            <option
              value={key === null ? key : undefined}
              key={index}
              className="text-black opacity-[100%]"
            >
              {key}
            </option>
          ))}
      </select>
    </div>
  );
}
