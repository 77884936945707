const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
  };


export const displayDateView=(date)=>{
  const specificDate = parseDate(date);
  const currentDate = new Date();

  const diffTime = Math.abs(currentDate - specificDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
if (diffDays===1) {
  return "1 day ago"
}
else if (diffDays===2) {
  return "2 days ago"
}
else if (diffDays===2) {
  return "3 days ago"
}
else if (diffDays===2) {
  return "4 days ago"
}
else if (diffDays===2) {
  return "5 days ago"
}
else if (diffDays>6 && diffDays<30) {
  return "a week ago"
}
else if (diffDays>30 && diffDays<365) {
  return "a month ago"
}

else{

  let dif=diffDays/365
  return parseInt(dif)+ " years ago"
}   
  }
 
 export const displayWeekView=(startDate,endDate)=>{
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    
    // Calculate the difference in time
    const diffTime = Math.abs(end - start);
    // Convert the difference in time to days
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const weeks=(parseInt(diffDays/7));
    const days=(diffDays%7);
return weeks+(weeks>1?" Weeks":" Week")+", "+days+" days"; 
  }