import React from "react";
import { Avatar } from "ui-neumorphism";

export default function BenifitsOfFreelancer() {
  const options = [
    {
      name: "Access to top-tier clients",
    },
    {
      name: "Streamlined Project Management",
    },
    {
      name: "Customized Solutions",
    },
    {
      name: "Global Reach",
    },
    {
      name: "Efficient Communication",
    },
    {
      name: "Enhanced Visibility",
    },
  ];

  return (
    <div className="flex flex-col gap-1 px-10 p-4 items-start">
      <div className="grid grid-cols-[39%_59%] ipadMax:flex ipadMax:flex-col-reverse ipadMin:flex ipadMin:flex-col-reverse mobile:flex mobile:flex-col-reverse place-items-center gap-10 w-full">
        <div className="relative w-full h-full grid place-items-center p-10">
          <div className="relative w-full h-full">
            <div className="absolute mobile:-right-8 right-0 mobile:-mt-10 largeScreen:-mt-2 laptop:mr-4 largeScreen:mr-24 w-[256px] h-[68px] rounded-[15px] bg-[#E3EDF7] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] flex flex-row gap-4 px-4 items-center justify-center">
              <Avatar
                src={require("../../assets/defaultScreen/benifits-freelancer-payment-logo.png")}
              />
              <div className="flex flex-col gap-1 w-fit">
                <h1 className="text-[#191A15] font-[500] text-[16px]">
                  Payment
                </h1>
                <h1 className="text-[#A6A6A6] font-[500] text-[12px]">
                  Secure payment
                </h1>
              </div>
              <Avatar
                size={35}
                src={require("../../assets/defaultScreen/benifits-freelancer-payment-icon.png")}
              />
            </div>
            <div className="absolute mobile:hidden ipadMin:hidden ipadMax:hidden left-0 mt-36 mb-4 laptop:-ml-6 largeScreen:ml-10 w-[102px] h-[102px] rounded-[30px] bg-[#2485FF] grid place-items-center">
              <img
                src={require("../../assets/defaultScreen/fa-icon-2.png")}
                alt=""
              />
            </div>
          </div>

          <div className="p-2 h-full rounded-[15px]">
            <img
              src={require("../../assets/defaultScreen/benifit-freelancer-logo.png")}
              alt=""
            />
          </div>
          <div className="relative w-full h-full">
            <div className="absolute mobile:hidden  ipadMin:hidden ipadMax:hidden right-0 -mt-24 laptop:-mt-19 laptop:-mr-6 mr-10 w-[102px] h-[102px] rounded-[15px] bg-[linear-gradient(212.87deg,_#FF716A_23.4%,_#FF2731_251.87%)] grid place-items-center">
              <img
                src={require("../../assets/defaultScreen/fa-icon-3.png")}
                alt=""
              />
            </div>
            <div className="absolute left-0 -mt-16 -ml-10 mobile:mt-0 w-[256px] h-[68px] rounded-[15px] bg-[#E3EDF7] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] flex flex-row gap-4 px-4 items-center justify-center">
              <Avatar
                src={require("../../assets/defaultScreen/benifits-freelancer-visibility-logo.png")}
              />
              <div className="flex flex-col gap-1 w-fit">
                <h1 className="text-[#191A15] font-[500] text-[16px]">
                  Visibility
                </h1>
                <h1 className="text-[#A6A6A6] font-[500] text-[12px]">
                  Stand out in Crowd
                </h1>
              </div>
              <img
                className="w-[35px] h-[35px]"
                src={require("../../assets/defaultScreen/benifits-freelancer-visibility-icon.png")}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-[500] text-[50px] leading-[67.5px] text-[#352F36]">
            Benefits of freelancers & companies
          </div>
          <div className="text-[#414B5A] opacity-[50%] w-[90%] font-[400] text-[26px]">
            Discover How Collaboration Drives Growth and Innovation.
          </div>
          <div className="flex flex-col gap-5">
            {options.map((item, index) => (
              <div key={index} className="flex flex-row gap-5 items-center">
                <img
                  className="w-[41px] h-[36px]"
                  src={require("../../assets/defaultScreen/benifit-freelancer-tick.png")}
                  alt=""
                />
                <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33] border-[1.2px] border-[#ffffff] px-6 p-2 rounded-[15px] w-fit flex flex-row gap-2 items-center">
                  <h1 className="text-[#414B5A] font-[500] text-[23px]">
                    {item.name}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
