import React from "react";
import { Avatar } from "ui-neumorphism";

import { BiSolidFilePdf } from "react-icons/bi";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NextButton from "../Button/NextButton";
import LogoIndexedView from "./LogoIndexedView";

export default function ProjectsPreview({ project }) {
  const assignHandler = () => {};

  const downloadFiles = (byteCode) => {
    if (byteCode) {
      const byteCharacters = atob(byteCode);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      // a.download = `file${index + 1}.bin`; // Adjust the file extension as needed
      document.body.appendChild(a);
      a.click();

      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }, 100);
    }
  };
  return (
    <div className="w-full col-span-2 flex flex-col gap-10">
      <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full h-fit p-4 flex flex-row justify-between rounded-[20px]">
        <div className="flex flex-row gap-3">
          <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit p-2 rounded-full">
            <Avatar
              src={require("../../assets/projects_logos/health_fitness.png")}
            />
          </div>
          <div className="flex flex-col">
            <span className="font-[700] text-[20px] text-[#352F36] capitalize">
              {project?.projectName}
            </span>
            <span className="text-[#414B5A] font-[500] text-[14px] opacity-[50%]">
              Posted on {project?.postedDate}
            </span>
          </div>
        </div>
        <LogoIndexedView />
      </div>

      <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full h-fit p-4 py-8 flex flex-col gap-5 justify-between rounded-[20px]">
        <div className="flex flex-col gap-2">
          <div className="font-[700] text-[24px] text-[#352F36]">
            Project brief:
          </div>
          <div className="font-[400] text-[18px] text-[#352F36] leading-[24.3px]">
            {project?.projectDescription}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-4">
            <div>
              <div className="font-[500] text-[22px] text-[#352F36]">
                Attach document
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center">
                <BiSolidFilePdf size={24} color="white" />
              </div>
              <div className="min-w-[296px] capitalize w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-1 items-center justify-start">
                <div className="w-[16.17px] h-[20px]">
                  <img
                    src={require("../../assets/navbar-logos/document-icon.png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <div onClick={() => downloadFiles(project?.projectFile)}>
                  {project?.projectName}...pdf
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-[700] text-[24px] text-[#352F36]">
            Skills required
          </div>
          <div className="flex flex-row flex-wrap gap-4">
            {project?.skills &&
              project.skills.map((skill, _) => (
                <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-fit rounded-[40px] p-4 px-6 flex flex-row gap-2 items-center justify-center">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#2485FF]"></div>
                  <div className="font-[500] text-[18px] text-[#352F36] ">
                    {skill}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {project?.needACompanyToWork && (
          <div className="flex flex-col gap-2">
            <div className="font-[700] text-[24px] text-[#352F36]">Add on</div>
            <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] p-4 px-6 rounded-[20px] w-full font-[500] text-[18px] text-[#352F36] leading-[24.3px]">
              We need a company to work with{" "}
              <span className="font-[700] text-[#2485FF]">
                {project?.companySize}
              </span>{" "}
              size of employees, with{" "}
              <span className="font-[700] text-[#2485FF]">
                {project?.experience} level of work experience.
              </span>
            </div>
          </div>
        )}

        <div className="w-full h-fit rounded-[20px] text-[#FFFFFF]  bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] flex flex-row gap-4 justify-center items-center">
          <div className="flex flex-row items-end h-full w-fit pt-4">
            <img
              className="w-[102px] h-[50px]"
              src={require("../../assets/navbar-logos/oreview-fulltime-offer-logo.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="font-[700] text-[22px] normal-case	">
            You can have an oppurtunity to work {project?.projectType}{" "}
            {/* {isContract === "Yes, it will become full time"
           ? "full-time"
           : "part-time"} */}
          </div>
        </div>

        <div className="flex flex-row w-fit gap-10 py-4">
          <div className="flex flex-col gap-2  h-fit">
            <div className="font-[700] text-[22px] text-[#352F36]">
              COW per hour
            </div>
            <div className="flex flex-row gap-5 pt-2">
              <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center">
                <AttachMoneyIcon size={24} style={{ color: "white" }} />
              </div>
              <div className="min-w-[150px] w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-3 items-center justify-start">
                <div className="flex flex-row gap-1 items-center justify-center w-full">
                  {project?.projectCost}{" "}
                  <img
                    className="w-[9px] h-[18px]"
                    src={require("../../assets/navbar-logos/doller-icon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2  h-fit">
            <div className="font-[700] text-[22px] text-[#352F36]">
              Fixed project budget
            </div>
            <div className="flex flex-row gap-5 pt-2">
              <div className="w-[64px] h-[64px] rounded-[20px] bg-[#2485FF] grid place-items-center">
                <AttachMoneyIcon size={24} style={{ color: "white" }} />
              </div>
              <div className="min-w-[150px] w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF] flex flex-row gap-3 items-center justify-start">
                <div className="flex flex-row gap-1 items-center justify-center w-full">
                  {project?.projectCost}{" "}
                  <img
                    className="w-[9px] h-[18px]"
                    src={require("../../assets/navbar-logos/doller-icon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full grid place-items-center h-fit">
          <NextButton
            bgActive={true}
            buttonName={"Assign"}
            nextHandler={assignHandler}
          />
        </div>
      </div>
    </div>
  );
}
