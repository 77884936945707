import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { Avatar, IconButton } from "ui-neumorphism";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

import Spinner, {
  apiUrl,
  clientId,
  handleBase64Change,
  token,
  hoverBg,
} from "../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import notificationImage from "../assets/noticationimage.png";
import editIcon from "../assets/edit (2).png";
import downloadIcon from "../assets/download.png";
import DatePicker from "../utils/DatePicker";
import { ClientIdContext, DashBoardContext, TokenContext } from "../App";
import toast from "react-hot-toast";
import InnerShadowAvatar from "../utils/InnerShadowAvatar";
import dummyImage from "../assets/noticationimage.png";

import googleMeetLogo from "../assets/team.png";
import groupMeetLogo from "../assets/video.png";
import InboxIcon from "@mui/icons-material/Inbox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ProjectActivities from "./ProjectActivities";
export default function ClientDashBoard() {
  const [isOpen, setIsOpen] = useState(false);
  const [isProjectCostingSelection, setProjectCostingSelection] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState("Daily");
  const [projectCostingSelectionDuration, setProjectCostingSelectionDuration] =
    useState("Daily");
  const dropdownRef = useRef(null);
  const [isAll, setIsAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("All");
  const location = useLocation();
  const [showProjectActivities, setShowProjectActivities] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentProjects, setCurrentProjects] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedTab, setSelectedTab] = useState("dashboard");

  const [calls, setCalls] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const { showDate, setShowDate, selectDate, setSelectDate } =
    useContext(DashBoardContext);
  const [loading, setLoading] = useState(false);
  const messages = [
    {
      id: 1,
      name: "John Adams",
      time: "7 mins ago",
      text: "I have completed mod 1, can you please review...",
      image: dummyImage,
    },
    {
      id: 2,
      name: "Jane Doe",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 3,
      name: "Perry",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 4,
      name: "Alysha ",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...?",
      image: dummyImage,
    },
    {
      id: 5,
      name: "Sarah",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 6,
      name: "Lionel",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...",
      image: dummyImage,
    },
    {
      id: 7,
      name: "Cristiano",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 8,
      name: "Micheal",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...",
      image: dummyImage,
    },

    // Add more messages as needed
  ];
  const projects = [
    // Example data, replace this with your actual data
    {
      id: 1,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    { id: 2, name: "E-commerce", cost: 2000, status: "paid", progress: "100%" },
    {
      id: 3,
      name: "Logo design",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    { id: 4, name: "Lms", cost: 2000, status: "paid", progress: "100%" },
    {
      id: 5,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    {
      id: 6,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
    { id: 7, name: "Lms", cost: 2000, status: "pending", progress: "75%" },
    {
      id: 8,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
    {
      id: 9,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    {
      id: 10,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
  ];
  const displayedMessages = showMessages ? messages : messages.slice(0, 4);
  const handleRowClick = (projectId, startDate, endDate) => {
    navigate(`/project`, { state: { startDate, endDate } });
  };
  const handleIconClick = () => {
    setShowProjectActivities(!showProjectActivities);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const handleProjectCosting = (option) => {
    setProjectCostingSelectionDuration(option);
    setProjectCostingSelection(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const itemsPerPage = 5; // Number of items per page

  const filteredProjects = useMemo(() => {
    return projects.filter((project) => {
      const matchesSearchTerm =
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.progress.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus =
        selectedOptions === "All" ||
        (selectedOptions === "Completed" && project.progress === "100%") ||
        (selectedOptions === "In-Progress" && project.progress !== "100%");
      return matchesSearchTerm && matchesStatus;
    });
  }, [projects, searchTerm, selectedOptions]);

  // Update total pages and current projects based on filteredProjects and currentPage
  useEffect(() => {
    // Ensure itemsPerPage is a valid number
    if (typeof itemsPerPage !== "number" || itemsPerPage <= 0) {
      console.error(
        "Invalid itemsPerPage value. It should be a positive number."
      );
      return;
    }

    const newTotalPages = Math.ceil(filteredProjects.length / itemsPerPage);
    if (newTotalPages !== totalPages) {
      setTotalPages(newTotalPages);
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newCurrentProjects = filteredProjects.slice(startIndex, endIndex);

    if (
      JSON.stringify(newCurrentProjects) !== JSON.stringify(currentProjects)
    ) {
      setCurrentProjects(newCurrentProjects);
    }
  }, [
    filteredProjects,
    currentPage,
    itemsPerPage,
    totalPages,
    currentProjects,
  ]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setClientInfo(res.data);
        }
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(apiUrl + `/contract/getContractOverview/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setContracts(response.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    axios
      .get(apiUrl + `/calls/getByUserId/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setCalls(response.data);
        setLoader(false);
        console.log(response.data);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {}, 2000);
        setLoader(false);
      });
  }, []);

  const totalProjects = contracts.length;
  const inProgressCount = contracts.filter(
    (contract) => contract.projectStatus === "In_progress"
  ).length;
  const completedCount = contracts.filter(
    (contract) => contract.projectStatus === "Completed"
  ).length;
  const overdueCount = contracts.filter(
    (contract) => contract.projectStatus === "Overdue"
  ).length;

  const calculatePageNumbers = () => {
    if (currentPage === totalPages) {
      return [totalPages];
    }
    return [currentPage, currentPage + 1];
  };

  const pageNumbers = calculatePageNumbers();

  const filteredContracts = contracts.filter(
    (contract) =>
      contract.status === "in-progress" || contract.status === "overdue"
  );

  // Calculate the total progress value
  const totalProgress = inProgressCount + overdueCount;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const logoutHandler = () => {
    localStorage.clear();
    updateClientId("");
    updateToken("");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/");
  };
  return loader ? (
    <Spinner titleName="Please wait we getting data" />
  ) : (
    <div className="w-screen h-screen bg-[#E3EDF7]  flex flex-col gap-2 font-Satoshi">
      <div className="w-full h-fit flex flex-row bg-[#E3EDF7]  items-center mobile:gap-1 justify-between shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] px-3 py-1 ">
        <div className="w-fit h-fit gap-3 medium:gap-1 flex flex-row items-center justify-center mt-3">
          <div className="w-fit h-fit gap-3 flex flex-row items-center justify-center">
            <IconButton
              rounded
              text={false}
              style={{ width: "24px", height: "24px" }}
              size="large"
              className=" px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
              onClick={() => navigate("/client-home-view")}
            >
              <ArrowBackIcon />{" "}
            </IconButton>
            {/* <BackButton /> */}
          </div>
          <div className="w-fit h-fit medium:gap-1 gap-3 flex flex-row items-center ">
            <span
              className={`${
                selectedTab === "dashboard"
                  ? "text-white hover:scale-[1.02]  bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]  rounded-[15px] cursor-pointer"
                  : "cursor-pointer"
              } px-4 py-3 text-center mobile:text-[12px] medium:p-2 mobile:p-1 medium:text-[14px] text-[18px] font-[700] font-Sathosi`}
              onClick={() => handleTabClick("dashboard")}
            >
              My Dashboard
            </span>
            <span
              className={`${
                selectedTab === "billing"
                  ? "text-white hover:scale-[1.02] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] rounded-[15px] cursor-pointer"
                  : "cursor-pointer"
              } px-4 py-3 text-center mobile:text-[12px] medium:p-2 mobile:p-1 text-[14px] text-[18px] font-[700] font-Sathosi`}
              onClick={() => handleTabClick("billing")}
            >
              Billings and Payments
            </span>
          </div>
        </div>
        <div className="w-fit h-fit gap-3 flex flex-row items-center justify-center ">
          <IconButton
            rounded
            text={false}
            size="large"
            className="pt-5 bg-gray-200 text-gray-800 rounded shadow "
            onClick={() =>
              navigate("/notification", {
                state: { pathName: location.pathname },
              })
            }
          >
            <img src={require("../assets/notification (2).png")} />{" "}
          </IconButton>
          <div
            onClick={handleDropdownToggle}
            className="w-[40px] h-[40px] mobile:w-[40px] mobile:h-[40px] rounded-[50%] shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] flex flex-row items-center justify-center"
          >
            <Avatar
              className="w-[25px] h-[25px]"
              bgColor="--white"
              src={
                clientInfo.profileImage
                  ? handleBase64Change(clientInfo.profileImage)
                  : require("../assets/noimage.png")
              }
            />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-20 right-5 medium:top-12  z-50  bg-[#E3EDF7] w-[200px]  shadow-[8px_8px_7px_#a6aabc,-8px_-8px_7px_#FAFBFF] p-4 rounded-[15px] py-2">
              <p
                className="px-1 py-3  border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10"
                onClick={() => navigate("/edit-profile")}
              >
                Edit Profile
              </p>
              <p
                className="px-1 py-3 border-b border-[#414B5A] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
                onClick={() => navigate("/clientDashboard")}
              >
                Dashboard
              </p>

              <p
                className="px-1 py-3 text-[red] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
                onClick={logoutHandler}
              >
                <ExitToAppIcon /> Sign Out
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-[#E3EDF7] h-[100%] p-3 overflow-y-auto block scrollbar">
        {selectedTab === "dashboard" ? (
          <div className="w-full bg-[#E3EDF7] h-[100%] medium:h-fit items-center justify-center  flex flex-row medium:flex-col gap-10 p-5">
            <div className="w-[70%] medium:w-[100%] medium:h-fit  bg-[#E3EDF7] h-[100%] flex flex-col gap-5">
              <div className="w-full h-fit p-3 grid grid-cols-5 small:grid-cols-3 mobile:grid-cols-2  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] gap-5 items-center justify-center">
                <img
                  src={
                    clientInfo.profileImage
                      ? handleBase64Change(clientInfo.profileImage)
                      : require("../assets/noimage.png")
                  } // Path to your Google logo image
                />
                <span className="flex flex-col w-fit gap-2 items-start">
                  <span className="text-[20px] font-[700] capitalize">
                    {clientInfo.name}
                  </span>
                  <span className="text-[14px] text-[#414B5A] font-[500]">
                    P2H Solutions
                  </span>
                </span>

                <div
                  className={`w-full text-[14px] p-2 hover:scale-[1.02] hover:text-[blue] cursor-pointer transform transition-transform duration-400 ease-in-out flex flex-row items-center justify-between rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] text-[#414B5A] font-[500]`}
                  onClick={() => setShowMessages(true)}
                >
                  <div
                    className={`w-full text-[14px] p-3  flex flex-row items-center justify-between rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] text-[#414B5A] hover:text-[#2485FF] font-[500]`}
                    onClick={() => setShowMessages(true)}
                  >
                    Inbox <InboxIcon />
                  </div>
                </div>
                <div className="w-full text-[14px] p-2 hover:scale-[1.02] hover:text-[#2485FF] transform transition-transform duration-400 ease-in-out  flex flex-col   items-center justify-between rounded-[15px]    font-[500]">
                  <div className="w-full text-[14px] p-2  flex flex-row items-center justify-between rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]  font-[500] ">
                    <button
                      className={`w-full text-[14px]  p-3.5 focus:outline-none pr-7 bg-[#E3EDF7] flex flex-row items-center justify-between rounded-[15px]   font-[500] ${
                        isOpen
                          ? "text-[#2485FF] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
                          : "text-[black] hover:text-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                      } `}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {selectedOption}
                      <svg
                        className={`w-5 h-5 ml-2 transition-transform transform ${
                          isOpen ? "rotate-180 text-[#2485FF]" : "rotate-0"
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {isOpen && (
                    <div className="relative bg-slate-100 w-fit h-fit bg-opacity-[10%] bottom-[0]  flex flex-row items-start justify-start">
                      <div className="absolute w-fit h-fit  p-5   rounded-[15px] shadow-lg bg-[#E3EDF7] ">
                        {["Daily", "Weekly", "Monthly"].map((option) => (
                          <div
                            key={option}
                            className="p-3.5 border-b border-opacity-50  border-[#414B5A] text-[#414B5A]  hover:text-[#2485FF] cursor-pointer"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full text-[14px] rounded-[15px] text-[#414B5A] font-[500]">
                  <div className="hover:scale-[1.02] hover:text-[#2485FF] p-2 transform transition-transform duration-400 ease-in-out flex flex-row items-center justify-between rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] w-full">
                    <div
                      onClick={() => setShowDate(true)}
                      className="w-full cursor-pointer hover:scale-[1.02] hover:text-[#2485FF] text-[14px] p-3  flex flex-row items-center justify-between rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] text-[#414B5A] font-[500]"
                      title="select a date"
                    >
                      {selectDate.day +
                        "-" +
                        selectDate.month +
                        "-" +
                        selectDate.year}
                      <CalendarMonthIcon />
                    </div>
                  </div>
                  {showDate && (
                    <div className="fixed bg-slate-100 w-screen h-screen bg-opacity-[20%] top-[0%] left-0 flex flex-row justify-center p-10 pl-20">
                      <div className="absolute w-fit h-fit">
                        <DatePicker />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full h-fit grid grid-cols-3  mobile:grid-cols-1 medium:grid-cols-2 p-3 gap-3  rounded-[15px]  border-[1.2px]   border-[solid] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset]">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="w-full hover:scale-[1.02] p-2  flex flex-col rounded-[15px]  justify-between  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                    <div className="w-full flex flex-col gap-5 p-4">
                      <div className="flex flex-col gap-1 items-center w-fit">
                        <p className="w-fit h-fit text-[16px] font-[500] pr-2">
                          Total projects
                        </p>
                        <p className="text-[60px] font-[700] -ml-4">
                          {totalProjects}
                        </p>
                      </div>
                      <div className="w-full grid grid-cols-[repeat(2,1fr)] justify-between items-center gap-5">
                        <div className="flex flex-row w-fit gap-3">
                          <div className="w-fit text-[22px] text-[#FF5F1B] font-[700]">
                            {inProgressCount}
                          </div>
                          <div className="w-fit flex flex-col  capitalize text-[12px] font-[500]">
                            <p className="">projects</p>
                            <p className="text-[gray]">Inprogress</p>
                          </div>
                        </div>
                        <div className="flex flex-row w-fit gap-3">
                          <div className="w-fit text-[22px] text-[#089949] font-[700]">
                            {completedCount}
                          </div>
                          <div className="w-fit flex flex-col  capitalize text-[12px] font-[500] ">
                            <p className="">projects</p>
                            <p className=" text-[gray]">Completed</p>
                          </div>
                        </div>{" "}
                        <div className="flex flex-row w-fit gap-3">
                          <div className="w-fit text-[22px] text-[#FF2731] font-[700]">
                            {overdueCount}
                          </div>
                          <div className="w-fit flex flex-col capitalize text-[12px] font-[500] ">
                            <p className="">projects</p>
                            <p className=" text-[gray]">Overdue</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="w-full rounded-b-[15px]"
                      src={require("../assets/Graph.png")} // Path to your Google logo image
                    />
                  </div>
                )}
                <div className="w-full hover:scale-[1.02] p-2  flex flex-col gap-10 p-3  bg-[#2485FF]  rounded-[15px] items-center justify-between shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                  <div className="w-full items-center h-fit flex flex-row justify-between">
                    <span className="w-[80%] h-fit text-[white] text-[20px] font-[700]">
                      Companies working for your project
                    </span>
                    <span className="w-[15%] bg-blue-500 items-center justify-center flex p-2 px-4  rounded-[40px] text-[white] text-[24px] font-[700] ">
                      {totalProgress}
                    </span>
                  </div>
                  {filteredContracts.map((contract) => (
                    <div
                      className="w-full items-center h-fit flex flex-row justify-between "
                      key={contract.contractId}
                    >
                      <div className="w-[50%] items-center h-fit flex flex-row gap-2">
                        <div className="w-fit h-fit rounded-[50%] p-[2px] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                          <img
                            src={handleBase64Change(contract.projectFile)} // Path to your Google logo image
                          />
                        </div>
                        <p className="w-fit h-fit text-[16px] font-[700] text-[white]">
                          {contract.username}
                        </p>
                      </div>
                      <div className="w-[50%] items-center justify-end h-fit flex flex-row gap-2">
                        <p className="w-fit h-fit text-[16px] font-[700] text-[#61DD1E]">
                          {contract.progress} %
                        </p>
                        <ArrowDropUpIcon className="text-[#61DD1E]" />
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => navigate("/project-duration")}
                  className="cursor-pointer hover:scale-[1.02] w-full flex flex-col justify-center items-center gap-10 h-[100%] bg-[#414B5A] rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] "
                >
                  <div className=" w-full  h-[100%] bg-[#2C2F33] pb-10 flex items-center justify-center mt-[10] rounded-t-[15px] rounded-bl-[150px] rounded-br-[150px]">
                    <p className="w-full text-center text-[22px] font-[500] text-[white] ">
                      {" "}
                      Post your new project here
                    </p>
                  </div>
                  <div className="w-full  h-full flex items-center justify-center">
                    <div className="w-[93px] h-[93px] rounded-[50%] p-5  bg-[#2C2F33] flex items-center justify-center">
                      <img
                        src={require("../assets/dashboardpostproject.png")} // Path to your Google logo image
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-fit p-3  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-center">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="w-full h-fit flex flex-row gap-5 ">
                    <table className="w-[95%] h-fit ">
                      <thead className="w-full pb-3">
                        <tr className="w-full border-none h-fit text-[14px] font-[500]  grid grid-cols-5 px-2 pb-3 gap-5  items-center  ">
                          <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                            Recent Projects
                          </th>
                          <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                            Project Started
                          </th>
                          <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                            Team Name
                          </th>
                          <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                            Due Date
                          </th>
                          <th className="w-full rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <tbody className=" max-h-60 overflow-y-auto block scrollbar rounded-[15px] border py-4 border-white shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        {contracts.map((contract) => (
                          <tr
                            className="w-full border-none hover:bg-slate-600 hover:bg-opacity-[20%] cursor-pointer hover:scale-[1.01] h-fit text-[12px] font-[500]  grid grid-cols-5 px-2 gap-5  items-center  "
                            key={contract.contractId}
                            onClick={() =>
                              handleRowClick(
                                contract.projectId,
                                contract.startDate,
                                contract.endDate
                              )
                            }
                          >
                            <td
                              className="w-full flex h-fit p-3 items-center justify-start truncate gap-2 text-center"
                              title={contract.projectName}
                            >
                              <InnerShadowAvatar
                                imgSrc={dummyImage}
                                size={20}
                              />
                              {contract.projectName}
                            </td>
                            <td className="w-full flex rounded-[15px] items-center justify-center h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]  text-center">
                              {contract.startDate}
                            </td>
                            <td
                              className="w-full flex h-fit p-3 items-center justify-start truncate gap-2 text-center"
                              title={contract.username}
                            >
                              <InnerShadowAvatar
                                imgSrc={dummyImage}
                                size={20}
                              />
                              {contract.username}
                            </td>

                            <td className="w-full flex rounded-[15px] items-center justify-center h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]  text-center">
                              {contract.endDate}
                            </td>

                            <td
                              className={`w-[100%] flex h-fit p-3 items-center justify-center rounded-[15px] text-center ${
                                contract.projectStatus === "Overdue"
                                  ? "bg-red-500 text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                                  : contract.projectStatus === "In_progress"
                                  ? "bg-[#FF5F1B] text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                                  : contract.projectStatus === "Completed"
                                  ? "bg-[#089949] text-white shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                                  : ""
                              }`}
                            >
                              {contract.projectStatus}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="w-[5%] h-full " onClick={handleIconClick}>
                      <IconButton
                        text={false}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "15px",
                        }}
                        size="large"
                      >
                        <img
                          src={require("../assets/messages2.png")}
                          className="w-[34px] h-[34px] p-1"
                          alt="Messages"
                        />
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full h-fit p-5 mobile:flex-col rounded-[15px] flex flex-row gap-5 items-center justify-center">
                <div className="w-[50%] hover:scale-[1.02] mobile:w-[100%] h-fit rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                  <div className="w-full px-5 h-fit flex flex-row mobile:flex-row medium:flex-col medium:items-start  items-center  justify-between">
                    <h1 className="w-fit text-[26px] font-[700] h-fit ">
                      Project costing
                    </h1>

                    <div className="w-fit text-[14px] p-2    flex flex-row items-center justify-between rounded-[15px]    font-[500] ">
                      <button
                        className={`w-full text-[14px]  p-3.5 focus:outline-none pr-7 bg-[#E3EDF7] flex flex-row items-center justify-between rounded-[15px]   font-[500] ${
                          isProjectCostingSelection
                            ? "text-[#2485FF] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
                            : "text-[black] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                        } `}
                        onClick={() =>
                          setProjectCostingSelection(!isProjectCostingSelection)
                        }
                      >
                        {projectCostingSelectionDuration}
                        <svg
                          className={`w-5 h-5 ml-2 transition-transform transform ${
                            isOpen ? "rotate-180 text-[#2485FF]" : "rotate-0"
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      {isProjectCostingSelection && (
                        <div className="relative bg-slate-100 w-fit h-fit bg-opacity-[10%] bottom-[0]  flex flex-row items-start justify-start">
                          <div className="absolute w-fit h-fit  p-5   rounded-[15px] shadow-lg bg-[#E3EDF7] ">
                            {["Daily", "Weekly", "Monthly"].map((option) => (
                              <div
                                key={option}
                                className="p-3.5 border-b border-opacity-50  border-[#414B5A] text-[#414B5A]  hover:text-[#2485FF] cursor-pointer"
                                onClick={() => handleProjectCosting(option)}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full p-5 h-fit flex flex-row   items-center justify-between">
                    <img
                      className="medium:hidden medium:hidden mobile:w-fit mobile:h-fit"
                      src={require("../assets/Analysis bar.png")} // Path to your Google logo image
                    />
                    <div className="flex flex-col gap-5 w-fit h-fit ">
                      <div className="w-fit flex flex-row gap-3 medium:gap-1 items-center ">
                        <div className="w-fit h-fit medium:p-1   p-4 bg-[#E3EDF7]  rounded-[50%] text-gray-800 flex flex-items-center  shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] ">
                          <img
                            src={require("../assets/percentage.png")} // Path to your Google logo image
                          />{" "}
                        </div>
                        <div className="flex flex-col">
                          <span className="font-[500] text-[12px] text-[#A6A6A6]">
                            Average
                          </span>
                          <span className="font-[500] text-[12px] text-[#414B5A] items-center flex flex-row gap-2">
                            <p className="text-[#414B5A] font-[700] text-[18px]">
                              60%
                            </p>
                            <ArrowDropUpIcon className="text-[#61DD1E]" />
                            <p className="flex flex-row text-[10px] text-[#61DD1E] font-[500]">
                              35%
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="w-fit flex flex-row gap-3 medium:gap-1 items-center ">
                        <div className="w-fit h-fit medium:p-1   p-4 bg-[#E3EDF7]  rounded-[50%] text-gray-800 flex flex-items-center  shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] ">
                          <img
                            src={require("../assets/dolor.png")} // Path to your Google logo image
                          />{" "}
                        </div>
                        <div className="flex flex-col">
                          <span className="font-[500] text-[12px] text-[#A6A6A6]">
                            Average
                          </span>
                          <span className="font-[500] text-[12px] text-[#414B5A] items-center flex flex-row gap-2">
                            <p className="text-[#414B5A] font-[700] text-[18px]">
                              200k
                            </p>
                            <ArrowDropUpIcon className="text-[#61DD1E]" />
                            <p className="flex flex-row text-[10px] text-[#61DD1E] font-[500]">
                              33%
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="w-fit flex flex-row gap-3 medium:gap-1 items-center ">
                        <div className="w-fit h-fit medium:p-1   p-4 bg-[#E3EDF7]  rounded-[50%] text-gray-800 flex flex-items-center  shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] ">
                          <img
                            src={require("../assets/dolor.png")} // Path to your Google logo image
                          />{" "}
                        </div>
                        <div className="flex flex-col">
                          <span className="font-[500] text-[12px] text-[#A6A6A6]">
                            Maximum
                          </span>
                          <span className="font-[500] text-[12px] text-[#414B5A] items-center flex flex-row gap-2">
                            <p className="text-[#414B5A] font-[700] text-[18px]">
                              400k
                            </p>
                            <ArrowDropUpIcon className="text-[#61DD1E]" />
                            <p className="flex flex-row text-[10px] text-[#61DD1E] font-[500]">
                              35%
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] mobile:w-[100%] h-[100%] cursor-pointer hover:scale-[1.02] h-full flex flex-col justify-center items-center gap-10 medium bg-[#414B5A] rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] ">
                  <div className=" w-full  h-[100%] bg-[#2C2F33]  flex items-center justify-center mt-[10] rounded-t-[15px] rounded-bl-[200px] rounded-br-[200px]">
                    <p className="w-[60%] text-[24px] font-[500] text-[white] ">
                      {" "}
                      Assign your projects
                    </p>
                  </div>
                  <div className="w-full  h-full flex items-center justify-center">
                    <div className="w-[93px] h-[93px] rounded-[50%] p-5  bg-[#2C2F33] flex items-center justify-center">
                      <img
                        src={require("../assets/dashboardpostproject.png")} // Path to your Google logo image
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[27%]  h-[100%]  medium:w-[100%] medium:h-fit flex flex-col gap-5">
              <div className="w-full h-fit flex flex-col gap-5  p-5  rounded-[15px]  justify-between shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                <h1 className="text-[26px] font-[500]">Project activities</h1>

                {displayedMessages.map((message) => (
                  <div
                    key={message.id}
                    className="w-full h-fit hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer hover:[box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] hover:p-2 rounded-[15px] flex flex-row justify-between"
                  >
                    <div className="w-full h-fit flex flex-row gap-4">
                      <InnerShadowAvatar imgSrc={dummyImage} size={30} />
                      <span className="w-full flex flex-col gap-2">
                        <p className="text-[14px] font-[700]">{message.name}</p>
                        <p className="text-[12px] text-[#352F36] font-[500] ">
                          {message.text}
                        </p>
                      </span>
                    </div>
                    <IconButton
                      text={false}
                      style={{
                        width: "14px",
                        height: "14px",
                        borderRadius: "10px",
                      }}
                      className="px-2 py-1"
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                    </IconButton>
                  </div>
                ))}
                {!showMessages && (
                  <div className="w-full h-fit flex items-center justify-center">
                    <button
                      onClick={() => navigate("/project")}
                      className="w-fit text-[22px] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer shadow-[5px_5px_10px_#a6aabc,-5px_-5px_15px_#FAFBFF] font-[500] font-Satoshi p-2 px-4 rounded-[15px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-white"
                    >
                      See more
                    </button>
                  </div>
                )}
              </div>

              <div className="w-full h-fit flex flex-col gap-3 p-5 rounded-[15px] justify-between shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                {calls.length === 0 ? (
                  <p>No calls scheduled</p>
                ) : (
                  <div className="flex w-full h-fit flex-col gap-5">
                    {calls.map((call) => (
                      <div
                        key={`${call.id}-${call.startTime}`}
                        className="w-full h-fit flex flex-row gap-3 items-center"
                      >
                        <div className="w-[30%]">
                          {call.type === "GROUP" ? (
                            <InnerShadowAvatar
                              imgSrc={googleMeetLogo}
                              size={30}
                            />
                          ) : (
                            <InnerShadowAvatar
                              imgSrc={groupMeetLogo}
                              size={30}
                            />
                          )}
                        </div>
                        <div className="flex flex-col w-[80%] gap-1">
                          <span className="text-[14px] font-[700]">
                            {call.startTime} - {call.endTime}
                          </span>
                          <span className="text-[12px] text-[#352F36] font-[500]">
                            {call.type === "GROUP"
                              ? `Group call on ${call.subject}`
                              : `Call scheduled with ${call.participants} on ${call.subject}`}
                          </span>
                        </div>
                        <div className="flex flex-col w-[20%] gap-1 w-fit">
                          <IconButton
                            text={false}
                            style={{
                              width: "14px",
                              height: "14px",
                              borderRadius: "10px",
                            }}
                            className="px-2 py-1"
                          >
                            <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="w-full h-fit flex items-center justify-center">
                  <button className="w-fit text-[22px] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer shadow-[5px_5px_10px_#a6aabc,-5px_-5px_15px_#FAFBFF] font-[500] font-Satoshi p-2 px-4 rounded-[15px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-white">
                    See more
                  </button>
                </div>
              </div>
            </div>
            {/* {showMessages && <Message />} */}
            {showMessages && <Message setshowMessages={setShowMessages} />}

            {showProjectActivities && (
              <div className="h-full w-full fixed items-center mt-14 flex flex-row justify-center">
                <ProjectActivities
                  setShowProjectActivities={setShowProjectActivities}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-full flex flex-col gap-3 px-5 ">
            <div className="w-full h-fit flex flex-row items-center justify-between">
              <div className="w-[70%]  flex flex-row p-2 rounded-[15px] items-center gap-10 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                <SearchIcon className="text-[#051118]" />
                <input
                  className="w-full h-fit focus:outline-none bg-[#E3EDF7] text-[18px] font-[500]"
                  placeholder="Search by project name, progress"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="w-[25%] bg-[white] flex flex-row p-2 rounded-[15px] items-center gap-10 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                <button
                  className={`w-full text-[14px] p-1 focus:outline-none pr-7 flex flex-row items-center justify-between rounded-[15px] font-[500]`}
                  onClick={() => setIsAll(!isAll)}
                >
                  {selectedOptions}
                  <svg
                    className={`w-5 h-5 ml-2 transition-transform transform ${
                      isAll ? "rotate-180 text-[#2485FF]" : "rotate-0"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {isAll && (
                  <div className="w-fit h-fit absolute  rounded-[15px] bg-[white] shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF]">
                    {["All", "In-Progress", "Completed"].map((option) => (
                      <div
                        key={option}
                        className="p-2 border-b border-opacity-50 border-[#414B5A] text-[#414B5A] hover:text-[#2485FF] cursor-pointer"
                        onClick={() => {
                          setSelectedOptions(option);
                          setIsAll(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <table className="w-full h-fit flex flex-col gap-3">
              <thead className="w-full bg-[white] rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                <tr className="w-full bg-[white] rounded-[15px] border-none h-fit text-[16px] font-[500] grid grid-cols-6 p-1 px-4 gap-10 items-center">
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Project name
                  </th>
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Name
                  </th>
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Progress
                  </th>
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Cost
                  </th>
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Payment status
                  </th>
                  <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                    Invoice
                  </th>
                </tr>
              </thead>
              <tbody className="w-full h-fit overflow-y-auto rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                {currentProjects.map((project) => (
                  <tr
                    key={project.id}
                    className="w-full border-b border-gray w-full border-none h-fit text-[16px] font-[500] grid grid-cols-6  px-4 gap-1 items-center"
                  >
                    <td className="w-[100%] flex flex-row  h-fit items-center text-center">
                      <div className="w-fit gap-2 h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                        <img
                          className="w-[25px] h-[25px]"
                          src={notificationImage}
                          alt="Notification"
                        />
                      </div>
                      {project.name}
                    </td>
                    <td className="w-[100%] items-center flex flex-row gap-2 h-fit text-center">
                      <div className="w-fit gap-2 h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                        <img
                          className="w-[25px] h-[25px]"
                          src={notificationImage}
                          alt="Notification"
                        />
                      </div>
                      {project.name}
                    </td>
                    <td
                      className={`w-[100%] flex rounded-[15px] items-center justify-center h-fit p-2 text-center ${
                        project.progress === "100%"
                          ? "bg-[#089949] shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                          : "bg-[#FF5F1B] shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                      } text-[white]`}
                    >
                      {project.progress}
                    </td>
                    <td className="w-[100%] flex rounded-[15px] items-center justify-center h-fit p-1 text-center">{`$${project.cost}`}</td>
                    <td
                      className={`w-[100%] flex rounded-[15px] items-center justify-center h-fit p-2 px-1 text-center ${
                        project.status === "paid"
                          ? "bg-[#089949] shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                          : "bg-[#FF5F1B] shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                      } text-[white]`}
                    >
                      {project.status}
                    </td>
                    <td className="w-[100%] flex flex-row text-[14px] rounded-[15px] items-center justify-center h-fit p-2 text-center">
                      {project.status === "paid" ? (
                        <button className="w-[100%] h-fit rounded-[10px] p-2 px-4 flex items-center shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                          <img
                            className="w-[15px] h-[15px]"
                            src={downloadIcon}
                            alt="Download"
                          />
                          <span>Download Invoice</span>
                        </button>
                      ) : (
                        <button className="w-[100%] h-fit rounded-[10px] items-center flex p-2 text-[white] bg-gradient-to-r from-indigo-800 to-sky-600  px-4  shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                          <img
                            className="w-[15px] h-[15px]"
                            src={editIcon}
                            alt="Edit"
                          />
                          <span>Create Invoice</span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex items-center justify-center flex-row gap-10">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-4 py-2 text-gray rounded-lg disabled:opacity-20"
              >
                Previous
              </button>
              <span className="text-[18px]">
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`px-4 py-2 rounded-lg ${
                      currentPage === pageNumber
                        ? "bg-blue-500 text-white"
                        : "text-gray-700"
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="px-4 py-2 text-gray rounded-lg disabled:opacity-20"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Message({ setshowMessages }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showQuickChat, setShowQuickChat] = useState(false);
  const navigate = useNavigate();
  const messages = [
    {
      id: 1,
      name: "John Adams",
      time: "7 mins ago",
      text: "I have completed mod 1, can you please review...",
      image: dummyImage,
    },
    {
      id: 2,
      name: "Jane Doe",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 3,
      name: "Perry",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 4,
      name: "Alysha ",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...?",
      image: dummyImage,
    },
    {
      id: 5,
      name: "Sarah",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 6,
      name: "Lionel",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...",
      image: dummyImage,
    },
    {
      id: 7,
      name: "Cristiano",
      time: "15 mins ago",
      text: "Can we schedule a meeting?",
      image: dummyImage,
    },
    {
      id: 8,
      name: "Micheal",
      time: "15 mins ago",
      text: "I have completed with mod 1, can you please review...",
      image: dummyImage,
    },
    // Add more messages as needed
  ];

  const filteredMessages = messages.filter(
    (message) =>
      message.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (message) => {
    setSelectedMessage(message);
    setShowQuickChat(true);
  };

  const handleCloseQuickChat = () => {
    setShowQuickChat(false);
    setSelectedMessage(null);
  };

  return (
    <div className="w-full h-full bg-white bg-opacity-60 fixed top-0 left-0 p-4 flex justify-center items-center">
      {selectedMessage ? (
        <div className="w-[60%]  medium:w-[90%] mobile:w-[90%]  h-fit bg-[#E3EDF7] rounded-[15px] p-5 flex flex-col gap-5 items-center">
          <div className="w-full shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] flex mobile:flex-col flex-row mobile:gap-3 justify-between p-3 rounded-[15px] items-center gap-10">
            <div className="w-fit  flex flex-row gap-5">
              <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] mobile:p-2 p-2">
                <img
                  className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                  src={selectedMessage.image}
                  alt="Notification"
                />
              </div>
              <div className="w-fit h-fit flex flex-col">
                <p className="text-[20px] mobile:text-[15px] font-[700]">
                  {selectedMessage.name}
                </p>
                <p className="text-[16px] mobile:text-[12px] fnt-[500] text-[#20BF55] flex flex-row gap-1">
                  online{" "}
                  <img
                    className="w-[10px] h-[10px]"
                    src={require("../assets/dot.png")}
                    alt="dot"
                  />
                </p>
              </div>
            </div>
            <div className="w-fit h-fit  flex flex-row gap-2 items-center">
              <IconButton
                style={{ width: "24px", height: "24px", borderRadius: "15px" }}
                size="large"
              >
                <img
                  src={require("../assets/edit.png")}
                  className="w-[34px] h-[34px]  p-1"
                  alt="Edit"
                />
              </IconButton>
              <IconButton
                style={{ width: "24px", height: "24px", borderRadius: "15px" }}
                size="large"
              >
                <img
                  src={require("../assets/messages2.png")}
                  className="w-[34px] h-[34px] p-1"
                  alt="Messages"
                />
              </IconButton>
              <div
                onClick={handleCloseQuickChat}
                className="w-[35px] h-[35px] rounded-[13px] pb-2 cursor-pointer flex items-center justify-center text-[34px] bg-[#FF716A] text-[white]"
              >
                &times;
              </div>
            </div>
          </div>
          <div className="w-full  max-h-60 overflow-y-auto block scrollbar  text-[18px] font-[500]  shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] flex flex-col p-3 gap-5 rounded-[15px] items-center">
            <div className="w-full h-fit flex flex-row gap-2 items-center justify-start rounded-[50%] p-3">
              <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                <img
                  className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                  src={selectedMessage.image}
                  alt="Notification"
                />
              </div>
              <div className="w-fit p-2 rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                Hello! How are you today?
              </div>
            </div>
            <div className="w-full h-fit flex flex-row gap-2 items-center justify-end rounded-[50%] p-2">
              <div className="w-fit p-2 bg-[#2485FF] rounded-[15px] text-[white] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                Hello! How are you today?
              </div>
              <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                <img
                  className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                  src={require("../assets/rohit.png")}
                  alt="Notification"
                />
              </div>
            </div>
            <div className="w-full h-fit flex flex-row gap-2 items-center justify-start rounded-[50%] p-3">
              <div className="w-fit h-fit  rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                <img
                  className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                  src={selectedMessage.image}
                  alt="Notification"
                />
              </div>
              <div className="w-fit  text-[16px] p-2  rounded-[15px] mobile:text-[12px] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                Hello! How are you today?
              </div>
            </div>
            <div className="w-full h-fit flex flex-row gap-2 items-center justify-end rounded-[50%] ">
              <div className="w-fit p-2 bg-[#2485FF] rounded-[15px] text-[white] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                Hello! How are you today?
              </div>
              <div className="w-fit shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] h-fit rounded-[50%] p-1">
                <img
                  className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                  src={require("../assets/rohit.png")}
                  alt="Notification"
                />
              </div>
            </div>
          </div>

          <div className="w-full h-fit flex flex-row gap-10 p-5 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
            <IconButton
              text={false}
              style={{ width: "24px", height: "24px", borderRadius: "15px" }}
              size="large"
              className="px-4 py-2"
            >
              <img
                className="w-[24px] h-[24px] mobile:w-[12px]  mobile:h-[18px]"
                src={require("../assets/document.png")}
                alt="Notification"
              />
            </IconButton>
            <input
              className="w-[70%] bg-[#E3EDF7] rounded-[15px] h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
              placeholder="message"
            />
            <IconButton
              text={false}
              style={{ width: "24px", height: "24px", borderRadius: "15px" }}
              size="large"
              className="px-4 py-2"
            >
              <img
                className="w-[24px] h-[24px] mobile:w-[12px]  mobile:h-[18px]"
                src={require("../assets/send.png")}
                alt="Notification"
              />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="w-[50%] medium:w-[90%] mobile:w-[90%]  h-fit bg-[#E3EDF7] rounded-[15px] p-5 flex flex-col gap-2 items-center justify-center">
          <div className="w-full flex flex-row mobile:flex-col items-center justify-between">
            <div className="w-fit mobile:w-[100%] h-fit flex flex-row  mobile:justify-between gap-2 items-center ">
              <h1 className="font-[700] text-[26px] medium:text-[20px] mobile:text-[16px] font-Satoshi">
                Messages
              </h1>
              <div className="w-[20px] h-[20px] flex items-center justify-center rounded-[50%] bg-[#FF716A] text-[white]">
                3
              </div>
            </div>
            <div className="w-fit mobile:w-[100%] h-fit flex flex-row mobile:justify-between gap-2 items-center">
              <IconButton
                text={false}
                style={{ width: "24px", height: "24px", borderRadius: "15px" }}
                size="large"
              >
                <img
                  src={require("../assets/edit.png")}
                  className="w-[34px] h-[34px] p-1"
                  alt="Edit"
                />
              </IconButton>
              <IconButton
                text={false}
                style={{ width: "24px", height: "24px", borderRadius: "15px" }}
                size="large"
              >
                <img
                  src={require("../assets/messages2.png")}
                  className="w-[34px] h-[34px] p-1"
                  alt="Messages"
                />
              </IconButton>

              <div
                className="w-[35px] h-[35px] text-[34px] pb-2 cursor-pointer  rounded-[13px] flex flex-row items-center text-center justify-center  bg-[#FF716A] text-[white]"
                onClick={() => setshowMessages(false)}
              >
                &times;
              </div>
            </div>
          </div>
          <div className="w-full mobile:py-2 flex flex-row p-3 rounded-[15px] items-center gap-10 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
            <SearchIcon className="text-[#051118]" />
            <input
              className="w-full h-fit focus:outline-none bg-[#E3EDF7] mobile:text-[12px] text-[18px] font-[500]"
              placeholder="Search for messages"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="w-[98%] p-1 flex flex-col gap-2 items-center max-h-60  overflow-y-auto block scrollbar">
            {filteredMessages.map((message) => (
              <div
                key={message.id}
                className="w-full flex flex-row rounded-[15px] items-center gap-1 cursor-pointer"
                onClick={() => handleRowClick(message)}
              >
                <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] mobile:p-1 p-3">
                  <img
                    className="w-[48px] h-[48px] mobile:w-[30px]  mobile:h-[30px]"
                    src={message.image}
                    alt="Notification"
                  />
                </div>
                <div className="flex w-full h-fit flex-col gap-1">
                  <div className="w-full h-fit items-center flex flex-row justify-between">
                    <p className="text-[20px] mobile:text-[14px] medium:text-[15px] font-[700]">
                      {message.name}
                    </p>
                    <p className="text-[14px] mobile:text-[10px] medium:text-[12px] font-[500] text-[#414B5A]">
                      {message.time}
                    </p>
                  </div>
                  <p className="text-[14px] mobile:text-[10px] medium:text-[12px] font-[500]">
                    {message.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-fit flex items-center justify-center">
            <button
              onClick={() => navigate("/message")}
              className="w-fit text-[22px] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer shadow-[5px_5px_10px_#a6aabc,-5px_-5px_15px_#FAFBFF] font-[500] font-Satoshi p-2 px-4 rounded-[15px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-white"
            >
              See more
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
