import React from 'react'

export default function SignalLightsDots() {
  return (
    <div className='w-fit flex flex-row gap-1'>
        <div className='w-[9px] h-[9px] rounded-full bg-[#EB001B]'></div>
        <div className='w-[9px] h-[9px] rounded-full bg-[#F79E1B]'></div>
        <div className='w-[9px] h-[9px] rounded-full bg-[#089949]'></div>
    </div>
  )
}
