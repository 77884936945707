import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import notificationImage from "../assets/noticationimage.png";
import dotsImage from "../assets/dots.png";
import correctWhite from "../assets/correctwhite.png";
import correctBlue from "../assets/correctblue.png";
import PostNavHeader from "../components/Post-Jobs/util/PostNavHeader";
import NextButton from "../components/Button/NextButton";
import Spinner, {
  apiUrl,
  handleBase64Change,
  hoverBg,
} from "../utils/constants";
import axios from "axios";

import toast from "react-hot-toast";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ClientIdContext, TokenContext } from "../App";
export default function Notification() {
  const [notification, setNotification] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);
  const [selectedOption, setSelectedOption] = useState("All");
  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMarkAsRead, setIsMarkAsRead] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    setLoader(true);
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setClientInfo(res.data);
        }
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      });
  }, []);
  useEffect(() => {
    setLoader(true);
    axios
      .get(apiUrl + `/notifications/statistics`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setNotification(res.data);
        }
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      });
  }, []);
  const markAllAsRead = async () => {
    setLoader(true);
    try {
      const response = await axios.put(
        apiUrl + "/notifications/markAllAsRead",
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNotifications(response.data);
      setLoader(false); // Return response data for further use
    } catch (error) {
      setLoader(false);
      console.error("Error marking notifications as read:", error);
      // Throw error to handle in calling function
    }
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Sort today's notifications: from most recent to oldest

  return loader ? (
    <Spinner titleName="Please wait we getting data" />
  ) : (
    <div className="w-screen h-screen overflow-auto bg-[#E3EDF7] font-Satoshi  flex flex-col  ">
      <PostNavHeader isBack={true} titleName="Notifications" />

      <div className="w-[100%] h-fit flex flex-col gap-5  justify-center items-center px-8 small:px-4">
        <div className="w-[100%] h-fit flex flex-row medium:flex-col gap-10 items-center justify-center py-1 px-3">
          <div className="w-[60%] medium:w-[100%] small:grid small:grid-cols-2 medium:place-items-center h-fit p-3 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-between px-8">
            <img
              className="w-[100px] h-[100px]  bg-[#E7EBF0]"
              src={
                clientInfo.profileImage
                  ? handleBase64Change(clientInfo.profileImage)
                  : require("../assets/noimage.png")
              } // Path to your Google logo image
            />
            <div className="flex w-fit flex-col  items-center">
              <div className="text-[30px] mobile:text-[20px] font-[700]">
                {clientInfo.name}
              </div>
              <div className="text-[14px] text-[#414B5A] font-[500]">
                P2H Solutions
              </div>
            </div>
            <button
              className="w-fit text-[22px] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer shadow-[5px_5px_10px_#a6aabc,-5px_-5px_15px_#FAFBFF] font-[500] font-Satoshi p-2 px-4 rounded-[15px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-white"
              onClick={() => navigate("/clientDashboard")}
            >
              Go to dashboard
            </button>
          </div>

          <div className="w-[40%] medium:w-[100%] mobile:grid small:grid gap-y-3 small:grid-cols-2 mobile:grid-cols-1  h-fit  p-3 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-center">
            <div className="w-[40%] small:w-[100%] bg-[#2485FF] text-[white] text-[14px] font-[500]   rounded-[15px] gap-2 p-2 flex flex-row  text-center justify-center items-center">
              <span className="text-[30px] mobile:text-[20px] font-[700]  pb-1">
                {notification.totalNotificationsCount}
              </span>{" "}
              Total notifications
            </div>

            <div className="w-[30%]  small:w-[100%] bg-[#089949] text-[white] text-[14px] font-[500]   rounded-[15px] gap-2 p-2 flex flex-row  text-center justify-center items-center">
              <span className="text-[30px] mobile:text-[20px] font-[700]  pb-1">
                {notification.readCount}
              </span>{" "}
              read
            </div>

            <div className="w-[30%]  small:w-[100%] bg-[#FF716A] text-[white] text-[14px] font-[500]  rounded-[15px] gap-2 p-2 flex flex-row  text-center justify-center items-center">
              <span className="text-[30px] mobile:text-[20px] font-[700]  pb-1">
                {notification.unreadCount}
              </span>{" "}
              Unread
            </div>
          </div>
        </div>

        <div className="w-[100%] flex flex-row gap-14 medium:flex-col mobile:flex-row-reverse  px-3  items-center text-[14px] font-[700] font-Satoshi ">
          <div className="w-[60%] medium:w-[100%] mobile:w-[5%] rounded-[15px] relative">
            <div className="hidden mobile:grid medium:grid-cols-2 text-right">
              <MoreVertIcon
                className="cursor-pointer"
                onClick={handleMenuClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {["All", "Unread", "RSVP", "Invites"].map((option) => (
                  <MenuItem
                    key={option}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>

            <div className="w-[100%] mobile:hidden grid grid-cols-4 rounded-[15px] gap-20 p-3 px-8 items-center shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] h-fit">
              {["All", "Unread", "RSVP", "Invites"].map((option) => (
                <div
                  key={option}
                  className="col-span-1 h-fit flex justify-center items-center"
                  onClick={() => handleOptionClick(option)}
                >
                  <div
                    className={`w-fit px-8 cursor-pointer rounded-[15px] text-center  p-2 ${
                      selectedOption === option
                        ? "text-[#2485FF] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : "shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                    }`}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-[40%] medium:w-[100%] flex flex-row gap-5">
            <div
              className="cursor-pointer hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] w-fit h-fit py-2 mobile:p-4 px-4 rounded-[15px] font-[500] mobile:text-[15px] text-[20px] items-center flex flex-row gap-2 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] "
              onClick={markAllAsRead}
            >
              {" "}
              <img
                className="mobile:hidden"
                src={require("../assets/correcttick.png")}
              />{" "}
              <span> Mark as all read</span>
            </div>

            <div className="cursor-pointer w-fit text-[22px] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer shadow-[5px_5px_10px_#a6aabc,-5px_-5px_15px_#FAFBFF] font-[500] font-Satoshi p-2 px-4 rounded-[15px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-white">
              {" "}
              Clear all <span className="mobile:hidden">&times;</span>
            </div>
          </div>
        </div>

        <div className="w-[100%] max-h-[370px] overflow-y-auto   px-4 mb-2 block scrollbar flex flex-row">
          {/* Render different content based on selectedOption */}
          {selectedOption === "All" && (
            <div className="w-[100%] flex flex-col gap-4">
              <AllNotifications />
            </div>
          )}
          {selectedOption === "Unread" && (
            <div className="w-[100%]  flex flex-col gap-4  ">
              <AllUnreadNotifications />
            </div>
          )}
          {selectedOption === "RSVP" && (
            <div className="w-[100%]  flex flex-col gap-3  ">
              <AllInvites />
            </div>
          )}

          {selectedOption === "Invites" && (
            <div className="w-[100%]  flex flex-col gap-3  ">
              <AllInvites />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
function AllNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/notifications/getUserNotifications`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setNotifications(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [clientId]);

  const todayNotifications = notifications.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() === new Date().toDateString();
  });

  const olderNotifications = notifications.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() !== new Date().toDateString();
  });

  //

  const calculateTimeDifference = (timestamp) => {
    const notificationDate = new Date(timestamp);
    const now = new Date();
    const diffMs = now - notificationDate;
    const diffMinutes = Math.floor(diffMs / 60000);
    const diffHours = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);

    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };
  const returnHomeHandler = () => {
    navigate(-1); // Navigate to the previous page
  };
  if (loading) return <Spinner>Loading notifications...</Spinner>;
  return (
    <div className="w-[100%] flex flex-col gap-4">
      {notifications.length === 0 ? (
        <div className="w-full h-full overflow-auto bg-[#E3EDF7] font-Satoshi  flex flex-col  ">
          <div className="w-full h-full grid place-items-center">
            <div className="w-fit h-fit flex flex-col items-center">
              <div>
                <img
                  className="w-[281px] h-[245px]"
                  src={require("../assets/notification_mobile-logo.png")}
                  alt=""
                />
              </div>
              <div className="font-[700] text-[45px] text-[#352F36] ">
                No notification found
              </div>
              <div className="font-[400] text-[30px] text-[#414B5A]">
                You currently have no notification. We’ll notify you when
                something new arrives!
              </div>
              <div className="py-4">
                <NextButton
                  bgActive={true}
                  buttonName="Return to homepage"
                  nextHandler={returnHomeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-[100%] h-fit flex flex-row items-center justify-between">
            <span className="text-[25px] font-[700]">Today</span>
          </div>

          {todayNotifications.length > 0 ? (
            todayNotifications.map((notif) => (
              <div
                key={notif.id}
                className={`${
                  notif.isMarkAsRead ? "opacity-50" : ""
                } w-[99%] flex rounded-[15px] items-center flex-row gap-10`}
              >
                <div className="w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          notif.senderImage
                            ? handleBase64Change(notif.senderImage)
                            : require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        {notif.body}
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-[#A6A6A6]">No Today Notifications</p>
          )}

          <span className="text-[25px] font-[700]">Older Notifications</span>

          {olderNotifications.length > 0 ? (
            olderNotifications.map((notif) => (
              <div
                key={notif.id}
                className={`${
                  notif.isMarkAsRead ? "opacity-50" : ""
                } w-[99%] flex rounded-[15px] items-center flex-row gap-10`}
              >
                <div className="w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          notif.senderImage
                            ? handleBase64Change(notif.senderImage)
                            : require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        {notif.body}
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-[#A6A6A6]">No Older Notifications</p>
          )}
        </>
      )}
    </div>
  );
}
function AllUnreadNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(apiUrl + `/notifications/unread`, {
          headers: {
            Authorization: token,
          },
        });
        setNotifications(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [clientId]);

  const returnHomeHandler = () => {
    navigate(-1); // Navigate to the previous page
  };

  const todayNotifications = notifications.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() === new Date().toDateString();
  });

  const olderNotifications = notifications.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() !== new Date().toDateString();
  });

  const calculateTimeDifference = (timestamp) => {
    const notificationDate = new Date(timestamp);
    const now = new Date();
    const diffMs = now - notificationDate;
    const diffMinutes = Math.floor(diffMs / 60000);
    const diffHours = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);

    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  if (loading) return <Spinner>Loading notifications...</Spinner>;
  return (
    <div className="w-[100%] flex flex-col gap-4">
      {notifications.length === 0 ? (
        <div className="w-full h-full overflow-auto bg-[#E3EDF7] font-Satoshi  flex flex-col  ">
          <div className="w-full h-full grid place-items-center">
            <div className="w-fit h-fit flex flex-col items-center">
              <div>
                <img
                  className="w-[281px] h-[245px]"
                  src={require("../assets/notification_mobile-logo.png")}
                  alt=""
                />
              </div>
              <div className="font-[700] text-[45px] text-[#352F36] ">
                No notification found
              </div>
              <div className="font-[400] text-[30px] text-[#414B5A]">
                You currently have no notification. We’ll notify you when
                something new arrives!
              </div>
              <div className="py-4">
                <NextButton
                  bgActive={true}
                  buttonName="Return to homepage"
                  nextHandler={returnHomeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-[100%] h-fit flex flex-row items-center justify-between">
            <span className="text-[25px] font-[700]">Today</span>
          </div>

          {todayNotifications.length > 0 ? (
            todayNotifications.map((notif) => (
              <div
                key={notif.id}
                className={`${
                  notif.isMarkAsRead ? "opacity-50" : ""
                } w-[99%] flex rounded-[15px] items-center flex-row gap-10`}
              >
                <div className="w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          notif.senderImage
                            ? handleBase64Change(notif.senderImage)
                            : require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        {notif.body}
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-[#A6A6A6]">No Today Notifications</p>
          )}

          <span className="text-[25px] font-[700]">Older Notifications</span>

          {olderNotifications.length > 0 ? (
            olderNotifications.map((notif) => (
              <div
                key={notif.id}
                className={`${
                  notif.isMarkAsRead ? "opacity-50" : ""
                } w-[99%] flex rounded-[15px] items-center flex-row gap-10`}
              >
                <div className="w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          notif.senderImage
                            ? handleBase64Change(notif.senderImage)
                            : require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        {notif.body}
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-[#A6A6A6]">No Older Notifications</p>
          )}
        </>
      )}
    </div>
  );
}
function AllInvites() {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/invitation/getInvitationsOfUser`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setInvitations(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchInvitations();
  }, [clientId, token]);

  const handleAccept = async (invitationId) => {
    try {
      await axios.put(apiUrl + `/invitation/accept/${invitationId}`, null, {
        headers: {
          Authorization: token,
        },
      });
      setInvitations(
        invitations.map((notif) =>
          notif.invitationId === invitationId
            ? { ...notif, status: "ACCEPTED" }
            : notif
        )
      );
    } catch (err) {
      setError(err);
    }
  };
  const calculateTimeDifference = (timestamp) => {
    const notificationDate = new Date(timestamp);
    const now = new Date();
    const diffMs = now - notificationDate;
    const diffMinutes = Math.floor(diffMs / 60000);
    const diffHours = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);

    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };
  const handleDecline = async (invitationId) => {
    try {
      await axios.put(apiUrl + `/invitation/decline/${invitationId}`, null, {
        headers: {
          Authorization: token,
        },
      });
      setInvitations(
        invitations.map((notif) =>
          notif.invitationId === invitationId
            ? { ...notif, status: "DECLINED" }
            : notif
        )
      );
    } catch (err) {
      setError(err);
    }
  };

  const returnHomeHandler = () => {
    navigate(-1); // Navigate to the previous page
  };
  const todayNotifications = invitations.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() === new Date().toDateString();
  });

  const olderNotifications = invitations.filter((notif) => {
    const notificationDate = new Date(notif.timestamp);
    return notificationDate.toDateString() !== new Date().toDateString();
  });
  if (loading) return <Spinner>Loading invitations...</Spinner>;

  return (
    <div className="w-[100%] flex flex-col gap-4">
      {invitations.length === 0 ? (
        <div className="w-full h-full overflow-auto bg-[#E3EDF7] font-Satoshi flex flex-col">
          <div className="w-full h-full grid place-items-center">
            <div className="w-fit h-fit flex flex-col items-center">
              <img
                className="w-[281px] h-[245px]"
                src={require("../assets/notification_mobile-logo.png")}
                alt="No invitations"
              />
              <div className="font-[700] text-[45px] text-[#352F36]">
                No invitations found
              </div>
              <div className="font-[400] text-[30px] text-[#414B5A]">
                You currently have no invitations. We’ll notify you when
                something new arrives!
              </div>
              <div className="py-4">
                <NextButton
                  bgActive={true}
                  buttonName="Return to homepage"
                  nextHandler={returnHomeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-[100%] h-fit flex flex-row items-center justify-between">
            <span className="text-[25px] font-[700]">Today</span>
          </div>
          {todayNotifications.length > 0 ? (
            todayNotifications.map((notif) => (
              <div
                key={notif.invitationId}
                className="w-[99%] flex rounded-[15px] items-center mobile:flex-col flex-row gap-5"
              >
                <div className="w-[70%] mobile:w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-2">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          handleBase64Change(notif.senderImage) ||
                          require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        you have received 1 notification
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>

                {notif.status === "PENDING" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <p
                      className="w-[50%] h-fit cursor-pointer p-3 rounded-[15px] font-[500] text-[20px] text-center justify-center items-center flex flex-row gap-2 bg-gradient-to-r from-indigo-800 to-sky-600 text-[white] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                      onClick={() => handleAccept(notif.invitationId)}
                    >
                      Accept
                      <img
                        className="text-[white]"
                        src={require("../assets/correctwhite.png")}
                        alt="Accept"
                      />
                    </p>
                    <p
                      className="w-[50%] cursor-pointer h-fit items-center rounded-[15px] p-3 font-[500] text-[20px] flex flex-row text-center justify-center gap-2 bg-[#E3EDF7] text-[#A6A6A6] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                      onClick={() => handleDecline(notif.invitationId)}
                    >
                      Decline &times;
                    </p>
                  </div>
                )}
                {notif.status === "ACCEPTED" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <div className="w-[50%]   mobile:w-[80%] p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-indigo-800 to-sky-600">
                      <p className="w-[100%]  h-fit p-3 rounded-[15px] font-[500] text-[20px] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#0829A9]">
                        Accepted
                        <img
                          className="text-[white]"
                          src={correctBlue}
                          alt="Accepted"
                        />
                      </p>
                    </div>
                  </div>
                )}
                {notif.status === "DECLINED" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <div className="w-[50%] mobile:w-[80%] p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-[#FF716A] to-[#FF2731]">
                      <p className="w-[100%] h-fit p-3 rounded-[15px] font-[500] text-[20px] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#FF716A]">
                        Declined &times;
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className=" text-[18px] text-[#A6A6A6]">
              No notifications today.
            </div>
          )}
          <div className="w-[100%] h-fit flex flex-row items-center justify-between">
            <span className="text-[25px] font-[700]">Older</span>
          </div>
          {olderNotifications.length > 0 ? (
            olderNotifications.map((notif) => (
              <div
                key={notif.invitationId}
                className="w-[99%] flex rounded-[15px] items-center mobile:flex-col flex-row gap-5"
              >
                <div className="w-[70%] mobile:w-[100%] bg-[white] h-fit mobile:flex-col flex flex-row gap-10 rounded-[15px] items-center justify-between border border-[0.5px] border-[#2485FF] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-5 py-2">
                  <div className="flex flex-row mobile:flex-col gap-3 items-center">
                    <div className="w-fit h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-2">
                      <img
                        className="w-[48px] h-[48px] rounded-[50%] object-cover"
                        src={
                          handleBase64Change(notif.senderImage) ||
                          require("../assets/noimage.png")
                        }
                        alt="Notification"
                      />
                    </div>
                    <span className="flex flex-col">
                      <p className="text-[22px] font-[700]">
                        {notif.senderName}
                      </p>
                      <p className="text-[16px] text-[#352F36] font-[500] text-[#A6A6A6]">
                        you have received 1 notification
                      </p>
                    </span>
                  </div>
                  <div className="w-fit h-fit flex flex-row gap-3 items-center justify-center">
                    <span className="text-[14px] font-[400] text-[#A6A6A6]">
                      {calculateTimeDifference(notif.timestamp)}
                    </span>
                    <img src={dotsImage} alt="Menu" />
                  </div>
                </div>

                {notif.status === "PENDING" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <p
                      className="w-[50%] h-fit cursor-pointer p-3 rounded-[15px] font-[500] text-[20px] text-center justify-center items-center flex flex-row gap-2 bg-gradient-to-r from-indigo-800 to-sky-600 text-[white] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                      onClick={() => handleAccept(notif.invitationId)}
                    >
                      Accept
                      <img
                        className="text-[white]"
                        src={require("../assets/correctwhite.png")}
                        alt="Accept"
                      />
                    </p>
                    <p
                      className="w-[50%] cursor-pointer h-fit items-center rounded-[15px] p-3 font-[500] text-[20px] flex flex-row text-center justify-center gap-2 bg-[#E3EDF7] text-[#A6A6A6] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
                      onClick={() => handleDecline(notif.invitationId)}
                    >
                      Decline &times;
                    </p>
                  </div>
                )}
                {notif.status === "ACCEPTED" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <div className="w-[50%]   mobile:w-[80%] p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-indigo-800 to-sky-600">
                      <p className="w-[100%]  h-fit p-3 rounded-[15px] font-[500] text-[20px] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#0829A9]">
                        Accepted
                        <img
                          className="text-[white]"
                          src={correctBlue}
                          alt="Accepted"
                        />
                      </p>
                    </div>
                  </div>
                )}
                {notif.status === "DECLINED" && (
                  <div className="w-[30%] mobile:w-[100%] flex flex-row gap-5">
                    <div className="w-[50%] mobile:w-[80%] p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-[#FF716A] to-[#FF2731]">
                      <p className="w-[100%] h-fit p-3 rounded-[15px] font-[500] text-[20px] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#FF716A]">
                        Declined &times;
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className=" text-[18px] text-[#A6A6A6]">
              No older notifications.
            </div>
          )}
        </>
      )}
    </div>
  );
}
