import { mdiGreaterThan, mdiLessThan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { IconButton } from "ui-neumorphism";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import DynamicBorderButton, {
  GreenBorderButton,
  OrangeBorderButton,
} from "../Button/DynamicBorderButton";
import axios from "axios";
import Spinner, { apiUrl } from "../../utils/constants";
import ViewMoreButton from "../Button/ViewMoreButton";

export default function PremiumProjects() {
  const [projects, setProjects] = useState([]);
  const [indexed, setIndexed] = useState(0);
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch projects data from your API
    axios
      .get(apiUrl + "/project/getPremiumProjects")
      .then((response) => {
        // Sort projects by budget in descending order

        setProjects(response.data);
        setLoading(false);
        console.log(response.data); // Display top 6 projects
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setLoading(false);
      });
  }, []);

  const next = () => {
    sliderRef.current.slickNext();
    if (indexed < projects.length - 1) {
      setIndexed(indexed + 1);
    }
  };

  const previous = () => {
    sliderRef.current.slickPrev();
    if (indexed > 0) {
      setIndexed(indexed - 1);
    }
  };

  const settings = {
    className:
      "slider variable-width flex flex-row w-full gap-5 bg-transparent",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const handleProjectClick = (projectId) => {
    navigate(`/default-project/${projectId}`);
  };
  const hasImage = projects.projectFile && projects.projectFile.trim() !== "";
  if (loading)
    return <Spinner titleName={"Loading premium project details..."} />;
  return (
    <div className="flex flex-col gap-6 w-full bg-transparent h-fit py-4 px-10">
      <div className="text-[#352F36] mobile:hidden text-[40px] font-[500] leading-[51.3px] text-left">
        Featured business and their <br /> premium projects
      </div>
      <div className="text-[#352F36] hidden mobile:block text-[40px] font-[500] leading-[51.3px] text-left">
        Featured business and their premium projects
      </div>

      <div className="slider-container h-fit">
        <Slider ref={sliderRef} {...settings}>
          {projects.map((project) => (
            <div key={project.projectId} className="p-2">
              <div className="card_shadow w-[306px] h-fit rounded-[36px] flex flex-col gap-7 p-4 px-6">
                <div className="flex flex-row gap-4 items-center h-fit w-full">
                  {hasImage ? (
                    <InnerShadowAvatar
                      size={30}
                      src={`data:image/png;base64,${project.projectFile}`}
                      alt={project.projectName}
                    />
                  ) : (
                    <div className="w-[50px] h-[50px] bg-blue-500 rounded-[50%] flex items-center justify-center text-white font-bold text-xl rounded">
                      {project.projectName.slice(0, 2)}
                    </div>
                  )}
                  <div>{project.projectName}</div>
                </div>
                <div className="flex flex-row gap-4 justify-between items-center h-fit w-full">
                  {project.type === "Business" ? (
                    <OrangeBorderButton
                      buttonName={"Business"}
                      color={"#FF5F1B"}
                    />
                  ) : (
                    <GreenBorderButton
                      buttonName={"Individual"}
                      color={"#089949"}
                    />
                  )}
                  <div className="font-[500] text-[24px] text-[#352F36]">
                    ${project.projectCost}
                  </div>
                </div>
                <div className="flex flex-row gap-4 items-center justify-center h-fit w-full">
                  <ViewMoreButton
                    onClickHandler={() => handleProjectClick(project.projectId)}
                    buttonName={"View more"}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="text-right w-full h-fit flex flex-row justify-between py-4">
          <div></div>
          <div className="w-fit flex flex-row gap-1">
            {projects.map((_, index) => (
              <div
                key={index}
                className={`${
                  index === indexed
                    ? "w-[48px] ease-in-out rounded-[6px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]"
                    : "w-[12px]"
                } h-[12px] bg-[#D9D9D9] rounded-full cursor-pointer`}
              ></div>
            ))}
          </div>
          <div className="w-fit flex flex-row gap-4">
            <IconButton
              rounded
              text={false}
              color={indexed === 0 ? "#D9D9D9" : "#4448B3"}
              onClick={previous}
              disabled={indexed === 0}
            >
              <Icon path={mdiLessThan} size={0.8} />
            </IconButton>
            <IconButton
              rounded
              text={false}
              color={indexed === projects.length - 1 ? "#D9D9D9" : "#4448B3"}
              onClick={next}
              disabled={indexed === projects.length - 1}
            >
              <Icon path={mdiGreaterThan} size={0.8} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}
