import React, { useState, useEffect, useContext } from "react";
import { Area, AreaChart } from "recharts";
import DoughnutChart from "./DoughnutChart";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../Button/NextButton";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import axios from "axios";

import toast from "react-hot-toast";
import { ClientIdContext, TokenContext } from "../../App";
import Spinner, { apiUrl } from "../../utils/constants";
export default function Progress() {
  const navigate = useNavigate();
  const location = useLocation();
  const [contracts, setContracts] = useState({
    totalProjectsCount: 0,
    progressCount: 0,
    completedCount: 0,
  });
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [loadingProposals, setLoadingProposals] = useState(true);
  const [proposals, setProposals] = useState({
    totalProposalsCount: 0,
    reviewedCount: 0,
    canceledCount: 0,
  });
  const [error, setError] = useState(null);

  const myProjectsHandler = () => {
    navigate("/projects-view", { state: { pathName: location.pathname } });
  };

  useEffect(() => {
    if (clientId) {
      // Fetch contracts
      setLoadingProgress(true);
      axios
        .get(apiUrl + `/contract/statistics`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setContracts(response.data);
          setLoadingProgress(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoadingProgress(false);
        });

      // Fetch proposals
      setLoadingProposals(true);
      axios
        .get(`${apiUrl}/proposals/statistics`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setProposals(response.data);
          console.log(response.data);
          setLoadingProposals(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoadingProposals(false);
        });
    } else {
      console.log("cliendId not found : " + clientId);
    }
  }, [token]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  const data = [
    { uv: 3400, pv: 1200 },
    { uv: 2800, pv: 1898 },
    { uv: 1400, pv: 4100 },
    { uv: 2780, pv: 1808 },
    { uv: 1890, pv: 3600 },
    { uv: 2390, pv: 2300 },
    { uv: 3290, pv: 1800 },
    { uv: 2400, pv: 1800 },
  ];

  const searchHandler = () => {};

  return (
    <div className=" w-full h-full  flex flex-row    p-4 small:p-1 small:w-[110%]">
      <div className=" w-[350px] h-[650px] ml-[-180px] pt-[40px] mt-[-20px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[650px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className=" w-full h-full  flex flex-col ml-[-180px] small:p-1   p-4">
        <div className="rounded-[20px] bg-[#E3EDF7] hover:scale-[1.02] w-full h-fit  border-[1.2px] border-[solid] p-[30px] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset]">
          <div className="w-full  min-h-[320px] max-h-fit rounded-[20px]  border-[1.4px] border-blue-800 border-opacity-40 p-[40px] overflow-hidden [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] flex flex-row gap-4 items-start justify-between">
            <div className="w-full flex flex-col gap-5">
              <div className="flex flex-row gap-5 items-start flex-wrap w-fit ">
                {" "}
                <div>
                  <InnerShadowAvatar
                    size={40}
                    imgSrc={require("../../assets/landingScreen/system-robot.png")}
                  />
                </div>
                <div className=" text-[30px] font-bold leading-[40.5px] text-left">
                  Ask me anything...{" "}
                  <span className="text-[18px] opacity-50">
                    ( Last update 1.0.1 )
                  </span>
                </div>
              </div>

              <div className="flex flex-row items-start medium:flex-wrap gap-5">
                <input
                  placeholder="Ask me anything"
                  className="w-[600px] h-[70px] gap-0 rounded-[20px] bg-[#E3EDF7] focus:outline-none px-6 [box-shadow:4px_4px_8px_0px_#BDC2C7BF,-4px_-4px_8px_0px_#FFFFFFBF] focus:[box-shadow:4px_4px_8px_0px_inset_#BDC2C7BF,-4px_-4px_8px_0px_inset_#FFFFFFBF]"
                />
                <div>
                  <NextButton
                    bgActive={true}
                    buttonName={"Search"}
                    nextHandler={searchHandler}
                  />
                </div>
              </div>

              <div className="flex flex-row gap-4 w-full medium:flex-col pt-4">
                <div className="min-[w,h]:[411px,36px] max-[w,h]:[fit,fit] p-4 px-6 small:px-4 gap-2  flex flex-row justify-start items-start rounded-[20px] border-[1.2px] border-[solid] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset]">
                  <div className="bg-[#6FBA47] mt-1 w-[8px] h-[8px] medium:w-[12px] mobile:w-[18px] rounded-full"></div>
                  <div className="text-[#414B5A]  text-[12px] font-normal leading-[16.2px] text-left">
                    How do I effectively search for and evaluate freelancers for
                    my project?
                  </div>
                </div>
                <div className="min-[w,h]:[411px,36px] max-[w,h]:[fit,fit] p-4 px-6 small:px-4 gap-2  flex flex-row justify-start items-start rounded-[20px] border-[1.2px] border-[solid] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset]">
                  <div className="bg-[#F79E1B] mt-1  w-[8px] h-[8px] medium:w-[12px] mobile:w-[18px] rounded-full"></div>
                  <div className="text-[#414B5A]  text-[12px] font-normal leading-[16.2px] text-left">
                    What are the pros and cons of fixed-price contracts versus
                    hourly contracts?
                  </div>
                </div>
              </div>
            </div>

            <div className="h-full w-full flex flex-row justify-end ">
              <span className="largeScreen:-mr-[180px] small:hidden w-[280px] h-[290px] bg-[#E3EDF7] border-[1.2px] border-[solid] [box-shadow:0px_5.37px_8.73px_0px_#00000040_inset,_0px_-10.37px_10.96px_0px_#FFFFFF_inset] mt-[-20px]  rounded-full flex flex-row items-center  p-4 pl-8">
                <img
                  src={require("../../assets/landingScreen/system-robot-removebg.png")}
                  className=" w-[130.99px] h-[195.99px]  border-[2px]  border-[solid] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)]"
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
        {loadingProgress || loadingProposals ? (
          <Spinner titleName={"Please wait, fetching data..."} />
        ) : (
          <div className="w-full bg-[#E3EDF7] py-10 ">
            <div className="w-[156px] h-[51px]   text-[38px] font-bold leading-[51.3px] text-left text-[#352F36]">
              Progress
            </div>
            <div className="flex w-full flex-col justify-start  py-2 gap-5">
              <div className="flex w-full  flex-row flex-wrap min-h-[640px] max-h-fit  justify-left gap-12">
                <div className="flex flex-row items-center justify-center w-full medium:flex-col gap-12">
                  <div
                    className={`cursor-pointer card rounded-[15px] hover:scale-[1.01] p-4  w-[40%] h-[300px] overflow-hidden  medium:w-[100%] small:h-fit`}
                    onClick={myProjectsHandler}
                  >
                    <div className="flex flex-row w-[100%] items-center px-10  justify-between">
                      <div className="flex flex-col gap-2 w-fit">
                        <div className="w-fit h-[30px]   text-[22px] font-bold leading-[29.7px] text-center text-[#352F36]">
                          Total projects
                        </div>
                        <div className="w-[88px] h-[95px]  text-[70px] font-normal leading-[94.5px] text-center text-[#414B5A]">
                          {contracts.totalProjectsCount}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="w-[160px] h-[27px] gap-[10px] flex flex-row justify-start items-center ">
                          <div className="bg-[#F79E1B] w-[8px] h-[8px] rounded-full"></div>
                          <div className="text-[#414B5A]  text-[16px] font-normal leading-[16.2px] text-center">
                            In-progress ({contracts.progressCount} )
                          </div>
                        </div>

                        <div className="w-[160px] h-[27px] gap-[10px] flex flex-row justify-start items-center ">
                          <div className="bg-[#6FBA47] w-[8px] h-[8px] rounded-full"></div>
                          <div className="text-[#414B5A]  text-[16px] font-normal leading-[16.2px] text-center">
                            Completed ( {contracts.completedCount} )
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <AreaChart
                        width={
                          window.innerWidth < 1024
                            ? window.innerWidth - 60
                            : window.innerWidth / 2
                        }
                        height={200}
                        data={data}
                        className="-ml-5 -mt-9  rounded-[20px]"
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="1"
                            y2="0"
                          >
                            <stop
                              offset="5%"
                              stopColor="#50CAFF"
                              stopOpacity={0.4}
                            />
                            <stop
                              offset="95%"
                              stopColor="#0478FF"
                              stopOpacity={0.6}
                            />
                          </linearGradient>
                          <linearGradient
                            id="colorPv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#2662F0"
                              stopOpacity={1}
                            />
                            <stop
                              offset="100%"
                              stopColor="#2662F0"
                              stopOpacity={0.2}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="uv"
                          stroke="#ffffff"
                          strokeOpacity={0.4}
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                        <Area
                          type="monotone"
                          dataKey="pv"
                          stroke="#ffffff"
                          strokeOpacity={0.8}
                          fillOpacity={1}
                          fill="url(#colorPv)"
                        />
                      </AreaChart>
                    </div>
                  </div>

                  <div className=" cursor-pointer min-h-[300px] h-fit hover:scale-[1.01] w-[60%]  medium:w-[100%] rounded-[15px] card p-2 px-16">
                    <div className="flex flex-row gap-4 mobile:flex-col justify-between">
                      <div className="flex flex-col gap-6 justify-start">
                        <div className="w-fit h-[30px]  text-[22px] font-bold leading-[29.7px] text-center text-[#352F36]">
                          Total Billing
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-row gap-2">
                            <img
                              className="w-[30px] h-[30px] mt-[2px]"
                              src={require("../../assets/navbar-logos/green-money-icon.png")}
                              alt=""
                            />
                            <div className="flex flex-row gap-1 text-[20.11px] font-[400] text-[#414B5A]">
                              <span>Paid: </span>
                              <span className="font-[700]">$11.2k</span>
                            </div>
                          </div>
                          <div className="flex flex-row gap-2">
                            <img
                              className="w-[30px] h-[30px] mt-[2px]"
                              src={require("../../assets/navbar-logos/orange-money-icon.png")}
                              alt=""
                            />
                            <div className="flex flex-row gap-1 text-[20.11px] font-[400] text-[#414B5A]">
                              <span>Unpaid: </span>
                              <span className="font-[700]">$2k</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="h-fit w-fit mobile:ml-[50px]">
                        <div className="relative w-full h-full">
                          <div className="absolute w-full h-full grid place-items-center mt-[45%] text-[#2D5587] text-[22px]  font-[700]">
                            $13.2k
                          </div>
                        </div>
                        <DoughnutChart />
                      </div>
                    </div>
                    <div className="-mt-6 mobile:mt-0 -ml-4">
                      <img
                        src={require("../../assets/navbar-logos/line-full-chart.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full medium:flex-col gap-12">
                  <div className="cursor-pointer card rounded-[15px] px-16  w-[60%] medium:w-[100%] min-h-[300px] h-fit hover:scale-[1.01] p-2">
                    <div className="flex flex-row gap-4 mobile:flex-col justify-between">
                      <div className="flex flex-col gap-6 justify-start">
                        <div className="w-fit h-[30px] gap-0  text-[22px] font-bold leading-[29.7px] text-center text-[#352F36]">
                          Outsourcing activities
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-row gap-2">
                            <img
                              className="w-[30px] h-[30px] mt-[2px]"
                              src={require("../../assets/navbar-logos/green-money-icon.png")}
                              alt=""
                            />
                            <div className="flex flex-row gap-1 text-[20.11px] font-[400] text-[#414B5A]">
                              <span>Paid: </span>
                              <span className="font-[700]">$11.2k</span>
                            </div>
                          </div>
                          <div className="flex flex-row gap-2">
                            <img
                              className="w-[30px] h-[30px] mt-[2px]"
                              src={require("../../assets/navbar-logos/orange-money-icon.png")}
                              alt=""
                            />
                            <div className="flex flex-row gap-1 text-[20.11px] font-[400] text-[#414B5A]">
                              <span>Unpaid: </span>
                              <span className="font-[700]">$2k</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="h-fit w-fit mobile:ml-[50px]">
                        <div className="relative w-full h-full">
                          <div className="absolute w-full h-full grid place-items-center  mt-[45%] text-[#2D5587] text-[22px]  font-[700]">
                            $13.2k
                          </div>
                        </div>
                        <DoughnutChart />
                      </div>
                    </div>
                    <div className="-mt-6 mobile:mt-0 -ml-4">
                      <img
                        src={require("../../assets/navbar-logos/line-full-chart.png")}
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className={`cursor-pointer card rounded-[15px] hover:scale-[1.01] p-4   w-[40%] h-[300px] overflow-hidden  medium:w-[100%] small:h-fit`}
                    onClick={() => navigate("/proposal-dashboard")}
                  >
                    <div className="flex flex-row w-[100%] items-center  justify-between">
                      <div className="flex flex-col gap-2 w-fit">
                        <div className=" w-fit  h-[30px]  text-[22px] font-bold leading-[29.7px] text-center text-[#352F36]">
                          Total proposals
                        </div>
                        <div className=" h-[95px]  text-[70px] font-normal leading-[94.5px] text-center text-[#414B5A]">
                          {proposals.totalProposalsCount}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="w-[160px] h-[27px] gap-[10px] flex flex-row justify-start items-center ">
                          <div className="bg-[#6FBA47] w-[8px] h-[8px] rounded-full"></div>
                          <div className="text-[#414B5A]  text-[16px] font-normal leading-[16.2px] text-center">
                            Reviewed ( {proposals.reviewedCount} )
                          </div>
                        </div>

                        <div className="w-[160px] h-[27px] gap-[10px] flex flex-row justify-start items-center ">
                          <div className="bg-[#F79E1B] w-[8px] h-[8px] rounded-full"></div>
                          <div className="text-[#414B5A]  text-[16px] font-normal leading-[16.2px] text-center">
                            Cancelled ( {proposals.canceledCount} )
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overflow-hidden w-full h-[152px] -ml-4 mt-6 rounded-b-[10px] flex flex-row justify-between items-end">
                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[109.41px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[79.56px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[102.09px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[61.91px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[78px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[86px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[63px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[60px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[102px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[51px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[67px] rounded-t-full"></div>
                      </div>

                      <div className="gap-4 flex flex-col -mr-2 ">
                        <div className="bg-[#4CC7FF] w-[13px] h-[13px] rounded-full"></div>
                        <div className="bg-[linear-gradient(180deg,_#50CAFF_-11.53%,_#0478FF_100%)] w-[13px] h-[78px] rounded-t-full"></div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>

              <div className="w-full grid place-items-center">
                <NextButton
                  buttonName="See more"
                  bgActive={true}
                  nextHandler={() => alert("pending")}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
