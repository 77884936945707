import React, { useState } from "react";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, IconButton } from "ui-neumorphism";
import Spinner, { apiUrl } from "../utils/constants";
import BackButton from "../components/Button/BackButton";
import NextButton from "../components/Button/NextButton";

function ForgetPassword() {
  const location = useLocation();
  const { usernameOrEmail } = location.state || { usernameOrEmail: "" };
  const [email, setEmail] = useState(usernameOrEmail);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logInError, setLogInError] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResetPassword = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("This field cannot be left blank");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address or username");
      return;
    }
    try {
      setLoading(true);
      await axios.post(apiUrl + `/auth/sendOtp/${email}`);
      navigate("/otpverification", { state: { email } });
    } catch (err) {
      setLogInError(
        "Sorry, we can’t find an account with this email address. Please try again or create a new account"
      );
      setLoading(false);
    }
  };
  if (loading) return <Spinner titleName={"Loading ..."} />;
  return (
    <div className="w-full h-screen bg-[#E3EDF7] font-Satoshi overflow-x-hidden p-4 pb-0">
      <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className="-mt-2">
        <BackButton />
      </div>
      <div className="relative w-full h-full flex flex-col justify-center items-center">
        <div className="small:mt-[50%] mt-[5%] z-50 w-[100%] h-full flex flex-col gap-8 justify-center items-center">
          <h2 className="text-[#352F36] text-[40px] font-[700] font-Satoshi w-[100%] text-center">
            Forgot password?
          </h2>
          <p className="w-[80%] h-fit leading-24 text-[#414B5A] text-[22px] font-Satoshi font-[400] text-center">
            Enter your email address and we'll send the link to reset password
          </p>
          <div className="w-[50%] h-fit mobile:w-[90%] medium:w-[400px] flex flex-col gap-3 items-center">
            <label className="w-[100%] text-[20px] font-Satoshi font-[500]">
              Email/Username
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`border ${
                emailError ? "border-red-500" : ""
              } w-[100%] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] rounded-[20px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-3 font-[400] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] font-Satoshi focus:outline-none`}
              name="email"
              id="email"
              required
              placeholder="Email ID or Phone number"
            />
            {logInError && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                  onClick={() => setLogInError("")}
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px]"
                  />
                </IconButton>
                {logInError}
              </p>
            )}
            {emailError && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px]"
                  />
                </IconButton>
                Please enter a valid email address or username
              </p>
            )}
          </div>

          <NextButton
            bgActive={email ? true : false}
            buttonName={"Reset Password"}
            nextHandler={handleResetPassword}
          />

          <Card
            style={{ borderRadius: "15px" }}
            className="hover:scale-[1.02] cursor-pointer"
          >
            <div
              className="hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] hover:border-none w-fit rounded-[15px] text-center justify-center p-3 px-6 items-center font-Satoshi text-[22px] font-[500]"
              onClick={() => navigate("/login")}
            >
              <span className="text-[22px] font-[500]">
                <ArrowBackIcon className="font-[22px]" /> Back to
              </span>
              <span className="font-[500] text-indigo-800"> Login page </span>
            </div>
          </Card>
        </div>

        <div className="w-[100%] h-[38%] z-10 relative flex justify-center">
          <div className="absolute bottom-0">
            <img
              src={require(`../assets/forgetpasswordbg.png`)}
              className="relative h-[250px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
