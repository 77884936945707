import React from "react";

export default function TextAreaField({
  labelName,
  inputValue,
  setInputValue,
  placeHolderValue,
}) {
  return (
    <div className="w-full h-fit large:w-[700px] grid place-items-center gap-4">
      <div className=" w-full text-[22px] font-medium leading-[29.7px]  pb-4 text-left">
        {labelName}
      </div>
      <textarea
        value={inputValue !== null ? inputValue : undefined}
      
        placeholder={
          placeHolderValue ? placeHolderValue : "Enter project description"
        }
        onChange={(e) => setInputValue(e.target.value)}
        className="resize-none large:w-[860px] w-full h-[200px]  bg-normal focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[20px] outline-none p-4"
      />
    </div>
  );
}
