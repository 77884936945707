import React from 'react'
import TextInput from '../../inputs/TextInput'

export default function ProjectTitle({projectTitle,setProjectTitle}) {
  
  return (
    <div className='w-full large:w-[700px] h-fit p-2'>
        <TextInput type={"text"} inputValue={projectTitle} setInputValue={setProjectTitle} labelName={"Project title"}/>
    </div>
  )
}
