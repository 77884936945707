import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import sarah from "../../assets/praposals/sarah_logo.png";
import ecommerce from "../../assets/projects_logos/ecommerce.png";
import { Avatar } from "ui-neumorphism";
import { displayWeekView } from "../../utils/PostedDateFormat";

export default function ContractsPreview({
  contractList,
  setContractList,
}) {

  const [searchQuery, setSearchQuery] = useState('');


 
  return (
    <div className="w-full col-span-2 flex flex-col gap-10">
     
      <SearchContent searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <ContractProjectsTable
        contractList={contractList}
        setContractList={setContractList}
      />
    </div>
  );
}

const SearchContent = ({searchQuery,setSearchQuery}) => {

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <div className="grid grid-cols-4 gap-10">
      <div className="col-span-2 w-full h-[70px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] p-4 flex flex-row gap-2 items-center">
        <IoIosSearch className="opacity-[50%]  text-[#414B5A]" />
        <input
          type="text"
          className="h-full w-full bg-transparent outline-none focus:outline-none"
          placeholder="Search "
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="col-span-1 w-full h-[70px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] p-4">
        <select
          name=""
          id=""
          className="opacity-[50%]  text-[#414B5A] w-full bg-transparent h-full outline-none focus:outline-none"
        >
          <option value="" className="opacity-[50%]  text-[#414B5A]">
            Individual
          </option>
          <option className="opacity-[50%]  text-[#414B5A]" value="">
            Team{" "}
          </option>
        </select>
      </div>
      <div className="col-span-1 w-full h-[70px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] p-4">
        <select
          name=""
          id=""
          className="opacity-[50%]  text-[#414B5A] w-full bg-transparent h-full outline-none focus:outline-none"
        >
          <option value="">In-progress</option>
          <option value="">completed </option>
        </select>
      </div>
    </div>
  );
};

const ContractProjectsTable = ({ contractList }) => {
  const tableHeaders = [
    "Project name",
    "name",
    "Progress",
    "Project schedule",
    "Project due",
    "Total earnings",
  ];
console.log(contractList);
  const paginationNumbers = [];
  const objectsForPage=10;
  const [pagination, setPaginationCount] = useState(1);

  for (let i = 0; i < contractList.length/10; i++) {
  
    paginationNumbers.push(i+1);
  }
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="w-full flex flex-col gap-10">
      <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[10px] w-full grid grid-cols-6 p-4 gap-10">
        {tableHeaders.map((header, _) => (
          <div className="capitalize col-span-1 [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-full rounded-[8px] p-2 text-center font-[700] text-[16px] text-[#352F36]">
            {header}
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[10px] px-4">
        {contractList &&
          contractList.map((rowInfo, index) => (
           ( index+1<=objectsForPage*pagination && index+1>objectsForPage*pagination-10 )&& <div
              className=" w-full grid grid-cols-6 gap-10 py-4 items-center  border-b-2 border-[#414B5A] border-opacity-[20%]"
              key={index}
            >
              <div className="col-span-1 flex flex-row justify-start gap-2 items-center capitalize truncate">
                <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit p-2 rounded-full">
                  <Avatar src={ecommerce} />
                </div>
                <div>{rowInfo.projectName}</div>
              </div>
              <div className="col-span-1 flex flex-row justify-start gap-2 items-center capitalize">
                <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit p-2 rounded-full">
                  <Avatar src={sarah} />
                </div>
                <div>{rowInfo.userName}</div>
              </div>
              <div className="grid place-items-center">
                <div
                  className={`px-6 py-1 rounded-[10px] text-[#FFFFFF] ${
                    rowInfo.progress < 100
                      ? "bg-[#FF5F1B] [box-shadow:2.5px_2.5px_5px_0px_#BF4E1D_inset,-2.5px_-2.5px_5px_0px_#FFFFFF66_inset]"
                      : "bg-[#089949]"
                  } [box-shadow:2.5px_2.5px_5px_0px_#096030_inset,_-2.5px_-2.5px_5px_0px_#FFFFFF66_inset]`}
                >
                  {rowInfo.progress}%
                </div>
              </div>
              <div className="grid place-items-center">
                {displayWeekView(rowInfo.startDate, rowInfo.endDate)}
              </div>
              <div className="grid place-items-center">
                <div className="bg-[#2485FF] px-4 py-1 rounded-[10px] text-[#FFFFFF] [box-shadow:2.5px_2.5px_5px_0px_#195994_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF4D_inset]">
                  {rowInfo.endDate}
                </div>
              </div>
              <div className="text-center">{rowInfo.totalEarnings}</div>
            </div>
          ))}
      </div>

      <div className="w-full flex flex-row justify-center items-center gap-3">
        {
        paginationNumbers.length> 1 && (
          <div onClick={()=>{
            if (pagination>1) {
              setPaginationCount(pagination-1)
            }
          }} className={`${pagination<2 && "text-[#CCCCCC]"} cursor-pointer`}>Prev</div>
        )}

        {paginationNumbers.map((pageNumber,index) => (
          <div
            key={pageNumber}
            onClick={()=>setPaginationCount(index+1)}
            className={`cursor-pointer ${pagination===index+1 && "bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]"} shadow-lg w-[32px] h-[32px] flex justify-center items-center rounded-[4px] `}
          >
            {pageNumber}
          </div>
        ))}
        { paginationNumbers.length> 1  && <div onClick={()=>setPaginationCount(pagination+1)} className={`${pagination===paginationNumbers.length && "text-[#CCCCCC]"} cursor-pointer`}>Next</div>}
      </div>
    </div>
  );
};
