import { mdiGreaterThan, mdiLessThan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Card, IconButton } from "ui-neumorphism";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Spinner, { apiUrl, token } from "../../utils/constants";

export default function AcclaimedCompanies() {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate(); // Create navigate function
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    axios
      .get(apiUrl + "/company/acclaimed-companies", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setCompanies(response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
        setLoader(false);
      });
  }, []);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    className: "slider variable-width flex flex-row w-fit h-fit gap-5",
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  if (loader) {
    return <Spinner titleName={"Please wait fetching data"} />;
  }
  return (
    <div className="flex flex-col gap-4 w-full h-fit py-4">
      <div className="text-[#051118] text-[38px] small:text-[24px] font-bold leading-[51.3px] text-left">
        Highly acclaimed companies
      </div>
      <div className="slider-container">
        <Slider ref={sliderRef} {...settings}>
          {companies.map((company) => (
            <div
              className="p-2 w-fit"
              key={company.id}
              style={{ cursor: "pointer" }}
            >
              <div className="w-[500px] hover:scale-[1.02] card small:w-[340px] h-[350px] small:h-[300px] rounded-[26px] grid grid-rows-[70%_30%]">
                <div className="overflow-hidden">
                  <img
                    className="rounded-t-[26px] w-full h-full"
                    src={company.logo}
                    alt={company.name}
                  />
                </div>
                <div className="px-6 -mt-10 py-10">
                  <div className="text-[38px] font-bold leading-[51.3px] text-left">
                    {company.name}
                  </div>
                  <div className="text-[#051118] text-[18px] font-medium leading-[24.3px]">
                    {company.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-right w-full flex flex-row justify-end py-4">
          <div className="w-fit flex flex-row gap-4">
            <IconButton
              rounded
              text={false}
              color="#4448B3"
              onClick={previous}
              disabled={currentSlide === 0}
            >
              <Icon path={mdiLessThan} size={0.8} />
            </IconButton>
            <IconButton
              rounded
              text={false}
              color="#4448B3"
              onClick={next}
              disabled={
                currentSlide === companies.length - settings.slidesToShow
              }
            >
              <Icon path={mdiGreaterThan} size={0.8} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}
