import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostNavHeader from "../util/PostNavHeader";
import ProgressBar from "../util/ProgressBar";
import Estimatetime from "./Estimatetime";
import NeedACompanyToWork from "./NeedACompanyToWork";
import LevelOfExperience from "./LevelOfExperience";
import IsContractToHire from "./IsContractToHire";
import NextButton from "../../Button/NextButton";
import { PostAJobContext } from "../../../App";
import ScrollToTop from "../util/ScrollToTop";

export default function EstimatedTimeMain() {
  const {
    progressStatus,
    setprogressStatus,
    estimateTime,
    setEstimateTime,
    timeValue,
    setTimeValue,
    experienceLevel,
    setExperienceLevel,
    needAcompanyToWork,
    setNeedAcompanyToWork,
    teamSize,
    setTeamSize,
    isContract,
    setIsContract,
  } = useContext(PostAJobContext);

  const location = useLocation();
  const redirectUrl=location.state?location.state.pathName:"/project-duration"

  useEffect(() => {
    if (location.pathname === "/project-estimation-time") {
      setprogressStatus(2);
    }
  }, []);

  const navigate = useNavigate();
  const nextHandler = () => {
    setprogressStatus(3);
    navigate("/project-payment",{state:{pathName:location.pathname}});
  };
useEffect(()=>{
  if (progressStatus<2) {
    navigate("/project-duration")
  }
})
  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} redirectUrl={redirectUrl}/>
      <div className="px-40 py-8 small:px-10 mobile:px-4 flex flex-col items-center gap-5 overflow-auto">
        <ProgressBar status={progressStatus} />
        <Estimatetime
          estimateTime={estimateTime}
          setEstimateTime={setEstimateTime}
          timeValue={timeValue}
          setTimeValue={setTimeValue}
        />
        <NeedACompanyToWork
          needAcompanyToWork={needAcompanyToWork}
          setNeedAcompanyToWork={setNeedAcompanyToWork}
          teamSize={teamSize}
          setTeamSize={setTeamSize}
        />
        <LevelOfExperience
          experienceLevel={experienceLevel}
          setExperienceLevel={setExperienceLevel}
        />
        <IsContractToHire
          isContract={isContract}
          setIsContract={setIsContract}
        />
        <div className="w-full grid place-items-center h-fit">
          <NextButton
            bgActive={estimateTime && timeValue && needAcompanyToWork==="Yes"?teamSize:needAcompanyToWork  && experienceLevel && isContract ? true : false}
            buttonName={"next"}
            nextHandler={nextHandler}
          />
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
}
