import React, { useContext, useEffect, useState } from "react";
import HomeNavBar from "./HomeNavBar";
import WorkSpace from "./WorkSpace";
import AclaimedCompanies from "./AclaimedCompanies";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Progress from "./Progress";
import TopInterestingBlogsAndArticles from "./TopInterestingBlogsAndArticles";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner, { apiUrl } from "../../utils/constants";
import { ClientIdContext, TokenContext } from "../../App";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function HomePage() {
  const [client, setClient] = useState({});
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);

  useEffect(() => {
    if (clientId) {
      getClientById();
    } else {
      console.log("ClientId not available: " + clientId);
    }
  }, [clientId]);
  const getClientById = () => {
    setLoader(true);
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setClient(res.data);
        getProjectsByClientId();
        if (res.data.role === "User") {
          setShowPopup(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoader(false);
      });
  };

  const getProjectsByClientId = () => {
    setLoader(true);
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setProjects(res.data);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoader(false);
      });
  };
  return loader ? (
    <Spinner titleName={"Please wait fetching data"} />
  ) : (
    <div className="w-screen h-screen bg-[#E3EDF7] pt-4 overflow-x-hidden	">
      <HomeNavBar user={client} />

      <div id="content" className="px-10   p-4 w-full flex flex-col">
        <WorkSpace clientInfo={client} />

        <Progress clientInfo={projects} />

        <TopInterestingBlogsAndArticles />
        <AclaimedCompanies />
        <Testimonials />
      </div>
      <Footer />
      {showPopup && (
        <div className="w-screen font-Sathoshi h-screen fixed z-50 top-0 left-0 grid place-items-center bg-white bg-opacity-[80%]">
          <div className="bg-[#E3EDF7] w-[60%] py-8 px-8 gap-5 flex flex-col  rounded shadow-lg items-center justify-center">
            <div className="w-fit flex  shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] p-2 items-center justify-center h-fit rounded-[50%] border border-white">
              <img
                src={require(`../../assets/rolechangerpopup.png`)}
                className="w-[50px] h-[50px] "
              />
            </div>
            <h2 className="text-[30px] text-[#352F36] font-[500] w-[90%]">
              You still have a few steps left to complete in order to post your
              jobs.
            </h2>

            <button
              className={`w-fit px-12  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_6px_#FAFBFF] text-center text-[22px] font-[700] font-Satoshi p-2  rounded bg-gradient-to-r from-indigo-800 to-sky-600 text-[white]
   
}`}
              onClick={() => navigate("/change-role")}
            >
              Continue <ArrowForwardIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
