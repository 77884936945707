import React, { useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
function HowItWorks(props, ref) {
  const navigate = useNavigate();
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));
  return (
    <div
      data-id="howItWorks"
      ref={localRef}
      className="  w-full grid grid-cols-2 mobile:grid-cols-1 ipadMin:grid-cols-1  -mt-6  gap-5"
    >
      <div className="w-full col-span-1 px-10 h-full flex flex-col gap-4">
        <h1 className="font-[500]  text-[40px] small:text-center small:text-[35px] text-[#352F36] h-fit">
          How it works
        </h1>
        <h1 className="text-[#414B5A] font-[400] small:text-center text-[24px] opacity-[70%]">
          Embark on your journey with us by signing up and crafting your unique
          profile. Share your ideas and turn into reality.
        </h1>
        <div className=" [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px] w-full h-[400px] p-[1px]">
          <iframe
            src="https://www.youtube.com/embed/hgynZJ5mPF8"
            allowFullScreen
            className="w-full h-full [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px]"
          ></iframe>
        </div>
      </div>
      <div className="h-full col-span-1 w-full flex flex-col items-start px-10 justify-end gap-5">
        <div className="flex flex-row gap-10 h-fit items-center">
          <div className="bg-[#2485FF] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] w-[115px] h-[90px] rounded-[20px] grid place-items-center">
            <img
              className="w-[40px] h-[40px] small:w-[30px] small:h-[30px]"
              src={require("../../assets/defaultScreen/howItWorks/Signup.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="h-fit w-full flex flex-col justify-center ">
            <h1 className="text-[#352F36] small:text-[22px] text-[30px] font-[500]">
              Signup{" "}
            </h1>
            <h1 className="text-[#414B5A] small:text-[14px] text-[18px] font-[400] small:opacity-[80%] opacity-[50%]">
              Start Your Journey with Us: Join and Craft Your Profile
            </h1>
          </div>
        </div>
        <div className="flex flex-row gap-10 h-fit items-center">
          <div className="bg-[#2485FF] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] w-[90px] h-[90px] rounded-[20px] grid place-items-center">
            <img
              className="w-[40px] h-[40px] small:w-[30px] small:h-[30px]"
              src={require("../../assets/defaultScreen/howItWorks/postJob_icon.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="h-fit flex flex-col justify-center">
            <h1 className="text-[#352F36] small:text-[22px] text-[30px] font-[500]">
              Post Job Opportunities{" "}
            </h1>
            <h1 className="text-[#414B5A] small:text-[14px] text-[18px] font-[400] small:opacity-[80%] opacity-[50%]">
              Share Your Projects: Post Job Opportunities
            </h1>
          </div>
        </div>
        <div className="flex flex-row gap-10 h-fit items-center">
          <div className="bg-[#2485FF] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] w-[90px] h-[90px] rounded-[20px] grid place-items-center">
            <img
              className="w-[40px] h-[40px] small:w-[30px] small:h-[30px]"
              src={require("../../assets/defaultScreen/howItWorks/connect_collobrate.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="h-fit flex flex-col justify-center">
            <h1 className="text-[#352F36] small:text-[22px] text-[30px] font-[500]">
              Connect and Collaborate{" "}
            </h1>
            <h1 className="text-[#414B5A] small:text-[14px] text-[18px] font-[400] small:opacity-[80%] opacity-[50%]">
              Seamless Networking: Connect and Collaborate
            </h1>
          </div>
        </div>
        <div className="flex flex-row gap-10 h-fit items-center">
          <div className="bg-[#2485FF] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] w-[90px] h-[90px] rounded-[20px] grid place-items-center">
            <img
              className="w-[40px] h-[40px] small:w-[30px] small:h-[30px]"
              src={require("../../assets/defaultScreen/howItWorks/bring_ideas_to_life.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="h-fit flex flex-col justify-center">
            <h1 className="text-[#352F36] small:text-[22px] text-[30px] font-[500]">
              Bring Your Ideas to Life{" "}
            </h1>
            <h1 className="text-[#414B5A] small:text-[14px] text-[18px] font-[400] small:opacity-[80%] opacity-[50%]">
              Transform Your Vision: Bring Your Ideas to Life
            </h1>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-fit hidden small:flex items-center justify-center">
        <button
          className="bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer small:py-4 small:px-6 p-2 px-4 rounded-[14px] text-[#ffffff]"
          onClick={() => navigate("/login")}
        >
          Signup / Login
        </button>
      </div>
    </div>
  );
}
export default React.forwardRef(HowItWorks);
