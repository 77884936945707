import React, { useState, useEffect, useContext } from "react";
import { Card } from "ui-neumorphism";
import axios from "axios";
import Spinner, { apiUrl } from "../../utils/constants";
import toast from "react-hot-toast";
import NextButton from "../Button/NextButton";
import { useNavigate } from "react-router-dom";
import { ClientIdContext, TokenContext } from "../../App";

export default function InviteBrowseExport() {
  const navigate = useNavigate();
  const [proposals, setProposals] = useState([]);
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [proposalCount, setProposalsCount] = useState({
    totalProposalsCount: 0,
    reviewedCount: 0,
    canceledCount: 0,
    unseenCount: 0,
  });

  useEffect(() => {
    const fetchProposals = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/proposals/${clientId}`, {
          headers: {
            Authorization: token,
          },
        });
        setProposals(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProposals();
  }, [clientId, token]);

  useEffect(() => {
    const fetchProposalStatistics = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/proposals/statistics`, {
          headers: {
            Authorization: token,
          },
        });
        setProposalsCount(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProposalStatistics();
  }, [token]);

  if (loading) {
    return <Spinner>Loading...</Spinner>;
  }

  const viewBrowseExpoertsHandler = () => {
    navigate("/browse-experts-view");
  };

  return (
    <div className="w-full h-fit grid grid-cols-5 medium:grid-cols-1 medium:justify-center gap-10 gap-y-4">
      <div className="col-span-3 medium:min-w-full h-full">
        <Card
          style={{ borderRadius: "20px" }}
          className="p-4 py-8 px-6 pb-0 medium:pb-6 flex flex-row small:flex-col small:pb-4 justify-between gap-10"
        >
          <div>
            <div className="font-[700] text-[24px] text-[#352F36]">
              Invite individuals or companies for your project
            </div>
            <div className="flex flex-row pt-4">
              <div className="font-[500] text-[14px] text-[#414B5A] opacity-[50%] -mt-2">
                You can also invite the team you wanted for your project
              </div>
              <div>
                <img
                  src={require("../../assets/praposals/childs-logo.png")}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div>
            <NextButton
              bgActive={true}
              buttonName={"Browse experts"}
              nextHandler={viewBrowseExpoertsHandler}
            />
          </div>
        </Card>
      </div>

      <div className="col-span-2 mobile:col-span-3 h-fullw-full">
        <Card style={{ borderRadius: "20px" }} className="w-full py-6 px-6">
          <div className="w-full grid grid-cols-[repeat(2,1fr)] mobile:grid-cols-1 gap-6">
            <div className="py-[5px] px-[16px] rounded-[14px] min-w-full [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,-2.5px_-2.5px_5px_0px_#FAFBFF_inset] flex flex-row gap-1 items-center justify-center">
              <span className="text-[20px] text-[#2485FF] font-[700] tracking-[-0.01em]">
                {proposalCount.totalProposalsCount}
              </span>
              <span className="text-[#414B5A] opacity-[50%] font-[500] text-[12px]">
                Proposals
              </span>
            </div>

            <div className="py-[5px] px-[16px] rounded-[14px] min-w-full [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,-2.5px_-2.5px_5px_0px_#FAFBFF_inset] flex flex-row gap-1 items-center justify-center">
              <span className="text-[20px] text-[#089949] font-[700] tracking-[-0.01em]">
                {proposalCount.reviewedCount}
              </span>
              <span className="text-[#414B5A] opacity-[50%] font-[500] text-[12px]">
                Accepted
              </span>
            </div>

            <div className="py-[5px] px-[16px] rounded-[14px] min-w-full [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,-2.5px_-2.5px_5px_0px_#FAFBFF_inset] flex flex-row gap-1 items-center justify-center">
              <span className="text-[20px] text-[#EB001B] font-[700] tracking-[-0.01em]">
                {proposalCount.canceledCount}
              </span>
              <span className="text-[#414B5A] opacity-[50%] font-[500] text-[12px]">
                Declined
              </span>
            </div>

            <div className="py-[5px] px-[16px] rounded-[14px] min-w-full [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,-2.5px_-2.5px_5px_0px_#FAFBFF_inset] flex flex-row gap-1 items-center justify-center">
              <span className="text-[20px] text-[#0829A9] font-[700] tracking-[-0.01em]">
                {proposalCount.unseenCount}
              </span>
              <span className="text-[#414B5A] opacity-[50%] font-[500] text-[12px]">
                Unseen
              </span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
