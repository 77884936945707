import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import dummyImage from "../assets/noticationimage.png";
import Spinner, {
  apiUrl,
  handleBase64Change,
  hoverBg,
} from "../utils/constants";
import toast from "react-hot-toast";
import InnerShadowAvatar from "../utils/InnerShadowAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, IconButton } from "ui-neumorphism";
import { ClientIdContext, TokenContext } from "../App";
function ProjectActivities() {
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useState({});
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { token } = useContext(TokenContext);
  const { clientId } = useContext(ClientIdContext);
  const location = useLocation();

  useEffect(() => {
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) {
          setClientInfo(res.data);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  }, [clientId, token]);

  useEffect(() => {
    setLoading(true); // Set loading to true before API call
    axios
      .get(apiUrl + `/contract/getContractOverview/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setContracts(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false); // Set loading to false in case of error
      });
  }, [clientId, token]);

  return (
    <div className="w-full h-full  bg-[#E3EDF7] rounde-[15%] flex flex-col gap-2 font-Satoshi">
      <h1 className="font-[700] text-[30px] p-5">Project activites</h1>
      {loading ? ( // Conditionally render loader
        <div className="flex justify-center items-center w-full h-full">
          <Spinner />
        </div>
      ) : (
        <div className="w-full h-fit p-3  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-center">
          <table className="w-full h-fit ">
            <thead className="w-full pb-3">
              <tr className="w-full border-none h-fit text-[14px] font-[500]  grid grid-cols-5 px-2 pb-3 gap-5  items-center  ">
                <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                  Recent Projects
                </th>
                <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                  Project Started
                </th>
                <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                  Team Name
                </th>
                <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                  Due Date
                </th>
                <th className="w-full rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                  Status
                </th>
              </tr>
            </thead>

            <tbody className="px-2 min-h-60 overflow-y-auto block scrollbar rounded-[15px] border py-4 border-white shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
              {contracts.map((contract) => (
                <tr
                  className="w-full  border-b-2 border-gray hover:bg-slate-600 hover:bg-opacity-[20%] cursor-pointer hover:scale-[1.01] h-fit text-[12px] font-[500]  grid grid-cols-5 px-2 gap-5  items-center  "
                  key={contract.contractId}
                  onClick={() => navigate("/project")}
                >
                  <td
                    className="w-full flex h-fit p-3 items-center justify-start truncate gap-2 text-center"
                    title={contract.projectName}
                  >
                    <InnerShadowAvatar imgSrc={dummyImage} size={20} />
                    {contract.projectName}
                  </td>
                  <td className="w-full bg-blue-500 flex rounded-[15px] items-center justify-center h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#2485FF,inset_-2.5px_-2.5px_2px_#f9faff]  text-center">
                    {contract.startDate}
                  </td>
                  <td
                    className="w-full flex h-fit p-3 items-center justify-start truncate gap-2 text-center"
                    title={contract.username}
                  >
                    <InnerShadowAvatar imgSrc={dummyImage} size={20} />
                    {contract.username}
                  </td>

                  <td
                    className={` ${
                      contract.projectStatus === "Overdue"
                        ? "bg-red-500 text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : contract.projectStatus === "In_progress"
                        ? "bg-red-500 text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : contract.projectStatus === "Completed"
                        ? "bg-[#089949] text-white shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : ""
                    } w-full flex rounded-[15px] items-center justify-center h-fit p-3   text-center`}
                  >
                    {contract.endDate}
                  </td>

                  <td
                    className={`w-[100%] flex h-fit p-3 items-center justify-center rounded-[15px] text-center ${
                      contract.projectStatus === "Overdue"
                        ? "bg-red-500 text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : contract.projectStatus === "In_progress"
                        ? "bg-[#FF5F1B] text-white shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : contract.projectStatus === "Completed"
                        ? "bg-[#089949] text-white shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                        : ""
                    }`}
                  >
                    {contract.projectStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ProjectActivities;
