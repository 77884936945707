import React, { useContext, useState, useEffect } from "react";
import DeclineButton from "../Button/DeclineButton";
import SignalLightsDesign from "../animations/SignalLightsDesign";
import AcceptButton from "../Button/AcceptButton";
import { Card } from "ui-neumorphism";
import { useLocation, useNavigate } from "react-router-dom";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import { handleBase64Change } from "../../utils/constants";
import axios from "axios";
import correctBlue from "../../assets/correctblue.png";
import Spinner, { apiUrl } from "../../utils/constants";
import { TokenContext } from "../../App";
// Assuming you have a Spinner component

export default function ProposalUserView({ userObject }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useContext(TokenContext);
  const [status, setStatus] = useState(userObject.proposal.status);
  const [loading, setLoading] = useState(false); // Loader state

  const navigateHandler = () => {
    setLoading(true);
    localStorage.setItem("redirectUrl", location.pathname);
    navigate("/proposal-profile-preview", { state: { userInfo: userObject } });
    setLoading(false);
  };

  const handleAccept = async () => {
    try {
      setLoading(true);
      await axios.put(
        apiUrl + `/proposals/accept/${userObject.proposal.proposalId}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStatus("ACCEPTED");
      setLoading(false);
    } catch (error) {
      console.error("Error accepting proposal:", error);
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    try {
      setLoading(true);
      await axios.put(
        apiUrl + `/proposals/decline/${userObject.proposal.proposalId}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStatus("DECLINED");
      setLoading(false);
    } catch (error) {
      console.error("Error declining proposal:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner>Loading...</Spinner>;
  }
  return (
    <Card style={{ borderRadius: "20px" }}>
      <div className="w-full h-fit py-4 px-8 small:px-4 flex flex-col gap-4">
        <div className="flex flex-row gap-4 items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <InnerShadowAvatar
              imgSrc={handleBase64Change(userObject.profileImage)}
              size={40}
            />
            <div className="flex flex-col">
              <span className="font-bold text-20 text-[#352F36] capitalize">
                {userObject.name}
              </span>
              <span className="text-[#414B5A] font-medium text-14">
                {userObject.designation}
              </span>
            </div>
          </div>
          <div className="flex flex-row gap-1 -mt-2 h-full items-center">
            <span>{userObject.country}</span>{" "}
            <img
              className="w-[24px] h-[17px]"
              src={require("../../assets/praposals/indian-logo.png")}
              alt="Country Flag"
            />
          </div>
          <div
            className="text-[#2485FF] font-medium text-16 cursor-pointer hover:underline"
            onClick={navigateHandler}
          >
            View Profile
          </div>
        </div>

        <div className="w-[90%] text-16 text-[#352F36]">{userObject.about}</div>

        {/* Proposal Details Section */}
        <div className="font-medium text-16 text-[#414B5A]">
          <p>{userObject.proposalDetails}</p>
        </div>

        <div className="flex flex-row justify-end gap-5">
          {status === "PENDING" && (
            <>
              <div className="w-[147px]">
                <AcceptButton onClickHandler={handleAccept} />
              </div>
              <div className="w-[147px]">
                <DeclineButton onClickHandler={handleDecline} />
              </div>
            </>
          )}
          {status === "ACCEPTED" && (
            <div className="w-fit p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-indigo-800 to-sky-600">
              <p className="w-[100%] h-fit p-3 py-2 rounded-[15px] font-[500] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#0829A9]">
                Accepted
                <img
                  className="text-[white]"
                  src={correctBlue}
                  alt="Accepted"
                />
              </p>
            </div>
          )}
          {status === "DECLINED" && (
            <div className="w-fit p-[1px] rounded-[15px] h-fit bg-gradient-to-r from-[#FF716A] to-[#FF2731]">
              <p className="w-[100%] h-fit p-3 py-2 rounded-[15px] font-[500] bg-[#E3EDF7] text-center justify-center items-center flex flex-row gap-2 text-[#FF716A]">
                Declined <span className="text-[20px]">&times;</span>
              </p>
            </div>
          )}
        </div>

        <SignalLightsDesign />
      </div>
    </Card>
  );
}
