import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

import { apiUrl, parseJwt, hoverBg } from "../../utils/constants";
const FindFreelancer = forwardRef(function (props, ref) {
  const navigate = useNavigate();
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));
  const handlePostJobClick = () => {
    navigate("/login", { state: { isPost: "post" } });
  };

  const handleGrabProjectClick = () => {
    navigate("/all-projects");
  };

  return (
    <div
      data-id="findFreelancer"
      ref={localRef}
      className="w-full h-[600px] ipadMin:h-fit mobile:h-fit grid grid-cols-[35%_65%] largeScreen:grid-cols-[40%_60%] ipadMax::grid-cols-[50%_50%] mobile:flex mobile:flex-col small:flex small:flex-col mobile:px-4 gap-2 justify-between"
    >
      <div className="w-[100%] col-span-1 pl-10 h-full flex flex-col gap-4 mobile:mt-2 mt-10">
        <h1 className="font-[500] text-[40px] small:text-center text-[#352F36] mobile:leading-[50px] leading-[60px]">
          Find the Freelance Services You Need, Right Here!
        </h1>
        <h1 className="text-[#414B5A] font-[400] mobile:text-[20px] text-[24px] opacity-[70%]">
          Streamlining the Freelancer Search Process for Seamless Project
          Success.
        </h1>

        <div className="flex flex-row gap-10 justify-start items-center small:hidden">
          <button
            onClick={handlePostJobClick}
            className={`${hoverBg} [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer rounded-[14px] px-3 py-[17px] text-[#FFFFFF] font-[600] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`}
          >
            Post a job - It’s free
          </button>
          <button
            onClick={handleGrabProjectClick}
            className="hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] font-[600] rounded-[14px] px-3 py-4 border text-[#929CA8]"
          >
            Grab your project
          </button>
        </div>
      </div>
      <div className="h-fit w-[100%] col-span-1 w-full flex flex-row justify-end pl-20">
        <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-full small:w-[90%] flex flex-row justity-end rounded-tl-[190px] rounded-bl-[50px] p-3">
          <img
            className="ipadMax:h-[400px] rounded-tr-[180px] rounded-br-[50px] w-full h-[450px] scale-x-[-1]"
            src={require("../../assets/defaultScreen/herosection.jpg")}
            alt=""
          />
        </div>
      </div>
      <div className="flex hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer flex-row gap-10 justify-center items-center py-3 text-[20px] mobile:text-[14px] hidden small:flex small:flex-row">
        <button
          onClick={handlePostJobClick}
          className="[box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px] px-6 py-4 text-[#FFFFFF] font-[600] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]"
        >
          Post a job - It’s free
        </button>
        <button
          onClick={handleGrabProjectClick}
          className="hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer font-[600] rounded-[14px] px-6 py-4 border text-[#929CA8] bg-[#E3EDF7]"
        >
          Grab your project
        </button>
      </div>
    </div>
  );
});
export default FindFreelancer;
