import React from "react";
import { Avatar, Card } from "ui-neumorphism";
import { CiMenuKebab } from "react-icons/ci";
import LogoIndexedView from "./LogoIndexedView";
import { displayDateView } from "../../utils/PostedDateFormat";

export default function ProjectsList({
  projectList,
  activeProjectId,
  setActiveProjectId
}) {
  const assignProjectsLength=()=>{
    let length=0;
    projectList && projectList.filter(project=>project.contractId!==null).map((_,index)=>{
      length+=1
    })
    return length;
  }

  return (
    <div className="w-full col-span-1 [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] flex flex-col  gap-5 rounded-[20px] p-4">
      <div className="flex flex-col gap-4">
        <div className="font-[700] text-[26px] text-center text-[#352F36]">Projects</div>
        <div className="w-full flex flex-row justify-end gap-4">
          <Card
            width={140}
            className="w-full flex flex-row gap-2 items-center justify-center p-1 px-2"
          >
            <div className="w-fit font-[700] text-[30px] text-[#414B5A]">
              {projectList.length}
            </div>{" "}
            <div className="w-fit font-[500] text-[12px] text-[#414B5A] opacity-[50%]">
              Total
            </div>
          </Card>
          <Card
            width={140}
            className="w-full flex flex-row gap-2 items-center justify-center p-1 px-2"
          >
            <div className="w-fit font-[700] text-[30px] text-[#414B5A]">
              {assignProjectsLength()?assignProjectsLength():0}
            </div>{" "}
            <div className="w-fit font-[500] text-[12px] text-[#414B5A] opacity-[50%]">
              Assigned
            </div>
          </Card>
        </div>
      </div>

      {projectList &&
        projectList.map((project, index) => (
          <div
            key={index}
            onClick={() => setActiveProjectId(project.projectId)}
            className={`${
              project.projectId === activeProjectId && "bg-[#2485FF] "
            } [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] w-full h-fit  flex flex-col gap-4 p-4 cursor-pointer hover:scale-[1.02] hover:border hover:border-gray-300`}
          >
            <div className="flex flex-row gap-4 justify-between items-center w-full">
              <div className="flex flex-row gap-3">
                <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit p-2 rounded-full">
                  <Avatar
                    src={require("../../assets/projects_logos/health_fitness.png")}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="font-[700] text-[20px] text-[#352F36] capitalize">
                    {project?.projectName}
                  </span>
                  <span className="text-[#414B5A] font-[500] text-[14px] opacity-[50%]">
                    Posted {project.postedDate && displayDateView(project.postedDate)}
                    {/* {project.postedDate} */}
                  </span>
                </div>
              </div>
              <div className="-mt-3">
                <CiMenuKebab color="black" />
              </div>
            </div>
            <div className="w-[95%] flex flex-row justify-between  ml-4">
              <LogoIndexedView />
              <div
                className={`[box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF40] border border-[#2485FF]  ${
                  project.projectId !== activeProjectId
                    ? "bg-[linear-gradient(96.37deg,_#0829A9_48.58%,_rgba(100,_233,_255,_0.8)_191.74%)] text-[#ffffff]"
                    : "bg-[#ffffff]"
                } w-fit p-1 text-[16px] font-[700] rounded-[20px] px-4`}
              >
                Assign
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
