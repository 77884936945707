import React, { useState, useEffect } from "react";
import BgButton from "../Button/BgButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import Spinner, { apiUrl, handleBase64Change } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

export default function TopIndividuals() {
  const [visibleCount, setVisibleCount] = useState(4);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [freelancers, setFreelancers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(`${apiUrl}/freelancer/getTopFreelancers`)
      .then((response) => {
        setFreelancers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching freelancers!", error);
        setLoading(false);
      });
  }, []);

  const handleImageClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle active state
  };

  const handleSeeMoreClick = () => {
    navigate("/All-individuals");
  };

  const handleProjectClick = (userId) => {
    navigate(`/default-individual/${userId}`);
  };
  if (loading)
    return <Spinner titleName={"Loading top Individuals details..."} />;
  return (
    <div className="w-full flex flex-col gap-1 px-10 p-4 items-center">
      <div className="font-[500] text-[40px] text-[#352F36]">
        Top Individuals
      </div>
      <div className="text-[#414B5A] opacity-[50%] font-[400] text-[30px]">
        Meet the exceptional talent shaping the future of freelancing
      </div>
      <div className="w-fit h-fit grid medium:grid-cols-[repeat(2,1fr)] mobile:grid-cols-[repeat(1,1fr)] laptop:grid-cols-[repeat(4,1fr)] grid-cols-[repeat(4,1fr)] gap-6">
        {freelancers.slice(0, visibleCount).map((freelancer, index) => (
          <div
            key={index}
            onClick={() => handleImageClick(index)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            className={`relative shadow-lg  h-[425px] rounded-[15px] cursor-pointer overflow-hidden transition-all duration-300 ${
              activeIndex === index
                ? "w-[380px] laptop:w-[350px]"
                : "w-[290px] laptop:w-[248px]"
            }`}
          >
            <img
              className="absolute w-full h-full rounded-[15px] object-cover"
              src={
                freelancer.profileImage
                  ? handleBase64Change(freelancer.profileImage)
                  : require("../../assets/noimage.png")
              }
            />
            <div className="relative h-full w-full p-5 text-[#FFFFFF] flex flex-col justify-between">
              <div className="relative h-full w-full py-5 text-[#FFFFFF] flex flex-row justify-between">
                <div className="relative h-full w-full p-5 text-[#FFFFFF] flex flex-col justify-between">
                  <div>
                    <span className="font-[500] text-[23px]">{index + 1}</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="font-[500] text-[23px]">
                      {freelancer.name}
                    </span>
                    <span className="font-[400] text-[16px]">
                      {freelancer.role}
                    </span>
                  </div>
                </div>
                <div
                  className={`relative h-full w-full p-5 text-[#FFFFFF] flex flex-col justify-between items-end ${
                    activeIndex === index ? "block" : "hidden"
                  }`}
                >
                  <div className="flex text-[white]">
                    <FavoriteBorderIcon />
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <span className="text-[white]">
                      <StarIcon /> {freelancer.rating}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`w-full p-5 text-[#FFFFFF] transition-opacity duration-300 flex flex-row justify-between ${
                  activeIndex === index && hoveredIndex === index
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="text-[white]">
                  ${freelancer.totalEarnings}.00/hr
                </div>
                <div
                  className="text-[white] flex-row items-center"
                  onClick={() => handleProjectClick(freelancer.userId)}
                >
                  view profile <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-5">
        <BgButton buttonName="See more" onClickHandler={handleSeeMoreClick} />
      </div>
    </div>
  );
}
