import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner, { apiUrl } from "../utils/constants";
import { Card, IconButton } from "ui-neumorphism";
import BackButton from "../components/Button/BackButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NextButton from "../components/Button/NextButton";
const OtpVerification = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || { email: "" };
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && !isNaN(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length !== 4 || isNaN(enteredOtp)) {
      setError("Verification code expired or invalid. Please retry");
    } else {
      try {
        setLoading(true);
        const response = await axios.post(
          apiUrl + `/auth/verify/${enteredOtp}`
        );
        console.log("OTP Verified:", response.data);
        setOtp(["", "", "", ""]);
        setError("");
        navigate("/newpassword", { state: { email } });
      } catch (error) {
        setError("Verification code expired or invalid. Please retry");
        console.error("OTP Verification Error:", error);
        setLoading(false);
      }
    }
  };

  const resendOtp = async () => {
    try {
      await axios.post(apiUrl + `/auth/sendOtp/${email}`);
      toast.success("Password reset link sent to your email");
    } catch (error) {
      toast.error("Failed to send reset link. Please try again later.");
    }
  };

  const isOtpFilled = otp.every((digit) => digit !== "");
  if (loading) return <Spinner titleName={"Loading ..."} />;
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi p-1 pb-0">
      <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className="-mt-2">
        <BackButton />
      </div>
      <div className="relative w-full h-full flex flex-col  justify-between  items-center">
        <div className="small:mt-[50%] mt-[5%] z-50 w-[100%] h-full flex flex-col gap-5 justify-center items-center">
          <h1 className="text-[#352F36] text-[40px] small:text-[30px]  font-[700] font-Satoshi w-[100%] text-center">
            Password reset
          </h1>
          <p className="w-[100%] items-center justify-center text-center flex flex-row mobile:flex-col gap-1 text-gray-600 small:text-[20px] text-[30px]">
            <span>We sent a code to</span>
            <span className="text-[30px] small:text-[20px] font-[500]">
              {" "}
              <MailOutlineIcon />
              {email}
            </span>
          </p>
          <div className="w-[100%] h-fit flex flex-row gap-5 items-center justify-center">
            {otp.map((digit, index) => (
              <input
                className={`w-[60px] h-[60px] text-center bg-[#E3EDF7] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out text-[40px] font-[700] focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[10px] justify-center focus:outline-none hide-caret ${
                  error ? "opacity-50" : ""
                }`}
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          {error && (
            <p className="w-[100%] flex flex-row items-center justify-center gap-2 text-[#FF716A] text-[18px]">
              <IconButton
                rounded
                text={false}
                color="var(--error)"
                style={{ width: "24px", height: "24px" }}
                size="small"
              >
                <img
                  src={require(`../assets/error.png`)}
                  className="w-[17px] h-[17px]"
                />
              </IconButton>
              {error}
            </p>
          )}
          <NextButton
            bgActive={isOtpFilled ? true : false}
            buttonName="Continue"
            nextHandler={handleSubmit}
          />
          <div className="w-[100%] flex flex-row w-full ites-center justify-center  gap-3 text-center">
            <span className="text-zinc-800 text-[20px] font-[500]">
              Didn’t receive the email?
            </span>
            {/* <span className="text-slate-950 text-[22px] font-[500]"></span> */}
            <span
              className="text-blue-800 text-[20px] hover:underline cursor-pointer font-[500]"
              onClick={resendOtp}
            >
              Click to resend
            </span>
          </div>
          <Card
            style={{ borderRadius: "15px" }}
            className="hover:scale-[1.02] cursor-pointer"
          >
            <div
              className="w-fit  hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] rounded-[15px] text-[18px] text-center justify-center p-3 px-6 items-center font-Satoshi font-[500]"
              onClick={() => navigate("/login")}
            >
              <span className=" font-[500] ">
                {" "}
                <ArrowBackIcon className="font-[22px] " /> Back to
              </span>{" "}
              <span className=" font-[500]  text-indigo-800"> Login page </span>
            </div>{" "}
          </Card>
        </div>
        <div className="w-[100%] h-[38%] z-10  relative flex  justify-center">
          <div className="absolute bottom-0">
            <img
              src={require(`../assets/forgetpasswordbg.png`)}
              className="relative h-[250px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
