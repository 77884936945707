import React from "react";
import NextButton from "../Button/NextButton";
import { useNavigate } from "react-router-dom";

export default function PostedSuccessFull() {
  const navigate = useNavigate();
  const returnHomeHandler = () => {
    navigate("/client-home-view");
  };
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi overflow-x-hidden p-4">
      <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className="w-full h-full grid place-items-center gap-3">
        {" "}
        <div className="w-full h-fit grid place-items-center gap-3">
          <div className="[box-shadow:18.75px_18.75px_37.5px_0px_#A6ABBD,_-18.75px_-18.75px_37.5px_0px_#FAFBFF] w-[150px] h-[150px] rounded-full grid place-items-center">
            <img src={require("../../assets/thumb-logo.png")} alt="" />
          </div>
          <div className="font-[700] text-[45px] text-center text-[#352F36] ">
            Your job has been posted successfully
          </div>
          <div className="font-[400] text-center text-[30px] text-[#414B5A]">
            Your post is now live and accessible to our vast network of talented
            freelancers.
          </div>
          <div className="py-4">
            <NextButton
              bgActive={true}
              buttonName="Return to homepage"
              nextHandler={returnHomeHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
