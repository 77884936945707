

import { mdiGreaterThan, mdiLessThan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useRef } from "react";
import Slider from "react-slick";
import { Avatar, IconButton } from "ui-neumorphism";
import pic_1 from "../../assets/projectPortfolio/img-1.png";
import pic_2 from "../../assets/projectPortfolio/img-2.png";
import pic_3 from "../../assets/projectPortfolio/img-3.png";
import pic_4 from "../../assets/projectPortfolio/img-4.png";
import { FaEye } from "react-icons/fa";

export default function ProfilesForYourRequirements() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    className: "slider variable-width flex flex-row w-full gap-5",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const portfolios = [
    {
      image: pic_1,
      name: "John abel",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_2,
      name: "Katherine",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_3,
      name: "Nova declan",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_4,
      name: "Langford",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_1,
      name: "John abel",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_2,
      name: "Katherine",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_3,
      name: "Nova declan",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
    {
      image: pic_4,
      name: "Langford",
      role: "UX UI Designer",
      country: "india",
      costPerHour: 200,
      successRate: 100,
    },
  ];
  return (
    <div className="flex flex-col gap-4 w-full h-fit px-4">
      <div className="text-[#051118]  text-[22px] font-[700] leading-[51.3px] text-left">
      Similar profiles that matches your requirement
      </div>
      <div className="slider-container">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {portfolios.map((folio, _) => (
            <div className="p-2">
              <div className="[box-shadow:4.84px_4.84px_7.26px_0px_#BDC2C740,-4.84px_-4.84px_7.26px_0px_#FFFFFF40] w-[300px] min-h-[111px] h-fit rounded-[26px] flex flex-col overflow-hidden">
                <div className="w-full h-full flex flex-col gap-4 justify-between gap-10">
                  <div className="w-full flex flex-row gap-10">
                    <div className="-ml-4 -mt-4 [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit h-fit p-2 rounded-full">
                      <Avatar size={100} src={folio.image} />
                    </div>
                    <div className="w-full flex flex-col gap-2 pr-4 py-2">
                      <div className="flex flex-row w-full justify-end items-center gap-1">
                        {folio.country}{" "}
                        <img
                          src={require("../../assets/flag.png")}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <div className="w-full flex flex-col items-start justify-start text-[14px] gap-1">
                        <span className="w-full pl-2">
                          ${folio.costPerHour}.00 / hr
                        </span>{" "}
                        <span className="flex flex-row text-[14px] w-full text-[#089949] ">
                          <img
                            className="w-[24px] h-[24px]"
                            src={require("../../assets/praposals/prize-award-icon.png")}
                            alt=""
                          />
                          {folio.successRate}% Success rate
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 pb-2 flex flex-row justify-between gap-5">
                    <div className="flex flex-col ">
                      <span className="font-[700] text-[23px] text-[#352F36] ">
                        {folio.name}
                      </span>
                      <span className="font-[500] text-[11px] text-[#051118] opacity-[50%]">
                        {folio.role}
                      </span>
                    </div>
                    <div className="w-[100px] h-[40px] text-[#ffffff] text-center  px-3 flex fex-row gap-1 items-center justify-center text-[16px] cursor-pointer hover:scale-[1.02] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] rounded-[20px]">
                      <FaEye /> View
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-right w-full flex flex-row justify-end pt-4">
          <div className="w-fit flex flex-row gap-4">
            <IconButton rounded text={false} color="#4448B3" onClick={previous}>
              <Icon path={mdiLessThan} size={0.8} />
            </IconButton>
            <IconButton rounded text={false} color="#4448B3" onClick={next}>
              <Icon path={mdiGreaterThan} size={0.8} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}
