import React from "react";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import SignalLightsDots from "../../utils/SignalLightsDots";
import zoho from "../../assets/projects_logos/zoho.png"
import accenture from "../../assets/projects_logos/accenture.png"
import cognigant from "../../assets/projects_logos/cognigant.png"
import infosys from "../../assets/projects_logos/infosys.png"
import tcs from "../../assets/projects_logos/tcs.png"
import hcl from "../../assets/projects_logos/hcl.png"
import ibm from "../../assets/projects_logos/ibm.png"
import SeeMoreButton from "../Button/SeeMoreButton";
import ApprochButton from "../Button/ApprochButton";




export default function RequestForProposal() {
    const trendingCompanies=[
        {
            companyName:"Infosys",
            companyLogo:infosys
        },
        {
            companyName:"TCS",
            companyLogo:tcs
        },
        {
            companyName:"HCL",
            companyLogo:hcl
        }
    ]
  return (
    <div className="flex flex-col gap-4 pb-10">
      <div className="font-[700] text-[26px] text-[#352F36]">
        <h1>Request for proposal</h1>
      </div>
      <div className="flex flex-col gap-4">
        <div className="w-full flex flex-col gap-4 h-fit min-h-[100px] p-4 card rounded-[14px]">
          <div className="flex flex-row justify-between">
            <div className="w-fit flex flex-row gap-4 items-center">
              <InnerShadowAvatar
                imgSrc={require("../../assets/projects_logos/health_fitness.png")}
                size={30}
              />
              <h1 className="font-[700] text-[24px] ">Health & Fitness app</h1>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <h2 className="font-[500] text-[20px] ">Status:</h2>
              <button className="border border-[#089949] text-[#089949] p-1 px-4 rounded-[8px]">
                Open
              </button>
            </div>
          </div>
          <div className="font-[400] text-[18px]">
            We are embarking on the development of a comprehensive Health &
            Fitness app aimed at empowering users to achieve their fitness
            goals, improve overall health, and lead a more active lifestyle.{" "}
          </div>
          <div className="flex flex-row gap-4 justify-between items-center">
            <div>
              <SignalLightsDots />
            </div>
            <div className="flex flex-row gap-4">
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">Open</button>
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">View RFI</button>
                <button className="w-fit flex flex-row gap-2 items-center px-6 py-1 bg-blue text-white rounded-[8px]">View Proposals  <div className="w-[18px] h-[18px] text-black grid place-content-center text-[12px] rounded-full bg-white">3</div></button>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4 h-fit min-h-[100px] p-4 card rounded-[14px]">
          <div className="flex flex-row justify-between">
            <div className="w-fit flex flex-row gap-4 items-center">
              <InnerShadowAvatar
                imgSrc={require("../../assets/projects_logos/ecommerce.png")}
                size={30}
              />
              <h1 className="font-[700] text-[24px] ">E-commerce website</h1>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <h2 className="font-[500] text-[20px] ">Status:</h2>
              <button className="border border-[#089949] text-[#089949] p-1 px-4 rounded-[8px]">
                Open
              </button>
            </div>
          </div>
          <div className="font-[400] text-[18px]">
          We propose to develop a fully functional e-commerce website tailored to your specific needs. This platform for selling your products online, providing customers with a seamless shopping experience.
          </div>
          <div className="flex flex-row gap-4 justify-between items-center">
            <div>
              <SignalLightsDots />
            </div>
            <div className="flex flex-row gap-4">
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">Open</button>
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">View RFI</button>
                <button className="w-fit flex flex-row gap-2 items-center px-6 py-1 bg-blue text-white rounded-[8px]">View Proposals  <div className="w-[18px] h-[18px] text-black grid place-content-center text-[12px] rounded-full bg-white">3</div></button>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4 h-fit min-h-[100px] p-4 card rounded-[14px]">
          <div className="flex flex-row justify-between">
            <div className="w-fit flex flex-row gap-4 items-center">
              <InnerShadowAvatar
                imgSrc={require("../../assets/projects_logos/logo_design.png")}
                size={30}
              />
              <h1 className="font-[700] text-[24px] ">Project management web app</h1>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <h2 className="font-[500] text-[20px] ">Status:</h2>
              <button className="border border-[#FF1539] text-[#FF1539] p-1 px-4 rounded-[8px]">
                Closed
              </button>
            </div>
          </div>
          <div className="font-[400] text-[18px]">
          We propose to develop a comprehensive project management web application to streamline your project workflows, The web app will make planning, tracking, and managing projects, tasks, and resources.
          </div>
          <div className="flex flex-row gap-4 justify-between items-center">
            <div>
              <SignalLightsDots />
            </div>
            <div className="flex flex-row gap-4">
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">Open</button>
                <button className="w-fit px-6 py-1 bg-blue text-white rounded-[8px]">Go to project</button>
            </div>
          </div>
        </div>


      </div>
      <div className="grid grid-cols-2 gap-4 py-2">
        <div className="col-span-1  min-h-[100px] max-h-fit card p-4 rounded-[14px] flex flex-col gap-4">
            <div className="font-[700] text-[24px] text-[#352F36]">Recently viewed</div>
            <div className="flex flex-col gap-4">
                <div className="card rounded-[14px] p-2 px-4 w-full flex flex-row items-center justify-between gap-4">
                    <div className="flex flex-row gap-2 items-center">
                        <InnerShadowAvatar imgSrc={zoho} size={30} />
                        <div className="text-[#352F36]">
                            <h1 className="font-[700] text-[18px]">ZOHO</h1>
                            <h2 className="text-[14px] font-[400] opacity-[50%]">#Trending 1</h2>
                        </div>
                    </div>
                    <div className="red h-fit w-fit px-2 rounded-[14px] text-[#FFFFFF]">New</div>
                </div>
                <div className="card rounded-[14px] p-2 px-4 w-full flex flex-row items-center justify-between gap-4">
                    <div className="flex flex-row gap-2 items-center">
                        <InnerShadowAvatar imgSrc={accenture} size={30} />
                        <div className="text-[#352F36]">
                            <h1 className="font-[700] text-[18px]">Accenture</h1>
                            <h2 className="text-[14px] font-[400] opacity-[50%]">#Trending 4</h2>
                        </div>
                    </div>
                    <div className="red h-fit w-fit px-2 rounded-[14px] text-[#FFFFFF]">New</div>
                </div>
                <div className="card rounded-[14px] p-2 px-4 w-full flex flex-row items-center justify-between gap-4">
                    <div className="flex flex-row gap-2 items-center">
                        <InnerShadowAvatar imgSrc={cognigant} size={30} />
                        <div className="text-[#352F36]">
                            <h1 className="font-[700] text-[18px]">Cognizant</h1>
                            <h2 className="text-[14px] font-[400] opacity-[50%]">#Trending 3</h2>
                        </div>
                    </div>
                    <div className="red h-fit w-fit px-2 rounded-[14px] text-[#FFFFFF]">New</div>
                </div>
            </div>
          <div className="w-full grid place-items-center">
          <SeeMoreButton />
          </div>
        </div>


        <div className="col-span-1  min-h-[100px] max-h-fit card p-4 rounded-[14px] flex flex-col gap-4">
            <div className="font-[700] text-[24px] text-[#352F36]">Trending profiles</div>
            <div className="flex flex-col gap-4">
              {
                trendingCompanies.map((company,index)=>(
                    <CompanyViewCardTrending companyName={company.companyName} companyImg={company.companyLogo} />
                ))
              }
              

            </div>
          <div className="w-full grid place-items-center">
          <SeeMoreButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export const CompanyViewCardTrending=({companyName,companyImg})=>{
    return(
        <div className="relative card rounded-[14px] p-3 px-4 w-full flex flex-row items-center justify-between gap-4">
        <div className="absolute right-0 w-[18px] grid place-content-center text-[18px] pb-1 font-[600] text-white h-[18px] red rounded-full top-0 -mt-3">
            &times;
        </div>
        <div className="flex flex-row gap-2 items-center">
            <InnerShadowAvatar imgSrc={companyImg} size={30} />
            <div className="text-[#352F36]">
                <h1 className="font-[700] text-[18px] ">{companyName}</h1>
            </div>
        </div>
        <div>
            <ApprochButton />
        </div>
    </div>
    )
}