import React, { useState } from "react";
import NextButton from "../Button/NextButton";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

export default function TopInterestingBlogsAndArticles() {
  const [slideIndex, setSlideIndex] = useState(1);
  const images = [
    "https://i.pinimg.com/originals/2b/de/de/2bdede0647e3cdf75b44ea33723201d9.jpg",
    "https://images6.alphacoders.com/462/thumb-1920-462371.jpg",
    "https://wallpaperaccess.com/full/1154341.jpg",
    "https://wallpapercave.com/wp/wp2634222.jpg",
    "https://images5.alphacoders.com/343/thumb-1920-343645.jpg",
  ];

  const seeMoreHandler=()=>{

  }

const prevHandler=()=>{
 if (slideIndex>1) {
  setSlideIndex(slideIndex-1)
 }
}
const postHandler=()=>{
  if (slideIndex<5) {
    setSlideIndex(slideIndex+1)
   }
}

  return (
    <div className="flex flex-col gap-4 w-full h-fit py-4 pb-8 px-4 mobile:px-1">
      <div className="text-[#051118]  text-[38px] medium:text-[30px] small:text-[24px] w-full font-bold leading-[51.3px] text-left">
        Top people with their interesting blogs and articles
      </div>
      <div className="flex flex-col gap-6">
    
      <section id="slider">
    <div className="left-arrow"  onClick={prevHandler}><FaArrowLeftLong /></div>
  <input type="radio" name="slider" id="s1" checked={slideIndex===1} onChange={e => {}} />
  <input type="radio" name="slider" id="s2" checked={slideIndex===2} onChange={e => {}}/>
  <input type="radio" name="slider" id="s3" checked={slideIndex===3} onChange={e => {}}/>
  <input type="radio" name="slider" id="s4" checked={slideIndex===4} onChange={e => {}}/>
  <input type="radio" name="slider" id="s5" checked={slideIndex===5} onChange={e => {}}/>
<div className="right-arrow"  onClick={postHandler}><FaArrowRightLong /></div>

  <label htmlFor="s1" id="slide1"><img src={require('../../assets/landingScreen/gallery-slides/Slide Item — 1.png')} alt=""/></label>
  <label htmlFor="s2" id="slide2"><img src={require('../../assets/landingScreen/gallery-slides/Slide Item — 2.png')} alt=""/></label>
  <label htmlFor="s3" id="slide3"><img src={require('../../assets/landingScreen/gallery-slides/Slide Item — 3.png')} alt=""/></label>
  <label htmlFor="s4" id="slide4"><img src={require('../../assets/landingScreen/gallery-slides/Slide Item — 4.png')} alt=""/></label>
  <label htmlFor="s5" id="slide5"><img src={require('../../assets/landingScreen/gallery-slides/Slide Item — 5.png')} alt=""/></label>
</section>
      </div>
      <div className="w-full grid place-items-center mt-10 mobile:mt-2 largeScreen:mt-14 pt-4">
        <NextButton bgActive={true} buttonName="See more" nextHandler={seeMoreHandler}/>
      </div>
    </div>
  );
}
