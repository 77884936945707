import React, { useContext, useEffect, useState } from "react";
import PostNavHeader from "../Post-Jobs/util/PostNavHeader";
import ProjectsPreview from "./ProjectsPreview";
import ProjectsList from "./ProjectsList";
import ContractsPreview from "./ContractsPreview";
import axios from "axios";
import Spinner, { apiUrl } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import BgButton from "../Button/BgButton";
import BorderButton from "../Button/BorderButton";
import { ClientIdContext, TokenContext } from "../../App";

export default function ProjectsView() {
  const [isProject, setIsProject] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [projectLoader, setProjectLoader] = useState(false);
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  useEffect(() => {
    getAllProjects();
    // getAllContracts()
  }, []);

  const getAllProjects = () => {
    setLoader(true);
    try {
      axios
        .get(apiUrl + `/project/getProjectsOfUser/${clientId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setProjectList(res.data);
            setActiveProjectId(res.data[0].projectId);
            console.log(res.data);
            console.log(res.data[0].projectId);
            getProjectByProjectId(res.data[0].projectId);
            getAllContracts();
          } else {
            setProjectList([]); // Clear project list if no projects found
            setActiveProjectId(null); // Ensure active project ID is reset
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.message);
        });
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (activeProjectId !== null) {
      getProjectByProjectId(activeProjectId);
    }
  }, [activeProjectId]);

  const getProjectByProjectId = (id) => {
    setProjectLoader(true);
    try {
      axios
        .get(apiUrl + `/project/getById/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setProject(res.data);
          setProjectLoader(false);
        })
        .catch((err) => {
          setProjectLoader(false);
          toast.error(err.message);
        });
    } catch (error) {
      setProjectLoader(false);
      toast.error(error.message);
    }
  };
  const getAllContracts = () => {
    // setLoader(true)
    try {
      axios
        .get(apiUrl + `/contract/getByContractByClient/${clientId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setContractList(res.data);
          console.log(res.data);
          // setLoader(false)
        })
        .catch((err) => {
          // setLoader(false)
          toast.error(err.response.data.message);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const projectHandler = () => {
    if (projectList.length > 0) {
      setIsProject(false);
    }
  };
  const contractsHandler = () => {
    if (projectList.length > 0) {
      setIsProject(true);
    }
  };

  const navigate = useNavigate();

  return loader ? (
    <Spinner />
  ) : (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} titleName="Projects & Contracts" />
      {projectList.length > 0 &&
        (isProject ? (
          <div className={`w-fit flex flex-row gap-6 px-10 py-4`}>
            <BorderButton
              buttonName="Projects"
              onclickHandler={projectHandler}
            />

            <BgButton
              buttonName="Contracts"
              onClickHandler={contractsHandler}
            />
          </div>
        ) : (
          <div className={`w-fit flex flex-row gap-6 px-10 py-4`}>
            <BgButton buttonName="Projects" onClickHandler={projectHandler} />

            <BorderButton
              buttonName="Contracts"
              onclickHandler={contractsHandler}
            />
          </div>
        ))}
      {isProject && projectList.length > 0 ? (
        <div className="px-10 py-8 small:px-4 w-full flex flex-col gap-5 overflow-auto">
          <ContractsPreview
            isProject={isProject}
            setIsProject={setIsProject}
            contractList={contractList}
            setContractList={setContractList}
          />
        </div>
      ) : projectList.length > 0 ? (
        <div className="px-10 py-8  w-full grid grid-cols-3 gap-5 overflow-auto">
          <div className={`medium:hidden top-0 left-0 col-span-2`}>
            <ProjectsPreview
              isProject={isProject}
              setIsProject={setIsProject}
              project={project}
            />
          </div>
          <div className="medium:col-span-3 col-span-1">
            <ProjectsList
              projectList={projectList}
              activeProjectId={activeProjectId}
              setActiveProjectId={setActiveProjectId}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full grid place-items-center font-[700] text-center text-[28px]">
          <h1>
            You haven't post any projects till now! <br />
            <span className="text-[16px] font-[400]">
              {" "}
              Please post project by clicking this link{" "}
              <a
                className="underline hover:text-red-700 cursor-pointer"
                onClick={() => navigate("/project-duration")}
              >
                Post new job
              </a>{" "}
            </span>
          </h1>
        </div>
      )}
    </div>
  );
}
