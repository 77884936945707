import React, { useState } from "react";
import PostNavHeader from "../Post-Jobs/util/PostNavHeader";
import { Avatar, Card } from "ui-neumorphism";
import ProjectPortfolio from "./ProjectPortfolio";
import ProfilesForYourRequirements from "./ProfilesForYourRequirements";
import ProfileBadgeView from "./ProfileBadgeView";
import ChatScreen from "./ChatScreen";
import { useNavigate, useLocation, useParams } from "react-router-dom";
export default function ViewProfileTopRatedIndividuals() {
  
  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} titleName="sarah's" />
      <div className="px-10 py-8 flex w-full flex-col gap-5 overflow-auto">
        <ProfileHeader />
        <ProjectPortfolio />
        <ProfilesForYourRequirements />
      </div>
    </div>
  );
}

export const ProfileHeader = () => {
  const [msgs,setMsgs]=useState(true)
  const location=useLocation()
  const navigate=useNavigate();
  return (
    <div className="w-full grid grid-cols-3 gap-10">
     <div className="col-span-2 flex flex-col gap-10"> 
     <div className="flex flex-row h-fit items-start gap-5">
        <div className="relative [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit h-fit p-2 rounded-full">
          <Avatar
            size={80}
            src={require("../../assets/praposals/sarah_logo.png")}
          />
          <img
            className=" absolute -mt-4 ml-16 w-[22px] h-[22px]"
            src={require("../../assets/praposals/logo-star-icon.png")}
          />
        </div>
        <div className="flex flex-col mt-4">
          <div className="flex flex-row gap-10 items-end">
            <div className="flex flex-col h-full justify-center">
              <span className="font-[700] text-[20px] text-[#352F36]">
                sarah
              </span>
              <span className="text-[#414B5A] font-[500] text-[14px] opacity-[50%]">
                UI UX Designer
              </span>
            </div>
            <div className="flex flex-row gap-1 h-full items-center">
              <span>India</span>{" "}
              <img
                className="w-[24px] h-[17px]"
                src={require("../../assets/praposals/indian-logo.png")}
              />
            </div>
          </div>
         {location.pathname!=="/" &&  <div className="pt-4 flex flex-row gap-4">
            <div className="text-[#2485FF] border rounded-[10px] w-fit border-[#2485FF] text-[18px] font-[700] p-1 px-3">
              View profile
            </div>
            <div className="text-[#ffffff]  rounded-[10px] w-fit bg-[#2485FF] text-[18px] font-[700] p-1 px-3" onClick={()=>navigate('/proposal-portfolio')}>
              View portfolio
            </div>
          </div>}
        </div>
      </div>
      <DescriptionView />
     </div>
     {location.pathname!=="/"?
      <div className="[box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] min-w-[200px] min-h-[100px] h-fit flex flex-col items-center gap-4 px-10 py-6">
        <div className="w-[50px] h-[50px] rounded-full bg-[#2485FF] grid place-items-center">
          <img src={require("../../assets/praposals/mdg-icon.png")} alt="" />
        </div>
        <div className="rounded-[24px] text-[#051118] opacity-[80%] w-fit h-fit">
          You can message sarah for her availability
        </div>

        <div className="text-[#ffffff]  rounded-[10px] w-fit bg-[#2485FF] text-[18px] font-[700] p-1 px-3">
         Message
        </div>
      </div>
      :
      <div className="col-span-1 max-w-[350px]">
      <div className="rounded-[20px] [box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] w-full h-full flex flex-col gap-5 p-4 py-6">
      {
        msgs?
        <ChatScreen />
        :
        <ProfileBadgeView />
      }
      </div>
    </div>
  }
    </div>
  );
};

export const DescriptionView=()=>{
  const note="Are you looking for guidance for Logo Design, Graphic Design, Brand Identity, or Packaging Design? I can help! Highly Rated & Highest fixed jobs (4.4k+) Freelancer on Upwork, specializing in Logo Design & Branding, Graphic Design & Digital & Print Marketing Materials. Are you looking for guidance for Logo Design, Graphic Design, Brand Identity, or Packaging Design? I can help! Highly Rated & Highest fixed jobs (4.4k+) Freelancer on Upwork, specializing in Logo Design & Branding, Graphic Design & Digital & Print Marketing Materials. "
  const skills=[
    "Prototyping",
    "Wireframing",
    "Highfedility",
    "User research",
    "User flow",
    "Information architecture",
    "Branding",
  ]
  const stringWithNewLines = note.split(". ").join("\n\n");
  return(
    <div className="w-full flex flex-col gap-10 max-w-[800px]">
        <div className="flex flex-col gap-2">
            <h1 className="font-[700] text-22px] text-[#352F36] ">Note*</h1>
            <Card rounded={20} className="p-4">
              <p className=" whitespace-pre-line w-full h-fit font-[500] text-[18px] text-[#352F36]">
                {stringWithNewLines}
              </p>
            </Card>
          </div>
          <div className="flex flex-row gap-2 flex-wrap">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="rounded-[30px] flex flex-row gap-2 w-fit h-fit items-center px-4 py-2 [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF]"
              >
                <div className="bg-[#2485FF] w-[10px] h-[10px] rounded-full"></div>
                <div className="font-[500] text-[18px] text-[#352F36]">
                  {skill}
                </div>
              </div>
            ))}
          </div>
    </div>
  )
}