import React from "react";
import { hoverBg } from "../../utils/constants";


export default function NextButton({bgActive, buttonName,nextHandler}) {
  return (
    <div onClick={()=>{bgActive && nextHandler()}} className={`min-w-[196px] ${hoverBg}  max-w-fit h-[60px] text-[20px] font-[700] ${bgActive? ` text-white active:scale-[1.02] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out cursor-pointer bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`:`text-[#929CA8] hover:text-[#FFFFFF] ${hoverBg} cursor-not-allowed`} [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px]  grid place-items-center	 capitalize p-1 px-4`}>
      {buttonName}
    </div>
  );
}

 