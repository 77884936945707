import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Card } from "ui-neumorphism";
import Spinner, { apiUrl } from "../utils/constants";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BackButton from "../components/Button/BackButton";
import NextButton from "../components/Button/NextButton";
const SetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [passwordValidationResults, setPasswordValidationResults] = useState(
    []
  );
  const [startedTyping, setStartedTyping] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordEmptyError, setPasswordEmptyError] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { email } = location.state || { email: "" };

  const passwordValidationRules = [
    {
      regex: /.{8,}/,
      message: "At least 8 characters",
    },
    {
      regex: /[a-z]/,
      message: "Contains one lowercase letter",
    },
    {
      regex: /[A-Z]/,
      message: "Contains one uppercase letter",
    },
    {
      regex: /\d/,
      message: "Contains one number",
    },
    {
      regex: /[@#$%^&+=]/,
      message: "Contains one special character ( @,#,%, etc.)",
    },
  ];

  const validatePassword = (password) => {
    return passwordValidationRules.map((rule) => ({
      ...rule,
      valid: rule.regex.test(password),
    }));
  };

  useEffect(() => {
    setPasswordValidationResults(validatePassword(password));
  }, [password]);

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const passwordErrors = passwordValidationResults.filter(
        (rule) => !rule.valid
      );

      if (password !== confirmPassword) {
        passwordErrors.push({ message: "Passwords do not match" });
      }

      if (passwordErrors.length > 0) {
        setErrors(passwordErrors);
        return;
      }

      // Send request to update password
      const response = await axios.post(
        apiUrl + `/auth/updatePassword/${email}`,
        null,
        {
          params: { password: password },
        }
      );

      if (response.status === 200) {
        navigate("/passwordsuccess");
      }
    } catch (error) {
      setErrors([{ message: "An error occurred. Please try again." }]);
      setLoading(false);
    }
  };

  const allValid = passwordValidationResults.every((rule) => rule.valid);

  const handleConfirmPasswordFocus = () => {
    if (!password) {
      setPasswordEmptyError(true); // Set the specific error state
    } else {
      setPasswordEmptyError(false); // Clear the specific error state if password is not empty
    }
  };
  if (loading) return <Spinner titleName={"Loading ..."} />;
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi overflow-x-hidden p-4 pb-0">
      <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className="-mt-2 ">
        <BackButton />
      </div>
      <div className="  relative w-full  h-full flex flex-col justify-center  items-center">
        <div
          className={`small:mt-[50%] mt-[2%] z-50 w-[100%] h-full flex flex-col  ${
            startedTyping ? "gap-3" : "gap-5"
          } justify-center items-center`}
        >
          <h1 className="w-[100%] text-center  mobile:text-[40px] text-zinc-800 text-[45px] font-bold font-Satoshi">
            Set new password
          </h1>
          <p className="w-[100%] text-center text-gray-600 text-3xl font-normal font-Satoshi">
            Your password must be different from previously used password
          </p>
          <div className="w-[60%] mobile:w-[90%] medium:w-[70%] flex flex-col gap-1 items-start">
            <label className="text-center text-[#352F36] text-[22px] font-[500] font-Satoshi">
              New password
            </label>
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setStartedTyping(true);
                }}
                className={`border p-3 w-full focus:scale-[1.02] transform transition-transform duration-400 ease-in-out focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] p-1 px-4 font-[400] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] font-Satoshi focus:outline-none ${
                  startedTyping && !allValid ? "border-red-500" : ""
                } ${passwordEmptyError ? "border-red-500" : ""}`}
              />
              <IconButton
                text={false}
                rounded
                size="small"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </div>
            {startedTyping && !allValid && (
              <div className="p-3 mobile:grid flex flex-row gap-3 flex-wrap  mt-1 medium:grid-cols-2 mobile:grid-cols-1  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-1 rounded-[20px]">
                {passwordValidationResults.map((rule, index) => (
                  <div
                    key={index}
                    className={`flex items-center gap-2 ${
                      rule.valid ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {rule.valid ? (
                      <IconButton text={false} rounded size="small">
                        <img
                          src={require(`../assets/right.png`)}
                          className="w-[14px] h-[14px]"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{ width: "20%" }}
                        rounded
                        text={false}
                        color="var(--error)"
                        size="small"
                        className="w-[20%] flex items-center p-10"
                      >
                        <img
                          src={require(`../assets/wrong.png`)}
                          className="w-[14px] h-[14px]"
                        />
                      </IconButton>
                    )}
                    {rule.message}
                  </div>
                ))}
              </div>
            )}
            {passwordEmptyError && (
              <div className="text-red-500 mt-1">
                Password should not be blank
              </div>
            )}
          </div>
          <div className="w-[60%] medium:w-[70%] mobile:w-[90%] flex flex-col gap-1 items-start">
            <label className="text-center text-[#352F36] text-[22px] font-[500] font-Satoshi">
              Confirm Password
            </label>
            <div className="relative w-full">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={handleConfirmPasswordFocus}
                className="border p-3 w-full focus:scale-[1.02] transform transition-transform duration-400 ease-in-out focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-1 px-4 font-[400] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[22px] rounded-[15px] font-Satoshi focus:outline-none"
              />
              <IconButton
                text={false}
                rounded
                size="small"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </div>
            {confirmPassword && (
              <div
                className={`flex items-center gap-2 mt-2 ${
                  passwordsMatch ? "text-green-500" : "text-red-500"
                }`}
              >
                {passwordsMatch ? (
                  <IconButton text={false} rounded size="small">
                    <img
                      src={require(`../assets/right.png`)}
                      className="w-[14px] h-[14px]"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    rounded
                    text={false}
                    color="var(--error)"
                    size="small"
                  >
                    <img
                      src={require(`../assets/wrong.png`)}
                      className="w-[14px] h-[14px]"
                    />
                  </IconButton>
                )}
                {passwordsMatch ? "Passwords match" : "Passwords do not match"}
              </div>
            )}
          </div>

          <NextButton
            bgActive={
              password && confirmPassword && password && confirmPassword
                ? true
                : false
            }
            buttonName="Reset Password"
            nextHandler={handleLogin}
          />

          <Card
            style={{ borderRadius: "15px" }}
            className="hover:scale-[1.02] cursor-pointer"
          >
            <div
              className="w-fit  hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] rounded-[15px] text-[18px] text-center justify-center p-3 px-6 items-center font-Satoshi font-[500]"
              onClick={() => navigate("/login")}
            >
              <span className=" font-[500] ">
                {" "}
                <ArrowBackIcon className="font-[22px] " /> Back to
              </span>{" "}
              <span className=" font-[500]  text-indigo-800"> Login page </span>
            </div>{" "}
          </Card>
        </div>
        <div className="w-[100%] h-[30%] z-10  relative flex  justify-center">
          <div className="absolute bottom-0">
            <img
              src={require(`../assets/forgetpasswordbg.png`)}
              className="relative h-[250px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
