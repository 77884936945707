import * as React from "react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DialogTitle, StyledEngineProvider } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker/StaticDatePicker";
import { DashBoardContext } from "../App";
import { monthNames } from "./constants";

function CustomActionBar(props) {
  const { className } = props;
  const { showDate, setShowDate } = React.useContext(DashBoardContext);
  const{selectDate,setSelectDate}=React.useContext(DashBoardContext);
  const [innerShadow, setInnerShadow] = React.useState(false);
  const cancelDateHandler=()=>{
    const date=new Date()
    const day=date.getDate()
    const month=date.getMonth()

    const year=date.getFullYear()

    const viewdate={day,month,year}
    setSelectDate(viewdate);
  }
  return (
    <>
      <DialogActions className={className}>
        <Button onClick={cancelDateHandler}>Cancel</Button>
        <Button onClick={()=>setShowDate(false)}>Ok</Button>
      </DialogActions>
      <DialogTitle
        className={
          " right-0 flex flex-col border-[#CAC4D0]  border-b w-full"
        }
      >
        <div className="text-[#414B5A] font-[500] text-[14px]">
          Select a date
        </div>
        <div className="flex flex-row justify-between">
          <div className="font-[500] text-[30px] text-[#352F36] -ml-2">{selectDate.day} {monthNames[selectDate.month]}, {selectDate.year}</div>
          <div className="flex flex-row gap-4">
          <div
            onClick={() => setInnerShadow(true)}
            onMouseLeave={() => setInnerShadow(false)}
            className={`${
              innerShadow ? "card_inner_shadow" : "card_shadow"
            } cursor-pointer hover:scale-[1.02] w-[40px] h-[40px] rounded-[8px] grid place-items-center`}
          >
            <img
              src={require("../assets/edit-icon.png")}
              alt=""
              sizes=""
              srcSet=""
            />
          </div>
          <div
            onClick={() => setShowDate(false)}
            className="cursor-pointer hover:scale-[1.02] text-white text-[32px] pb-2 bg-[linear-gradient(212.87deg,_#FF716A_23.4%,_#FF2731_251.87%)] card_shadow w-[40px] h-[40px] rounded-[8px] grid place-items-center"
          >
            <span className="-mt-1"> &times;</span>
          </div>{" "}
        </div>
        </div>
       
      </DialogTitle>
    </>
  );
}

export default function DatePicker() {
  const {selectDate,setSelectDate}=React.useContext(DashBoardContext);
  const dateAssignHandler=(e)=>{
    const day=e.date()
    const month=e.month()

    const year=e.year()

    const date={day,month,year}
    setSelectDate(date);
    
  }

  return (
    <div className="text-[10px] w-[360px] h-[200px]">
      <StyledEngineProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker className=""
            sx={{ display: "flex", flexDirection: "column-reverse",background:"#E3EDF7",borderRadius:"20px",
            boxShadow:"5px 5px 10px 0px #A6ABBD,-5px -5px 10px 0px #FAFBFF", 
             }}
            defaultValue={dayjs(new Date())}
            onChange={ e=>dateAssignHandler(e)}
            slots={{
              actionBar: CustomActionBar,
            }}
            slotProps={{
              actionBar: {
                actions: ["today"],
              },
            }}
          />
        </LocalizationProvider>
      </StyledEngineProvider>
    </div>
  );
}
