import React, { useContext, useState } from "react";
import axios from "axios";
import { Card } from "ui-neumorphism";
import { apiUrl, hoverBg } from "../../utils/constants";
import { TokenContext } from "../../App";
export default function Footer({
  scrollToSection,
  categoriesRef,
  findFreelancerRef,
  findCompaniesRef,
  startupsAndIncubatorsRef,
}) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { token, updateToken } = useContext(TokenContext);
  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        apiUrl + `/newsletters/subscribe`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.data.subscribed === true) {
        setEmail("");
        setMessage("Subscribed successfully!");
      }
    } catch (error) {
      console.error("There was an error subscribing!", error);
      setMessage("Failed to subscribe. Please try again.");
    }
  };

  return (
    <footer className="h-fit w-full bg-[#E2EDF7]">
      <Card className="p-10 flex flex-row gap-10 small:flex-col h-fit justify-around">
        <div className="h-fit flex flex-col gap-6">
          <span className="w-[82px] h-[52px] top-[50px] left-[60px]   text-[42px]  leading-[51.66px] text-left text-[#051118]">
            P2H
          </span>
          <span className="w-fit  h-fit   text-[30px] mobile:text-[20px] font-[700] leading-[40.5px] text-left text-[#051118]">
            Subscribe to get our newsletter
          </span>
          <form
            className="flex flex-row items-center medium:flex-col medium:items-start  gap-5"
            onSubmit={handleSubscribe}
          >
            <input
              placeholder="Your email"
              className="w-[350px] focus:scale-[1.02]  mobile:w-full h-[54px] gap-0 rounded-[25px] bg-[#E3EDF7] focus:outline-none px-6 [box-shadow:4px_4px_8px_0px_#BDC2C7BF,-4px_-4px_8px_0px_#FFFFFFBF] focus:[box-shadow:4px_4px_8px_0px_inset_#BDC2C7BF,-4px_-4px_8px_0px_inset_#FFFFFFBF]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className={`${hoverBg} w-fit h-[54] hover:scale-[1.02] text-white font-[700]  p-2 px-6 gap-[16px] rounded-[15px] text-[22px]  leading-[29.7px] text-center [box-shadow:5px_5px_10px_0px_#A6ABBD]  active:[box-shadow:-5px_-5px_10px_0px_#FAFBFF] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`}
            >
              Subscribe
            </button>
          </form>
          {message && <p>{message}</p>}
        </div>
        <div className="flex flex-row mobile:flex-col gap-10">
          <div className="w-full flex flex-row gap-10">
            <div className={list_parent_div}>
              <h2 className={list_title_styles}>Quick Links</h2>
              <ul className={list_ul_styles}>
                <li
                  onClick={() => {
                    scrollToSection(categoriesRef);
                  }}
                  className={list_li_styles}
                >
                  Categories
                </li>
                <li
                  onClick={() => {
                    scrollToSection(findFreelancerRef);
                  }}
                  className={list_li_styles}
                >
                  Find freelancers
                </li>
                <li
                  onClick={() => {
                    scrollToSection(findCompaniesRef);
                  }}
                  className={list_li_styles}
                >
                  Find companies
                </li>
                <li
                  onClick={() => {
                    scrollToSection(startupsAndIncubatorsRef);
                  }}
                  className={list_li_styles}
                >
                  Startups & Incubators
                </li>
              </ul>
            </div>
            <div className={list_parent_div}>
              <h2 className={list_title_styles}>Contact</h2>
              <ul className={list_ul_styles}>
                <li className={list_li_styles}>info@p2h.com</li>
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </footer>
  );
}

const list_li_styles =
  "text-[18px] font-medium leading-[24.3px] w-fit text-left opacity-[50%] cursor-pointer hover:opacity-[90%]";
const list_title_styles = "text-[24px] font-bold leading-[32.4px] text-left";
const list_ul_styles = "flex flex-col gap-5 w-fit";
const list_parent_div = "flex flex-col gap-5 w-fit";
