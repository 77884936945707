import React, { useState } from "react";
import TextInput from "../inputs/TextInput";
import SeeMoreButton from "../Button/SeeMoreButton";
import NextButton from "../Button/NextButton";

export default function CreateRFP({setRFPClicked}) {
  const [projectTitle, setProjectTitle] = useState(null);
  const [projectOverview, setProjectOverview] = useState(null);
  const [filterSkills, setFilterSkill]=useState([])

  const options=[
    "brd","frd","Requirement doc 3","Requirement doc 4","Requirement doc 5","Requirement doc 6"
  ]

  const RFPClickHandler=()=>{
    setRFPClicked(true)
  }
  return (
    <div className="text-[#352F36]">
      <div className="font-[700] text-[24px] ">
        <h1>Request for proposal</h1>
      </div>
      <div className="py-6 flex flex-col gap-4">
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-fit">
            <TextInput
              type={"text"}
              inputValue={projectTitle}
              setInputValue={setProjectTitle}
              labelName={"Project title"}
              placeholder={"Add title"}
            />
          </div>
          <label for="file">
            <div className="cursor-pointer border-dashed border-2 border-gray-300 hover:border-gray-400 hover:scale-[1.01] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-[280px] h-fit rounded-[20px] px-[30px] py-[20px] flex flex-col gap-2 items-center justify-center">
              <div className="flex fex-row gap-2 items-center">
                <div>
                  {" "}
                  <img
                    className=" "
                    src={require("../../assets/document.png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="font-[500] text-[18px] ">Attach document</div>
              </div>
              <div className="font-[500] text-[14px] text-[#352F36] opacity-[50%]">
                Only pdf, docx
              </div>
            </div>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              //  onChange={inputFileHandler}
              name="image"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf"
              multiple=""
              data-original-title="upload photos"
            />
          </label>
        </div>

        <div className="w-full">
          <div className=" w-fit text-[22px] font-medium leading-[29.7px]  pb-4 text-left">
            Project overview
          </div>
          <textarea
            value={projectOverview}
            placeholder="Add overview"
            onChange={(e) => setProjectOverview(e.target.value)}
            className="resize-none w-full min-h-[100px] max-h-[200px]  bg-normal focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[20px] outline-none p-4"
          />
        </div>

        <div className="w-full grid grid-cols-[repeat(2,1fr)] gap-4">
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Project scope"}
            placeholder={"Add project scope"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Project goals"}
            placeholder={"Add project goals"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Project timeline"}
            placeholder={"Add timeline"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Project budget"}
            placeholder={"Add budget $"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Evaluation Criteria"}
            placeholder={"Add criteria"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Resources"}
            placeholder={"Add resources"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Technical requirement"}
            placeholder={"Add requirement"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Qualification & Experience"}
            placeholder={"Add Qua & Exp"}
          />
        
          <div className="w-full flex flex-col gap-3">
          <div className=" text-[20px] font-[500] text-left">
          Proposal format
      </div>
          <label for="file">
          <div className="rounded-[10px] p-3 cursor-pointer hover:scale-[1.01] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF]  px-[30px] h-[60px] flex flex-row gap-2 items-center justify-between">
           
            <div className="font-[500] text-[18px] opacity-[50%]">Add format</div>
            <div className="card p-2 px-3 rounded-[8px]">
              <img className="w-[18px] h-[20px]" src={require('../../assets/document.png')} alt="" />
            </div>
          </div>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            // onChange={inputFileHandler}
            name="image"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf"
            multiple=""
            data-original-title="upload photos"
          />
        </label>
          </div>
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Terms and Conditions"}
            placeholder={"Add T&C"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Contact information"}
            placeholder={"Add contact info"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Appendices"}
            placeholder={"Add appendices"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Current roadblocks"}
            placeholder={"Add roadblocks"}
          />
          <TextInput
            type={"text"}
            inputValue={projectTitle}
            setInputValue={setProjectTitle}
            labelName={"Evaluation metrics"}
            placeholder={"Add metrics"}
          />
        </div>
        <div className="w-full">
          <div className=" w-fit text-[22px] font-medium leading-[29.7px]  pb-4 text-left">
            Submission requirements
          </div>
          <textarea
            value={projectOverview}
            placeholder="Add submission requirements"
            onChange={(e) => setProjectOverview(e.target.value)}
            className="resize-none w-full min-h-[100px] max-h-[200px]  bg-normal focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[20px] outline-none p-4"
          />
        </div>
        <div className="grid grid-cols-[repeat(3,1fr)] gap-4 py-4">
          {options.map((option, index) => (
            <div key={index} className="flex flex-row gap-2 items-center">
              <input
                className="w-[16px] input-bg h-[22px] rounded-[12px]"
                type="checkbox"
                value={option}
                checked={filterSkills && filterSkills.includes(option)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterSkill([...filterSkills, e.target.value]);
                  } else {
                    const arr = filterSkills.filter(
                      (item) => item !== e.target.value
                    );
                    setFilterSkill(arr);
                  }
                }}
              />
              <label className={`font-[400] text-[20px] text-[#352F36] ${option.length>3?"normalcase":"uppercase"}`}>
                {option}
              </label>
            </div>
          ))}
        </div>
        <div className="grid place-items-center">
          <NextButton bgActive={true} buttonName={"Create RFP"} nextHandler={RFPClickHandler} />
        </div>
      </div>
    </div>
  );
}
