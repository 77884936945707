import React from 'react'

export  const OrangeBorderButton=({buttonName,color})=>{
  return (
  <div className='w-fit text-[#FF5F1B] border-[1px] border-[#FF5F1B] px-6 p-1 rounded-[28px]'>
{buttonName}
  </div>
  )
}
export  const GreenBorderButton=({buttonName})=>{
    return (
    <div className='w-fit text-[#089949] border-[1px] border-[#089949] px-6 p-1 rounded-[28px]'>
  {buttonName}
    </div>
    )
  }