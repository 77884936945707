import React, { useState, useEffect, useContext } from "react";
import { Card } from "ui-neumorphism";
import axios from "axios";
import Spinner, { apiUrl } from "../../utils/constants";
import toast from "react-hot-toast";
import ProposalUserView from "./ProposalUserView";
import { ClientIdContext, TokenContext } from "../../App";

export default function ViewProposalsList({ filterSkills, setFilterSkill }) {
  const [senderDetails, setSenderDetails] = useState({});
  const [requestProposals, setRequestProposals] = useState([]);
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/proposals/${clientId}`, {
          headers: {
            Authorization: token,
          },
        });
        if (response.data) {
          setRequestProposals(response.data);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    };

    fetchProposals();
  }, [clientId, token]);

  useEffect(() => {
    const fetchSenderDetails = async (senderId) => {
      if (!senderDetails[senderId]) {
        try {
          setLoading(true);
          const response = await axios.get(
            `${apiUrl}/freelancer/getById/${senderId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setSenderDetails((prevState) => ({
            ...prevState,
            [senderId]: response.data,
          }));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching sender details:", error);
          setLoading(false);
        }
      }
    };

    requestProposals.forEach((proposal) => {
      if (!senderDetails[proposal.senderId]) {
        fetchSenderDetails(proposal.senderId);
      }
    });
  }, [requestProposals, senderDetails, token]);

  if (loading) {
    return <Spinner>Loading...</Spinner>;
  }

  return (
    <div className="flex flex-col gap-6 py-4 px-2">
      {requestProposals
        .filter((proposal) => {
          const senderInfo = senderDetails[proposal.senderId] || {};
          if (filterSkills.length > 0) {
            return (
              senderInfo.skills &&
              senderInfo.skills.some((skill) => filterSkills.includes(skill))
            );
          }
          return true;
        })
        .map((proposal) => {
          const senderInfo = senderDetails[proposal.senderId] || {};
          return (
            <ProposalUserView
              key={proposal.proposalId}
              userObject={{
                ...senderInfo,
                proposal,
              }}
            />
          );
        })}
    </div>
  );
}
