import React from "react";

export default function DocumentsUploadField({
  document,
  setDocument,
  documentName,
  setDocumentName,
}) {
  const inputFileHandler = (e) => {
    setDocument(e.target.files[0]);
    setDocumentName(e.target.files[0].name);
  };
  return (
    <div className="w-full large:w-[700px] h-fit ">
      <div className="flex flex-col gap-4">
        <div>
          <div className="font-[500] text-[22px] text-[#352F36]">
          Attach document
          </div>
          <div className="font-[500] text-[14px] text-[#352F36] opacity-[50%]">
            Only pdf, docx
          </div>
        </div>
        <label htmlFor="file">
          <div className="cursor-pointer border-dashed border-2 border-gray-300 hover:border-gray-400 hover:scale-[1.01] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-[280px] h-[64px] rounded-[20px] px-[30px] py-[20px] flex flex-row gap-2 items-center justify-center">
            <div>
              {" "}
              <img
                className=" "
                src={require("../../assets/navbar-logos/upload-icon.png")}
                alt=""
                srcSet=""
              />
            </div>
            <div className="font-[500] text-[18px] ">Upload</div>
          </div>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={inputFileHandler}
            name="image"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf"
            multiple=""
            data-original-title="upload photos"
          />
        </label>
      </div>
    </div>
  );
}
