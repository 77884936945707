import React from "react";
import PostNavHeader from "../Post-Jobs/util/PostNavHeader";
import InviteBrowseExport from "./InviteBrowseExport";
import ProposalFilters from "./ProposalFilters";

export default function PraposalHome() {
  return (
    <div className="flex flex-col h-full  w-full bg-normal">
      <PostNavHeader isBack={true} titleName={"Proposals"} />
      <div className="px-6 py-2 h-full flex flex-col gap-4  overflow-auto">
        <InviteBrowseExport />
        <ProposalFilters />
      </div>
    </div>
  );
}
