import { mdiGreaterThan, mdiLessThan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { IconButton } from "ui-neumorphism";
import chatBoot from "../../assets/defaultScreen/ai_popular_logo.png";
import predictive from "../../assets/defaultScreen/ai_popular_predict.png";
import recognition from "../../assets/defaultScreen/ai_popular_recognization.png";

export default function PopularAIServices() {
  const sliderRef = useRef(null);
  const [isActive, setIsActive] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const dataSlides = [
    {
      image: chatBoot,
      title: "Create a Chatbot with our skilled professionals",
    },
    {
      image: predictive,
      title: "Predictive Analysis",
    },
    {
      image: recognition,
      title: "Image Recognition",
    },
    {
      image: chatBoot,
      title: "Create a Chatbot with our skilled professionals",
    },
    {
      image: predictive,
      title: "Predictive Analysis",
    },
    {
      image: recognition,
      title: "Image Recognition",
    },
  ];

  const slidesToShow = 3;

  useEffect(() => {
    setIsNextDisabled(isActive >= dataSlides.length - 1);
    setIsPrevDisabled(isActive <= 0);
  }, [isActive, dataSlides.length]);

  const next = () => {
    if (isActive < dataSlides.length - 1) {
      setIsActive(isActive + 1);
    }
  };

  const previous = () => {
    if (isActive > 0) {
      setIsActive(isActive - 1);
    }
  };

  const handleSlideClick = (index) => {
    setIsActive(index);
  };

  return (
    <div className="flex flex-col gap-6 w-full h-fit py-4 px-6">
      <div className="text-[#352F36] text-[40px] font-[500] leading-[51.3px] text-center">
        Our popular AI services
      </div>

  
    

      <div className="slider-container w-full h-[80%]">
        <div className="flex justify-center items-center gap-2">
         
          
        <div className="flex flex-row gap-5 overflow-hidden">
  {dataSlides.map((slideInfo, index) => {
    // Check if the current index is the active index
    if (index === isActive) {
      return (
        <div
          key={index}
          className={`relative text-[#FFFFFF] rounded-[60px] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset] laptop:w-[350px] w-[450px] cursor-pointer h-[450px] p-2 ${
            isActive === index ? "w-[600px] laptop:w-[550px]" : ""
          }`}
          onClick={() => handleSlideClick(index)}
        >
          <div className="relative rounded-[60px] grid place-items-center w-full h-full p-4">
            <img
              className="absolute h-[95%] rounded-[60px] w-[96%] bg-cover"
              src={slideInfo.image}
              alt=""
            />
            <div className="relative h-full p-10 flex flex-row items-end text-[24px] font-[500]">
              <div>{slideInfo.title}</div>
            </div>
          </div>
        </div>
      );
    }
    // Check if the current index is the previous image (only when isActive is not the first)
    else if (index === isActive - 1 || index === isActive + 1 || (isActive === 0 && index === 1) || (isActive === 0 && index === 2) || (isActive === dataSlides.length - 1 && index >= dataSlides.length - 3)) {
      return (
        <div
          key={index}
          className={`relative text-[#FFFFFF] rounded-[60px] [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset] w-[350px] cursor-pointer h-[450px] p-2`}
          onClick={() => handleSlideClick(index)}
        >
          <div className="relative rounded-[60px] grid place-items-center w-full h-full p-4">
            <img
              className="absolute h-[95%] rounded-[60px] w-[96%] bg-cover"
              src={slideInfo.image}
              alt=""
            />
            <div className="relative h-full p-10 flex flex-row items-end text-[24px] font-[500]">
              <div>{slideInfo.title}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  })}
</div>


        </div>
       
        <div className="text-right w-full flex flex-row justify-between py-4">
          <div></div>
          <div className="w-fit flex flex-row gap-1">
            {dataSlides.map((_, index) => (
              <div
                key={index}
                className={`${
                  index === isActive
                    ? "w-[48px] ease-in-out rounded-[6px] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]"
                    : "w-[12px]"
                } h-[12px] bg-[#D9D9D9] rounded-full `}
              ></div>
            ))}
          </div>
          <div className="w-fit flex flex-row gap-4">
         
          <IconButton
            rounded
            text={false}
            color={isPrevDisabled ? "#CCCCCC" : "#4448B3"}
            onClick={previous}
            disabled={isPrevDisabled}
          >
            <Icon path={mdiLessThan} size={0.8} />
          </IconButton>
          
          <IconButton
            rounded
            text={false}
            color={isNextDisabled ? "#CCCCCC" : "#4448B3"}
            onClick={next}
            disabled={isNextDisabled}
          >
            <Icon path={mdiGreaterThan} size={0.8} />
          </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

