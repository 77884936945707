import React, { useContext, useEffect } from "react";
import SelectProjectDuration from "./SelectProjectDuration";
import PostNavHeader from "../util/PostNavHeader";
import ProgressBar from "../util/ProgressBar";
import ProjectTitle from "./ProjectTitle";
import RequiredSkills from "./RequiredSkills";
import NextButton from "../../Button/NextButton";
import { useLocation, useNavigate } from "react-router-dom";
import { PostAJobContext } from "../../../App";
import ScrollToTop from "../util/ScrollToTop";

export default function ProjectDurationMain() {
  const {projectDuration, setProjectDuration,progressStatus, setprogressStatus,projectTitle, setProjectTitle,selectedSKills, setSelectedSkills} =useContext(PostAJobContext);
 
  const navigate = useNavigate();

  const location = useLocation();
   const redirectUrl=location.state?location.state.pathName:"/clinet-home-view"
  useEffect(() => {
    if (location.pathname === "/project-duration") {
      setprogressStatus(1);
    }
  }, []);


  const nextHandler = () => {
    setprogressStatus(2);
    navigate("/project-estimation-time");
  };

  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} />
      <div className="px-40 py-8 small:px-10 mobile:px-4 flex flex-col items-center gap-5 overflow-auto">
        <ProgressBar status={progressStatus} />
        <SelectProjectDuration
          projectDuration={projectDuration}
          setProjectDuration={setProjectDuration}
        />
        <ProjectTitle
          projectTitle={projectTitle}
          setProjectTitle={setProjectTitle}
        />
        <RequiredSkills
          selectedSkills={selectedSKills}
          setSelectedSkills={setSelectedSkills}
        />
        <div className="w-full grid place-items-center h-fit">
          <NextButton bgActive={projectDuration && projectTitle && selectedSKills?.length ?true:false} buttonName={"next"} nextHandler={nextHandler} />
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
}
