import { Chart } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react'

export default function DoughnutChart() {
    const chartRef=useRef(null)
    const chartInstance=useRef(null)
    useEffect(()=>{
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const myChartRef=chartRef.current.getContext("2d");
        chartInstance.current=new Chart(myChartRef,{
            type: 'doughnut',
            data: {
               
                  datasets: [{
                    data: [200, 120, 100,80],
                    backgroundColor: [
                      '#089949','#089949',
                      '#FFA600',
                      '#A6ABBD'
                    ],
                    // borderWidth:[0,6,10,18],
                    borderColor:"#E3EDF7",
                    hoverBorderColor:"#E3EDF7",                            
                }]
                
            },
           
           
        })
       
        return()=>{
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        }
    })
  
  return (
    <div className='w-fit h-fit'>
        
        <canvas ref={chartRef} style={{width:'203px',height:'203px',}} ></canvas>
    </div>
  )
}
