import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Spinner, { apiUrl } from "../utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CiMenuKebab } from "react-icons/ci";
import { handleBase64Change } from "../utils/constants";
import { IconButton, Avatar, ProgressLinear } from "ui-neumorphism";
import InnerShadowAvatar from "../utils/InnerShadowAvatar";
import { ClientIdContext, TokenContext } from "../App";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import notificationImage from "../assets/noticationimage.png";
import editIcon from "../assets/edit (2).png";
import downloadIcon from "../assets/download.png";
function ProjectBrief() {
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [contracts, setContracts] = useState([]);
  const [project, setProject] = useState(null);
  const [client, setClient] = useState({});
  const [loader, setLoader] = useState(true);
  const [selectedContractIndex, setSelectedContractIndex] = useState(0); // Default to index 1
  const [error, setError] = useState(null);
  const [senderDetails, setSenderDetails] = useState({});
  const location = useLocation();
  const [contractSenders, setContractSenders] = useState({});
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const itemsPerPage = 5; // Number of items per page
  const projects = [
    // Example data, replace this with your actual data
    {
      id: 1,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    { id: 2, name: "E-commerce", cost: 2000, status: "paid", progress: "100%" },
    {
      id: 3,
      name: "Logo design",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    { id: 4, name: "Lms", cost: 2000, status: "paid", progress: "100%" },
    {
      id: 5,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    {
      id: 6,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
    { id: 7, name: "Lms", cost: 2000, status: "pending", progress: "75%" },
    {
      id: 8,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
    {
      id: 9,
      name: "Fitness app",
      cost: 2000,
      status: "pending",
      progress: "75%",
    },
    {
      id: 10,
      name: "Logo design",
      cost: 2000,
      status: "paid",
      progress: "100%",
    },
  ];
  const calculatePageNumbers = () => {
    if (currentPage === totalPages) {
      return [totalPages];
    }
    return [currentPage, currentPage + 1];
  };
  const [isAll, setIsAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("All");
  const [currentProjects, setCurrentProjects] = useState([]);
  const pageNumbers = calculatePageNumbers();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredProjects = useMemo(() => {
    return projects.filter((project) => {
      const matchesSearchTerm =
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.progress.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus =
        selectedOptions === "All" ||
        (selectedOptions === "Completed" && project.progress === "100%") ||
        (selectedOptions === "In-Progress" && project.progress !== "100%");
      return matchesSearchTerm && matchesStatus;
    });
  }, [projects, searchTerm, selectedOptions]);
  const logoutHandler = () => {
    localStorage.clear();
    updateClientId("");
    updateToken("");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/");
  };
  // Update total pages and current projects based on filteredProjects and currentPage
  useEffect(() => {
    // Ensure itemsPerPage is a valid number
    if (typeof itemsPerPage !== "number" || itemsPerPage <= 0) {
      console.error(
        "Invalid itemsPerPage value. It should be a positive number."
      );
      return;
    }

    const newTotalPages = Math.ceil(filteredProjects.length / itemsPerPage);
    if (newTotalPages !== totalPages) {
      setTotalPages(newTotalPages);
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newCurrentProjects = filteredProjects.slice(startIndex, endIndex);

    if (
      JSON.stringify(newCurrentProjects) !== JSON.stringify(currentProjects)
    ) {
      setCurrentProjects(newCurrentProjects);
    }
  }, [
    filteredProjects,
    currentPage,
    itemsPerPage,
    totalPages,
    currentProjects,
  ]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/client/getById/${clientId}`, {
        headers: { Authorization: token },
      })
      .then((response) => setClient(response.data))
      .catch((error) => setError("Error fetching client details"));

    axios
      .get(`${apiUrl}/contract/getContractOverview/${clientId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setContracts(response.data);
        console.log(response.data);
        setLoader(false);
      })
      .catch((error) => setError("Error fetching contracts"));
  }, [clientId, apiUrl, token]);

  useEffect(() => {
    if (contracts.length > 0) {
      const fetchSenderDetails = async () => {
        const senderDetails = {};
        for (const contract of contracts) {
          console.log(contract);
          try {
            const response = await axios.get(
              `${apiUrl}/client/getById/${contract.userId}`,
              {
                headers: { Authorization: token },
              }
            );
            senderDetails[contract.contractId] = response.data;
          } catch (error) {
            setError("Error fetching sender details");
          }
        }
        setContractSenders(senderDetails);
      };

      fetchSenderDetails();
    }
  }, [contracts, apiUrl, token]);
  const calculateDaysDifference = (endDate) => {
    const endDateTime = new Date(endDate).getTime();
    const presentDateTime = new Date().getTime();
    const differenceInMilliseconds = endDateTime - presentDateTime;
    return Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
  };

  const handleContractClick = (index) => setSelectedContractIndex(index);

  const selectedContract = contracts[selectedContractIndex] || null;
  const daysLeft = selectedContract
    ? calculateDaysDifference(selectedContract.endDate)
    : 0;
  const daysLeftForContract = calculateDaysDifference(contracts.endDate);
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div className="w-screen min-h-screen bg-[#E3EDF7] overflow-x-hidden flex flex-col gap-3 font-Satoshi">
      <div className="w-full h-fit flex flex-row bg-[#E3EDF7]  items-center mobile:gap-1 justify-between shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] px-3 py-1 ">
        <div className="w-fit h-fit gap-3 medium:gap-1 flex flex-row items-center justify-center mt-3">
          <div className="w-fit h-fit gap-3 flex flex-row items-center justify-center">
            <IconButton
              rounded
              text={false}
              style={{ width: "24px", height: "24px" }}
              size="large"
              className=" px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
              onClick={() => navigate("/clientDashboard")}
            >
              <ArrowBackIcon />{" "}
            </IconButton>
            {/* <BackButton /> */}
          </div>
          <div className="w-fit h-fit medium:gap-1 gap-3 flex flex-row items-center ">
            <span
              className={`${
                selectedTab === "dashboard"
                  ? "text-white hover:scale-[1.02]  bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]  rounded-[15px] cursor-pointer"
                  : "cursor-pointer"
              } px-4 py-3 text-center mobile:text-[12px] medium:p-2 mobile:p-1 medium:text-[14px] text-[18px] font-[700] font-Sathosi`}
              onClick={() => handleTabClick("dashboard")}
            >
              My Dashboard
            </span>
            <span
              className={`${
                selectedTab === "billing"
                  ? "text-white hover:scale-[1.02] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] rounded-[15px] cursor-pointer"
                  : "cursor-pointer"
              } px-4 py-3 text-center mobile:text-[12px] medium:p-2 mobile:p-1 text-[14px] text-[18px] font-[700] font-Sathosi`}
              onClick={() => handleTabClick("billing")}
            >
              Billings and Payments
            </span>
          </div>
        </div>
        <div className="w-fit h-fit gap-3 flex flex-row items-center justify-center ">
          <IconButton
            rounded
            text={false}
            size="large"
            className="pt-5 bg-gray-200 text-gray-800 rounded shadow "
            onClick={() =>
              navigate("/notification", {
                state: { pathName: location.pathname },
              })
            }
          >
            <img src={require("../assets/notification (2).png")} />{" "}
          </IconButton>
          <div
            onClick={handleDropdownToggle}
            className="w-[40px] h-[40px] mobile:w-[40px] mobile:h-[40px] rounded-[50%] shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] flex flex-row items-center justify-center"
          >
            <Avatar
              className="w-[25px] h-[25px]"
              bgColor="--white"
              src={
                client.profileImage
                  ? handleBase64Change(client.profileImage)
                  : require("../assets/noimage.png")
              }
            />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-20 right-5 medium:top-12  z-50  bg-[#E3EDF7] w-[200px]  shadow-[8px_8px_7px_#a6aabc,-8px_-8px_7px_#FAFBFF] p-4 rounded-[15px] py-2">
              <p
                className="px-1 py-3  border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10"
                onClick={() => navigate("/edit-profile")}
              >
                Edit Profile
              </p>
              <p
                className="px-1 py-3 border-b border-[#414B5A] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
                onClick={() => navigate("/clientDashboard")}
              >
                Dashboard
              </p>

              <p
                className="px-1 py-3 text-[red] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
                onClick={logoutHandler}
              >
                <ExitToAppIcon /> Sign Out
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="w-[100%] flex flex-row-reverse medium:flex-col medium:gap-5 gap-10  bg-[#E3EDF7] h-full mb-5 p-5">
        {loader ? (
          <div className="w-[70%] h-full flex flex-col gap-5 items-center">
            <Spinner titleName="Please wait we getting data" />
          </div>
        ) : (
          <>
            {selectedTab === "dashboard" ? (
              <div className="w-[100%] flex flex-row-reverse medium:flex-col medium:gap-5 gap-10  bg-[#E3EDF7] h-full mb-5 p-5">
                <div className="w-[30%] medium:w-[100%] p-5 h-full overflow-auto flex flex-col rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] gap-5">
                  <div className="w-[100%] h-fit">
                    <h1 className="w-fit text-[26px] font-[700]">
                      Projects Activities
                    </h1>
                  </div>
                  <div className="w-full grid grid-cols-3 gap-5">
                    {/* Project statistics */}
                  </div>
                  {contracts.length > 0 ? (
                    <div className="w-full flex flex-col justify-left gap-4 items-center">
                      {contracts.map((contract, index) => (
                        <div
                          key={contract.contractId}
                          className={`w-full flex flex-col justify-left p-2 rounded-[15px] gap-4 shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] ${
                            selectedContractIndex === index
                              ? "bg-blue text-white"
                              : ""
                          }`}
                          onClick={() => handleContractClick(index)}
                        >
                          <div className="flex flex-row gap-4 justify-between items-center w-full">
                            <div className="flex flex-row gap-3 items-center">
                              <div className="bg-[#E3EDF7] w-fit p-2 rounded-full">
                                <Avatar
                                  bgColor="--white"
                                  size={30}
                                  src={
                                    contractSenders[contract.contractId]
                                      ?.projectImage
                                      ? handleBase64Change(
                                          contractSenders[contract.contractId]
                                            .projectImage
                                        )
                                      : require("../assets/noimage.png")
                                  }
                                />
                              </div>
                              <div className="flex flex-col">
                                <span className="font-[700] text-[20px] capitalize">
                                  {contract.projectName}
                                </span>
                                <span className="font-[500] text-[14px]">
                                  startDate: {contract.startDate}
                                </span>
                              </div>
                            </div>
                            <div className="-mt-3">
                              <CiMenuKebab color="black" />
                            </div>
                          </div>
                          <div className="flex flex-row px-2 items-center gap-3">
                            <img
                              className="w-[20px] h-[20px]"
                              src={require("../assets/Icons.png")}
                            />
                            <div className="w-full flex flex-col gap-2 p-1 px-2">
                              <div className="flex flex-row gap-2">
                                <div className="font-bold">
                                  {contract.progress} %
                                </div>
                                <div className="text-[16px]">
                                  {contract.projectStatus}
                                </div>
                              </div>
                              <div className="flex flex-col w-[70%] items-center">
                                <ProgressLinear
                                  height={10}
                                  value={contract.progress}
                                  color="#FF5F1B"
                                  width={contract.progress}
                                  style={{
                                    boxShadow:
                                      "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-full items-end flex flex-col items-cend justify-end">
                            <div
                              className={`w-fit  font-[400] p-2 text-[14px] rounded-[15px] flex flex-row gap-1 justify-end items-end ${
                                contract.progress < 100
                                  ? daysLeft > 0
                                    ? "bg-orange-500 shadow-[inset_2.5px_2.5px_2px_orange,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for days left with positive value
                                    : "bg-red-500 shadow-[inset_2.5px_2.5px_2px_red,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for overdue with negative value
                                  : "bg-green-500 shadow-[inset_2.5px_2.5px_2px_green,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for completed contracts
                              }`}
                            >
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/daysleft.png")}
                                alt="Notification"
                              />{" "}
                              {contract.progress < 100
                                ? daysLeftForContract > 0
                                  ? `${daysLeftForContract} days left`
                                  : "Overdue"
                                : "Completed"}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>No contracts available</div>
                  )}
                </div>

                <div className="w-[70%] medium:w-[100%] p-5 h-full overflow-auto flex flex-col rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] gap-5">
                  {selectedContract ? (
                    <>
                      <div className="w-full medium:w-[100%] p-5 h-full overflow-auto flex items-center justify-center flex-col gap-5">
                        <div className="w-[100%] h-fit p-3 flex flex-row  rounded-[15px] gap-5 items-center justify-between shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                          <div className="w-fit h-fit flex fle-row  gap-5 items-center font-[500]">
                            <div className="w-fit h-fit small:w-[30px] small:h-[30px] rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3">
                              <Avatar
                                bgColor="--white"
                                size={60}
                                src={
                                  contractSenders[selectedContract.contractId]
                                    ?.projectImage
                                    ? handleBase64Change(
                                        contractSenders[
                                          selectedContract.contractId
                                        ].projectImage
                                      )
                                    : require("../assets/noimage.png")
                                }
                              />
                            </div>
                            {selectedContract.projectName}
                          </div>
                          <div className="w-fit h-fit p-2 flex flex-row rounded-[15px] gap-5 items-center justify--between shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                            <div className="w-fit h-fit flex items-center rounded-[15px] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-3">
                              Start date: {selectedContract.startDate}{" "}
                            </div>
                            <div
                              className={`font-[400] p-2 text-[16px] rounded-[15px] flex flex-row gap-1 item-center ${
                                selectedContract.progress < 100
                                  ? daysLeft > 0
                                    ? "bg-orange-500 shadow-[inset_2.5px_2.5px_2px_orange,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for days left with positive value
                                    : "bg-red-500 shadow-[inset_2.5px_2.5px_2px_red,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for overdue with negative value
                                  : "bg-green-500 shadow-[inset_2.5px_2.5px_2px_green,inset_-2.5px_-2.5px_2px_#f9faff] text-white" // Background color for completed contracts
                              }`}
                            >
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/daysleft.png")}
                                alt="Notification"
                              />{" "}
                              {selectedContract.progress < 100
                                ? daysLeft > 0
                                  ? `${daysLeft} days left`
                                  : "Overdue"
                                : "Completed"}
                            </div>
                          </div>
                        </div>

                        <div className="w-[80%] h-fit p-3 text-center border border-white border-t-0 bg-[#FF5F1B] rounded-b-[15px] text-[white] font-[18px] mt-[-20px] font-[700] flex justify-center">
                          Your project deadline is on {selectedContract.endDate}
                        </div>
                        <div className="w-[100%] h-fit p-10 flex flex-col rounded-[15px] gap-5 items-center justify-between shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                          <h1 className="w-[90%] text-[24px]  font-[700]">
                            Project brief:
                          </h1>
                          <p className="w-[90%] text-justify font-[400] text-[18px] leading-7">
                            We are embarking on the development of a
                            comprehensive Health & Fitness app aimed at
                            empowering users to achieve their fitness goals,
                            improve overall health, and lead a more active
                            lifestyle. The app will offer a range of features
                            including workout tracking, nutrition planning,
                            progress monitoring, and social engagement to create
                            a holistic fitness experience.
                          </p>
                          <h1 className="w-[90%] text-[24px]  font-[400] text-[#414B5A]">
                            Document Attached{" "}
                          </h1>
                          <div className="w-[90%] medium:w-[100%] p-3  items-center  flex flex-row  gap-5 ">
                            <div className="w-fit h-fit text-[22px] gap-10 flex flex-row p-3 rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF] font-[500]">
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/documentmodule.png")}
                                alt="Notification"
                              />
                              Module 1.PDF
                            </div>
                            <div className="w-fit h-fit px-6 p-2 bg-[#2485FF] text-center rounded-[30px]  text-[white] ">
                              View
                            </div>
                          </div>

                          <div className="w-[90%] medium:w-[100%] p-3 small:px-1 mobile:flex-col  items-center  flex flex-row medium:gap-3 gap-5">
                            <div className="w-fit h-fit gap-5 medium:gap-2 items-center flex flex-row px-6 p-3 bg-gradient-to-r from-indigo-800 to-sky-600 text-center rounded-[10px]  text-[white] ">
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/projectmodule.png")}
                                alt="Notification"
                              />
                              Module 1
                            </div>
                            <div
                              className="w-fit h-fit gap-10 flex flex-row px-6 p-3
#E3EDF7 text-center rounded-[10px]  items-center   shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]"
                            >
                              Sketching & wireframing
                            </div>
                            <div className="w-fit h-fit  items-center flex flex-row px-6 p-3  text-center rounded-[10px]  text-[green] ">
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/modulesubbmit.png")}
                                alt="Notification"
                              />
                              Submitted
                            </div>
                          </div>

                          <div className="w-[90%] medium:w-[100%] p-3 small:px-1 mobile:flex-col  items-center  flex flex-row medium:gap-3 gap-5">
                            <div className="w-fit h-fit gap-5 medium:gap-2 items-center flex flex-row px-6 p-3 bg-gradient-to-r from-indigo-800 to-sky-600 text-center rounded-[10px]  text-[white] ">
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/projectmodule.png")}
                                alt="Notification"
                              />
                              Module 2
                            </div>
                            <div
                              className="w-fit h-fit gap-10 flex flex-row px-6 p-3 mobile:p-2
 text-center rounded-[10px]  items-center   shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]"
                            >
                              High fedility & prototype
                            </div>
                          </div>
                          <div className="w-[90%] medium:w-[100%] mobile:p-1 p-3 small:px-1 mobile:flex-col  items-center  flex flex-row medium:gap-3 gap-5">
                            <div className="w-fit h-fit gap-5 medium:gap-2 items-center flex flex-row px-6 p-3 bg-gradient-to-r from-indigo-800 to-sky-600 text-center rounded-[10px]  text-[white] ">
                              <img
                                className="w-[20px] h-[20px]"
                                src={require("../assets/projectmodule.png")}
                                alt="Notification"
                              />
                              Module 3
                            </div>
                            <div
                              className="w-fit h-fit gap-10 flex flex-row px-6 p-3
text-center rounded-[10px]  items-center   shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]"
                            >
                              Mockups & assets
                            </div>
                          </div>
                          <div className="flex flex-row w-[100%] text-[#2B32B2] text-[16px] font-[500] font-[Poppins] justify-center items-center ">
                            <img
                              src={require("../assets/showbar.png")}
                              alt="Notification"
                            />
                          </div>
                          <div className="w-[100%] h-[20%]  flex flex-col gap-2   ">
                            <div className="w-[50%] h-[100%] medium:w-[100%]  flex flex-col gap-2 p-5 rounded-[15px]  shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                              <input
                                placeholder="Upload your completed works here..."
                                className="pb-5 bg-[#E3EDF7] w-[100%] h-[90%] focus:outline-none "
                              />
                              <div className="flex flex-row items-center justify-between w-[100%] h-fit">
                                <IconButton
                                  text={false}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "15px",
                                  }}
                                  size="large"
                                  className="px-4 py-2"
                                >
                                  <img
                                    className="w-[24px] h-[24px]"
                                    src={require("../assets/document.png")}
                                    alt="Notification"
                                  />
                                </IconButton>

                                <IconButton
                                  text={false}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "15px",
                                  }}
                                  size="large"
                                  className="px-4 py-2"
                                >
                                  <img
                                    className="w-[24px] h-[24px]"
                                    src={require("../assets/send.png")}
                                    alt="Notification"
                                  />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>No contract details available</div>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex flex-col gap-3 px-5 ">
                <div className="w-full h-fit flex flex-row items-center justify-between">
                  <div className="w-[70%]  flex flex-row p-2 rounded-[15px] items-center gap-10 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                    <SearchIcon className="text-[#051118]" />
                    <input
                      className="w-full h-fit focus:outline-none bg-[#E3EDF7] text-[18px] font-[500]"
                      placeholder="Search by project name, progress"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="w-[25%] bg-[white] flex flex-row p-2 rounded-[15px] items-center gap-10 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                    <button
                      className={`w-full text-[14px] p-1 focus:outline-none pr-7 flex flex-row items-center justify-between rounded-[15px] font-[500]`}
                      onClick={() => setIsAll(!isAll)}
                    >
                      {selectedOptions}
                      <svg
                        className={`w-5 h-5 ml-2 transition-transform transform ${
                          isAll ? "rotate-180 text-[#2485FF]" : "rotate-0"
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                    {isAll && (
                      <div className="w-fit h-fit absolute  rounded-[15px] bg-[white] shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF]">
                        {["All", "In-Progress", "Completed"].map((option) => (
                          <div
                            key={option}
                            className="p-2 border-b border-opacity-50 border-[#414B5A] text-[#414B5A] hover:text-[#2485FF] cursor-pointer"
                            onClick={() => {
                              setSelectedOptions(option);
                              setIsAll(false);
                            }}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <table className="w-full h-fit flex flex-col gap-3">
                  <thead className="w-full bg-[white] rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                    <tr className="w-full bg-[white] rounded-[15px] border-none h-fit text-[16px] font-[500] grid grid-cols-6 p-1 px-4 gap-10 items-center">
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Project name
                      </th>
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Name
                      </th>
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Progress
                      </th>
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Cost
                      </th>
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Payment status
                      </th>
                      <th className="w-[100%] bg-[#E3EDF7] rounded-[15px] h-fit p-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                        Invoice
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-fit overflow-y-auto rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]">
                    {currentProjects.map((project) => (
                      <tr
                        key={project.id}
                        className="w-full border-b border-gray w-full border-none h-fit text-[16px] font-[500] grid grid-cols-6  px-4 gap-1 items-center"
                      >
                        <td className="w-[100%] flex flex-row  h-fit items-center text-center">
                          <div className="w-fit gap-2 h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                            <img
                              className="w-[25px] h-[25px]"
                              src={notificationImage}
                              alt="Notification"
                            />
                          </div>
                          {project.name}
                        </td>
                        <td className="w-[100%] items-center flex flex-row gap-2 h-fit text-center">
                          <div className="w-fit gap-2 h-fit rounded-[50%] shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff] p-1">
                            <img
                              className="w-[25px] h-[25px]"
                              src={notificationImage}
                              alt="Notification"
                            />
                          </div>
                          {project.name}
                        </td>
                        <td
                          className={`w-[100%] flex rounded-[15px] items-center justify-center h-fit p-2 text-center ${
                            project.progress === "100%"
                              ? "bg-[#089949] shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                              : "bg-[#FF5F1B] shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                          } text-[white]`}
                        >
                          {project.progress}
                        </td>
                        <td className="w-[100%] flex rounded-[15px] items-center justify-center h-fit p-1 text-center">{`$${project.cost}`}</td>
                        <td
                          className={`w-[100%] flex rounded-[15px] items-center justify-center h-fit p-2 px-1 text-center ${
                            project.status === "paid"
                              ? "bg-[#089949] shadow-[inset_2.5px_2.5px_2px_#096030,inset_-2.5px_-2.5px_2px_#f9faff]"
                              : "bg-[#FF5F1B] shadow-[inset_2.5px_2.5px_2px_#BF4E1D,inset_-2.5px_-2.5px_2px_#f9faff]"
                          } text-[white]`}
                        >
                          {project.status}
                        </td>
                        <td className="w-[100%] flex flex-row text-[14px] rounded-[15px] items-center justify-center h-fit p-2 text-center">
                          {project.status === "paid" ? (
                            <button className="w-[100%] h-fit rounded-[10px] p-2 px-4 flex items-center shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                              <img
                                className="w-[15px] h-[15px]"
                                src={downloadIcon}
                                alt="Download"
                              />
                              <span>Download Invoice</span>
                            </button>
                          ) : (
                            <button className="w-[100%] h-fit rounded-[10px] items-center flex p-2 text-[white] bg-gradient-to-r from-indigo-800 to-sky-600  px-4  shadow-[5px_5px_10px_#a6aabc,-8px_-8px_7px_#FAFBFF]">
                              <img
                                className="w-[15px] h-[15px]"
                                src={editIcon}
                                alt="Edit"
                              />
                              <span>Create Invoice</span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex items-center justify-center flex-row gap-10">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 text-gray rounded-lg disabled:opacity-20"
                  >
                    Previous
                  </button>
                  <span className="text-[18px]">
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`px-4 py-2 rounded-lg ${
                          currentPage === pageNumber
                            ? "bg-blue-500 text-white"
                            : "text-gray-700"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 text-gray rounded-lg disabled:opacity-20"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProjectBrief;
