import React from 'react'

import zoho from "../../assets/projects_logos/zoho.png"
import accenture from "../../assets/projects_logos/accenture.png"
import cognigant from "../../assets/projects_logos/cognigant.png"
import infosys from "../../assets/projects_logos/infosys.png"
import tcs from "../../assets/projects_logos/tcs.png"
import hcl from "../../assets/projects_logos/hcl.png"
import ibm from "../../assets/projects_logos/ibm.png"
import SeeMoreButton from "../Button/SeeMoreButton";
import { CompanyViewCardTrending } from './RequestForProposal'

export default function CompanyProfiles() {
    
    const trendingCompanies=[
        {
            companyName:"Zoho",
            companyLogo:zoho
        },
        {
            companyName:"Accenture",
            companyLogo:accenture
        },
        {
            companyName:"Cognizant",
            companyLogo:cognigant
        },
        {
            companyName:"Infosys",
            companyLogo:infosys
        },
        {
            companyName:"TCS",
            companyLogo:tcs
        },
        {
            companyName:"HCL",
            companyLogo:hcl
        },
        {
            companyName:"IBM",
            companyLogo:ibm
        }
    ]
  return (
    <div className="col-span-1  min-h-[100px] max-h-fit card p-4 rounded-[14px] flex flex-col gap-4">
    <div className="font-[700] text-[24px] text-[#352F36]">Recommendations</div>
    <div className="flex flex-col gap-4">
      {
        trendingCompanies.map((company,_)=>(
            <CompanyViewCardTrending companyName={company.companyName} companyImg={company.companyLogo} />
        ))
      }
      
    </div>
  <div className="w-full grid place-items-center">
  <SeeMoreButton />
  </div>
</div>
  )
}

export const RecommendationCompanyProfiles=()=>{
    
  const trendingCompanies=[
    {
      companyName:"Infosys",
      companyLogo:infosys
  },
  {
      companyName:"TCS",
      companyLogo:tcs
  },
  {
      companyName:"HCL",
      companyLogo:hcl
  },
  {
      companyName:"IBM",
      companyLogo:ibm
  },
      {
          companyName:"Zoho",
          companyLogo:zoho
      },
      {
          companyName:"Accenture",
          companyLogo:accenture
      },
      {
          companyName:"Cognizant",
          companyLogo:cognigant
      },
    
  ]
return (
  <div className="col-span-1  min-h-[100px] max-h-fit card p-4 rounded-[14px] flex flex-col gap-4">
  <div className="font-[700] text-[24px] text-[#352F36]">Trending profiles</div>
  <div className="flex flex-col-reverse gap-4">
    {
      trendingCompanies.map((company,index)=>(
         index<3 &&
          <CompanyViewCardTrending companyName={company.companyName} companyImg={company.companyLogo} />
         
      ))
    }
    
  </div>
<div className="w-full grid place-items-center">
<SeeMoreButton />
</div>
</div>
)
}
