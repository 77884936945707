import React from "react";
import SelectInput from "../../inputs/SelectInput";
import TextInput from "../../inputs/TextInput";

export default function TypesOfPayments({
  paymentType,
  setPaymentType,
  costOfWork,
  setCostOfWork,
  projectBudget,
  setProjectBudget,
  listOfPaymentType
}) {
  return (
    <div className="flex flex-col gap-5 w-full large:w-[700px] items-start">
      <SelectInput
        list={listOfPaymentType}
        selectedValue={paymentType}
        setSelectedValue={setPaymentType}
        labelName="Payment type"
      />
      <div className="flex flex-row w-full gap-4">
        {paymentType === "Hourly" && (
          <div className="w-full">
            <TextInput
              type="text"
              inputValue={costOfWork}
              setInputValue={setCostOfWork}
              placeholder="USD $"
              labelName="Cost of working (Per hour)"
            />
          </div>
        )}
        {paymentType !== "Hourly" && (
          <div className="w-full">
            <TextInput
              type="text"
              inputValue={projectBudget}
              setInputValue={setProjectBudget}
              placeholder="USD $"
              labelName={`Cost of working (${paymentType})`}
            />
          </div>
        )}
      </div>

     
    </div>
  );
}
