import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner, { apiUrl, clientId, parseJwt } from "../utils/constants";
import { Card } from "ui-neumorphism";
import { IconButton } from "ui-neumorphism";
import BackButton from "../components/Button/BackButton";
import NextButton from "../components/Button/NextButton";
import { useContext } from "react";
import { ClientIdContext, TokenContext } from "../App";
export const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      <path
        fillRule="evenodd"
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const EyeClosedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
    </svg>
  );
};
function LogInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [terms, setTerms] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [logInError, setLogInError] = useState("");
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [loader, setLoader] = useState(false);
  const inactivityTimeout = useRef(null);
  useEffect(() => {
    localStorage.setItem("previousPath", location.pathname);
  }, [location]);
  useEffect(() => {
    const resetTimeout = () => {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      inactivityTimeout.current = setTimeout(() => {
        setSessionTimeOut(true);
      }, 30000); // 60000 milliseconds = 1 minute
    };

    const handleMouseMovement = () => {
      resetTimeout();
    };

    // Set up event listeners for mouse movements
    window.addEventListener("mousemove", handleMouseMovement);

    // Initialize the timeout for the first time
    resetTimeout();

    // Cleanup event listeners and timeout on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMovement);
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update state based on field
    if (name === "usernameOrEmail") {
      setUsernameOrEmail(value);
      setEmailError(""); // Clear email error when typing in username/email field
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const [usernameFocused, setUsernameFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleUsernameChange = (e) => {
    setUsernameOrEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsernameFocus = () => {
    setUsernameFocused(true);
    setEmailError("");
    if (passwordFocused && !password) {
      // You can handle error display or state here
      setPasswordError("Password cannot be empty.");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
    setPasswordError("");

    if (usernameFocused && !usernameOrEmail) {
      setEmailError("Username cannot be empty.");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    // Check for token in URL after OAuth redirect
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("userId", parseJwt(token).userId);
      toast.success("Successfully logged in with Google");
      navigate("/client-home-view");
    }
  }, [navigate]);
  const handleToggle = (e) => {
    setTerms(e.target.checked);

    // Reset the error message for terms when terms are selected as true
    if (e.target.checked) {
      setTermsError("");
    }
  };
  const handleGoogleLogin = async () => {
    try {
      window.location.href = `${apiUrl}/oauth2/authorization/google`;
    } catch (error) {
      console.error("Google Login Error:", error);
      toast.error("Failed to initiate Google login");
    }
  };
  const handleLogin = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!usernameOrEmail || !emailRegex.test(usernameOrEmail)) {
      setEmailError("Please enter a valid email address or username ");
      return;
    }
    if (password.length > 0 && password.length < 8) {
      setPasswordError(
        "Your password must contain between 8 and 60 characters"
      );
      return;
    }
    if (password.length === 0) {
      setPasswordError("This field cannot be left blank");
    } else {
      setPasswordError("");
      setEmailError("");
    }

    if (!terms) {
      setTermsError("Please accept the terms");
      return;
    } else {
      setTermsError("");
    }

    if (passwordError === "" && emailError === "") {
      setLoader(true);
      try {
        axios
          .post(apiUrl + "/auth/login", {
            email: usernameOrEmail,
            password: password,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            let token1 = response.data.token;
            updateToken(token1);
            localStorage.setItem(
              "userId",
              parseJwt(response.data.token).userId
            );
            let userId = parseJwt(response.data.token).userId;
            updateClientId(userId);
            toast.success("Successfully login");
            if (location.state && location.state.isPost === "post") {
              setLoader(false);
              navigate("/project-duration");
            } else {
              const from =
                location.state?.from?.pathname || "/client-home-view";
              setLoader(false);
              navigate(from, { replace: true });
            }
          })
          .catch((err) => {
            setLogInError(
              "Sorry, we can’t find an account with this email address. please try again or create a new account"
            );
            setLoader(false);
          });
      } catch (error) {
        toast.error(error.response);
        setLoader(false);
      }
    }
  };
  const [time, setTime] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setTime(!time);
    }, 4000);
  });
  const sessionRestartHandler = () => {
    setSessionTimeOut(!sessionTimeOut);
  };
  const handleForgotPassword = () => {
    navigate("/forgetpassword", { state: { usernameOrEmail } });
  };
  if (loader) return <Spinner titleName={"Loading ..."} />;
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi  overflow-x-hidden p-2">
      <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
      </div>
      <div className="mobile:-mt-6">
        <BackButton backHandler={() => navigate("/")} />
      </div>
      <div className="mobile:mt-12  w-full h-full mt-16 p-5 flex flex-col gap-5 justify-center    items-center">
        <h1 className="w-[100%] h-fit text-center">
          <span className="text-[#352F36] text-[40px] font-[700] font-Satoshi">
            Welcome back !
          </span>
        </h1>
        <p className=" w-[100%]  text-center text-[#414B5A] text-[22px] font-[400] ">
          Login into your account
        </p>
        <div className="w-[55%] medium:w-[100%] z-30 gap-10 h-fit  flex flex-row justify-center  items-center medium:flex-col mobile:gap-y-4">
          <div
            className="card w-[50%] hover:scale-[1.02] hover:border-none  transform transition-transform hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] duration-400 ease-in-out p-3 px-8 bg-[#E3EDF7] cursor-pointer  h-fit  flex rounded-[20px]  flex-row gap-2 border-l-2 border-b-2 border-blue-800 border-opacity-30  justify-center items-center    "
            onClick={handleGoogleLogin}
          >
            <img
              src={require("../assets/Google.png")} // Path to your Google logo image
              alt="Google Icon"
              className="w-[34px] h-[34px] p-1 "
            />
            <span className="font-[500] text-[#352F36] text-center text-[18px] font-Satoshi">
              Continue with Google
            </span>
          </div>

          <div className="w-[50%] card hover:scale-[1.02] hover:border-none hover:shadow-[inset_3px_3px_5px_#a6aabc,inset_-3px_-3px_5px_#f9faff] transform transition-transform duration-400 ease-in-out p-3 px-8 bg-[#E3EDF7] cursor-pointer  h-fit  flex rounded-[20px]  flex-row gap-2 border-l-2 border-b-2 border-blue-800 border-opacity-30  justify-center items-center    ">
            <img
              src={require("../assets/appleicon.png")} // Path to your Google logo image
              alt="Apple Icon"
              className="w-[34px] h-[34px]  p-1 mb-1"
            />
            <span className=" font-[500] text-center text-[18px] font-Satoshi">
              Continue with Apple
            </span>
          </div>
        </div>
        <div className="w-[30%] flex flex-row justify-center gap-2 items-center">
          <hr className="border-t border-gray-400 w-full mt-2" />
          <div className="text-black w-[15%] text-center text-[18px] font-[500] font-Satoshi">
            or
          </div>
          <hr className="border-t border-gray-400 w-full mt-2" />
        </div>
        {logInError && (
          <p className="w-[50%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
            <IconButton
              rounded
              text={false}
              color="var(--error)"
              style={{ width: "24px", height: "24px" }}
              size="small"
              onClick={() => setLogInError("")}
            >
              <img
                src={require(`../assets/error.png`)}
                className="w-[17px] h-[17px] "
              />
            </IconButton>
            {logInError}
          </p>
        )}
        <div className="w-[100%]  h-fit gap-6 flex flex-col items-center justify-center">
          <div className="w-[55%] medium:w-[400px] mobile:w-full flex flex-col gap-2 items-start ">
            <label className=" text-center  text-[18px] font-[500] font-Satoshi">
              {" "}
              Email or Username
            </label>
            <input
              type="text"
              className={` border ${
                emailError ? "border-red-500" : ""
              } w-[100%] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out  focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff]  shadow-[5px_5px_10px_#a6aabc,-6px_-6px_15px_#FFFFFF]  rounded-[15px] p-3  font-[400] bg-[#E3EDF7]   text-[18px]  font-Satoshi focus:outline-none`}
              value={usernameOrEmail}
              onChange={handleUsernameChange}
              onFocus={handleUsernameFocus}
              required
              placeholder="Email ID or Phone number"
            />
          </div>
          {emailError && (
            <p className="w-[55%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
              <IconButton
                rounded
                text={false}
                color="var(--error)"
                style={{ width: "24px", height: "24px" }}
                size="small"
                onClick={() => setEmailError("")}
              >
                <img
                  src={require(`../assets/error.png`)}
                  className="w-[17px] h-[17px] "
                />
              </IconButton>{" "}
              {emailError}
            </p>
          )}
          <div className="w-[55%] medium:w-[400px] mobile:w-full flex flex-col gap-2 items-start ">
            <label className=" text-center  text-[18px] font-[500] font-Satoshi">
              Password
            </label>
            <div className="w-full relative fex flex-row items-center">
              <input
                type={!showPin ? "password" : "text"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                onFocus={handlePasswordFocus}
                className={` border ${
                  passwordError ? "border-red-500" : ""
                } w-[100%] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff]  shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]  rounded-[15px] p-3  font-[400]  bg-[#E3EDF7]   text-[#414B5A] text-[18px]  font-Satoshi focus:outline-none`}
                placeholder="Password"
                name="password"
                required
              />

              <IconButton
                text={false}
                rounded
                size="small"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => setShowPin(!showPin)}
              >
                {showPin ? <EyeIcon /> : <EyeClosedIcon />}
              </IconButton>
            </div>
          </div>
          {passwordError && (
            <p className="w-[55%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
              <IconButton
                rounded
                text={false}
                color="var(--error)"
                style={{ width: "24px", height: "24px" }}
                size="small"
                onClick={() => setPasswordError("")}
              >
                <img
                  src={require(`../assets/error.png`)}
                  className="w-[17px] h-[17px] "
                />
              </IconButton>
              {passwordError}
            </p>
          )}
        </div>
        <div className="w-[55%]  medium:w-[400px] mobile:w-full flex px-2 flex-row justify-between items-center  gap-10 medium:flex-col medium:gap-2">
          <div className="w-fit h-fit mt-2 mb-2 gap-2  justify-start items-center    flex flex-row ">
            <input
              type="checkbox"
              className={` ${
                terms
                  ? "accent-red"
                  : "appearance-none shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
              } ${
                usernameOrEmail && password
                  ? " cursor-pointer "
                  : " cursor-not-allowed "
              } w-[15px] h-[15px]  `}
              checked={terms}
              onChange={handleToggle}
              disabled={!usernameOrEmail || !password}
            />
            <a
              href="/privacy-policy"
              className="cursor-pointer text-[black] text-[18px] font-[500] font-Satoshi"
            >
              Newsletter and privacy policy
            </a>
          </div>

          <span
            className="cursor-pointer text-[18px] font-[500] font-Satoshi text-[#041FA3] hover:underline"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </span>
        </div>
        {termsError && (
          <p className="w-[50%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
            <IconButton
              rounded
              text={false}
              color="var(--error)"
              style={{ width: "24px", height: "24px" }}
              size="small"
            >
              <img
                src={require(`../assets/error.png`)}
                className="w-[17px] h-[17px] "
              />
            </IconButton>
            {termsError}
          </p>
        )}
        <div className="w-[75%] mb-6 pb-2 h-fit gap-5  flex flex-col text-[white] text-[14px] font-[500]  leading-normal  justify-center items-center text-center">
          <NextButton
            bgActive={usernameOrEmail && password && terms ? true : false}
            buttonName="Log In"
            nextHandler={handleLogin}
          />
        </div>
        <Card
          style={{ borderRadius: "15px" }}
          className="cursor-pointer hover:scale-[1.02]"
        >
          <div
            onClick={() => navigate("/signup")}
            className="hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] w-fit h-fit gap-5 px-4  py-2 rounded-[15px] flex flex-row text-[18px] font-[500]  leading-normal  justify-center items-center text-center"
          >
            <p className=" w-fit  h-fit text-[18px] cursor-pointer font-[500] font-Satoshi">
              Don’t have an account?{" "}
              <span className="text-[#041FA3] pl-1 w-fit cursor-pointer h-fit text-[18px] font-[500] font-Satoshi hover:underline">
                {" "}
                Signup{" "}
              </span>
            </p>
          </div>
        </Card>
      </div>
      {sessionTimeOut && (
        <div className="w-screen h-screen fixed z-50 top-0 left-0 grid place-items-center bg-white bg-opacity-[80%]">
          <div className="card_shadow rounded-[20px] bg-normal w-[90%] h-fit p-6 flex flex-col items-center gap-5">
            <div className="flex flex-row items-center gap-4 w-fit">
              <img
                className="w-[43px] h-[43px]"
                src={require("../assets/timeout-icon.png")}
              />
              <h1 className="text-[#352F36] text-[40px] font-[700]">
                Still with us ?
              </h1>
            </div>
            <div>
              <h1 className=" w-[100%]  text-center text-[#414B5A] text-[18px] font-[500] ">
                Your login session has timed out due to inactivity, Please login
                again.
              </h1>
            </div>
            <NextButton
              bgActive={true}
              buttonName="Login"
              nextHandler={sessionRestartHandler}
            />
          </div>
        </div>
      )}{" "}
    </div>
  );
}

export default LogInPage;
