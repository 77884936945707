import React from 'react'
import sarah from "../../assets/praposals/sarah_logo.png";
import micheal from "../../assets/praposals/micheal_logo.png";
export default function LogoIndexedView() {
    const images = [
        sarah,
        micheal,
        sarah,
        micheal,
        sarah,
        micheal,
        sarah,
        micheal,
        sarah,
        micheal,
        sarah,
        micheal,
      ];
  return (
    <div className="flex flex-row items-center">
    {images &&
      images.map((img, index) => (
        <div
          className={`-ml-4 ${
            index === 0
              ? "z-0"
              : index === 1
              ? "z-10"
              : index === 2
              ? "z-20"
              : index === 3
              ? "z-30"
              : "z-40"
          } w-[30px] h-[30px] rounded-full border-2 ${
            index > 4 && "hidden"
          }`}
        >
          <img src={img} alt="img" />
        </div>
      ))}
    <div className="font-[500] text-[16px] text-[#352F36]">
      +{images.length - 5} proposals
    </div>
  </div>
  )
}
