import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ForgetPassword from "./screens/ForgetPassword";
import PasswordSuccess from "./screens/PasswordSuccess";
import SetNewPassword from "./screens/SetNewPassword";
import LogInPage from "./screens/LogInPage";
import OtpVerfication from "./screens/OtpVerfication";
import SignUp from "./screens/SignUp";
import Notification from "./screens/Notification";
import Client from "./screens/Client";
import { ClientSingleEntrepreneurFlow } from "./screens/ClientSingleEntrepreneurFlow";
import ClientCompanyFlow from "./screens/ClientCompanyFlow";
import ProjectDurationMain from "./components/Post-Jobs/Status1/ProjectDurationMain";
import EstimatedTimeMain from "./components/Post-Jobs/Status2/EstimatedTimeMain";
import PaymentType from "./components/Post-Jobs/Status3/PaymentType";
import ProjectDescription from "./components/Post-Jobs/Status3/ProjectDescription";
import PreviewPostMain from "./components/Post-Jobs/PreviewPosts/PreviewPostMain";
import PraposalHome from "./components/Praposals/ProposalHome";
import PraposalProfileView from "./components/Praposals/ProposalProfileView";

import BrowseExperts from "./components/Praposals/BrowseExperts";
import ProjectsView from "./components/Projects_Contracts/ProjectsView";
import TopRatedIndividuals from "./components/Projects_Contracts/TopRatedIndividuals";
import ViewProfileTopRatedIndividuals from "./components/Projects_Contracts/ViewProfileTopRatedIndividuals";
import ProfileView from "./components/Projects_Contracts/ProfileView";
import RoleChanger from "./screens/RoleChanger";
import DefaultHome from "./components/DefaultHomeScreen/DefaultHome";
import HomePage from "./components/Landing Screen/HomePage";
import ClientDashBoard from "./screens/ClientDashBoard";
import Notifications from "./screens/Notifications";
import ProjectBrief from "./screens/ProjectBrief";
import Messages from "./screens/Messages";
import PostedSuccessFull from "./components/Post-Jobs/PostedSuccessFull";
import UserPortfolio from "./components/Freelancer/UserPortfolio";
import MyDashBoard from "./components/Freelancer/MyDashBoard";
import OpenRequest from "./components/Freelancer/OpenRequest";
import ReviewProposal from "./components/Freelancer/ReviewProposal";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener, requestPermission } from "./firebaseConfig";
import ProjectActivities from "./screens/ProjectActivities";
import ProjectList from "./components/DefaultHomeScreen/ProjectList";
import ProjectPage from "./components/DefaultHomeScreen/ProjectPage";
import IndividualPage from "./components/DefaultHomeScreen/IndividualPage";
import AllIndividuals from "./components/DefaultHomeScreen/AllIndividuals";
import PrivateRoute from "./components/Post-Jobs/util/PrivateRoute";
import TermsAndCondition from "./screens/TermsAndCondition";
import AllTestimonials from "./components/Landing Screen/AllTestimonials";
export const PostAJobContext = createContext();
export const ProposalContext = createContext();
export const DashBoardContext = createContext();
export const TokenContext = createContext();
export const ClientIdContext = createContext();
function App() {
  const [progressStatus, setprogressStatus] = useState(1);
  const [projectDuration, setProjectDuration] = useState(null);
  const [projectTitle, setProjectTitle] = useState(null);
  const [selectedSKills, setSelectedSkills] = useState([]);
  const [estimateTime, setEstimateTime] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [experienceLevel, setExperienceLevel] = useState(null);
  const [isContract, setIsContract] = useState(null);
  const [needAcompanyToWork, setNeedAcompanyToWork] = useState(null);
  const [teamSize, setTeamSize] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);
  const [projectDocument, setProjectDocument] = useState(null);
  const [projectDocumentName, setProjectDocumentName] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [costOfWork, setCostOfWork] = useState(null);
  const [projectBudget, setProjectBudget] = useState(null);
  const listOfPaymentType = ["Hourly", "Fixed"];
  const [clientId, setClientId] = useState("");
  if (!clientId && localStorage.getItem("userId")) {
    setClientId(localStorage.getItem("userId"));
  }
  const [token, setToken] = useState("");
  if (!token && localStorage.getItem("token")) {
    setToken(localStorage.getItem("token"));
  }
  const updateClientId = (newValue) => {
    setClientId(newValue);
  };
  const updateToken = (newValue) => {
    setToken(newValue);
  };

  const postAJob = {
    progressStatus,
    setprogressStatus,
    projectDuration,
    setProjectDuration,
    projectTitle,
    setProjectTitle,
    selectedSKills,
    setSelectedSkills,
    estimateTime,
    setEstimateTime,
    timeValue,
    setTimeValue,
    experienceLevel,
    setExperienceLevel,
    isContract,
    setIsContract,
    needAcompanyToWork,
    setNeedAcompanyToWork,
    teamSize,
    setTeamSize,
    projectDescription,
    setProjectDescription,
    projectDocument,
    setProjectDocument,
    projectDocumentName,
    setProjectDocumentName,
    listOfPaymentType,
    projectBudget,
    setProjectBudget,
    costOfWork,
    setCostOfWork,
    paymentType,
    setPaymentType,
  };

  // dashboard data variables
  const [showDate, setShowDate] = useState(false);
  const date = new Date();
  const [selectDate, setSelectDate] = useState({
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  });
  const dashboadValues = {
    showDate,
    setShowDate,
    selectDate,
    setSelectDate,
  };

  const [projectName, setProjectName] = useState(null);
  const [proposalProjectDocument, setProposalProjectDocument] = useState(null);
  const [proposalProjectOverview, setProposalProjectoverview] = useState(null);
  const [projectScope, setProjectScope] = useState(null);
  const [projectGoals, setProjectGoals] = useState(null);
  const [projectTimeline, setProjectTimeline] = useState(null);
  const [proposalProjectBudget, setProposalProjectBudget] = useState(null);
  const [evaluationCriteria, setEvaluationCriteria] = useState(null);
  const [resource, setResource] = useState(null);
  const [technicalRequirement, setTechnicalRequirement] = useState(null);
  const [qualificationandExperience, setQualificationAndExperience] =
    useState(null);
  const [proposalFormat, setProposalFormat] = useState(null);
  const [TermsAndConditions, setTermsAndConditions] = useState(null);
  const [contactInformation, setContactInformation] = useState(null);
  const [appendices, setAppendices] = useState(null);
  const [currentRoadblocks, setCurrentRoadblocks] = useState(null);
  const [evaluationMatrics, setEvaluationMatrics] = useState(null);
  const [submissionRequirements, setSubmissionRequirements] = useState(null);

  const proposalDashboardView = {
    projectName,
    setProjectName,
    proposalProjectDocument,
    setProposalProjectDocument,
    proposalProjectOverview,
    setProposalProjectoverview,
    projectScope,
    setProjectScope,
    projectGoals,
    setProjectGoals,
    projectTimeline,
    setProjectTimeline,
    proposalProjectBudget,
    setProposalProjectBudget,
    evaluationCriteria,
    setEvaluationCriteria,
    resource,
    setResource,
    technicalRequirement,
    setTechnicalRequirement,
    qualificationandExperience,
    setQualificationAndExperience,
    proposalFormat,
    setProposalFormat,
    TermsAndConditions,
    setTermsAndConditions,
    contactInformation,
    setContactInformation,
    appendices,
    setAppendices,
    currentRoadblocks,
    setCurrentRoadblocks,
    evaluationMatrics,
    setEvaluationMatrics,
    submissionRequirements,
    setSubmissionRequirements,
  };

  // const [notification, setNotification] = useState({title: '', body: ''});
  // const [fCMToken,setFCMToken]=useState(null)
  // const notify = () =>  toast(<ToastDisplay/>);
  // function ToastDisplay() {
  //   return (
  //     <div className="min-w-[300px] flex flex-col gap-2">
  //       <p><b>{notification?.title}</b></p>
  //       <p>{notification?.body}</p>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   if (notification?.title && localStorage.getItem('token)){
  //    notify()
  //   }
  // }, [notification])

  // requestPermission(setFCMToken);

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
  //   })
  //   .catch((err) => console.log('failed: ', err));

  return (
    <div className="font-Satoshi  overflow-hidden">
      <Toaster position="top-center" reverseOrder={true} />
      <PostAJobContext.Provider value={postAJob}>
        <DashBoardContext.Provider value={dashboadValues}>
          <ProposalContext.Provider value={proposalDashboardView}>
            <TokenContext.Provider value={{ token, updateToken }}>
              <ClientIdContext.Provider value={{ clientId, updateClientId }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<DefaultHome />} />
                    <Route path="/login" element={<LogInPage />} />
                    <Route
                      path="/change-role"
                      element={
                        <PrivateRoute>
                          <RoleChanger />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/forgetpassword"
                      element={<ForgetPassword />}
                    />
                    <Route
                      path="/otpverification"
                      element={<OtpVerfication />}
                    />
                    <Route path="/newpassword" element={<SetNewPassword />} />
                    <Route
                      path="/passwordsuccess"
                      element={<PasswordSuccess />}
                    />
                    <Route path="/signup" element={<SignUp />} />
                    <Route
                      path="/client"
                      element={
                        <PrivateRoute>
                          <Client />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/clientsingleentrepreneur"
                      element={
                        <PrivateRoute>
                          <ClientSingleEntrepreneurFlow />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/clientscompany"
                      element={
                        <PrivateRoute>
                          <ClientCompanyFlow />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/client-home-view"
                      element={
                        <PrivateRoute>
                          <HomePage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/All-Testiomonials"
                      element={
                        <PrivateRoute>
                          <AllTestimonials />
                        </PrivateRoute>
                      }
                    />

                    {/* dashboard */}
                    <Route
                      path="/clientDashboard"
                      element={
                        <PrivateRoute>
                          <ClientDashBoard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/notification"
                      element={
                        <PrivateRoute>
                          <Notifications />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route path="/notification" element={<Notification/>} /> */}
                    <Route
                      path="/project"
                      element={
                        <PrivateRoute>
                          <ProjectBrief />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/message"
                      element={
                        <PrivateRoute>
                          <Messages />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/project-activities"
                      element={
                        <PrivateRoute>
                          <ProjectActivities />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/all-projects" element={<ProjectList />} />
                    <Route
                      path="/default-project/:projectId"
                      element={<ProjectPage />}
                    />
                    {/* post job routes  */}
                    <Route
                      path="/project-duration"
                      element={<ProjectDurationMain />}
                    />
                    <Route
                      path="/privacy-policy"
                      element={<TermsAndCondition />}
                    />
                    <Route
                      path="/default-individual/:userId"
                      element={<IndividualPage />}
                    />
                    <Route
                      path="/all-individuals"
                      element={<AllIndividuals />}
                    />
                    <Route
                      path="/project-estimation-time"
                      element={
                        <PrivateRoute>
                          <EstimatedTimeMain />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/project-payment"
                      element={
                        <PrivateRoute>
                          <PaymentType />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/project-description"
                      element={
                        <PrivateRoute>
                          <ProjectDescription />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/post-preview"
                      element={
                        <PrivateRoute>
                          <PreviewPostMain />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/post-Success-message"
                      element={
                        <PrivateRoute>
                          <PostedSuccessFull />
                        </PrivateRoute>
                      }
                    />
                    {/* praposals flow */}
                    <Route
                      path="/proposal-dashboard"
                      element={
                        <PrivateRoute>
                          <PraposalHome />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/proposal-profile-preview"
                      element={
                        <PrivateRoute>
                          <PraposalProfileView />
                        </PrivateRoute>
                      }
                    />
                    {/* browse experts flow */}
                    <Route
                      path="/browse-experts-view"
                      element={
                        <PrivateRoute>
                          <BrowseExperts />
                        </PrivateRoute>
                      }
                    />
                    {/* projects and contracts */}
                    <Route
                      path="/projects-view"
                      element={
                        <PrivateRoute>
                          <ProjectsView />
                        </PrivateRoute>
                      }
                    />
                    {/* top rated individuals */}
                    <Route
                      path="/top-rated-individuals"
                      element={
                        <PrivateRoute>
                          <TopRatedIndividuals />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/top-rated-individuals_preview"
                      element={
                        <PrivateRoute>
                          <ViewProfileTopRatedIndividuals />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/top-rated-profile"
                      element={
                        <PrivateRoute>
                          <ProfileView />
                        </PrivateRoute>
                      }
                    />
                    {/* dashboard send proposals and view flow */}
                    <Route
                      path="/proposal-controller-dashboard"
                      element={
                        <PrivateRoute>
                          <MyDashBoard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/proposal-open-request"
                      element={
                        <PrivateRoute>
                          <OpenRequest />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/proposal-portfolio"
                      element={
                        <PrivateRoute>
                          <UserPortfolio />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/proposal-review"
                      element={
                        <PrivateRoute>
                          <ReviewProposal />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </ClientIdContext.Provider>
            </TokenContext.Provider>
          </ProposalContext.Provider>
        </DashBoardContext.Provider>
      </PostAJobContext.Provider>
    </div>
  );
}

export default App;
