import React from "react";

export default function PreviewButton({previewHandler}) {
  return (
    <div onClick={previewHandler} className="text-[#929CA8] hover:scale-[1.02] transform transition-transform duration-400 ease-in-out hover:[text-shadow:none] hover:text-[white] [text-shadow:6px_6px_18px_#929CA8,_-4px_-4px_1px_rgb(239,_239,_239)] hover:scale-[1.02] hover:bg-gradient-to-r from-[#FF913A] to-[#E46E10] min-w-[196px] max-w-fit h-[60px] text-[22px] font-[700] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px] active:scale-[1.01] grid place-items-center	cursor-pointer  capitalize">
      
        Preview
      
    </div>
  );
}
