import React from 'react'

export default function SignalLightsDesign() {
  return (
    <div className='w-fit h-fit flex flex-row gap-1'>
    <div className='w-[9px] h-[9px] bg-[#EB001B] rounded-full'></div>
    <div className='w-[9px] h-[9px] bg-[#F79E1B] rounded-full'></div>
    <div className='w-[9px] h-[9px] bg-[#089949] rounded-full'></div>
   </div>
  )
}
