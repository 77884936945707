import React from "react";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import ProgressBar from "../Post-Jobs/util/ProgressBar";
import NextButton from "../Button/NextButton";

export default function FlowForRequestForProposal({
  flowPlaced,
  setFlowPlaced,
}) {

const NextHandler=()=>{
    setFlowPlaced(flowPlaced+1)
}

  return (
    <div className="flex flex-col gap-4 pb-10">
      <div className="font-[700] text-[24px] ">
        <h1>Request for proposal</h1>
      </div>
      <div className="card p-4 rounded-[14px] flex flex-row gap-4 items-center">
        <InnerShadowAvatar
          size={30}
          imgSrc={require("../../assets/projects_logos/health_fitness.png")}
        />
        <h1 className="font-[700] text-[24px] ">
          Requests for RFP - {"Health & Fitness App"}
        </h1>
      </div>
      <div>
        <ProgressBar status={flowPlaced} />
      </div>
    <div>
       {
        flowPlaced===1?
        <RFPStartScreen />
        :
        flowPlaced===2?
        <RFPSecondScreen />
        :
        flowPlaced===3 && <RFPFinalScreen />
       }
    </div>
   <div className="w-full grid place-items-center ml-[200px] py-10">
    <NextButton bgActive={true} buttonName={flowPlaced<3?"Next":"post"} nextHandler={NextHandler} />
   </div>
    </div>
  );
}

const RFPStartScreen=()=>{
    return(
        <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">Project title</h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Health & Fitness app
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Project overview
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        We are embarking on the development of a comprehensive Health & Fitness app aimed at empowering users to achieve their fitness goals, improve overall health, and lead a more active lifestyle. The app will offer a range of features including workout tracking, nutrition planning, progress monitoring, and social engagement to create a holistic fitness experience.
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">Project scope</h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        The project involves the design, development, testing, and deployment of a native mobile application for iOS and Android platforms.        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
    Project goals
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Empower users to achieve their health and wellness goals through personalized plans and tracking tools.        </div>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Provide actionable insights and recommendations based on user data and preferences.   </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Project timeline
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        The project is expected to be completed within [08] months from the date of contract.
        </div>
      
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Project budget
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        The estimated budget for the project is [30000 USD], inclusive of all design, development, testing, deployment, and support costs.
        </div>
      
      </div>
        </div>
    )
}
const RFPSecondScreen=()=>{
    return(
        <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">Evaluation Criteria</h1>
        <ul className="list-disc ml-6 font-[400] text-[16px] text-[#352F36]">
          <li>
            Experience and expertise in developing health and fitness
            applications Understanding of project requirements and ability to
            deliver a high-quality soluti
          </li>
          <li>
            Understanding of project requirements and ability to deliver a
            high-quality soluti
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Resources
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
          Vendors are expected to provide all necessary resources, including
          project management, design, development, testing, and support teams.
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">Technical requirement</h1>
        <ul className="list-disc ml-6 font-[400] text-[16px] text-[#352F36]">
          <li>
          Native mobile application development for iOS and Android platforms
          
          </li>
          <li>
          Integration with third-party APIs for fitness tracking, nutrition data, and social features
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Qualification & Experience
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Vendors should have a proven track record of successfully delivering similar projects and possess relevant expertise in mobile application development, particularly in the health and fitness domain.
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Proposal format
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Proposals should be submitted in PDF format and include the following sections
        </div>
        <ul className="list-disc ml-6 font-[400] text-[16px] text-[#352F36] flex flex-row items-center flex-wrap gap-4">
          <li className="w-fit pr-4">
          Executive Summary
          
          </li>
          <li className="w-fit pr-4">
          Company Profile and Experience
          </li>
          <li className="w-fit pr-4">
          Proposed Solution and Approach
          
          </li>
          <li className="w-fit pr-4">
          Proposed Solution and Approach
          </li>
          <li className="w-fit pr-4">
          Cost Estimate and Payment Terms
          </li>
        </ul>
      </div>
        </div>
    )
}
const RFPFinalScreen=()=>{
    return(
        <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">Terms and Conditions</h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        The selected vendor will be required to enter into a formal contract outlining the terms and conditions of the project, including deliverables, milestones, payment schedule, and intellectual property rights.
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Contact Information
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        For inquiries and submission of proposals, please contact
        </div>
        <ul className="list-disc ml-6 font-[400] text-[16px] text-[#352F36] flex flex-row items-center flex-wrap gap-4">
          <li className="w-fit pr-4">
          Rithesh sharma
          
          </li>
          <li className="w-fit pr-4">
          Rithesh sharma
          </li>
          <li className="w-fit pr-4">
          ritheshp2h@gmail.com
          
          </li>
          <li className="w-fit pr-4">
          9876543210
          </li>
         
        </ul>
      </div>


      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Current Roadblocks
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Any known challenges or constraints that may impact project execution or delivery
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Evaluation Metrics
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Key performance indicators (KPIs) and success metrics for evaluating project outcomes
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="font-[700] text-[22px]">
        Submission Requirements
        </h1>
        <div className=" font-[400] text-[16px] text-[#352F36]">
        Proposals should be submitted electronically to the designated contact person by [Submission Deadline]. Late submissions may not be considered.        </div>
      </div>

     
        </div>
    )
}