import React from "react";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import globalReach from "../../assets/defaultScreen/global-reach.png";
import scalabilty from "../../assets/defaultScreen/scalabilty.png";
import quality from "../../assets/defaultScreen/quality-assurance.png";
import flexibility from "../../assets/defaultScreen/flexibility.png";
import cost_effective from "../../assets/defaultScreen/cost effective.png";
import payments_protection from "../../assets/defaultScreen/payments-protection.png";
import diverse_talent from "../../assets/defaultScreen/diverse-talent.png";

export default function WhyUsStandsForClients() {
  const options = [
    {
      image: globalReach,
      name: "Global Reach",
    },
    {
      image: scalabilty,
      name: "Scalability",
    },
    {
      image: quality,
      name: "Quality Assurance",
    },
    {
      image: flexibility,
      name: "Flexibility",
    },
    {
      image: cost_effective,
      name: "Cost-Effectiveness",
    },
    {
      image: payments_protection,
      name: "Payment Protection",
    },
    {
      image: diverse_talent,
      name: "Access to Diverse Talent",
    },
  ];
  return (
    <div className="flex flex-col gap-1 px-10 p-4 items-start">
      <div className="font-[500] text-[40px] text-[#352F36]">
        Why our platform stands out for clients{" "}
      </div>
      <div className="grid grid-cols-[59%_39%]  ipadMax:flex ipadMin:flex mobile:flex ipadMin:flex-col-reverse ipadMax:flex-col-reverse  mobile:flex-col-reverse  w-full">
        <div className="flex flex-col gap-10">
          <div className="text-[#414B5A] opacity-[50%] w-[90%] mobile:w-full font-[400] text-[26px]">
            Discover the Client Advantage: Unmatched Talent, Scalability, and
            More
          </div>
          <div className="flex  ipadMin:grid-cols-[repeat(1,auto)] mobile:grid-cols-[repeat(1,auto)]  gap-8 flex-wrap">
            {options.map((item,index) => (
              <div key={index} className="[box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FFFFFF]  px-6  rounded-[25px] w-fit flex flex-row gap-2 items-center">
                <div className="w-[60px]  h-[60px] border-[1px] border-[#ffffff] rounded-full p-2">
              <div className="w-full h-full rounded-full  [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset] grid place-items-center">
               
                <img
          src={item.image}
          alt="Category"
        />
              </div>
              </div>
                <h1 className="text-[#414B5A] font-[500] text-[23px]">
                  {item.name}
                </h1>
              </div>
            ))}
          </div>
        </div>
        <div className="relative w-full h-full grid place-items-center p-10">
          <div className="relative w-full h-full">
            <div className="absolute right-0 -mt-10 -mr-10 w-[102px] h-[102px] rounded-[30px] bg-[#FF5F1B] grid place-items-center ">
              <img
                src={require("../../assets/defaultScreen/fa-icon-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className=" [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,0px_-8.37px_11.96px_0px_#FFFFFF_inset] p-2 h-full rounded-[30px]">
            <img
              src={require("../../assets/defaultScreen/client-stands.png")}
              alt=""
            />
          </div>
          <div className="relative w-full h-full">
            <div className="absolute left-0 -mt-20 mb-4 -ml-10 w-[102px] h-[102px] rounded-[30px] bg-[#2485FF] grid place-items-center ">
              <img
                src={require("../../assets/defaultScreen/fa-icon-2.png")}
                alt=""
              />
            </div>
            <div className="absolute right-0 -mt-14 -mr-10 w-[102px] h-[102px] rounded-[30px] bg-[linear-gradient(212.87deg,_#FF716A_23.4%,_#FF2731_251.87%)] grid place-items-center ">
              <img
                src={require("../../assets/defaultScreen/fa-icon-3.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
