import React from "react";
import UserHeader from "./UserHeader";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";

export default function UserPortfolio() {
  return (
    <div className="bg-normal w-full h-screen overflow-hidden">
      <UserHeader isBack={true}
        titleName="Portfolio" />
      <div className="h-[82%] overflow-auto scrollbar">
        <UserProfilePart />
        <UserProjectSuggestions />
      </div>
    </div>
  );
}
function UserProfilePart() {
  return (
   <div className="w-full h-fit flex flex-col items-center">
     <div className="grid relative place-items-center bg_img_union p-10 w-fit h-fit">
            <img className="absolute left-0 mt-[114px] -ml-3" src={require("../../assets/union-orange-curve.png")} alt="" />
              
               <div className=" card_inner_shadow w-[300px] h-[300px] grid place-items-center rounded-full p-4 ml-2 mt-2">
      <img src={require('../../assets/girl-logo.png')} alt="" />
      </div>
               <img className="absolute right-0 -mt-[100px] -mr-6" src={require("../../assets/union-blue-curve.png")} alt="" />

    </div>
    <div className="text-center py-4">
        <h1 className="font-[700] text-[24px] text-[#352F36]">Sarah</h1>
        <h2 className="opacity-[80%] font-[500] text-[20px] text-[#352F36]">UX UI Designer</h2>
    </div>
   </div>
  );
}

function UserProjectSuggestions(){
    return (
      <div className="w-full h-fit pb-16 flex flex-col gap-4 px-10">
        <div className="w-full h-fit grid grid-cols-[75%_25%] gap-4">
          <div className="w-full h-full flex flex-col gap-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1 bg-[linear-gradient(180deg,_#30393F_0%,_#1A1A1E_100%)] overflow-hidden [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"EV-Station app"} />
                <div className="relative h-fit w-full">
                  <img
                    className=" absolute w-[240px] h-[200px] right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/car.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="overflow-hidden col-span-2 bg-[linear-gradient(180deg,_#6F7AFC_0%,_#4554EE_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Dr.care - Appointment App"} />
                <div className="relative h-[80%] w-full flex flex-row justify-between items-end">
                  <div className="p-6 flex flex-row items-center gap-4">
                    <InnerShadowAvatar
                      imgSrc={require("../../assets/girl-logo.png")}
                      size={20}
                    />
                    <div>
                      <h1 className="font-[700] text-[16px]">Sarah</h1>
                      <h1 className="font-[500] text-[12px] opacity-[70%]">
                        UX UI Designer
                      </h1>
                    </div>
                  </div>
                  <img
                    className="absolute w-[300px] h-[300px] right-0 bottom-1 -mt-7 -mr-4"
                    src={require("../../assets/project_app_logos/mobile.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="overflow-hidden col-span-2 bg-[linear-gradient(180deg,_#FBFBFC_0%,_#DBDBDB_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Marketing agency - website"} islogo={true}/>
                <div className="relative h-[80%] w-full">
                  <img
                    className="absolute w-[300px] h-[300px] right-0 bottom-2 -mt-8 -mr-4"
                    src={require("../../assets/project_app_logos/monitoe.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="overflow-hidden col-span-1 bg-[linear-gradient(180deg,_#FCA326_0%,_#E24329_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Be smart - UI"} />
                <div className="relative h-fit w-full -ml-10 mt-2">
                  <img
                    className=" absolute w-[240px] h-[200px] z-50 right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/watch.png")}
                    alt=""
                  />
                   <img
                    className=" absolute w-[240px] h-[200px] z-40 right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/large-watch.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden h-full bg-[linear-gradient(180deg,_#FF1539_0%,_#D50020_100%)] rounded-[14px] p-6">
          <ViewButton headerName={"Food recipe app"} />
          <div className="relative h-full w-full -ml-12 mt-2  flex flex-col justify-end">
                  <img
                    className=" absolute w-[500px] h-[600px] z-40 right-0 mt-20 -mr-5"
                    src={require("../../assets/project_app_logos/foodMobile.png")}
                    alt=""
                  />
                   <img
                    className=" absolute w-[420px] h-[400px] z-50 right-0 -mt-6 -mr-20"
                    src={require("../../assets/project_app_logos/chef.png")}
                    alt=""
                  />
                </div>
          </div>
        </div>

        <div className="w-full h-fit grid grid-cols-[25%_75%] gap-4">
         
          <div className="overflow-hidden h-full bg-[linear-gradient(180deg,_#FF1539_0%,_#D50020_100%)] rounded-[14px] p-6">
          <ViewButton headerName={"Food recipe app"} />
          <div className="relative h-full w-full -ml-12 mt-2  flex flex-col justify-end">
                  <img
                    className=" absolute w-[500px] h-[600px] z-40 right-0 mt-20 -mr-5"
                    src={require("../../assets/project_app_logos/foodMobile.png")}
                    alt=""
                  />
                   <img
                    className=" absolute w-[420px] h-[400px] z-50 right-0 -mt-6 -mr-20"
                    src={require("../../assets/project_app_logos/chef.png")}
                    alt=""
                  />
                </div>
          </div>

          <div className="w-full h-full flex flex-col gap-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1 bg-[linear-gradient(180deg,_#30393F_0%,_#1A1A1E_100%)] overflow-hidden [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"EV-Station app"} />
                <div className="relative h-fit w-full">
                  <img
                    className=" absolute w-[240px] h-[200px] right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/car.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="overflow-hidden col-span-2 bg-[linear-gradient(180deg,_#6F7AFC_0%,_#4554EE_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Dr.care - Appointment App"} />
                <div className="relative h-[80%] w-full flex flex-row justify-between items-end">
                  <div className="p-6 flex flex-row items-center gap-4">
                    <InnerShadowAvatar
                      imgSrc={require("../../assets/girl-logo.png")}
                      size={20}
                    />
                    <div>
                      <h1 className="font-[700] text-[16px]">Sarah</h1>
                      <h1 className="font-[500] text-[12px] opacity-[70%]">
                        UX UI Designer
                      </h1>
                    </div>
                  </div>
                  <img
                    className="absolute w-[300px] h-[300px] right-0 bottom-1 -mt-7 -mr-4"
                    src={require("../../assets/project_app_logos/mobile.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="overflow-hidden col-span-2 bg-[linear-gradient(180deg,_#FBFBFC_0%,_#DBDBDB_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Marketing agency - website"} islogo={true}/>
                <div className="relative h-[80%] w-full">
                  <img
                    className="absolute w-[300px] h-[300px] right-0 bottom-2 -mt-8 -mr-4"
                    src={require("../../assets/project_app_logos/monitoe.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="overflow-hidden col-span-1 bg-[linear-gradient(180deg,_#FCA326_0%,_#E24329_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] w-full h-[325px] rounded-[14px] p-4">
                <ViewButton headerName={"Be smart - UI"} />
                <div className="relative h-fit w-full -ml-10 mt-2">
                  <img
                    className=" absolute w-[240px] h-[200px] z-50 right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/watch.png")}
                    alt=""
                  />
                   <img
                    className=" absolute w-[240px] h-[200px] z-40 right-0 mt-6 -mr-5"
                    src={require("../../assets/project_app_logos/large-watch.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
} 

function ViewButton({headerName,islogo}) {
    return (
        <div className=" flex flex-col items-start gap-1">
            <h1 className={`font-[700] text-[24px] ${islogo?"text-[#000]":"text-[#FFFFFF]"}`}>{headerName}</h1>
            <button className={`${islogo?"bg-[linear-gradient(180deg,_#30393F_0%,_#1A1A1E_100%)] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] rounded-[14px] text-[#FFFFFF]":"button_bg_logo "}  px-6 hover:scale-[1.02] py-2 cursor-pointer `}>View</button>
        </div>
    )
}