import React, { useState } from "react";
import UserHeader from "./UserHeader";
import { BannerDashboard, DashboardActiveScreen } from "./MyDashBoard";
import CompanyProfiles, {
  RecommendationCompanyProfiles,
} from "./CompanyProfiles";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import { FaFilter } from "react-icons/fa";
import OpenEyeButton from "../Button/OpenEyeButton";
import CancelButton from "../Button/CancelButton";
import { Avatar } from "ui-neumorphism";

import zoho from "../../assets/projects_logos/zoho.png";
import accenture from "../../assets/projects_logos/accenture.png";
import cognigant from "../../assets/projects_logos/cognigant.png";
import infosys from "../../assets/projects_logos/infosys.png";
import tcs from "../../assets/projects_logos/tcs.png";
import hcl from "../../assets/projects_logos/hcl.png";
import ibm from "../../assets/projects_logos/ibm.png";
import siemens from "../../assets/projects_logos/siemens.png";
import countryLogo from "../../assets/flag.png";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import TextInput from "../inputs/TextInput";

export default function OpenRequest() {
  const [activeScreen, setActiveScreen] = useState(
    localStorage.getItem("activeProject")
      ? localStorage.getItem("activeProject")
      : 0
  );

  const [isRFI, setRFI] = useState(false);
   const [clickedProject,setClickedProject]=useState({})
  return (
    <div className="bg-normal w-full h-screen overflow-hidden">
      <UserHeader isProfile={true} isBack={true} titleName={"My Dashboard"} />

      <div className="grid grid-cols-[70%_30%] gap-4 h-[82%]   place-items-start overflow-x-hidden w-full scrollbar overflow-y-auto px-10 pt-4">
        <div className="w-full flex flex-col gap-4">
          <BannerDashboard isCreateRFP={true} />
          <DashboardActiveScreen
            activeScreen={activeScreen ? activeScreen : 0}
            setActiveScreen={setActiveScreen}
          />
          <ProjectCard setRFI={setRFI} />
          <OpenRequestForProject setRFI={setRFI} setClickedProject={setClickedProject} />
        </div>
        <div className="flex flex-col gap-4">
          <RecommendationCompanyProfiles />
          <CompanyProfiles />
        </div>
      </div>
      {isRFI && <RFIForSelectedCompany setRFI={setRFI} companyInfo={clickedProject} />}
    </div>
  );
}

const ProjectCard = ({ projectName, logo }) => {
  return (
    <div className="card w-full p-4 rounded-[14px] flex flex-row gap-4 items-center">
      <InnerShadowAvatar
        size={30}
        imgSrc={require("../../assets/projects_logos/health_fitness.png")}
      />
      <div className="font-[700] text-[24px] ">
        RFI Requests For RFP - Health & Fitness App
      </div>
    </div>
  );
};
const OpenRequestForProject = ({setRFI,setClickedProject}) => {
  const listOfCompanies = [
    {
      logo: zoho,
      name: "zoho",
      privaneName: "zoho pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "30min ago",
    },
    {
      logo: tcs,
      name: "tcs",
      privaneName: "tcs pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "45min ago",
    },
    {
      logo: hcl,
      name: "hcl",
      privaneName: "hcl pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "57min ago",
    },
    {
      logo: infosys,
      name: "infosys",
      privaneName: "infosys pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "1hr ago",
    },
    {
      logo: accenture,
      name: "accenture",
      privaneName: "accenture pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "1hr ago",
    },
    {
      logo: cognigant,
      name: "cognigant",
      privaneName: "cognigant pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "2h ago",
    },
    {
      logo: ibm,
      name: "ibm",
      privaneName: "ibm pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "2h ago",
    },
    {
      logo: siemens,
      name: "siemens",
      privaneName: "siemens pvt ltd",
      country: "india",
      countryLogo: countryLogo,
      postedTime: "3h ago",
    },
  ];
  const RFIHandler=(obj)=>{
    setRFI(true)
    setClickedProject(obj)
  }
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-4 items-center">
          <h1 className="font-[700] text-[22px]">Open requests</h1>{" "}
          <div className="red w-[26px] h-[26px] rounded-full text-[14px] grid place-content-center text-white">
            32
          </div>
        </div>
        <div className="card w-fit p-2 rounded-[8px]">
          <FaFilter style={{ fontSize: "16px" }} />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {listOfCompanies &&
          listOfCompanies.map((company, index) => (
            <div
              key={index}
              className="card hover:scale-[1.01] p-4 px-6 rounded-[14px] w-full grid grid-cols-[30%_15%_15%_40%]"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className=" [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit h-fit p-1 rounded-full">
                  <Avatar size={30} src={company.logo} />
                  <div className="relative">
                    <div className="absolute -bottom-3 -right-2">
                      <img
                        src={require("../../assets/blue-start-tick.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <h1
                    className={`font-[700] text-[20px] ${
                      company.name.length < 4 ? "uppercase" : "capitalize"
                    }`}
                  >
                    {company.name}
                  </h1>
                  <h2 className="font-[500] text-[14px] opacity-[50%] text-[#414B5A] capitalize">
                    {company.privaneName}
                  </h2>
                </div>
              </div>
              <div className=" flex flex-row gap-2 items-center w-fit capitalize">
                {company.country}{" "}
                <img
                  className="mt-1 w-[25px] h-[20px]"
                  src={countryLogo}
                  alt=""
                />
              </div>
              <div className=" flex flex-row items-center gap-2 w-fit">
                {" "}
                <img
                  className="mt-[0.5] w-[16px] h-[16px]"
                  src={require("../../assets/time-logo.png")}
                  alt=""
                />
                {company.postedTime}
              </div>
              <div className=" flex flex-row items-center gap-2 w-fit">
                <div onClick={()=>RFIHandler(company)}><OpenEyeButton /></div>
                <CancelButton
                  isBg={index === listOfCompanies.length - 1 ? true : false}
                />
              </div>
            </div>
          ))}
      </div>
      <div className="w-screen grid place-items-center gap-4">
        <div className="flex flex-row items-center gap-4 capitalize">
          <div className=" text-[#CCCCCC] cursor-pointer ">prev</div>
          <div className=" card w-[32px] h-[32px] rounded-[8px] grid place-content-center bg-blue cursor-pointer">
            1
          </div>
          <div className=" card w-[32px] h-[32px] rounded-[8px] grid place-content-center cursor-pointer">
            2
          </div>
          <div className="card w-[32px] h-[32px] rounded-[8px] grid place-content-center cursor-pointer">
            3
          </div>
          <div className="card w-[32px] h-[32px] rounded-[8px] grid place-content-center cursor-pointer pb-2">
            ...
          </div>
          <div className="  card w-[32px] h-[32px] rounded-[8px]  grid place-content-center cursor-pointer">
            10
          </div>
          <div className="  cursor-pointer">next</div>
        </div>
        <div className="flex flex-row gap-2 justify-center items-center min-w-[130px] p-2 px-4 bg-blue card w-fit text-center text-white capitalize rounded-[8px]">
          next <HiOutlineArrowLongRight />{" "}
        </div>
      </div>
    </div>
  );
};

const RFIForSelectedCompany = ({setRFI,companyInfo}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full grid place-items-center bg-[#FFFFFF4D] overflow-auto p-10">
      <div className="card bg-normal w-[500px] h-fit rounded-[14px] p-4 flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="font-[700] text-[24px] text-[#352F36]">
            RFI - <span className="capitalize">{companyInfo.privaneName}</span>
          </div>
          <div onClick={()=>setRFI(false)} className="card cursor-pointer hover:scale-[1.02] w-[28px] grid place-content-center text-[22px] pb-1 h-[28px] rounded-[8px] text-[#414B5A]">
            &times;
          </div>
        </div>
        <div className="w-full grid grid-cols-[repeat(2,1fr)] gap-2">
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q1"}
            placeholder={"Add text"}
          />
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q2"}
            placeholder={"Add text"}
          />
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q3"}
            placeholder={"Add text"}
          />
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q4"}
            placeholder={"Add text"}
          />
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q3"}
            placeholder={"Add text"}
          />
          <TextInput
            type={"text"}
            inputValue={null}
            setInputValue={null}
            labelName={"Q4"}
            placeholder={"Add text"}
          />
        </div>
        <TextInput
          type={"text"}
          inputValue={null}
          setInputValue={null}
          labelName={"Other details"}
          placeholder={"Add text"}
        />
        <TextInput
          type={"text"}
          inputValue={null}
          setInputValue={null}
          labelName={"Supportive documents"}
          placeholder={"Add text"}
        />
        <div className="w-full flex flex-row gap-4 justify-center">
          <CancelButton />
          <div className={`bg-blue min-w-[136px] p-2 px-4 flex flex-row gap-2 justify-center text-white items-center rounded-[8px] cursor-pointer hover:scale-[1.02]`}>Send</div>
        </div>
      </div>
    </div>
  );
};
