import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Card } from "ui-neumorphism";
import { apiUrl } from "../../utils/constants";
import { ClientIdContext, TokenContext } from "../../App";

export default function SearchInput({
  type,
  inputValue,
  setInputValue,
  labelName,
  placeholder,
}) {
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [listOfSkills, setListOfSkills] = useState(["java", "react js", "sql"]);
  const [search, setSearch] = useState("");
  const [newSkill, setNewSkill] = useState(false);
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const addSkillHandler = (value) => {
    setInputValue([...inputValue, value]);
    setSearch("");
  };
  const removeSkillFromList = (value) => {
    const newArr = inputValue.filter((item, index) => index !== value);
    setInputValue(newArr);
  };

  useEffect(() => {
    if (search.length > 0) {
      const skill = listOfSkills.filter((item) => {
        return search.toLowerCase() === ""
          ? item
          : item.toLocaleLowerCase().includes(search);
      });
      if (skill < 1) {
        setNewSkill(true);
        // alert(search)
      }
    } else {
      setNewSkill(false);
    }
  }, [search]);

  useEffect(() => {
    getSkillsApi();
  }, []);
  const getSkillsApi = () => {
    try {
      axios
        .get(apiUrl + `/skills/getRelatedSkill`, {
          headers: { Authorization: token },
        })
        .then((res) => {
          console.log(res);
          setListOfSkills(res?.data?.map((item) => item.name));
          if (search.length > 0) {
            addSkillHandler(search);
          }
        })
        .catch((err) => toast.error(err.message));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const addNewSkill = () => {
    const data = { id: search, name: search };
    try {
      axios
        .post(apiUrl + "/skills", data, { headers: { Authorization: token } })
        .then((res) => {
          setNewSkill(false);
          getSkillsApi();
        })
        .catch((err) => toast.error(err.message));
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="w-full  h-full flex flex-col gap-2 ">
      <div className=" text-[22px] font-medium leading-[29.7px] text-left">
        {labelName}
      </div>

      <div
        className={`relative w-full flex flex-row flex-wrap gap-2 items-center ${
          inputValue.length !== 0 || focus
            ? "shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff]"
            : " shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]"
        } p-2 outline-none  rounded-[5px]  font-[500] border-1 leading-24 bg-[#E3EDF7] text-[#414B5A] text-[22px]  focus:outline-none" ${
          error && "border border-red-500"
        }`}
      >
        {inputValue &&
          inputValue.map((key, index) => (
            <div
              className="flex flex-row gap-2 items-center shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] w-fit p-1 px-6 rounded-[30px]"
              key={index}
            >
              <span className="w-[10px] h-[10px] bg-[#20BF55] rounded-full"></span>
              <span>{key}</span>
              <span
                className=" text-[#414B5A] opacity-[50%] hover:text-black hover:opacity-[100%] cursor-pointer"
                onClick={() => removeSkillFromList(index)}
              >
                &times;
              </span>
            </div>
          ))}
        <input
          type={type}
          className={` min-w-[100px] w-fit p-1 outline-none placeholder:text-[16px] leading-24 bg-transparent text-[#414B5A] text-[22px]  focus:outline-none`}
          style={{ borderRadius: "10px" }}
          placeholder={placeholder}
          name={labelName}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {newSkill && (
          <div className="w-full flex flex-row pr-2 pb-2 justify-end">
            <button
              className="bg-blue text-white px-10 hover:scale-[1.01] py-1  rounded-[14px] w-fit"
              onClick={addNewSkill}
            >
              Add
            </button>
          </div>
        )}
      </div>
      {search.length > 0 && !newSkill && (
        <div className="py-6 w-full">
          <div className="card rounded-[20px] w-full flex flex-col p-4">
            <div className="w-full text-[22px] font-medium leading-[29.7px] text-left border-b border-gray-300">
              Relevant skills
            </div>
            <div className="w-full p-4 flex flex-row flex-wrap px-2 gap-4">
              {listOfSkills &&
                listOfSkills
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : item.toLocaleLowerCase().includes(search);
                  })
                  .map((key, index) => (
                    <div
                      className="w-fit cursor-pointer"
                      key={index}
                      onClick={() => addSkillHandler(key)}
                    >
                      <Card style={{ borderRadius: "20px" }}>
                        <div className="w-fit p-2 px-4">{key}</div>
                      </Card>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
