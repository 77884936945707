import React from 'react'
import { Avatar } from 'ui-neumorphism'

export default function InnerShadowAvatar({size,imgSrc}) {
  return (
    <div className=" [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit h-fit p-2 bg-normal rounded-full">
          <Avatar
            size={size?size:60}
            src={imgSrc?imgSrc:require("../assets/praposals/sarah_logo.png")}
          />
        
        </div>
  )
}
