import React, { useState } from 'react'
import { Card } from 'ui-neumorphism'
import RadioInput from '../../inputs/RadioInput'

export default function LevelOfExperience({experienceLevel,setExperienceLevel}) {
  return (
    <div className="flex flex-col w-full gap-4 py-4">
      <div className="font-[500] text-[22px] ">Level of experience ?</div>
      <div className="flex flex-row medium:flex-wrap medium:gap-4 gap-10">
        <Card width={168} height={58} style={{borderRadius:"20px"}} >
          <RadioInput
            radioValue={experienceLevel}
            setRadioValue={setExperienceLevel}
            labelValue={"Entry level"}
          />
        </Card>
        <Card width={218} height={58} style={{borderRadius:"20px"}} >
          <RadioInput
            radioValue={experienceLevel}
            setRadioValue={setExperienceLevel}
            labelValue={"Intermediate level"}
          />
        </Card>
        <Card width={218} height={58} style={{borderRadius:"20px"}} >
          <RadioInput
            radioValue={experienceLevel}
            setRadioValue={setExperienceLevel}
            labelValue={"Experience level"}
          />
        </Card>
      </div>
      </div>
  )
}
