import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BgButton from "../Button/BgButton";
import SearchIcon from "@mui/icons-material/Search";

const Categories = forwardRef(function (props, ref) {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));
  const categories = [
    {
      name: "Graphic design",
      image: "graphic-designer-logo.png",
      imageAlt: "graphic-designer-logo-alt.png",
      services: [
        "Brand Logo design",
        "Brand Identity Guidelines",
        "Social Media Graphics",
        "Product Packaging Design",
      ],
    },
    {
      name: "Web development",
      image: "web-dev-logo.png",
      imageAlt: "web-dev-logo-alt.png",
      services: [
        "Full-stack development",
        "Single page applications",
        "Progressive web apps",
        "Web analytics and tracking",
      ],
    },
    {
      name: "Animations",
      image: "animation-logo.png",
      imageAlt: "animation-logo-alt.png",
      services: [
        "3D Animation",
        "2D Animation",
        "Motion Graphics",
        "Whiteboard Animation",
      ],
    },
    {
      name: "IT services",
      image: "it-services.png",
      imageAlt: "it-services-alt.png",
      services: [
        "IT Consulting",
        "Network Security",
        "Cloud Services",
        "Technical Support",
      ],
    },
  ];

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  const handleCardClick = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };

  return (
    <div
      data-id="categories"
      ref={localRef}
      className="flex flex-col ipadMin:flex-col flex-wrap justify-between gap-10 w-full px-10 p-4"
    >
      <div className="w-fit flex flex-col gap-7">
        <div className="font-[500] text-[40px] text-[#352F36]">Categories</div>
        <div className="text-[#414B5A] opacity-[50%] font-[400] text-[26px]">
          Explore Our Wide Range of Offerings
        </div>
        <div className="w-fit h-fit flex flex-row gap-5">
          <div className="w-fit p-2 text-left flex flex-row gap-2 rounded-[15px] items-center min-w-[150px] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] focus:[box-shadow:2.7px_2.7px_5.5px_1px_#A6ABBD_inset] ">
            <SearchIcon className="font-30 text-[gray]" />{" "}
            <input
              type="search"
              className="outline-none bg-[#E3EDF7] p-1 w-full placeholder:opacity-[50%] text-[#414B5A]"
              placeholder="Search your services"
            />
          </div>
          <div className="w-fit">
            {" "}
            <BgButton buttonName="Explore more" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-[repeat(4,1fr)] mobile:grid-cols-[repeat(1,1fr)] medium:grid-cols-[repeat(2,1fr)] ipadMin:grid-cols-[repeat(1,1fr)] ipadMin:w-full mobile:w-full gap-10 w-fit">
        {categories.map((category) => (
          <div className="flex flex-col gap-4" key={category.name}>
            <div
              onMouseEnter={() => handleMouseEnter(category.name)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleCardClick(category.name)}
              className={`w-[300px] h-[274px] grid place-items-center gap-5 cursor-pointer hover:scale-[1.02] [box-shadow:4px_4px_8px_0px_#BDC2C7BF,_-4px_-4px_8px_0px_#FFFFFFBF] py-[20px] px-[30px] rounded-[26px] ${
                selectedCategory === category.name ? "" : ""
              }`}
            >
              <div className="w-[180px] h-[180px] border-[1px] border-[#ffffff] rounded-full p-4">
                <div className="w-full h-full rounded-full [box-shadow:0px_8.37px_22.73px_0px_#00000040_inset,_0px_-8.37px_11.96px_0px_#FFFFFF_inset] grid place-items-center">
                  <img
                    src={require(`../../assets/defaultScreen/${
                      hoveredCategory === category.name ||
                      selectedCategory === category.name
                        ? category.image
                        : category.imageAlt
                    }`)}
                    alt="Category"
                  />
                </div>
              </div>
              <div className="text-[#352F36] font-[500] text-[25px]">
                {category.name}
              </div>
            </div>
            {(hoveredCategory === category.name ||
              selectedCategory === category.name) && (
              <div className="flex flex-col items-center gap-6">
                {category.services.map((service) => (
                  <div
                    onClick={() => setSelectedCategory(null)}
                    className="w-[278px] cursor-pointer hover:scale-[1.02]  hover:text-[blue] h-[52px] max-h-fit text-[#929CA8] text-center font-[700] [box-shadow:5px_5px_10px_0px_#A6ABBD,-5px_-5px_10px_0px_#FAFBFF] rounded-[14px] py-[14px] px-[20px]"
                    key={service}
                  >
                    {service}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Categories;
