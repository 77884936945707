import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "ui-neumorphism";
import { handleBase64Change, hoverBg } from "../../utils/constants";
import Person2Icon from "@mui/icons-material/Person2";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function WorkSpace({ clientInfo }) {
  const navigate = useNavigate();

  const postJobHandler = () => {
    navigate("/project-duration");
  };
  const myProjectsHandler = () => {
    navigate("/projects-view");
  };
  const dashboardNavigationHandler = () => {
    navigate("/clientDashboard");
  };

  return (
    <div className="h-fit min-h-full w-full z-10 flex flex-row justif-center gap-10 px-4 medium:px-1 p-4">
      <div className="largeScreen:ml-10 h-fit w-full z-50 flex flex-col justif-center gap-10 medium:px-0 px-4 p-4">
        <div className="grid grid-cols-2  medium:grid-cols-1 medium:gap-4 gap-20 w-full">
          <Card className="col-span-1 hover:scale-[1.02] min-w-[450px] medium:min-w-full medium:w-full  h-[331px] medium:h-fit medium: overflow-hidden">
            <div className="flex rounded-[15px]  flex-row medium:flex-col h-full items-center p-5">
              <div className="bg-[#E7EBF0] h-fit w-fit ">
                <img
                  src={
                    clientInfo.profileImage
                      ? handleBase64Change(clientInfo.profileImage)
                      : require("../../assets/noimage.png")
                  }
                  alt=""
                  className="w-[297px] h-[297px] rounded-[10px] bg-[#E7EBF0] object-cover"
                />
              </div>
              <div className="flex flex-col items-left">
                <CardContent>
                  <h1 className=" text-[34px] font-bold capitalize leading-[51.3px] text-left">
                    {clientInfo.name}
                  </h1>
                  <h6 className="font-[18px] opacity-50">P2H Solutions</h6>
                </CardContent>
                <div className="flex flex-col items-start w-[260px] pl-4">
                  <button
                    className={`${hoverBg} w-fit h-fit text-white hover:scale-[1.02]  p-2 px-10 gap-[16px] rounded-[15px] [box-shadow:5px_5px_10px_0px_#A6ABBD] rounded-md active:[box-shadow:-5px_-5px_10px_0px_#FAFBFF] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`}
                    onClick={dashboardNavigationHandler}
                  >
                    {" "}
                    Go to dashboard
                  </button>{" "}
                  <div className="text-[#352F36] w-[90%] h-[48px] top-[263px] left-[295px]    text-[16px] -ml-2 font-normal leading-[24.3px] text-center">
                    “Turning Ideas into ventures, Dreams into reality”
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Card className="col-span-1 hover:scale-[1.02] min-w-[450px] medium:min-w-full medium:w-full  h-[331px] medium:h-fit medium: overflow-hidden">
            <div
              className={`w-full h-full rounded-[15px]  overflow-hidden flex flex-col justify-between p-10 mobile:px-1`}
            >
              <div className="flex flex-row justify-between gap-10 z-10 relative">
                <div className="w-[297px]  text-[20px] font-medium  text-left p-6 pb-0">
                  <div>
                    <img
                      src={require("../../assets/landingScreen/lights.png")}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div className="z-40 relative">
                    Got a project in mind? Post it here and connect with the
                    right experts!
                  </div>
                </div>
                <div className="w-fit  border-[1.2px] border-[solid] [border-image-source:linear-gradient(156.29deg,_#FFFFFF_9.54%,_#FFFFFF_85.03%)] [box-shadow:0px_8.37px_22.73px_0px_#00000030_inset] flex items-center justify-center rounded-full p-4 overflow-hidden absolute right-[-90px] mt-[-60px] ">
                  <img
                    className=" w-[150.2px] h-[150.2px] rounded-full"
                    src={require("../../assets/landingScreen/user_logo.png")}
                    alt=""
                  />
                </div>
              </div>

              <div className="p-4 flex flex-row small:flex-col small:gap-4 gap-10">
                <button
                  className={`${hoverBg} w-fit h-fit rounded-[15px] text-white hover:scale-[1.02]  p-2 px-6 gap-[16px]  [box-shadow:5px_5px_10px_0px_#A6ABBD] rounded-md active:[box-shadow:-5px_-5px_10px_0px_#FAFBFF] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`}
                  onClick={postJobHandler}
                >
                  {" "}
                  Post a job
                </button>{" "}
                <button
                  onClick={myProjectsHandler}
                  className={`${hoverBg} w-fit h-fit text-white hover:scale-[1.02]  p-2 px-6 gap-[16px]  [box-shadow:5px_5px_10px_0px_#A6ABBD] rounded-md active:[box-shadow:-5px_-5px_10px_0px_#FAFBFF] bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)]`}
                >
                  {" "}
                  Outsource my project
                </button>{" "}
              </div>
            </div>
          </Card>
        </div>

        <div className="your_workSpace w-full flex flex-col gap-2">
          <div className="text-[#352F36]  text-[38px] small:text-[26px] medium:text-[30px] font-bold leading-[51.3px] text-left">
            Your workspace
          </div>
          <div className=" text-[30px] small:text-[22px] medium:text-[26px] font-medium leading-[40.5px] text-left text-[#414B5A]">
            Complete these steps to standout and hire fast
          </div>
          <div className="flex flex-row flex-wrap gap-10 py-4 justify-start">
            <Card className="w-[448px] hover:scale-[1.02] min-h-[139px] max-h-fit">
              <div className="flex  flex-col w-full h-full p-4 gap-4">
                <div className=" text-[18px] font-medium leading-[24.3px] text-left">
                  Required to hire
                </div>
                <div className="flex flex-row justify-between gap-4 items-center h-full">
                  <div className="flex flex-row items-center mobile:items-start gap-4 w-fit">
                    <div>
                      <img
                        className="w-[21.5px] h-[21.5px] top-[1.25px] mt-2 left-[1.25px] "
                        src={require("../../assets/landingScreen/pending_icon.png")}
                        alt=""
                      />
                    </div>
                    <div className=" text-[22px] mobile:text-[18px] font-bold leading-[29.7px] text-left">
                      <div>Add a billing method.</div>
                      <div className="font-[500]">
                        you could start hiring 3X faster
                      </div>
                    </div>
                  </div>

                  <span className="pt-7">
                    <img
                      className="w-[40px] h-[28.37px] top-[5.82px]  bg-[linear-gradient(97.39deg,_rgba(8,_41,_169,_0.3)_17.03%,_rgba(100,_233,_255,_0.18)_161.49%)]"
                      src={require("../../assets/landingScreen/dolor_logo.png")}
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </Card>
            <Card className="w-[448px]  min-h-[139px] hover:scale-[1.02] max-h-fit">
              <div className="flex rounded-[15px]  border-l-2 border-b-2 border-blue-800 border-opacity-30 flex-col w-full h-full p-4 gap-4">
                <div className=" text-[18px] font-medium leading-[24.3px] text-left">
                  Required to hire
                </div>
                <div className="flex flex-row justify-between gap-4 items-center h-full">
                  <div className="flex flex-row items-cente gap-4 w-fit">
                    <div>
                      <img
                        className="w-[21.5px] h-[21.5px] mt-2 top-[1.25px] left-[1.25px] "
                        src={require("../../assets/landingScreen/success_logo.png")}
                        alt=""
                      />
                    </div>
                    <div className=" text-[22px] mobile:text-[18px] font-bold leading-[29.7px] text-left">
                      <div>You verified your email address</div>
                    </div>
                  </div>

                  <img
                    className="w-[40px] h-[28.37px] top-[5.82px]  bg-[linear-gradient(97.39deg,_rgba(8,_41,_169,_0.3)_17.03%,_rgba(100,_233,_255,_0.18)_161.49%)]"
                    src={require("../../assets/landingScreen/mail_logo.png")}
                    alt=""
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="relative small:hidden">
        <div className="absolute  w-[450px] h-[450px] -right-20 mr-[-180px] pt-[40px] p-10 -mt-6 rounded-tl-full rounded-bl-full  border-white border-2">
          <div className="w-[440px] h-[440px] mr-[-20px] mt-[-28px] rounded-tl-full rounded-bl-full p-2 shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
        </div>
      </div>
    </div>
  );
}
function PopUp({ onClose }) {
  const handleContinue = () => {
    onClose(); // Close the popup
    navigate("/project-duration"); // Navigate to project duration page
  };
  const navigate = useNavigate();
  return (
    <div className="w-full h-full   bg-white bg-opacity-60 fixed top-0 left-0  flex  justify-center items-center">
      <div className="w-fit h-fit shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] bg-[#E3EDF7] rounded-[15px]  flex flex-col gap-5  py-14 justify-center items-center">
        <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] border border-white w-fit p-2 rounded-full">
          <div className="[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] border border-white w-fit  rounded-full">
            <img
              className="w-[100px] h-[100px]"
              src={require("../../assets/postjobpopup.png")}
            />
          </div>
        </div>
        <div className="w-[70%] h-fit text-[30px] font-[500]">
          You still have a few steps left to complete in order to post your
          jobs.
        </div>
        <div
          onClick={handleContinue}
          className="w-fit shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] h-fit rounded-[15px] text-[white] flex flex-row gap-3 items-center justify-center bg-gradient-to-r from-indigo-800 to-sky-600 p-3 px-6 "
        >
          <button className="w-fit  text-white text-[22px] font-[500]  h-fit  text-center flex items-center ">
            Continue
          </button>
          <ArrowForwardIcon className="mt-1" />
        </div>
        {/* <button onClick={onClose}>Cancel</button> */}
      </div>
    </div>
  );
}
