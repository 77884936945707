import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BackButton from "../Button/BackButton";
import Spinner, { apiUrl } from "../../utils/constants";
import { Avatar, IconButton } from "ui-neumorphism";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoader(true);
    axios
      .get(apiUrl + "/project/getAll")
      .then((response) => {
        setProjects(response.data);
        console.log(response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setLoader(false);
      });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  // Get current projects
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleProjectClick = (projectId) => {
    navigate(`/default-project/${projectId}`);
  };
  const [previousPath, setPreviousPath] = useState(null);
  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location]);
  return loader ? (
    <Spinner titleName={"Please wait fetching data"} />
  ) : (
    <div className="w-screen h-screen bg-[#E3EDF7] flex flex-col gap-2 font-Satoshi">
      <div className="flex flex-col gap-5">
        <div className="-mt-2">
          <BackButton
            backHandler={() => {
              if (previousPath === "/login") {
                navigate("/client-home-view");
              } else if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate("/");
              }
            }}
          />
        </div>
        <h1 className="font-[700] text-[30px] w-full items-center flex justify-center">
          Project Activities
        </h1>
      </div>
      <div className="w-full h-fit p-3 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-center">
        <table className="w-full h-fit">
          <thead className="w-full pb-3">
            <tr className="w-full border-none h-fit text-[14px] font-[500] grid grid-cols-4 px-2 pb-3 gap-5 items-center">
              <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                Recent Projects
              </th>
              <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                Project Started
              </th>
              <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                Team Name
              </th>
              <th className="w-[99%] rounded-[15px] h-fit py-3 px-2 shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
                Due Date
              </th>
            </tr>
          </thead>
          <tbody className="px-2 max-h-80 overflow-y-auto block scrollbar rounded-[15px] border py-4 border-white shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]">
            {currentProjects.map((contract, index) => (
              <tr
                className="w-full border-b-2 border-gray hover:bg-slate-600 hover:bg-opacity-[20%] cursor-pointer hover:scale-[1.01] h-fit text-[12px] font-[500] grid grid-cols-4 px-2 gap-5 items-center"
                key={index}
                onClick={() => handleProjectClick(contract.projectId)}
              >
                <td
                  className="w-full flex h-fit p-3 items-center justify-center truncate gap-2 text-center"
                  title={contract.projectName}
                >
                  {contract.projectName}
                </td>
                <td className="w-full bg-blue-500 flex rounded-[15px] items-center justify-center h-fit p-3 shadow-[inset_2.5px_2.5px_2px_#2485FF,inset_-2.5px_-2.5px_2px_#f9faff] text-center">
                  {contract.postedDate}
                </td>
                <td
                  className="w-full flex h-fit p-3  items-center justify-center truncate gap-2 text-center"
                  title={contract.username}
                >
                  {contract.projectType}
                </td>
                <td
                  className={` w-full flex rounded-[15px] items-center justify-center h-fit p-3 text-center`}
                >
                  {contract.estimatedTime}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-center mt-4">
        <button
          className={`px-4 py-2 mx-1 rounded border ${
            currentPage === 1
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <button
            key={pageNumber + 1}
            className={`px-4 py-2 mx-1 rounded border ${
              currentPage === pageNumber + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          className={`px-4 py-2 mx-1 rounded border ${
            currentPage === totalPages
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ProjectList;
