import React, { useState, useEffect, useContext } from "react";
import PostNavHeader from "../Post-Jobs/util/PostNavHeader";
import { useLocation } from "react-router-dom";
import { Avatar, Card } from "ui-neumorphism";
import ProfileBadgeView from "../Projects_Contracts/ProfileBadgeView";
import { handleBase64Change, apiUrl } from "../../utils/constants";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import axios from "axios";
import { ClientIdContext, TokenContext } from "../../App";
export default function PraposalProfileView() {
  const location = useLocation();
  const { userInfo } = location.state;
  const { token, updateToken } = useContext(TokenContext);
  const [userEducation, setUserEducation] = useState([]);
  const [userCertificates, setUserCertificates] = useState([]);
  useEffect(() => {
    if (userInfo && userInfo.userId) {
      fetchUserEducation(userInfo.userId);
    }
  }, [userInfo]);

  const fetchUserEducation = async (userId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/userEducation/getAllByUserId/${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setUserEducation(response.data);
    } catch (error) {
      console.error("Error fetching user education:", error);
    }
  };
  useEffect(() => {
    if (userInfo && userInfo.userId) {
      fetchUserCertificates(userInfo.userId);
    }
  }, [userInfo]);

  const fetchUserCertificates = async (userId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/userCertificate/getAllByUserId/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserCertificates(response.data);
    } catch (error) {
      console.error("Error fetching user certificates:", error);
    }
  };

  if (!userInfo) {
    return <div>Error: No user info available</div>;
  }

  const skills = userInfo.skills || [];
  const Languages = userInfo.languagesKnown || [];
  const userId = userInfo.userId;
  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} titleName={`${userInfo.name}'s profile`} />
      <div className="px-20 py-8 medium:px-6 large:pr-6 large:grid large:grid-cols-3 flex flex-col gap-5 overflow-auto">
        <div className="col-span-2 flex flex-col gap-10">
          <div className="flex flex-row items-center gap-10">
            <div className="flex flex-row items-center gap-3">
              <div className="relative [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-fit h-fit p-2 rounded-full">
                <Avatar
                  size={80}
                  src={handleBase64Change(userInfo.profileImage)}
                />
                <img
                  className="absolute -mt-4 ml-16 w-[22px] h-[22px]"
                  src={require("../../assets/praposals/logo-star-icon.png")}
                  alt="Star Icon"
                />
              </div>
              <div className="flex flex-col">
                <span className="font-[700] text-[20px] text-[#352F36]">
                  {userInfo.name}
                </span>
                <span className="text-[#414B5A] font-[500] text-[14px] opacity-[50%]">
                  {userInfo.designation}
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-1 -mt-2 h-full items-center">
              <span>{userInfo.country}</span>{" "}
              <img
                className="w-[24px] h-[17px]"
                src={require("../../assets/praposals/indian-logo.png")}
                alt="Country Flag"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h1 className="font-[700] text-[22px] text-[#352F36] ml-2">
              Note*
            </h1>
            <Card style={{ borderRadius: "20px" }} className="p-4">
              <p className="whitespace-pre-line w-full h-fit font-[500] text-[18px] text-[#352F36]">
                {userInfo.about}
              </p>
            </Card>
          </div>
          <div className="flex flex-row gap-2 flex-wrap">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="rounded-[30px] flex flex-row gap-2 w-fit h-fit items-center px-4 py-2 [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF]"
              >
                <div className="bg-[#2485FF] w-[10px] h-[10px] rounded-full"></div>
                <div className="font-[500] text-[18px] text-[#352F36]">
                  {skill}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1 large:max-w-[350px] w-full">
          <div className="rounded-[20px] [box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] w-full h-full flex flex-col medium:flex-row flex-wrap gap-10 p-4 py-6">
            <div className="w-full">
              <div className="flex flex-col medium:flex-row flex-wrap w-full gap-10">
                {" "}
                <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full max-w-[300px] mobile:max-w-full h-[260px] flex flex-col gap-2">
                  <div className="portfolio_bg_logo rounded-[20px]   w-full h-[130px] grid place-items-center">
                    <div className="shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] p-1 rounded-[50%]">
                      <Avatar
                        size={40}
                        src={handleBase64Change(userInfo.profileImage)}
                      />
                    </div>
                  </div>
                  <div className="h-[130px] w-full flex flex-row justify-center items-center gap-10">
                    <div className="bg-[#2485FF] rounded-[24px] cursor-pointer hover:scale-[1.02] w-fit h-fit text-white p-2 px-6">
                      View portfolio
                    </div>
                    <div className="w-[40px] h-[40px] p-2 rounded-full bg-[#2485FF] cursor-pointer hover:scale-[1.02] grid place-items-center">
                      <img
                        src={require("../../assets/praposals/mdg-icon.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
                  <div>
                    <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%]">
                      Total earnings
                    </div>
                    <div className="font-[700] text-[28px] text-[#352F36]">
                      $ {userInfo.totalEarnings}
                    </div>
                  </div>
                  <div className="w-[58px] h-[63px]">
                    <img
                      src={require("../../assets/praposals/total_earnings-logo.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
                  <div>
                    <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%]">
                      Total Jobs
                    </div>
                    <div className="font-[700] text-[28px] text-[#352F36]">
                      {userInfo.noOfProjects}
                    </div>
                  </div>
                  <div className="h-[59px] w-[63px]">
                    <img
                      src={require("../../assets/praposals/total-jobs.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
                  <div className="flex flex-col gap-2">
                    <div className="font-[700] text-[18px] text-[#352F36]">
                      Costing per hour
                    </div>
                    <div className="text-[#414B5A] font-[500] text-[16px]">
                      <span className=" opacity-[50%]"> Available for</span>{" "}
                      <span className="text-[#2485FF] font-[500] opacity-[100%]">
                        30hrs/week
                      </span>
                    </div>
                  </div>
                  <div className="[box-shadow:7.5px_7.5px_15px_0px_#A6ABBD,_-7.5px_-7.5px_15px_0px_#FAFBFF] font-[700] text-[18px] text-[#352F36] h-[60px] w-[60px] rounded-full grid place-items-center">
                    ${userInfo.hourlyRate}
                  </div>
                </div>{" "}
                <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
                  <div className="flex w-full flex-col gap-2">
                    <div className="font-[700] text-[18px] text-[#352F36]">
                      Languages known
                    </div>
                    <div className="w-full text-[#414B5A] font-[500] text-[16px] flex flex-col justify-between">
                      {Languages.map((language, index) => (
                        <div
                          key={index}
                          className="w-full rounded-[30px] flex flex-row justify-between w-fit h-fit items-center px-4 py-2 "
                        >
                          <div className="font-[500] text-[18px] text-[#352F36]">
                            {language}
                          </div>
                          <span className="text-[#2485FF] font-[500] opacity-[100%]">
                            Fluent
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
              <div className="flex w-full flex-col gap-2">
                <div className="font-[700] text-[18px] text-[#352F36]">
                  Education
                </div>
                <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%]">
                  {userEducation.map((education, index) => (
                    <div key={index} className="flex flex-row gap-2">
                      <div className="w-fit text-[#414B5A] font-[500] text-[14px]">
                        {education.university}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
              <div className="flex flex-col gap-2">
                <div className="font-[700] text-[18px] text-[#352F36]">
                  Certifications
                </div>
                <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%] flex flex-col gap-3">
                  {userCertificates.map((certificate) => (
                    <span key={certificate.certificationId}>
                      {certificate.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
