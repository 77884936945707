import React from "react";
import BackButton from "../components/Button/BackButton";
function TermsAndCondition() {
  return (
    <div className="w-screen h-screen flex flex-col gap-5 p-5">
      <div className="flex flex-col gap-5">
        <div className="-mt-2">
          <BackButton />
        </div>
        <div className="font-[700] text-[30px] w-full items-center flex justify-center">
          Terms & Conditions
        </div>
      </div>
      <div className="flex flex-col gap-5 px-10">
        <div>
          1. Acceptance of Terms By accessing or using our site and services,
          you agree to comply with and be bound by these terms. If you do not
          agree with any part of these terms, you must not use our services.
        </div>
        <div>
          2. Changes to Terms We reserve the right to modify these terms at any
          time. Any changes will be effective immediately upon posting on this
          page. Your continued use of the site following the changes constitutes
          your acceptance of the new terms.
        </div>
        <div>
          {" "}
          3. User Responsibilities You agree to use the site for lawful purposes
          and in a manner that does not infringe upon the rights of others. You
          must provide accurate information during registration and keep it
          updated. You are responsible for maintaining the confidentiality of
          your account information and password.
        </div>
        <div>
          {" "}
          4. Content Ownership All content, trademarks, and other intellectual
          property on the site are owned by [Your Company Name] or its
          licensors. You may not reproduce, distribute, or create derivative
          works without our express written permission.
        </div>
      </div>
    </div>
  );
}

export default TermsAndCondition;
