import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "ui-neumorphism";
import Spinner, { apiUrl } from "../../utils/constants";
import axios from "axios";
import { ClientIdContext, TokenContext } from "../../App";
import BackButton from "../Button/BackButton";
import { Rate } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
function AllTestimonials() {
  const { clientId } = useContext(ClientIdContext);
  const { token } = useContext(TokenContext);
  const [testimonials, setTestimonials] = useState([]);
  const [loader, setLoader] = useState(true);
  const [userDetailsLoader, setUserDetailsLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestimonialsWithUserDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/testimonial/getUserTestimonials/${clientId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const fetchedTestimonials = response.data;
        setLoader(false);

        const userIds = [
          ...new Set(
            fetchedTestimonials.map((testimonial) => testimonial.sentBy.userId)
          ),
        ];

        setUserDetailsLoader(true);

        const userRequests = userIds.map((userId) =>
          axios.get(`${apiUrl}/client/getById/${userId}`, {
            headers: {
              Authorization: token,
            },
          })
        );

        const userResponses = await Promise.all(userRequests);

        const userDetailsMap = {};
        userResponses.forEach((response) => {
          const userId = response.config.url.split("/").pop();
          userDetailsMap[userId] = response.data;
        });

        const testimonialsWithUserDetails = fetchedTestimonials.map(
          (testimonial) => ({
            ...testimonial,
            userDetails: userDetailsMap[testimonial.sentBy.userId] || {},
          })
        );

        setTestimonials(testimonialsWithUserDetails);
      } catch (error) {
        console.error(
          "There was an error fetching the testimonials or user details!",
          error
        );
      } finally {
        setLoader(false);
        setUserDetailsLoader(false);
      }
    };

    fetchTestimonialsWithUserDetails();
  }, [clientId, token]);

  if (loader || userDetailsLoader) {
    return <Spinner titleName={"Please wait, fetching data..."} />;
  }
  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    return (
      <div className="flex gap-1">
        {[...Array(totalStars)].map((_, index) => (
          <div
            key={index}
            className="h-[28px] w-[28px] grid place-items-center rounded-full bg-[#219653]"
          >
            {index < filledStars ? (
              <StarFilled style={{ color: "white", fontSize: "16px" }} />
            ) : index === filledStars && hasHalfStar ? (
              <StarFilled
                style={{
                  color: "white",
                  fontSize: "16px",
                  clipPath: "inset(0 50% 0 0)", // This makes the star half-filled
                }}
              />
            ) : (
              <StarOutlined style={{ color: "gray", fontSize: "16px" }} />
            )}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-4 w-full h-fit py-4 px-4 medium:px-1">
      <div className="flex flex-col gap-5 w-full">
        <div className="-mt-2">
          <BackButton />
        </div>
        <div className="text-[#051118] w-full flex justify-center text-center text-[38px] font-bold leading-[51.3px]">
          Testimonials
        </div>
      </div>
      <div className="flex flex-col gap-10 w-full h-fit">
        {testimonials.length === 0 ? (
          <div className="text-center text-gray-500 text-xl">
            No testimonials available
          </div>
        ) : (
          testimonials.map((testimonial, index) => (
            <div className="w-fit h-fit testimonial_alt_cards" key={index}>
              <div className="relative">
                <div className="absolute mt-[-14px] text-right w-full pr-6 flex flex-row gap-1 justify-end">
                  {renderStars(testimonial.rating)}
                </div>
              </div>
              <div className="alt_even_cards">
                <Card style={{ borderRadius: "20px" }}>
                  <div className="large:w-[910px] large:h-[140px] medium:w-full medium:h-full flex flex-row medium:flex-wrap p-4 items-center gap-10">
                    <section className="bg-[#E4EEF7] w-[80px] h-[80px]">
                      <img
                        className="w-[100%] h-[100%] object-cover rounded-[50%]"
                        src={
                          testimonial.userDetails.profileImage
                            ? `data:image/png;base64,${testimonial.userDetails.profileImage}`
                            : "path/to/placeholder/image.png"
                        }
                        alt={testimonial.sentBy.name || "User"}
                      />
                    </section>
                    <section>
                      <div className="w-fit h-[51px] text-[38px] font-bold leading-[51.3px] text-left">
                        {testimonial.sentBy.name || "Name not available"}
                      </div>
                      <div className="w-fit h-[24px] text-[16px] font-[500] opacity-[50%] leading-[24.3px] text-left">
                        {testimonial.title || "Title not available"}
                      </div>
                    </section>
                    <section>
                      <div className="large:w-[588px] medium:w-full large:h-[58px] text-[20px] font-[400] leading-[28.6px] text-left">
                        <span className="text-[40px] font-bold leading-[28.6px] text-left">
                          “
                        </span>
                        {testimonial.testimonial || "Testimonial not available"}
                        <span className="text-[40px] font-bold leading-[28.6px] text-left">
                          ”
                        </span>
                      </div>
                    </section>
                  </div>
                </Card>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default AllTestimonials;
