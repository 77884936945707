import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner, { apiUrl } from "../../utils/constants";
import BackButton from "../Button/BackButton";
function ProjectPage() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiUrl}/project/getById/${projectId}`)
      .then((response) => {
        setProject(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching project details:", error);
        setLoading(false);
      });
  }, [projectId]);

  if (loading) return <Spinner titleName={"Loading project details..."} />;

  return (
    <div className="w-screen h-screen bg-[#E3EDF7] flex flex-col gap-5 font-Satoshi p-5">
      <div className="flex flex-col gap-5">
        <div className="-mt-2">
          <BackButton />
        </div>
        <h1 className="font-[700] text-[30px] w-full items-center flex justify-center">
          {" "}
          Project Details
        </h1>
      </div>
      <div className="w-full h-fit p-3 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-col gap-5">
        <h2 className="text-xl font-bold">{project.projectName}</h2>
        <p>
          <strong>Started:</strong> {project.postedDate}
        </p>
        <p>
          <strong>Team Name:</strong> {project.projectType}
        </p>
        <p>
          <strong>Due Date:</strong> {project.estimatedTime}
        </p>

        {/* Add more project details here */}
      </div>
    </div>
  );
}

export default ProjectPage;
