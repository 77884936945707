import React from "react";
import { Card } from "ui-neumorphism";

export default function ProgressBar({ status}) {
  return (
    <div className="w-full h-fit py-4 flex flex-row justify-center text-[#414B5A]  items-center gap-2">
      <div
        className={`flex flex-col gap-2 ${
          status >= 1 ? "text-[#352F36] opacity-[100%]" : "opacity-[50%]"
        }`}
      >
        <div className={`flex flex-row items-center gap-1`}>
          <Card
            width={43}
            height={44}
            rounded
            className={`${
              status === 1 && " border border-[#A6ABBD] "
            } grid place-items-center`}
          >
            <div
              className={`${
                status > 1 &&
                "w-full h-full grid rounded-full bg-[#0162DD]  place-items-center"
              }`}
            >
              {status === 1 ? (
                " 01"
              ) : (
                <img
                  src={require("../../../assets/navbar-logos/tick_amrk.png")}
                />
              )}
            </div>
          </Card>
          <div
            className={`w-[80px] h-[2px]  ${
              status>1 ? "bg-[#0162DD]":"opacity-[50%] bg-[#352F36]"
            }`}
          ></div>
        </div>
        <div
          className={`text-[18px] small:text-[14px] font-medium leading-[24.3px] text-left  ${
            status > 1 && "text-[#0162DD]"
          }`}
        >
          Step 1
        </div>
      </div>

      <div
        className={`flex flex-col gap-2 ${
          status >= 2 ? "text-[#352F36] opacity-[100%]" : "opacity-[50%]"
        }`}
      >
        <div className={`flex flex-row items-center gap-1`}>
          <Card
            width={43}
            height={44}
            rounded
            className={`${
              status === 2 && " border border-[#A6ABBD] "
            } grid place-items-center`}
          >
            <div
              className={`${
                status > 2 &&
                "w-full h-full grid rounded-full bg-[#0162DD]  place-items-center"
              }`}
            >
              {status >2  ? (
              <img
              src={require("../../../assets/navbar-logos/tick_amrk.png")}
            />
              ) : (
                "02"
              )}
            </div>
          </Card>
          <div
            className={`w-[80px] h-[2px]  ${
              status>2 ? "bg-[#0162DD]":"opacity-[50%] bg-[#352F36]"
            }`}
          ></div>
        </div>
        <div
          className={`text-[18px] small:text-[14px] font-medium leading-[24.3px] text-left  ${
            status > 2 && "text-[#0162DD]"
          }`}
        >
          Step 2
        </div>
      </div>
      <div
        className={`flex flex-col gap-2 ${
          status >2 ? "text-[#352F36] opacity-[100%]" : "opacity-[50%]"
        }`}
      >

        <div className={`flex flex-row items-center gap-1`}>
          <Card
            width={43}
            height={44}
            rounded
            className={`${
              status === 3 && " border border-[#A6ABBD] "
            } grid place-items-center`}
          >
            <div
              className={`${
                status > 3 &&
                "w-full h-full grid rounded-full bg-[#0162DD]  place-items-center"
              }`}
            >
              {status > 3 ? (
                <img
                src={require("../../../assets/navbar-logos/tick_amrk.png")}
              />
              ) : (
               "03"
              )}
            </div>
          </Card>
         
        </div>
        <div
          className={`text-[18px] small:text-[14px] font-medium leading-[24.3px] text-left  ${
            status >3 && "text-[#0162DD]"
          }`}
        >
          Step 3
        </div>
      </div>
    </div>
  );
}
