import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Card, ProgressLinear, IconButton } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClientRadioInput from "../components/inputs/ClientRadioInput";
import axios from "axios";
import { apiUrl, clientId, parseJwt, token } from "../utils/constants";
import BackButton from "../components/Button/BackButton";
import NextButton from "../components/Button/NextButton";
import { ClientIdContext, TokenContext } from "../App";
import { useContext } from "react";
export function ClientCompanyFlow() {
  const [teamSize, setTeamSize] = useState(null);
  const [techLead, setTechLead] = useState(null);
  const [supportByOthers, setSupportByOthers] = useState(null);
  const navigate = useNavigate();
  const handleNavigateToTeamSize = () => {
    setTeamSize(null);

    // Navigate to the TeamSize component route
  };

  const handleNavigateToTechLead = () => {
    setTechLead(null);
  };

  return (
    <div className="w-screen h-screen bg-[#E3EDF7] overflow-hidden ">
      <div className="absolute w-[300px] h-[300px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%]  [box-shadow:inset_-2px_-2px_15px_rgba(255,_255,_255,_0.1),_inset_2px_2px_10px_rgba(32,_32,_32,_0.336)]">
        <div className="w-[300px] h-[300px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  [box-shadow:inset_-2px_-2px_15px_rgba(255,_255,_255,_0.1),_inset_2px_2px_10px_rgba(32,_32,_32,_0.336)]"></div>
      </div>

      <div className=" h-full w-full bg-[#E3EDF7]  flex flex-col justify-between items-center gap-10">
        {teamSize && techLead ? (
          <SupportByOthers
            teamSize={teamSize}
            techLead={techLead}
            setSupportByOthers={setSupportByOthers}
            handleNavigateToTechLead={handleNavigateToTechLead}
          />
        ) : teamSize ? (
          <TechLead
            setTechLead={setTechLead}
            handleNavigateToTeamSize={handleNavigateToTeamSize}
          />
        ) : (
          <TeamSize setTeamSize={setTeamSize} />
        )}
      </div>
    </div>
  );
}
export const TeamSize = ({ setTeamSize, range, setRange }) => {
  const [currentOption, setCurrentOption] = useState("");

  const NextHandler = () => {
    if (currentOption) {
      setTeamSize(currentOption);
    }
  };
  return (
    <div className="h-full  bg-[#E3EDF7]   w-full h-fit flex flex-col gap-10">
      <BackButton />
      <div className=" h-full w-full  flex flex-col justify-between items-center gap-3">
        <div className=" mt-[3%] w-[100%] h-[80%] flex flex-col justify-center gap-5 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">
              Keep up the momentum!
            </span>{" "}
            You're almost done, keep going!
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Team size
          </h2>
          <div className="flex flex-row  justify-center gap-5 flex-wrap p-1 ">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2  flex cursor-pointer  text-[22px] font-[500] flex-row items-center"
                onClick={() => setCurrentOption("2-25")}
              >
                <ClientRadioInput
                  radioValue={currentOption}
                  setRadioValue={setCurrentOption}
                  labelValue={"2-25"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer   flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setCurrentOption("25-50")}
              >
                <ClientRadioInput
                  radioValue={currentOption}
                  setRadioValue={setCurrentOption}
                  labelValue={"25-50"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setCurrentOption("50-100")}
              >
                <ClientRadioInput
                  radioValue={currentOption}
                  setRadioValue={setCurrentOption}
                  labelValue={"50-100"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setCurrentOption("100+")}
              >
                <ClientRadioInput
                  radioValue={currentOption}
                  setRadioValue={setCurrentOption}
                  labelValue={"100+"}
                />
              </div>
            </Card>
          </div>

          <NextButton
            bgActive={currentOption}
            buttonName="  Next"
            nextHandler={NextHandler}
          />
          <ProgressLinear
            height={20}
            value={25}
            color="linear-gradient(to right, #0829A9, #64E9FF)"
            // Gradient background color
            style={{
              width: "70%",
              boxShadow:
                "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
            }}
          />
        </div>

        <div className="w-[100%] h-[20%] flex flex-row   justify-between items-center">
          <img
            src={require("../assets/lookingfor1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/techlead.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
};

export const TechLead = ({ setTechLead, handleNavigateToTeamSize }) => {
  const [checked, setChecked] = useState("");
  const navigate = useNavigate();
  const NextHandler = () => {
    if (checked) {
      setTechLead(true);
    } else {
      setTechLead(false);
    }
  };

  return (
    <div className="h-full  justify-between   w-full h-fit flex flex-col gap-10 items-center">
      <IconButton
        rounded
        text={false}
        style={{ width: "24px", height: "24px" }}
        size="large"
        className="absolute top-0 left-10 mobile:left-4 px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
        onClick={handleNavigateToTeamSize}
      >
        <ArrowBackIcon />{" "}
      </IconButton>
      <div className=" h-full w-full  flex flex-col justify-between items-center gap-3">
        <div className=" mt-[3%] w-[100%] h-[80%] flex flex-col justify-center gap-3 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">
              Keep up the momentum!
            </span>{" "}
            You're almost done, keep going!
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Are you a tech lead
          </h2>
          <div className="flex flex-row  justify-center gap-5 flex-wrap p-1 ">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setChecked("yes")}
              >
                <ClientRadioInput
                  radioValue={checked}
                  setRadioValue={setChecked}
                  labelValue={"yes"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setChecked("no")}
              >
                <ClientRadioInput
                  radioValue={checked}
                  setRadioValue={setChecked}
                  labelValue={"no"}
                />
              </div>
            </Card>
          </div>

          <NextButton
            bgActive={checked !== ""}
            buttonName="  Next"
            nextHandler={NextHandler}
          />
          <ProgressLinear
            height={20}
            value={55}
            color="linear-gradient(to right, #0829A9, #64E9FF)"
            // Gradient background color
            style={{
              width: "70%",
              boxShadow:
                "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
            }}
          />
        </div>
        <div className="w-[100%] h-[20%] flex flex-row   justify-between items-center">
          <img
            src={require("../assets/lookingfor1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/techlead.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
};
export const SupportByOthers = ({
  setSupportByOthers,
  techLead,
  teamSize,
  seekingFor,
  setTechLead,
  handleNavigateToTechLead,
}) => {
  const location = useLocation();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const { clientType, companyName, userData } = location.state;
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  // Combine user details with supportByOthers
  const submithandler = async () => {
    if (selected !== null) {
      setSupportByOthers(selected);

      // Set default values if needed
      const clientDetailsData = {
        role: userData.role, // Default role if undefined
        teamSize: teamSize, // Default value for teamSize
        companyType: clientType, // Default value for companyType
        fundingSupport: selected,
        seekingFor: seekingFor, // Default value for lookingFor
        techLead: techLead !== undefined ? techLead : false, // Default value for techLead
      };

      console.log("Submitting data:", clientDetailsData, clientId); // Debugging log

      try {
        const response = await axios.put(
          `${apiUrl}/client/update/${clientId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              role: userData.role, // Default role if undefined
              teamSize: teamSize, // Default value for teamSize
              companyType: clientType, // Default value for companyType
              fundingSupport: selected,
              seekingFor: seekingFor, // Default value for lookingFor
              techLead: techLead !== undefined ? techLead : false,
            },
          }
        );

        // Check the response structure
        console.log("Response:", response.data); // Debugging log

        // Handle successful response
        if (response.status === 202) {
          toast.success("Successfully updated client details");
          // Navigate to another page or update state as needed
          navigate("/client-home-view");
        } else {
          toast.error("Failed to update client details");
        }
      } catch (error) {
        // Handle error response
        console.error(
          "Error updating client details:",
          error.response ? error.response.data : error.message
        ); // Debugging log
        toast.error("Error updating client details");
      }
    } else {
      toast.error("Please select an option before submitting."); // Inform user if no option is selected
    }
  };

  return (
    <div className="h-full  justify-between   w-full h-fit flex flex-col gap-10 items-center">
      <IconButton
        rounded
        text={false}
        style={{ width: "24px", height: "24px" }}
        size="large"
        className="absolute top-0 left-10 mobile:left-4 px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
        onClick={handleNavigateToTechLead}
      >
        <ArrowBackIcon />{" "}
      </IconButton>
      <div className=" h-full w-full  flex flex-col items-center justify-center gap-3">
        <div className=" w-[100%] mt-[3%] h-[80%] flex flex-col justify-center gap-5 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">You've done it!</span>{" "}
            Keep advancing and explore what's next!
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Would you like to support others?
          </h2>
          <div className="flex flex-row w-full justify-center gap-5 flex-wrap">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setSelected("Helping other startups")}
              >
                <ClientRadioInput
                  radioValue={selected}
                  setRadioValue={setSelected}
                  labelValue={"Helping other startups"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setSelected("Monitoring")}
              >
                <ClientRadioInput
                  radioValue={selected}
                  setRadioValue={setSelected}
                  labelValue={"Monitoring"}
                />
              </div>
            </Card>
          </div>

          <NextButton
            bgActive={selected}
            buttonName={selected ? "Complete" : "Next"}
            nextHandler={submithandler}
          />
          {selected ? (
            <ProgressLinear
              height={20}
              value={100}
              color="linear-gradient(to right, #0829A9, #64E9FF)"
              style={{
                width: "70%",
                boxShadow:
                  "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
              }}
            />
          ) : (
            <ProgressLinear
              height={20}
              value={75}
              color="linear-gradient(to right, #0829A9, #64E9FF)"
              style={{
                width: "70%",
                boxShadow:
                  "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
              }}
            />
          )}
        </div>
        <div className="w-[100%] h-[20%] flex flex-row pt-5 justify-between items-center">
          <img
            src={require("../assets/supportothers1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/supportothers2.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
};
export default ClientCompanyFlow;
