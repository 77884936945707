import React, { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import {  Card, ProgressLinear,IconButton } from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css';
import BackButton from "../components/Button/BackButton";
import ClientRadioInput from "../components/inputs/ClientRadioInput";
import NextButton from "../components/Button/NextButton";
function Client() {
    const [clientType, setClientType] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate=useNavigate();
   
  const location = useLocation();
  const userData = location.state;
  const NextHandler = () => {
    if (clientType === 'Single entrepreneur') {
        navigate('/clientsingleentrepreneur', { state: { clientType , userData } });
    } else if (clientType === 'company') {
        if (companyName !== '') {
            navigate('/clientscompany', { state: { clientType, companyName , userData } });
        } else {
            setErrorMessage('Please enter your company name.');
        }
    } else {
        setErrorMessage('Please select a client type.');
    }
};

   
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi overflow-hidden p-4 pb-0">
    <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
      <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
    </div>
   <div className="-mt-2">
   <BackButton  />
   </div>
           <div className=" h-full w-full mt-1 sticky  flex flex-col justify-between items-center gap-10">
          <div className='mt-[3%] w-[100%] h-[80%] flex flex-col justify-center gap-5 items-center'>
      <div className='font-[600] text-[40px] mobile:text-[30px] text-center'>
        <span className='text-[#0624A6] font-[700] '>Almost there!</span> just a few more steps to go
      </div>
      <h2 className='text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]'>Could you please specify whether you are</h2>
      <div className='flex flex-row w-full items-center justify-center gap-5 flex-wrap'>
     
      <Card
          style={{width:"fit" ,borderRadius:"15px"}}
          className="flex  flex-row items-center"
        >
          <div
            className="w-full h-full py-2 justify-center cursor-pointer flex text-[22px] font-[500] flex-row items-center"
            
           onClick={()=>setClientType("Single entrepreneur")} >
      <ClientRadioInput
           radioValue={clientType}
           setRadioValue={setClientType}
             labelValue={"Single entrepreneur"}
          />
          </div>
        </Card>
        <Card
          style={{width:"fit" , borderRadius:"15px"}}
          className="flex  flex-row items-center"
        >
          <div
            className="w-full cursor-pointer py-2 justify-center  h-full  flex text-[22px] font-[500] flex-row items-center" onClick={()=>setClientType("company")}>
        
          <ClientRadioInput
           radioValue={clientType}
           setRadioValue={setClientType}
             labelValue={"company"}
          />
          </div>
        </Card>
       
      </div>
      
                    {clientType === 'company' && (
                      <div className="flex flex-col gap-5">
                      <Card  style={{width:"fit" ,borderRadius:"15px"}} className="flex flex-row items-center">
                         <div className="w-full h-full  flex text gap-5 flex-row items-center py-2 px-3" >
                          <img className="w-[34px] h-[34px]" src={require("../assets/companyname.png")}/>
                     
                       <input
                       className="w-fit bg-[#E3EDF7] text-[22px] font-[500]  h-fit focus:outline-none " 
                       type="text"
                       placeholder="Company name"
                       value={companyName}
                       onChange={(e)=>setCompanyName(e.target.value)}
                   /></div>
                   </Card>
                   {errorMessage && (
                        <p className="w-fit flex flex-row items-center gap-2 text-[#FF716A] text-[18px]"><IconButton rounded text={false} color='var(--error)' style={{width:"24px",height:"24px"}} size="small" >
                        <img src={require(`../assets/error.png`)} className='w-[17px] h-[17px] ' /></IconButton>{errorMessage}</p>
                     )}
                   </div>
                  )}
               
      
      <NextButton
      bgActive={clientType !== null && (clientType === 'Single entrepreneur' || (clientType === 'company' && companyName))}
            buttonName="  Next"
            nextHandler={NextHandler}
          />
      <ProgressLinear
        className="shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] bg-blue"
        height={20}
        value={10}
        color='linear-gradient(to right, #0829A9, #64E9FF)'
        // Gradient background color
        style={{ width: "70%" ,boxShadow: 'inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff'}}
      />
      </div>
     <div className={`w-[100%] h-[20%] flex flex-row  justify-between items-center ${clientType ==="company" ? "pb-[-20px] mobile:pb-0":""}`}>
      <img
                 src={require("../assets/areyouan1.png")} // Path to your Google logo image
                 alt="Google Icon"
              className="w-fit h-fit"
               />
               <img
                 src={require("../assets/areyouan2.png")} // Path to your Google logo image
                 alt="Google Icon"
                 className="w-fit h-fit"
               />
      </div>
    </div>
    </div>
  );
}

export default Client