import React from "react";
import { Avatar } from "ui-neumorphism";
import InnerShadowAvatar from "../../utils/InnerShadowAvatar";

export default function ProfileBadgeView() {
  return (
    <div className="flex flex-col medium:flex-row flex-wrap w-full gap-10">
      {" "}
      <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full max-w-[300px] mobile:max-w-full h-[260px] flex flex-col gap-2">
        <div className="portfolio_bg_logo rounded-[20px] w-full h-[130px] grid place-items-center">
          <InnerShadowAvatar
            size={40}
            imgSrc={require("../../assets/praposals/sarah_logo.png")}
          />
        </div>
        <div className="h-[130px] w-full flex flex-row justify-center items-center gap-10">
          <div className="bg-[#2485FF] rounded-[24px] w-fit h-fit text-white p-2 px-6">
            View portfolio
          </div>
          <div className="w-[40px] h-[40px] p-2 rounded-full bg-[#2485FF] grid place-items-center">
            <img src={require("../../assets/praposals/mdg-icon.png")} alt="" />
          </div>
        </div>
      </div>
      <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
        <div>
          <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%]">
            Total earnings
          </div>
          <div className="font-[700] text-[28px] text-[#352F36]">$ 10000</div>
        </div>
        <div className="w-[58px] h-[63px]">
          <img
            src={require("../../assets/praposals/total_earnings-logo.png")}
            alt=""
          />
        </div>
      </div>
      <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
        <div>
          <div className="text-[#414B5A] font-[500] text-[16px] opacity-[50%]">
            Total Jobs
          </div>
          <div className="font-[700] text-[28px] text-[#352F36]">18</div>
        </div>
        <div className="h-[59px] w-[63px]">
          <img src={require("../../assets/praposals/total-jobs.png")} alt="" />
        </div>
      </div>
      <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
        <div className="flex flex-col gap-2">
          <div className="font-[700] text-[18px] text-[#352F36]">
            Costing per hour
          </div>
          <div className="text-[#414B5A] font-[500] text-[16px]">
            <span className=" opacity-[50%]"> Available for</span>{" "}
            <span className="text-[#2485FF] font-[500] opacity-[100%]">
              30hrs/week
            </span>
          </div>
        </div>
        <div className="[box-shadow:7.5px_7.5px_15px_0px_#A6ABBD,_-7.5px_-7.5px_15px_0px_#FAFBFF] font-[700] text-[18px] text-[#352F36] h-[60px] w-[60px] rounded-full grid place-items-center">
          $30
        </div>
      </div>{" "}
      <div className="[box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF] rounded-[20px] w-full flex flex-row justify-between items-center px-6 py-4">
        <div className="flex w-full flex-col gap-2">
          <div className="font-[700] text-[18px] text-[#352F36]">
            Languages known
          </div>
          <div className="text-[#414B5A] font-[500] text-[16px] flex flex-row justify-between">
            <span className=" opacity-[50%]"> English</span>{" "}
            <span className="text-[#2485FF] font-[500] opacity-[100%]">
              Fluent
            </span>
          </div>
          <div className="text-[#414B5A] font-[500] text-[16px] flex flex-row justify-between">
            <span className=" opacity-[50%]"> Tamil</span>{" "}
            <span className="text-[#2485FF] font-[500] opacity-[100%]">
              Fluent
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
