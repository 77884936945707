import React, { useState } from 'react'
import PostNavHeader from '../Post-Jobs/util/PostNavHeader';
import BrowseExpertsFilters from '../Praposals/BrowseExpertsFilters';
import { useLocation } from 'react-router-dom';

export default function TopRatedIndividuals() {
    const [filterSkills, setFilterSkill] = useState([]);
    const location=useLocation()
    return (
      <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} titleName="Top-rated individuals"  />
      <div className="px-10 py-8 flex w-full flex-col gap-5 overflow-auto">
         <BrowseExpertsFilters filterSkills={filterSkills}
          setFilterSkill={setFilterSkill} />
      </div>
      </div>
    )
}
