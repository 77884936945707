import React from 'react'
import InnerShadowAvatar from '../../utils/InnerShadowAvatar'

export default function ChatScreen() {
  return (
    <div className='w-full flex flex-col gap-4'>
        <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full h-fit rounded-[10px] flex flex-row items-center px-4 py-2'>
            <InnerShadowAvatar />
            <div className='flex flex-col gap-1'>
                <div className='font-[700] text-[18px] text-[#414B5A] '>Sarah</div>
                <div className='flex flex-row gap-1 h-fit items-center'>
                    <div className='text-[#20BF55]  font-[400] text-[14px]'>Online</div>
                    <div className='w-[6px] h-[6px] mt-1 rounded-full bg-[#20BF55]'></div>
                </div>
            </div>
        </div>
        <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full min-h-[100px] rounded-[10px] flex flex-col items-between p-4 h-[500px]'>
        <div className=' flex flex-col gap-5 h-[400px] overflow-y-auto'>
            <div className='font-[400] text-[14px] opacity-[50%] text-[#352F36] text-center w-full h-fit'>You started a chat with @Sarah. we use information from this chat to improve your experience.</div>
            <div className='flex flex-col gap-4 text-[14px] w-full'>
             
              <div className='w-full flex flex-row justify-start'>
              <div className='w-[85%] flex flex-row h-fit items-center gap-2 charts_alt_msgs'>
                    <InnerShadowAvatar size={20} />
                    <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full rounded-[20px] h-fit p-2'>Hello! How are you today?</div>
                </div>
              </div>
           
              <div className='w-full flex flex-row justify-end'>
                <div className='w-[85%] flex flex-row h-fit items-center gap-2 charts_alt_msgs'>
                    <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full rounded-[20px] h-fit p-2'>Hello! How are you today?</div>
                    <InnerShadowAvatar size={20} />
                </div>
                </div>

                <div className='w-full flex flex-row justify-start'>
              <div className='w-[85%] flex flex-row h-fit items-center gap-2 charts_alt_msgs'>
                    <InnerShadowAvatar size={20} />
                    <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full rounded-[20px] h-fit p-2'>Hello! How are you today?</div>
                </div>
              </div>
              <div className='w-full flex flex-row justify-start'>
              <div className='w-[85%] grid grid-cols-[14%_83%] h-fit items-center gap-2 charts_alt_msgs'>
                         <div></div>
                    <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full rounded-[20px] h-fit p-2'>Hello! How are you today?</div>
                </div>
              </div>
           
              <div className='w-full flex flex-row justify-end'>
                <div className='w-[85%] flex flex-row h-fit items-center gap-2 charts_alt_msgs'>
                    <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] w-full rounded-[20px] h-fit p-2'>Hello! How are you today?</div>
                    <InnerShadowAvatar size={20} />
                </div>
                </div>

            </div>
        </div>
        <div className='flex flex-row justify-between items-end'>
            <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] active:[box-shadow:2px_2px_4px_0px_#A6ABBD_inset,_-2px_-2px_4px_0px_#FAFBFF_inset]  cursor-pointer grid place-items-center w-[44px] h-[44px] rounded-[14px]'><img
                    src={require("../../assets/navbar-logos/document_white_logo.png")}
                    alt=""
                    srcSet=""
                  /></div>
                  <div className='[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] w-[176px] h-[44px] rounded-[10px]'>
                    <textarea type="text" className='resize-none hide-scrollbar text-wrap	h-full bg-transparent w-[176px] px-2 p-2 outline-none focus:outline-none' />
                  </div>
                  <div className='[box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] active:[box-shadow:2px_2px_4px_0px_#A6ABBD_inset,_-2px_-2px_4px_0px_#FAFBFF_inset] grid place-items-center cursor-pointer w-[44px] h-[44px] rounded-[14px]'><img
                    src={require("../../assets/navbar-logos/send-button-icon.png")}
                    alt=""
                    srcSet=""
                  /></div>
        </div>
        </div>
    </div>
  )
}
