import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, ProgressLinear, IconButton } from "ui-neumorphism";
import toast from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "ui-neumorphism/dist/index.css";
import ClientRadioInput from "../components/inputs/ClientRadioInput";
import axios from "axios";
import BackButton from "../components/Button/BackButton";
import { apiUrl, clientId, parseJwt, token } from "../utils/constants";
import NextButton from "../components/Button/NextButton";
import { ClientIdContext, TokenContext } from "../App";
export function ClientSingleEntrepreneurFlow() {
  const [lookingFor, setLookingFor] = useState(null);
  const [techLead, setTechLead] = useState(null);
  const [supportByOthers, setSupportByOthers] = useState(null);
  const navigateToCompanyName = () => {
    setLookingFor(null); // Navigate to the TeamSize component route
  };
  const handleNavigateToTechLead = () => {
    setTechLead(null);
  };
  return (
    <div className="w-screen h-screen bg-[#E3EDF7] overflow-hidden ">
      <div
        className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]
  "
      >
        <div
          className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]
  "
        ></div>
      </div>
      <div className="h-[100%] w-100">
        {techLead && lookingFor ? (
          <SupportByOthers
            setSupportByOthers={setSupportByOthers}
            techLead={techLead}
            lookingFor={lookingFor}
            handleNavigateToTechLead={handleNavigateToTechLead}
          />
        ) : lookingFor ? (
          <TechLead
            lookingFor={lookingFor}
            setTechLead={setTechLead}
            navigateToCompanyName={navigateToCompanyName}
          />
        ) : (
          <SeekingFor setLookingFor={setLookingFor} />
        )}
      </div>
    </div>
  );
}
export function SeekingFor({ setLookingFor }) {
  const [clientType, setClientType] = useState(null);

  const [vendorSize, setVendorSize] = useState(null);
  const [error, setError] = useState("");

  const NextHandler = () => {
    if (clientType === "vendors" && !vendorSize) {
      setError("Please select a vendor size.");
      return;
    }
    // Clear error if validation passes
    else {
      // Logic for navigating to individual-specific next page
      setLookingFor(clientType);
    }
  };

  return (
    <div className="h-full  bg-[#E3EDF7]   w-full h-fit flex flex-col gap-10">
      <BackButton />
      <div className=" h-full w-full sticky  flex flex-col justify-between items-center gap-10">
        <div className=" w-[100%] h-[80%] flex flex-col justify-center gap-5 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">
              Keep moving forward!
            </span>{" "}
            You're getting closer with each step
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Are you seeking for
          </h2>
          <div className="flex flex-row  justify-center gap-5 flex-wrap p-1 ">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() =>
                  setClientType("Individual professional work on your project")
                }
              >
                <ClientRadioInput
                  radioValue={clientType}
                  setRadioValue={setClientType}
                  labelValue={"Individual professional work on your project"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer  flex text-[22px] font-[500] flex-row items-center"
                onClick={() => setClientType("vendors")}
              >
                <ClientRadioInput
                  radioValue={clientType}
                  setRadioValue={setClientType}
                  labelValue={"vendors"}
                />
              </div>
            </Card>
          </div>
          {clientType === "vendors" && (
            <div className="flex flex-row w-full justify-center gap-5 flex-wrap">
              <Card
                style={{ width: "fit", borderRadius: "15px" }}
                className="flex  flex-row items-center"
              >
                <div
                  className="w-full h-full py-2 cursor-pointer h-full  flex text-[22px] font-[500] flex-row items-center"
                  onClick={() => setVendorSize("Small")}
                >
                  <ClientRadioInput
                    radioValue={vendorSize}
                    setRadioValue={setVendorSize}
                    labelValue={"Small"}
                  />
                </div>
              </Card>
              <Card
                style={{ width: "fit", borderRadius: "15px" }}
                className="flex  flex-row items-center"
              >
                <div
                  className="w-full h-full py-2 cursor-pointer  flex text-[22px] font-[500] flex-row items-center"
                  onClick={() => setVendorSize("Medium")}
                >
                  <ClientRadioInput
                    radioValue={vendorSize}
                    setRadioValue={setVendorSize}
                    labelValue={"Medium"}
                  />
                </div>
              </Card>
              <Card
                style={{ width: "fit", borderRadius: "15px" }}
                className="flex  flex-row items-center"
              >
                <div
                  className="w-full h-full py-2  cursor-pointer flex text-[22px] font-[500] flex-row items-center"
                  onClick={() => setVendorSize("Big")}
                >
                  <ClientRadioInput
                    radioValue={vendorSize}
                    setRadioValue={setVendorSize}
                    labelValue={"Big"}
                  />
                </div>
              </Card>
            </div>
          )}
          {error && (
            <p className="w-fit flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
              <IconButton
                rounded
                text={false}
                color="var(--error)"
                style={{ width: "24px", height: "24px" }}
                size="small"
              >
                <img
                  src={require(`../assets/error.png`)}
                  className="w-[17px] h-[17px] "
                />
              </IconButton>
              {error}
            </p>
          )}
          <NextButton
            bgActive={
              clientType !== null &&
              (clientType === "Individual professional work on your project" ||
                (clientType === "vendors" && vendorSize))
            }
            buttonName="  Next"
            nextHandler={NextHandler}
          />
          <ProgressLinear
            height={20}
            value={25}
            color="linear-gradient(to right, #0829A9, #64E9FF)"
            // Gradient background color
            style={{
              width: "70%",
              boxShadow:
                "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
            }}
          />
        </div>
        <div className="w-[100%] h-[20%] flex flex-row  mt-[-5%] justify-between items-center">
          <img
            src={require("../assets/lookingfor1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/lookingfor2.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
}

export const TechLead = ({ setTechLead, navigateToCompanyName }) => {
  const [checked, setChecked] = useState("");

  const NextHandler = () => {
    if (checked) {
      setTechLead(true);
    } else {
      setTechLead(false);
    }
  };

  return (
    <div className="h-full  justify-between   w-full h-fit flex flex-col gap-10 items-center">
      <IconButton
        rounded
        text={false}
        style={{ width: "24px", height: "24px" }}
        size="large"
        className="absolute top-0 left-10 mobile:left-4 px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
        onClick={navigateToCompanyName}
      >
        <ArrowBackIcon />{" "}
      </IconButton>
      <div className=" h-full w-full  flex flex-col justify-between items-center gap-3">
        <div className=" mt-[3%] w-[100%] h-[80%] flex flex-col justify-center gap-3 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">
              Keep up the momentum!
            </span>{" "}
            You're almost done, keep going!
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Are you a tech lead
          </h2>
          <div className="flex flex-row  justify-center gap-5 flex-wrap p-1 ">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setChecked("yes")}
              >
                <ClientRadioInput
                  radioValue={checked}
                  setRadioValue={setChecked}
                  labelValue={"yes"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full p-2 cursor-pointer flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setChecked("no")}
              >
                <ClientRadioInput
                  radioValue={checked}
                  setRadioValue={setChecked}
                  labelValue={"no"}
                />
              </div>
            </Card>
          </div>

          <NextButton
            bgActive={checked !== ""}
            buttonName="  Next"
            nextHandler={NextHandler}
          />
          <ProgressLinear
            height={20}
            value={55}
            color="linear-gradient(to right, #0829A9, #64E9FF)"
            // Gradient background color
            style={{
              width: "70%",
              boxShadow:
                "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
            }}
          />
        </div>
        <div className="w-[100%] h-[20%] flex flex-row   justify-between items-center">
          <img
            src={require("../assets/lookingfor1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/techlead.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
};
export const SupportByOthers = ({
  setSupportByOthers,
  techLead,
  teamSize,
  lookingFor,
  setTechLead,
  handleNavigateToTechLead,
}) => {
  const location = useLocation();
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const { clientType, userData } = location.state;
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  // Combine user details with supportByOthers
  const submithandler = async () => {
    if (selected !== null) {
      setSupportByOthers(selected);

      // Ensure all fields are correctly set
      const clientDetailsData = {
        role: userData.role, // Default role if undefined
        // Default value for teamSize
        companyType: clientType, // Default value for companyType
        fundingSupport: selected,
        lookingFor: lookingFor, // Default value for lookingFor
        techLead: techLead !== undefined ? techLead : false, // Default value for techLead
      };

      console.log("Submitting data:", clientDetailsData); // Debugging log

      try {
        const response = await axios.put(
          `${apiUrl}/client/update/${clientId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              role: userData.role, // Default role if undefined
              // Default value for teamSize
              companyType: clientType, // Default value for companyType
              fundingSupport: selected,
              lookingFor: lookingFor, // Default value for lookingFor
              techLead: techLead !== undefined ? techLead : false,
            },
          }
        );

        // Check the response structure
        console.log("Response:", response.data); // Debugging log

        // Handle successful response
        if (response.status === 202) {
          toast.success("Successfully updated client details");
          // Navigate to another page or update state as needed
          navigate("/client-home-view");
        } else {
          toast.error("Failed to update client details");
        }
      } catch (error) {
        // Handle error response
        console.error(
          "Error updating client details:",
          error.response ? error.response.data : error.message
        ); // Debugging log
        toast.error("Error updating client details");
      }
    } else {
      toast.error("Please select an option before submitting."); // Inform user if no option is selected
    }
  };

  return (
    <div className="h-full  justify-between   w-full h-fit flex flex-col gap-10 items-center">
      <IconButton
        rounded
        text={false}
        style={{ width: "24px", height: "24px" }}
        size="large"
        className="absolute top-0 left-10 mobile:left-4 px-4 py-2 bg-gray-200 text-gray-800 rounded shadow "
        onClick={handleNavigateToTechLead}
      >
        <ArrowBackIcon />{" "}
      </IconButton>
      <div className=" h-full w-full  flex flex-col items-center justify-center gap-3">
        <div className=" w-[100%] mt-[3%] h-[80%] flex flex-col justify-center gap-5 items-center">
          <div className="font-[600] text-[40px] mobile:text-[30px] text-center">
            <span className="text-[#0624A6] font-[700] ">You've done it!</span>{" "}
            Keep advancing and explore what's next!
          </div>
          <h2 className="text-[#414B5A] text-center text-[40px] mobile:text-[30px] font-[400]">
            Would you like to support others?
          </h2>
          <div className="flex flex-row w-full justify-center gap-5 flex-wrap">
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setSelected("Helping other startups")}
              >
                <ClientRadioInput
                  radioValue={selected}
                  setRadioValue={setSelected}
                  labelValue={"Helping other startups"}
                />
              </div>
            </Card>
            <Card
              style={{ width: "fit", borderRadius: "15px" }}
              className="flex  flex-row items-center"
            >
              <div
                className="w-full h-full py-2 cursor-pointer  flex  text-[22px] font-[500] flex-row items-center"
                onClick={() => setSelected("Monitoring")}
              >
                <ClientRadioInput
                  radioValue={selected}
                  setRadioValue={setSelected}
                  labelValue={"Monitoring"}
                />
              </div>
            </Card>
          </div>

          <NextButton
            bgActive={selected}
            buttonName={selected ? "Complete" : "Next"}
            nextHandler={submithandler}
          />
          {selected ? (
            <ProgressLinear
              height={20}
              value={100}
              color="linear-gradient(to right, #0829A9, #64E9FF)"
              style={{
                width: "70%",
                boxShadow:
                  "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
              }}
            />
          ) : (
            <ProgressLinear
              height={20}
              value={75}
              color="linear-gradient(to right, #0829A9, #64E9FF)"
              style={{
                width: "70%",
                boxShadow:
                  "inset 2.5px 2.5px 5px #a6aabc, inset -2.5px -2.5px 5px #f9faff",
              }}
            />
          )}
        </div>
        <div className="w-[100%] h-[20%] flex flex-row pt-5 justify-between items-center">
          <img
            src={require("../assets/supportothers1.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
          <img
            src={require("../assets/supportothers2.png")} // Path to your Google logo image
            alt="Google Icon"
            className="w-fit mobile:w-[150px]  medium:w-[200px] h-fit"
          />
        </div>
      </div>
    </div>
  );
};
