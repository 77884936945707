import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultHeader from "./DefaultHeader";
import FindFreelancer from "./FindFreelancer";
import PopularAIServices from "./PopularAIServices";
import HowItWorks from "./HowItWorks";
import SuccessStories from "./SuccessStories";
import TopIndividuals from "./TopIndividuals";
import TopProjects from "./TopProjects";
import UpgradeYourExperience from "./UpgradeYourExperience";
import WhyUsStandsForClients from "./WhyUsStandsForClients";
import Categories from "./Categories";
import DiscoverTailoredServices from "./DiscoverTailoredServices";
import BenifitsOfFreelancer from "./BenifitsOfFreelancer";
import PremiumProjects from "./PremiumProjects";
import WhyChooseP2h from "./WhyChooseP2h";
import Footer from "../Landing Screen/Footer";

export default function DefaultHome() {
  const navigate = useNavigate();
  const howItWorksRef = useRef(null);
  const categoriesRef = useRef(null);
  const findFreelancerRef = useRef(null);
  const findCompaniesRef = useRef(null);
  const startupsAndIncubatorsRef = useRef(null);
  const [activeSection, setActiveSection] = useState("");
  function scrollToSection(ref) {
    if (ref.current && ref.current.scrollTo) {
      ref.current.scrollTo();
    }
  }
  const sections = [
    { id: "howItWorks", ref: howItWorksRef },
    { id: "categories", ref: categoriesRef },
    { id: "findFreelancer", ref: findFreelancerRef },
    { id: "findCompanies", ref: findCompaniesRef },
    { id: "startupsAndIncubators", ref: startupsAndIncubatorsRef },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = sections.find(
              (section) => section.ref.current === entry.target
            )?.id;
            if (id) {
              setActiveSection(id);
            }
          }
        });
      },
      {
        threshold: 0.5, // Adjust based on when you want to trigger the change
      }
    );

    sections.forEach((section) => {
      if (section.ref.current && section.ref.current instanceof Element) {
        observer.observe(section.ref.current);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section.ref.current && section.ref.current instanceof Element) {
          observer.observe(section.ref.current);
        }
      });
    };
  }, [sections]);
  return (
    <div className=" w-full h-screen bg-normal  flex flex-col gap-5 overflow-hidden">
      <DefaultHeader
        scrollToSection={scrollToSection}
        howItWorksRef={howItWorksRef}
        categoriesRef={categoriesRef}
        findFreelancerRef={findFreelancerRef}
        findCompaniesRef={findCompaniesRef}
        startupsAndIncubatorsRef={startupsAndIncubatorsRef}
        activeSection={activeSection}
      />
      <div className="h-full  z-[10%] w-full flex flex-col overflow-x-hidden gap-5 overflow-y-auto">
        <div id="content" className="h-full w-full flex flex-col gap-5">
          <FindFreelancer ref={findFreelancerRef} />
          <PopularAIServices />
          <HowItWorks ref={howItWorksRef} />
          <SuccessStories />
          <TopIndividuals />
          <TopProjects />
          <UpgradeYourExperience ref={startupsAndIncubatorsRef} />
          <WhyUsStandsForClients />
          <Categories ref={categoriesRef} />
          <DiscoverTailoredServices ref={findCompaniesRef} />
          <BenifitsOfFreelancer />
          <PremiumProjects />
          <WhyChooseP2h />
          <Footer
            scrollToSection={scrollToSection}
            categoriesRef={categoriesRef}
            findFreelancerRef={findFreelancerRef}
            findCompaniesRef={findCompaniesRef}
            startupsAndIncubatorsRef={startupsAndIncubatorsRef}
          />
        </div>
      </div>
    </div>
  );
}
