import React, { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import ViewProposalsList from "./ViewProposalsList";

export default function ProposalFilters() {
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterBadges, setFilterBadges] = useState([]);
  const [filterPricing, setFilterPricing] = useState([]);
  const [filterMoneyEarned, setFilterMoneyEarned] = useState([]);

  return (
    <div className="w-full h-full grid grid-cols-[26%_auto] small:grid-cols-1 gap-10">
      <ProposalsFiltered
        filterSkills={filterSkills}
        setFilterSkills={setFilterSkills}
        filterBadges={filterBadges}
        setFilterBadges={setFilterBadges}
        filterPricing={filterPricing}
        setFilterPricing={setFilterPricing}
        filterMoneyEarned={filterMoneyEarned}
        setFilterMoneyEarned={setFilterMoneyEarned}
      />
      <ViewProposalsList
        filterSkills={filterSkills}
        setFilterSkills={setFilterSkills}
        filterBadges={filterBadges}
        setFilterBadges={setFilterBadges}
        filterPricing={filterPricing}
        setFilterPricing={setFilterPricing}
        filterMoneyEarned={filterMoneyEarned}
        setFilterMoneyEarned={setFilterMoneyEarned}
      />
    </div>
  );
}

export const ProposalsFiltered = ({
  filterSkills,
  setFilterSkills,
  filterBadges,
  setFilterBadges,
  filterPricing,
  setFilterPricing,
  filterMoneyEarned,
  setFilterMoneyEarned,
}) => {
  const removeAllFiltersHandler = () => {
    setFilterSkills([]);
    setFilterBadges([]);
    setFilterPricing([]);
    setFilterMoneyEarned([]);
  };

  return (
    <div className="w-full small:hidden h-fit pb-4">
      <div className="h-fit w-full">
        <div className="h-fit rounded-[20px] p-4 px-6 [box-shadow:2.83px_2.83px_5.65px_0px_#BDC2C7BF,_-2.83px_-2.83px_5.65px_0px_#FFFFFFBF]">
          <div className="w-full flex flex-row justify-between border-b border-[#414B5A] border-opacity-[20%]">
            <div className="flex flex-row items-center gap-2 p-1 ">
              <TuneIcon />
              <span className="font-[700] text-[20px] text-[#352F36]">
                Filters
              </span>
            </div>
            {filterSkills.length > 0 && (
              <div
                className="text-[#FF2731] text-[18px] cursor-pointer flex flex-row gap-2 items-center"
                onClick={removeAllFiltersHandler}
              >
                <span>Clear all</span>
                <span className="text-[22px] -mt-[1px]">&times;</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 py-4 h-fit">
            <Badges
              filterBadges={filterBadges}
              setFilterBadges={setFilterBadges}
            />
            <Skills
              filterSkills={filterSkills}
              setFilterSkills={setFilterSkills}
            />
            <Pricing
              filterPricing={filterPricing}
              setFilterPricing={setFilterPricing}
            />
            <MoneyEarned
              filterMoneyEarned={filterMoneyEarned}
              setFilterMoneyEarned={setFilterMoneyEarned}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Badges = ({ filterBadges, setFilterBadges }) => {
  const [options, setOptions] = useState([
    { id: 1, label: "Golden badge", checked: false },
    { id: 2, label: "Silver badge", checked: false },
    { id: 3, label: "Bronze badge", checked: false },
  ]);

  const handleCheckboxChange = (id) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, checked: !option.checked } : option
      )
    );

    setFilterBadges((prev) =>
      prev.includes(id)
        ? prev.filter((badgeId) => badgeId !== id)
        : [...prev, id]
    );
  };

  return (
    <div className="flex flex-col">
      <div className="font-[700] text-[20px] text-[#352F36]">Badges</div>
      <div className="flex flex-col gap-4 py-4">
        {options.map((option) => (
          <div key={option.id} className="flex flex-row gap-2 items-center">
            <input
              className="w-[16px] input-bg h-[22px] rounded-[12px]"
              type="checkbox"
              checked={option.checked}
              onChange={() => handleCheckboxChange(option.id)}
            />
            <label className="font-[400] text-[20px] text-[#352F36]">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const Skills = ({ filterSkills, setFilterSkills }) => {
  const [options, setOptions] = useState([
    "java",
    "python",
    "iot engineer",
    ".net",
    "php",
    "css",
    "html",
    "prototyping",
    "wireframing",
    "high fidelity",
    "user research",
    "user flow",
    "information architecture",
    "branding",
  ]);

  const handleCheckboxChange = (option) => {
    setFilterSkills((prev) =>
      prev.includes(option)
        ? prev.filter((skill) => skill !== option)
        : [...prev, option]
    );
  };

  return (
    <div className="flex flex-col">
      <div className="font-[700] text-[20px] text-[#352F36]">Skills</div>
      <div className="flex flex-col gap-4 py-4">
        {options.map((option, index) => (
          <div key={index} className="flex flex-row gap-2 items-center">
            <input
              className="w-[16px] input-bg h-[22px] rounded-[12px]"
              type="checkbox"
              value={option}
              checked={filterSkills.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            />
            <label className="font-[400] text-[20px] text-[#352F36] capitalize">
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const Pricing = ({ filterPricing, setFilterPricing }) => {
  const [options, setOptions] = useState([
    { id: 1, label: "$30", checked: false },
    { id: 2, label: "$40 - $80", checked: false },
    { id: 3, label: "$80 - $180", checked: false },
    { id: 4, label: "$180 and above", checked: false },
  ]);

  const handleCheckboxChange = (id) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, checked: !option.checked } : option
      )
    );

    setFilterPricing((prev) =>
      prev.includes(id)
        ? prev.filter((priceId) => priceId !== id)
        : [...prev, id]
    );
  };

  return (
    <div className="flex flex-col">
      <div className="font-[700] text-[20px] text-[#352F36]">Pricing</div>
      <div className="flex flex-col gap-4 py-4">
        {options.map((option) => (
          <div key={option.id} className="flex flex-row gap-2 items-center">
            <input
              className="w-[16px] input-bg h-[22px] rounded-[12px]"
              type="checkbox"
              checked={option.checked}
              onChange={() => handleCheckboxChange(option.id)}
            />
            <label className="font-[400] text-[20px] text-[#352F36]">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const MoneyEarned = ({ filterMoneyEarned, setFilterMoneyEarned }) => {
  const [options, setOptions] = useState([
    { id: 1, label: "$3000 and below", checked: false },
    { id: 2, label: "$4000 - $8000", checked: false },
    { id: 3, label: "$4000 - $1000000", checked: false },
    { id: 4, label: "$4000 and above", checked: false },
  ]);

  const handleCheckboxChange = (id) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, checked: !option.checked } : option
      )
    );

    setFilterMoneyEarned((prev) =>
      prev.includes(id)
        ? prev.filter((moneyId) => moneyId !== id)
        : [...prev, id]
    );
  };

  return (
    <div className="flex flex-col">
      <div className="font-[700] text-[20px] text-[#352F36]">Money Earned</div>
      <div className="flex flex-col gap-4 py-4">
        {options.map((option) => (
          <div key={option.id} className="flex flex-row gap-2 items-center">
            <input
              className="w-[14px] input-bg h-[22px] rounded-[12px]"
              type="checkbox"
              checked={option.checked}
              onChange={() => handleCheckboxChange(option.id)}
            />
            <label className="font-[400] text-[20px] text-[#352F36]">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
