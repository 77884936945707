import React from 'react'
import SearchInput from '../../inputs/SearchInput'

export default function RequiredSkills({selectedSkills,setSelectedSkills}) {
  return (
    <div className='w-full large:w-[700px] h-fit p-2'>
    <SearchInput type="search" inputValue={selectedSkills} setInputValue={setSelectedSkills} labelName={"Skills required"} placeholder={"Search and add skills"}/>
</div>
  )
}
