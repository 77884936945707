import React, { useState } from "react";
import UserHeader from "./UserHeader";
import { BannerDashboard, DashboardActiveScreen } from "./MyDashBoard";
import CompanyProfiles, {
  RecommendationCompanyProfiles,
} from "./CompanyProfiles";
import { TfiEmail } from "react-icons/tfi";
import { MdDownload } from "react-icons/md";
import { PiEyeBold } from "react-icons/pi";

import InnerShadowAvatar from "../../utils/InnerShadowAvatar";
import { FaFilter } from "react-icons/fa";
import OpenEyeButton from "../Button/OpenEyeButton";
import CancelButton from "../Button/CancelButton";
import { Avatar } from "ui-neumorphism";

import zoho from "../../assets/projects_logos/zoho.png";
import accenture from "../../assets/projects_logos/accenture.png";
import cognigant from "../../assets/projects_logos/cognigant.png";
import infosys from "../../assets/projects_logos/infosys.png";
import tcs from "../../assets/projects_logos/tcs.png";
import hcl from "../../assets/projects_logos/hcl.png";
import ibm from "../../assets/projects_logos/ibm.png";
import siemens from "../../assets/projects_logos/siemens.png";
import countryLogo from "../../assets/flag.png";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import TextInput from "../inputs/TextInput";

export default function ReviewProposal() {
  const [activeScreen, setActiveScreen] = useState(
    localStorage.getItem("activeProject")
      ? localStorage.getItem("activeProject")
      : 0
  );

  return (
    <div className="bg-normal w-full h-screen overflow-hidden">
      <UserHeader isProfile={true} isBack={true} titleName={"My Dashboard"} />

      <div className="grid grid-cols-[70%_30%] gap-6 h-[82%] scrollbar place-items-start overflow-x-hidden w-full overflow-y-auto  px-10 pt-4">
        <div className="w-full flex flex-col gap-4">
          <BannerDashboard isCreateRFP={true} />
          <DashboardActiveScreen
            activeScreen={activeScreen ? activeScreen : 0}
            setActiveScreen={setActiveScreen}
          />
          <ReviewProposalContent />
        </div>
        <div className="flex flex-col gap-4">
          <RecommendationCompanyProfiles />
          <CompanyProfiles />
        </div>
      </div>
    </div>
  );
}

const ReviewProposalContent = () => {
  const [isAgreementShare,setAgreementShare]=useState(false)
  const shareAgreementHandler=()=>{
    setAgreementShare(true)
  }
  const sendProposalHandler=()=>{
    alert("need api call")
  }
  return (
    <div className="text-[#352F36] flex flex-col gap-4">
      <div className="p-4 rounded-[14px] card font-[700] text-[24px]">
        Review Proposal - Zoho pvt ltd
      </div>
      <div className="card w-full min-h-[500px] h-fit p-4 rounded-[14px] flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="font-[700] text-[20px]">Project title:</h1>
          <div className=" font-[400] text-[16px] text-[#352F36]">
            We are embarking on the development of a comprehensive Health &
            Fitness app aimed at empowering users to achieve their fitness
            goals, improve overall health, and lead a more active lifestyle.
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-[700] text-[20px]">Project scope</h1>
          <div className=" font-[400] text-[16px] text-[#352F36]">
            The project involves the design, development, testing, and
            deployment of a native mobile application for iOS and Android
            platforms.{" "}
          </div>
        </div>

        {
          !isAgreementShare && <>
          <div className="flex flex-row gap-2 items-center">
          <h1 className="font-[700] text-[20px]">Timeline:</h1>
          <div className="mt-1 font-[400] text-[16px] text-[#352F36] flex flex-row gap-2 items-center">
            <img
              className=" w-[14px] h-[14px]"
              src={require("../../assets/time-logo.png")}
              alt=""
            />
            8 months
          </div>
        </div>

        <div className="flex flex-row gap-2 items-center">
          <h1 className="font-[700] text-[20px]">Budget:</h1>
          <div className="mt-1 font-[400] text-[16px] text-[#352F36] flex flex-row gap-2 items-center">
            $ 30000
          </div>
        </div>
          </>
        }
        <div className="flex flex-row gap-4 items-center">
        <h1 className="font-[700] text-[20px]">NDA</h1>
                <div className="card flex flex-row gap-4 items-center p-4 rounded-[8px]">
                  {" "}
                  <img
                    className="w-[16px] h-[20px]"
                    src={require("../../assets/documentmodule.png")}
                    alt=""
                  />{" "}
                  <h1>NDA file to sign</h1>{" "}
                  <span className="scale-[2] -mt-1 cursor-pointer">
                    &times;
                  </span>
                </div>
                <div className="w-[30px] h-[30px] rounded-full bg-blue grid place-items-center p-1">
                  <img src={require("../../assets/eyeLogo.png")} alt="" />
                </div>
              </div>
        <div className="flex flex-col gap-2 justify-center">
          <h1 className="font-[700] text-[20px]">Supporting documents</h1>
          <div className="mt-1 font-[400] text-[16px] text-[#352F36] flex flex-row gap-10 items-center">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4 items-center">
                <div className="card flex flex-row gap-4 items-center p-4 rounded-[8px]">
                  {" "}
                  <img
                    className="w-[16px] h-[20px]"
                    src={require("../../assets/documentmodule.png")}
                    alt=""
                  />{" "}
                  <h1>Supporting document</h1>{" "}
                  <span className="scale-[2] -mt-1 cursor-pointer">
                    &times;
                  </span>
                </div>
                <div className="w-[30px] h-[30px] rounded-full bg-blue grid place-items-center p-1">
                  <img src={require("../../assets/eyeLogo.png")} alt="" />
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <div className="card flex flex-row gap-4 items-center p-4 rounded-[8px]">
                  {" "}
                  <img
                    className="w-[16px] h-[20px]"
                    src={require("../../assets/documentmodule.png")}
                    alt=""
                  />{" "}
                  <h1>Supporting document</h1>{" "}
                  <span className="scale-[2] -mt-1 cursor-pointer">
                    &times;
                  </span>
                </div>
                <div className="w-[30px] h-[30px] rounded-full bg-blue grid place-items-center p-1">
                  <img src={require("../../assets/eyeLogo.png")} alt="" />
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <div className="card flex flex-row gap-4 items-center p-4 rounded-[8px]">
                  {" "}
                  <img
                    className="w-[16px] h-[20px]"
                    src={require("../../assets/documentmodule.png")}
                    alt=""
                  />{" "}
                  <h1>Supporting document</h1>{" "}
                  <span className="scale-[2] -mt-1 cursor-pointer">
                    &times;
                  </span>
                </div>
                <div className="w-[30px] h-[30px] rounded-full bg-blue grid place-items-center p-1">
                  <img src={require("../../assets/eyeLogo.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="bg-blue p-2 px-4 text-white rounded-[8px] flex flex-row gap-2 items-center">
              <MdDownload style={{ marginTop: "2px" }} /> Download all
            </div>
          </div>
        </div>

      {
        !isAgreementShare &&
        <>
          <div className="flex flex-col gap-2 ">
          <h1 className="font-[700] text-[20px]">Contact information</h1>
          <div className="bg-gradient-to-r from-[#6F7AFC] via-[#4554EE] to-indigo-400 text-transparent bg-clip-text  mt-1 font-[400] text-[16px] text-[#352F36] flex flex-row gap-2 items-center">
            <TfiEmail style={{ color: "#6F7AFC" }} />
            ritheshp2h@gmail.com
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-[700] text-[20px]">Blueprint</h1>
          <div className=" font-[400] text-[16px] text-[#352F36]">
            We are seeking proposals from qualified vendors to provide blueprint
            and architectural design services for the development of a
            comprehensive health and fitness application.{" "}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="font-[700] text-[20px]">Architecture</h1>
          <div className=" font-[400] text-[16px] text-[#352F36]">
            The blueprint and architecture phase of the project will lay the
            foundation for the app's structure, user flow, and technical
            infrastructure.{" "}
          </div>
        </div>
        </>
      }
        <div className="flex flex-row gap-2">
          <h1 className="font-[700] text-[20px]">Status:</h1>
          <div className={`border ${isAgreementShare?"border-[#FF5F1B] text-[#FF5F1B]":"border-[#089949] text-[#089949]"}  font-[400] text-[16px] rounded-[8px] p-1 px-4`}>
           {isAgreementShare?"In-Signing":"Open"}
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <h1 className="font-[700] text-[20px]">Proposal submitted by:</h1>
          <div className=" font-[400] text-[16px] text-[#352F36] flex flex-row gap-2 items-center">
            <InnerShadowAvatar imgSrc={zoho} size={30} />
          Zoho pvt ltd
          </div>
        </div>


      </div>
      <div className="w-screen flex flex-row gap-4 justify-center">
          {isAgreementShare && <CancelButton />}
          <div onClick={isAgreementShare?sendProposalHandler:shareAgreementHandler} className={`bg-blue min-w-[136px] p-2 px-6 flex flex-row gap-2 justify-center text-white items-center rounded-[8px] cursor-pointer hover:scale-[1.02]`}>{isAgreementShare?"Send":"Share agreement"}</div>
        </div>
    </div>
  );
};
