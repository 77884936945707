import React, { useState } from "react";
import { Card } from "ui-neumorphism";
import RadioInput from "../../inputs/RadioInput";

export default function NeedACompanyToWork({needAcompanyToWork, setNeedAcompanyToWork,teamSize, setTeamSize}) {
 
  return (
    <div className="flex flex-col w-full gap-4 py-4">
      <div className="font-[500] text-[22px] ">Need a company to work?</div>
      <div className="flex flex-row small:flex-wrap small:gap-4 gap-10">
        <Card width={120} height={58} style={{borderRadius:"20px"}} >
          <RadioInput
            radioValue={needAcompanyToWork}
            setRadioValue={setNeedAcompanyToWork}
            labelValue={"Yes"}
          />
        </Card>
        <Card width={120} height={58} style={{borderRadius:"20px"}} >
          <RadioInput
            radioValue={needAcompanyToWork}
            setRadioValue={setNeedAcompanyToWork}
            labelValue={"No"}
          />
        </Card>
      </div>
      {needAcompanyToWork==="Yes" && <div className="font-[500] text-[22px] w-full text-left ">Select team size</div>
      }
      {needAcompanyToWork==="Yes"  && (
        <div className="flex flex-row medium:flex-wrap medium:gap-4 gap-10">
          <Card width={133} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={teamSize}
              setRadioValue={setTeamSize}
              labelValue={"2-25"}
            />
          </Card>
          <Card width={133} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={teamSize}
              setRadioValue={setTeamSize}
              labelValue={"25-50"}
            />
          </Card>
          <Card width={133} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={teamSize}
              setRadioValue={setTeamSize}
              labelValue={"50-100"}
            />
          </Card>
          <Card width={133} height={58} style={{borderRadius:"20px"}} >
            <RadioInput
              radioValue={teamSize}
              setRadioValue={setTeamSize}
              labelValue={"100+"}
            />
          </Card>
        </div>
      )}
    </div>
  );
}
