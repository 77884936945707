import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner, { apiUrl, parseJwt } from "../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";

import { Card, IconButton } from "ui-neumorphism";

import BackButton from "../components/Button/BackButton";

import VisibilityIcon from "@mui/icons-material/Visibility";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Afghanistan from "../assets/Afghanistan.png";
import India from "../assets/flag.png";
import NextButton from "../components/Button/NextButton";
function SignUp() {
  const navigate = useNavigate();

  const location = useLocation();

  const [isFocused, setIsFocused] = useState(false);

  const [isFocused1, setIsFocused1] = useState(false);

  const [terms, setTerms] = useState(false);

  const [loader, setLoader] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [user, setUser] = useState({
    role: "User",
    firstName: "",

    lastName: "",
    email: "",
    phone: "",

    country: "",

    password: "",

    confirmPassword: "",
  });
  const isAnyFieldEmpty = () => {
    for (const key in user) {
      if (user[key] === "") {
        return true;
      }
    }
    return false;
  };

  const [errors, setErrors] = useState({
    role: "User",

    firstName: "",

    lastName: "",
    email: "",
    phone: "",

    country: "",

    password: "",

    confirmPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let filteredValue = value;

    // Filter input based on field name
    if (name === "phone") {
      // Allow only numbers for phone
      filteredValue = value.replace(/[^0-9]/g, "");
    } else if (name === "firstName" || name === "lastName") {
      // Allow only alphabets (and spaces if needed) for names
      filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    }

    setUser((prevState) => ({
      ...prevState,
      [name]: filteredValue,
    }));

    if (name === "confirmPassword") {
      setPasswordsMatch(filteredValue === user.password);
    }

    // Reset the error message when the user starts typing
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setUser((prevState) => ({
      ...prevState,
      password: value,
    }));

    const criteria = {
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      digit: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    };

    setPasswordCriteria(criteria);
  };

  const validateInputs = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let valid = true;

    const newErrors = {
      firstName: errors.firstName,
      lastName: errors.lastName,
      email: errors.email,
      phone: errors.phone,
      country: errors.country,
      password: errors.password,
      confirmPassword: errors.confirmPassword,
      terms: errors.terms,
    };

    // Validate each field only if it was previously empty and not currently focused
    if (errors.firstName === "" && !usernameFocused) {
      if (!user.firstName.trim()) {
        newErrors.firstName = "First name cannot be left blank";
        valid = false;
      }
    }

    if (errors.lastName === "" && !usernameFocused) {
      if (!user.lastName.trim()) {
        newErrors.lastName = "Last name cannot be left blank";
        valid = false;
      }
    }

    if (errors.email === "" && !usernameFocused) {
      if (!user.email.trim()) {
        newErrors.email = "Email cannot be left blank";
        valid = false;
      } else if (!emailRegex.test(user.email)) {
        newErrors.email = "Please provide a valid email address";
        valid = false;
      }
    }

    if (errors.phone === "" && !usernameFocused) {
      if (!user.phone.trim()) {
        newErrors.phone = "Phone number cannot be left blank";
        valid = false;
      } else if (user.phone.length !== 10 || isNaN(user.phone)) {
        newErrors.phone = "Phone number must have exactly 10 digits";
        valid = false;
      }
    }

    if (errors.country === "" && !usernameFocused) {
      if (!user.country.trim()) {
        newErrors.country = "Country cannot be left blank";
        valid = false;
      }
    }

    if (errors.password === "" && !usernameFocused) {
      if (!user.password.trim()) {
        newErrors.password = "Password cannot be left blank";
        valid = false;
      } else if (user.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters long";
        valid = false;
      }
    }

    if (errors.confirmPassword === "" && !usernameFocused) {
      if (!user.confirmPassword.trim()) {
        newErrors.confirmPassword = "Confirm password cannot be left blank";
        valid = false;
      } else if (user.confirmPassword !== user.password) {
        newErrors.confirmPassword = "Passwords do not match";
        valid = false;
      }
    }

    if (!terms) {
      newErrors.terms = "Please accept the terms and conditions";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  };

  const signUpHandler = async () => {
    if (validateInputs()) {
      try {
        setLoader(true);

        const response = await axios.post(`${apiUrl}/auth/signUp`, user);

        toast.success("Sign up successful!");
        setLoader(false);
        navigate("/login", { state: user });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            // Assuming 409 Conflict for email already used
            errors.email("Email already in use. Please use a different email.");
          } else if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message); // Custom message from backend
          } else {
            toast.error("Failed to sign up. Please try again.");
          }
        } else {
          toast.error(
            "Failed to sign up. Please check your network connection."
          );
        }
      } finally {
        setLoader(false);
      }
    }
  };
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectRef = useRef(null);

  const handleClickOutside1 = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsFocused1(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const handleFocus1 = () => {
    setIsFocused(true);
  };

  const handleToggle = (e) => {
    setTerms(e.target.checked);

    // Reset the error message for terms when terms are selected as true

    if (e.target.checked) {
      setErrors((prevErrors) => ({
        ...prevErrors,

        terms: "",
      }));
    }
  };
  const renderPasswordStrength = () => {
    const criteriaMet = Object.values(passwordCriteria).filter(Boolean).length;

    // Determine color and text based on the number of criteria met
    let color;
    let text;
    let textColor;
    if (criteriaMet === 1) {
      color = "bg-red-500";
      text = "Poor";
      textColor = "text-red-500";
    } else if (criteriaMet === 2) {
      color = "bg-red-300";
      text = "Bad";
      textColor = "text-red-300";
    } else if (criteriaMet === 3) {
      color = "bg-orange-500";
      text = "Average";
      textColor = "text-orange-500";
    } else if (criteriaMet === 4) {
      color = "bg-green-300";
      text = "Good";
      textColor = "text-green-300";
    } else if (criteriaMet === 5) {
      color = "bg-green-500";
      text = "Great";
      textColor = "text-green-500";
    }

    // Create strength display lines
    const strengthDisplay = Array.from({ length: 5 }, (_, i) => (
      <div
        key={i}
        className={`h-1 ${i < criteriaMet ? color : "hide"} m-0.5`}
        style={{ width: "20%" }}
      />
    ));

    // Ensure all 5 lines are displayed, regardless of criteriaMet
    while (strengthDisplay.length < 5) {
      strengthDisplay.push(
        <div
          key={strengthDisplay.length}
          className="h-1 bg-transparent m-0.5"
          style={{ width: "20%" }}
        />
      );
    }

    return (
      <div className="flex items-center">
        <div className="flex w-full">{strengthDisplay}</div>
        <div className={`ml-2 ${textColor}`}>{text}</div>
      </div>
    );
  };

  const countries = [
    { name: "Afghanistan", code: "+93", flag: Afghanistan },
    { name: "India", code: "+91", flag: India },
    // Add other countries here
  ];

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setUser({ ...user, country: country.name }); // Assuming country name is stored in user object
    setIsPopupOpen(false);
    setErrors((prevState) => ({ ...prevState, country: "" })); // Clear the error for country
  };
  const [usernameFocused, setUsernameFocused] = useState(false);

  const handleFocus = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Function to validate a field based on specific criteria
      const validateField = (fieldName, validationFn, errorMsg) => {
        if (!validationFn(user[fieldName])) {
          newErrors[fieldName] = errorMsg;
        } else {
          newErrors[fieldName] = "";
        }
      };

      // Check if the field was previously focused and is currently empty
      if (usernameFocused && user[field] === "") {
        switch (field) {
          case "firstName":
            validateField(
              "firstName",
              (value) => value.trim() !== "",
              "First name cannot be left blank"
            );
            break;

          case "lastName":
            validateField(
              "lastName",
              (value) => value.trim() !== "",
              "Last name cannot be left blank"
            );
            break;

          case "email":
            if (user.email.trim() === "" || !emailRegex.test(user.email)) {
              newErrors.email = "Email cannot be left blank";
            } else if (!emailRegex.test(user.email)) {
              newErrors.email = "Please provide a valid email address";
            } else {
              newErrors.email = "";
            }
            break;

          case "phone":
            validateField(
              "phone",
              (value) =>
                value.trim() !== "" && value.length === 10 && !isNaN(value),
              "Phone number must have exactly 10 digits"
            );
            break;

          case "country":
            validateField(
              "country",
              (value) => value.trim() !== "",
              "Country cannot be left blank"
            );
            break;

          case "password":
            validateField(
              "password",
              (value) => value.trim() !== "",
              "Password cannot be left blank"
            );
            break;

          case "confirmPassword":
            validateField(
              "confirmPassword",
              (value) => value.trim() !== "" && value === user.password,
              "Passwords do not match"
            );
            break;

          default:
            break;
        }
      } else {
        // Reset the error for the current focused field
        newErrors[field] = "";
      }

      return newErrors;
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  if (loader) return <Spinner titleName={"Loading ..."} />;
  return (
    <div className="w-screen h-screen max-h-fit pb-10  bg-[#E3EDF7] overflow-y-auto ">
      <div
        className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] transparent rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]

  "
      >
        <div
          className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]

  "
        ></div>
      </div>

      <div className="-mt-1">
        <BackButton />
      </div>

      <div className="w-full  mt-2 h-full bg-[#E3EDF7] flex flex-col gap-4 items-center">
        <h1 className="w-[85%] sticky  medium:w-[85%]  h-fit text-center text-[#352F36] mobile:text-[20px] mobile:mt-12 medium:text-[28px] large:text-[40px] font-[700]">
          Sign up now to explore tailored solutions and unlock a world of
          possibilities.
        </h1>

        <div className="w-[65%] medium:w-[100%] medium:px-4 medium:items-center medium:justify-center h-fit gap-3 flex flex-row mobile:flex-col   items-center ">
          <div className=" mobile:w-[90%] w-[48%]  sticky  ">
            <div className="w-[91%] card hover:scale-[1.02] hover:border-none  transform transition-transform hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] duration-400 ease-in-out cursor-pointer mobile:w-[100%] mobile:items-center  bg-[#E3EDF7]  h-fit  flex rounded-[20px]  flex-row gap-2 border-l-2 border-b-2 border-blue-800 border-opacity-30  justify-center items-center py-2 px-4 gap-1">
              <img
                src={require("../assets/Google.png")} // Path to your Google logo image
                alt="Apple Icon"
                className="w-[34px] h-[34px]  p-1 mb-1"
              />

              <span className="font-[500] text-center text-[18px] font-Satoshi">
                Continue with Google
              </span>
            </div>
          </div>
          <div className=" w-[4%] text-center ml-[-10px] flex font-semibold">
            Or
          </div>

          <div className=" mobile:w-[90%] w-[48%]   sticky ">
            <div className="w-[91%] hover:scale-[1.02] hover:border-none  transform transition-transform hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] cursor-pointer duration-400 ease-in-out card mobile:w-[100%] ml-[-5px] mobile:items-center  bg-[#E3EDF7]  h-fit  flex rounded-[20px]  flex-row gap-2 border-l-2 border-b-2 border-blue-800 border-opacity-30  justify-center items-center py-2 px-6 gap-1">
              <img
                src={require("../assets/appleicon.png")} // Path to your Google logo image
                alt="Apple Icon"
                className="w-[34px] h-[34px]  p-1 mb-1"
              />

              <span className="font-[500] text-center text-[18px] font-Satoshi">
                Continue with Apple
              </span>
            </div>
          </div>
        </div>

        <div className="w-[65%]   medium:w-[100%] medium:px-4 medium:items-center medium:justify-center h-fit gap-[10px] flex flex-row mobile:flex-col   items-center justify-between ">
          <div className="w-[48%] sticky mobile:w-[100%] mobile:items-center items-start flex flex-col gap-1">
            <div className="w-[90%] h-fit flex flex-col  gap-3 items-center">
              <label className="w-[100%] px-2  text-[18px] font-[500] font-Satoshi">
                <span className="text-[red]">*</span> Name
              </label>

              <input
                type="text"
                className={` w-[100%] rounded-[15px] focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]  p-3 font-[500] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] font-Satoshi
 focus:outline-none focus:border focus:border-[#2485FF] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out " ${
   errors.firstName && "border border-red-500"
 }`}
                placeholder="First name"
                name="firstName"
                value={user.firstName}
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => {
                  setUsernameFocused(false);
                  handleFocus("firstName");
                }}
                onChange={handleInputChange}
                required
              />
            </div>

            {errors.firstName && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.firstName}
              </p>
            )}
          </div>

          <div className="w-[48%] sticky mobile:w-[100%] mobile:items-center items-start flex flex-col gap-1">
            <div className="w-[90%] h-fit flex flex-col gap-3 items-center">
              <label className="w-[100%] px-2  text-[18px] font-[500] font-Satoshi">
                <span className="text-[red]">*</span> Last Name
              </label>

              <input
                type="text"
                className={` w-[100%] rounded-[15px] focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF]  p-3 font-[500] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] font-Satoshi
 focus:outline-none focus:border focus:border-[#2485FF] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out ${
   errors.lastName && "border border-red-500"
 }`}
                placeholder="Last name"
                name="lastName"
                value={user.lastName}
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => {
                  setUsernameFocused(false);
                  handleFocus("lastName");
                }}
                onChange={handleInputChange}
                required
              />
            </div>

            {errors.lastName && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.lastName}
              </p>
            )}
          </div>
        </div>

        <div className="w-[65%] sticky medium:w-[100%] medium:px-4 medium:items-center medium:justify-center h-fit gap-[10px] flex flex-row mobile:flex-col   items-center justify-between ">
          <div className="w-[48%] mobile:w-[100%] mobile:items-center items-start flex flex-col gap-3">
            <div className="w-[100%] h-fit flex flex-row gap-3 mobile:pl-5 items-center">
              <label className=" px-2  w-fit font-[500] text-[18px] font-Satoshi">
                <span className="text-[red]">*</span> Email
              </label>

              <div className="card_shadow w-fit h-fit rounded-full p-1">
                <img
                  src={require(`../assets/notnull.png`)}
                  className="w-[16px] h-[16px] "
                />
              </div>
            </div>

            <input
              type="text"
              className={` w-[90%] focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] p-3 font-[500] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] font-Satoshi
 focus:outline-none focus:border focus:border-[#2485FF] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out" ${
   errors.email && "border border-red-500"
 }`}
              placeholder="Work email Id"
              name="email"
              value={user.email}
              onFocus={() => setUsernameFocused(true)}
              onBlur={() => {
                setUsernameFocused(false);
                handleFocus("email");
              }}
              onChange={handleInputChange}
              style={{ borderRadius: "10px" }}
              required
            />

            {errors.email && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.email}
              </p>
            )}
          </div>

          <div className="w-[48%] mobile:w-[100%] mobile:items-center items-start flex flex-col gap-3">
            <div className="w-[100%] h-fit flex flex-row gap-3 mobile:pl-5 items-center">
              <label className="w-fit px-2   text-[18px] font-[500] font-Satoshi">
                <span className="text-[red]">*</span> Mobile no
              </label>

              <div className="card_shadow w-fit h-fit rounded-full p-1">
                <img
                  src={require(`../assets/notnull.png`)}
                  className="w-[16px] h-[16px] "
                />
              </div>
            </div>

            <div
              className={`w-[90%] rounded-[15px] items-center flex flex-row p-2.5 shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF] ${
                isFocused
                  ? "border border-[#2485FF] scale-[1.02] transform transition-transform duration-400 ease-in-out shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] "
                  : ""
              } ${errors.phone && "border border-red-500"}`}
              onClick={handleFocus1}
            >
              <span
                className={`w-fit pt-1.5 pr-1 gap-2 flex flex-row text-[18px] font-[500] font-Satoshi border-r-2 border-[#414B5A] ${
                  isFocused ? " " : " opacity-50 "
                } `}
              >
                {" "}
                <img
                  src={
                    selectedCountry
                      ? selectedCountry.flag
                      : require("../assets/flag.png")
                  } // Dynamically render selected country's flag
                  className={`w-[34px] h-[34px] pb-1 ${
                    isFocused ? "" : " opacity-50 "
                  }`}
                  alt="Country Flag"
                />{" "}
                {selectedCountry
                  ? `${selectedCountry.name.substring(0, 2).toUpperCase()} ${
                      selectedCountry.code
                    }`
                  : "IN +91"}
              </span>

              <input
                ref={inputRef}
                type="text"
                className={`w-[50%] p-1 font-[500] leading-24 bg-[#E3EDF7] rounded-[15px] text-[#414B5A] text-[18px] font-Satoshi focus:outline-none  ${
                  isFocused && "border-blue-500"
                }`}
                placeholder="Mobile number"
                name="phone"
                value={user.phone}
                onChange={handleInputChange}
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => {
                  setUsernameFocused(false);
                  handleFocus("phone");
                }}
                pattern="\d*"
                required
              />
            </div>

            {errors.phone && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.phone}
              </p>
            )}
          </div>
        </div>
        <div
          className={`w-[65%] medium:w-[100%] medium:px-4 mobile:items-center medium:items-center gap-[10px] flex flex-col justify-between ${
            isPopupOpen ? "" : "h-fit"
          }`}
        >
          <label className="w-[100%] px-2  font-[500] text-[18px] mobile:pl-5 font-Satoshi">
            <span className="text-[red]">*</span> Select your country
          </label>
          <div
            className={`w-[95%] mobile:w-[90%] rounded-[15px] items-center flex flex-row p-2 shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF] 
    ${
      isPopupOpen
        ? "border border-[#2485FF] scale-[1.02] transform transition-transform duration-400 ease-in-out shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] opacity-100"
        : ""
    }
    ${selectedCountry ? "" : "opacity-50"}
    ${errors.country ? "border border-red-500" : ""}`}
            onClick={() => {
              setIsPopupOpen(true);
              setUsernameFocused(true);
            }}
            onBlur={() => {
              setUsernameFocused(false);
              handleFocus("country");
            }}
            tabIndex={0} // Ensure the div is focusable
            role="button" // Add a role for accessibility
          >
            <img
              src={
                selectedCountry
                  ? selectedCountry.flag
                  : require("../assets/flag.png")
              }
              className={`w-[34px] h-[34px] border-r-2 pr-1 border-[#414B5A] ${
                selectedCountry ? "" : "opacity-50 text-[#414B5A]"
              }`}
              alt="country flag"
            />
            <span className="w-[95%] rounded-[15px] p-1 font-[500] leading-24 bg-[#E3EDF7] text-[18px] font-Satoshi focus:outline-none">
              {user.country ? user.country : "Select your country"}
            </span>
            <svg
              className={`w-5 h-5 ml-2 transition-transform transform ${
                isPopupOpen ? "rotate-180 " : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <div className="w-[95%]">
            {isPopupOpen && (
              <div className="w-full flex flex-row items-end justify-end">
                <div className=" w-fit     mt-2 bg-[#E3EDF7] p-4 px-8 shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF] rounded-[15px] z-10">
                  <div className="flex flex-row justify-between mb-4">
                    <input
                      type="text"
                      placeholder="Search country"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-[80%] bg-[#E3EDF7] focus:outline-none p-2 px-3 shadow-[5px_5px_15px_#a6aabc,-5px_-5px_15px_#FAFBFF] rounded-[15px]"
                    />
                    <button
                      onClick={() => setIsPopupOpen(false)}
                      className=" text-[30px] rounded"
                    >
                      &times;
                    </button>
                  </div>
                  <ul className="max-h-60 overflow-y-auto">
                    {filteredCountries.map((country) => (
                      <li
                        key={country.name}
                        className="flex items-center p-2 cursor-pointer hover:bg-gray-300 rounded-[5px]"
                        onClick={() => handleCountrySelect(country)}
                      >
                        <img
                          src={country.flag}
                          alt={`${country.name} flag`}
                          className="w-8 h-8 mr-2"
                        />
                        <span>{country.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {errors.country && (
            <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
              <IconButton
                rounded
                text={false}
                color="var(--error)"
                style={{ width: "24px", height: "24px" }}
                size="small"
              >
                <img
                  src={require(`../assets/error.png`)}
                  className="w-[17px] h-[17px] "
                />
              </IconButton>

              {errors.country}
            </p>
          )}
        </div>
        <div className="w-[100%] relative signup h-[100%] gap-4 pb-4 flex flex-col items-center justify-center">
          <div className="w-[65%]  medium:w-[100%] medium:px-4 mobile:items-center medium:items-center h-fit gap-[10px] flex flex-col    justify-between ">
            <label className="w-[100%]   font-[500] text-[18px] px-2 font-Satoshi">
              <span className="text-[red]">*</span> Password
            </label>

            <div className="relative w-[95%] mobile:w-[90%]">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={user.password}
                onChange={handlePasswordChange}
                className={` w-full focus:outline-none focus:border focus:border-[#2485FF] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] p-3 font-[500] leading-24 bg-[#E3EDF7]  text-[18px] font-Satoshi focus:outline-none
 " ${errors.password && "border border-red-500"}`}
                required
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => {
                  setUsernameFocused(false);
                  handleFocus("password");
                }}
              />

              <IconButton
                text={false}
                rounded
                size="small"
                style={{
                  position: "absolute",

                  right: "10px",

                  top: "50%",

                  transform: "translateY(-50%)",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            <div className=" w-[95%]">{renderPasswordStrength()}</div>
            {errors.password && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.password}
              </p>
            )}
          </div>

          <div className="w-[65%]  medium:w-[100%] medium:px-4 mobile:items-center medium:items-center h-fit gap-[10px] flex flex-col    justify-between ">
            <label className="w-[100%] px-2  font-[500] text-[18px]  font-Satoshi">
              <span className="text-[red]">*</span> Confirm password
            </label>

            <div className="relative w-[95%] mobile:w-[90%]">
              <input
                placeholder="confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={user.confirmPassword}
                name="confirmPassword"
                onChange={handleInputChange}
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => {
                  setUsernameFocused(false);
                  handleFocus("confirmPassword");
                }}
                className={`border w-full focus:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] p-3 font-[500] leading-24 bg-[#E3EDF7] text-[#414B5A] text-[18px] rounded-[15px] font-Satoshi
 focus:outline-none focus:outline-none focus:border focus:border-[#2485FF] focus:scale-[1.02] transform transition-transform duration-400 ease-in-out ${
   errors.confirmPassword && "border border-red-500"
 }`}
                required
              />

              <IconButton
                text={false}
                rounded
                size="small"
                style={{
                  position: "absolute",

                  right: "10px",

                  top: "50%",

                  transform: "translateY(-50%)",
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </div>

            {user.confirmPassword && (
              <div
                className={`flex items-center gap-2 mt-2 ${
                  passwordsMatch ? "text-green-500" : "text-red-500"
                }`}
              >
                {passwordsMatch ? (
                  <IconButton text={false} rounded size="small">
                    <img
                      src={require(`../assets/right.png`)}
                      className="w-[14px] h-[14px]"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    rounded
                    text={false}
                    color="var(--error)"
                    size="small"
                  >
                    <img
                      src={require(`../assets/error.png`)}
                      className="w-[14px] h-[14px]"
                    />
                  </IconButton>
                )}
                {passwordsMatch ? "Passwords match" : "Passwords do not match"}
              </div>
            )}
            {errors.confirmPassword && (
              <p className="w-[100%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.confirmPassword}
              </p>
            )}
          </div>

          <div className="w-[65%] mobile:w-[100%]  flex flex-col mobile:items-center gap-1  ">
            <div className="w-full mobile:w-[90%] px-4 h-fit mt-2 mb-2 gap-2  justify-start items-center    flex flex-row ">
              <input
                type="checkbox"
                className={` ${
                  user.firstName ||
                  user.lastName ||
                  user.email ||
                  user.phone ||
                  user.country ||
                  user.password ||
                  user.confirmPassword
                    ? " cursor-pointer "
                    : " cursor-not-allowed "
                } ${
                  terms
                    ? "accent-red"
                    : "appearance-none shadow-[inset_2.5px_2.5px_2px_#a6aabc,inset_-2.5px_-2.5px_2px_#f9faff]"
                } w-[15px] h-[15px] `}
                checked={terms}
                onChange={handleToggle}
                disabled={isAnyFieldEmpty()}
              />

              <a
                href="/privacy-policy"
                className="cursor-pointer text-[black] text-[18px] font-[500] font-Satoshi"
              >
                Newsletter and privacy policy
              </a>
            </div>

            {errors.terms && (
              <p className="w-[65%] flex flex-row items-center gap-2 text-[#FF716A] text-[18px]">
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  style={{ width: "24px", height: "24px" }}
                  size="small"
                >
                  <img
                    src={require(`../assets/error.png`)}
                    className="w-[17px] h-[17px] "
                  />
                </IconButton>

                {errors.terms}
              </p>
            )}
          </div>

          <div className="w-[75%] h-fit gap-5  flex flex-col text-[white] text-[14px] font-[500]  leading-normal  justify-center items-center text-center">
            {/* <button
              className={`w-fit text-[18px]  shadow-xl font-[500] px-6   p-3 rounded-[15px] bg-gradient-to-r from-indigo-800 to-sky-600 text-[white]  ${Object.values(errors).some(error => error !== '') ? ' cursor-not-allowed' : 'cursor-pointer'}`}
      
       
              onClick={signUpHandler}
            >
              {" "}
              Create account{" "}
            </button> */}

            <NextButton
              bgActive={
                user.firstName &&
                user.lastName &&
                user.email &&
                user.phone &&
                user.country &&
                user.password &&
                user.confirmPassword &&
                terms
                  ? true
                  : false
              }
              buttonName="      Create account"
              nextHandler={signUpHandler}
            />
          </div>

          <Card
            size="large "
            style={{ borderRadius: "15px" }}
            className="cursor-pointer hover:scale-[1.02]"
          >
            <div
              onClick={() => navigate("/login")}
              className="w-fit h-fit gap-5 px-4 rounded-[15px]  hover:shadow-[inset_2.5px_2.5px_5px_#a6aabc,inset_-2.5px_-2.5px_5px_#f9faff]  py-3 flex flex-row text-[18px] font-[500]  leading-normal  justify-center items-center text-center"
            >
              <p className="w-fit  h-fit text-[18px] cursor-pointer font-[500] font-Satoshi">
                Already have an account?
                <span
                  className="text-[#041FA3]  w-fit cursor-pointer h-fit text-[18px] font-[500] "
                  onClick={() => navigate("/login")}
                >
                  {" "}
                  Login{" "}
                </span>
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
