import React, { useEffect } from 'react';

function ScrollToTop() {
  useEffect(() => {
    const scrollListener = () => {
      window.scrollTo(0, 0);
    };
scrollListener()
    // window.addEventListener('hashchange', scrollListener);
    // window.addEventListener('popstate', scrollListener);

    // return () => {
    //   window.removeEventListener('hashchange', scrollListener);
    //   window.removeEventListener('popstate', scrollListener);

    // };
  }, []);

  return null;
}

export default ScrollToTop;
