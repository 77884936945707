import React from "react";

export default function AcceptButton({ buttonName, onClickHandler }) {
  return (
    <div
      onClick={onClickHandler}
      className="w-full h-full capitalize bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] flex flex-row gap-2 py-1 px-4 rounded-[8px] hover:scale-[1.02] text-white items-center justify-center cursor-pointer"
    >
      {buttonName ? buttonName : "Accept"}{" "}
      <img
        className="w-[24px] h-[13.8px]"
        src={require("../../assets/praposals/accept_button_ticks.png")}
        alt=""
      />{" "}
    </div>
  );
}



