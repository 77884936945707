import React, { useState } from "react";
import { Card } from "ui-neumorphism";
import RadioInput from "../../inputs/RadioInput";

export default function SelectProjectDuration({projectDuration,setProjectDuration}) {
  return (
    <div className=" h-fit w-full ">
      <div className="flex flex-col gap-4 items-center">
        <div className=" text-[22px] small:text-[18px] w-full font-medium leading-[29.7px] text-center text-[#352F36]">
          Select project duration
        </div>
        <div className="flex flex-row small:flex-wrap small:justify-center gap-10 ">
          <Card width={230} height={74} style={{borderRadius:"20px"}}>
            <RadioInput
              radioValue={projectDuration}
              setRadioValue={setProjectDuration}
              labelValue={"Long term project"}
            />
          </Card>
          <Card width={230} height={74} style={{borderRadius:"20px"}}>
            <RadioInput
              radioValue={projectDuration}
              setRadioValue={setProjectDuration}
              labelValue={"Short term project"}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
