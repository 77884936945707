import React, { useState } from 'react'
import PostNavHeader from '../Post-Jobs/util/PostNavHeader'
import ProposalFilters from './ProposalFilters'
import BrowseExpertsFilters from './BrowseExpertsFilters'

export default function BrowseExperts() {
  const [filterSkills, setFilterSkill] = useState([]);
  return (
    <div className="flex flex-col h-screen w-full bg-normal">
    <PostNavHeader redirectUrl={localStorage.getItem("redirectUrl")} isBack={true} titleName="Browse Experts" />
    <div className="px-10 py-8 flex w-full flex-col gap-5 overflow-auto">
       <BrowseExpertsFilters filterSkills={filterSkills}
        setFilterSkill={setFilterSkill} />
    </div>
    </div>
  )
}
