import React, { forwardRef, useImperativeHandle, useRef } from "react";

function UpgradeYourExperience(props, ref) {
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));
  return (
    <div
      data-id="startupsAndIncubators"
      ref={localRef}
      className="w-full h-fit px-10 p-4"
    >
      <div className="relative h-fit w-full bg-blue p-10 pb-0 rounded-[60px]  flex flex-row justify-between ipadMin:flex-col-reverse mobile:flex-col-reverse  gap-10 text-[#ffffff]">
        <div className="flex h-fit flex-col gap-5">
          <div className="font-[700] text-[40px]  medium:text-[30px] small:text-[24px]">
            Upgrade Your Experience
          </div>
          <div className="font-[400] text-[24px] medium:text-[20px] small:text-[18px]">
            Unlock premium features with P2H subscription
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-2 w-fit">
              <img
                src={require("../../assets/defaultScreen/topIndividuals/start_tick.png")}
                alt=""
              />
              <div className="font-[400] text-[20px] mobile:text-[16px]">
                Access to premium projects
              </div>
            </div>
            <div className="flex flex-row gap-2 w-fit">
              <img
                src={require("../../assets/defaultScreen/topIndividuals/start_tick.png")}
                alt=""
              />
              <div className="font-[400] text-[20px] mobile:text-[16px]">
                Priority support
              </div>
            </div>
            <div className="flex flex-row gap-2 w-fit">
              <img
                src={require("../../assets/defaultScreen/topIndividuals/start_tick.png")}
                alt=""
              />
              <div className="font-[400] text-[20px] mobile:text-[16px]">
                Cost savings
              </div>
            </div>
            <div className="flex flex-row gap-2 w-fit">
              <img
                src={require("../../assets/defaultScreen/topIndividuals/start_tick.png")}
                alt=""
              />
              <div className="font-[400] text-[20px] mobile:text-[16px]">
                Streamlined workflow
              </div>
            </div>
          </div>
          <div className="flex flex-row mobile:flex-col-reverse items-start w-fit h-fit">
            <button className="hover:bg-[#000000] hover:text-[#FFFFFF] [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33] w-[220px] h-fit  px-6 p-2 bg-[#E3EDF7] text-[22px] text-[#352F36] rounded-[44px]">
              Subscribe
            </button>
            <img
              className="mobile:-mt-10 -mt-20 largeScreen:-mt-30 opacity-[70%] ipadMin:scale-[0.6] mobile:scale-[0.5]"
              src={require("../../assets/defaultScreen/hp-laptop.png")}
              alt=""
            />
          </div>
        </div>
        <div className="-mb-4 relative h-fit">
          <img
            className=""
            src={require("../../assets/defaultScreen/experience_user_logo.png")}
            alt=""
          />
          <div className="absolute w-fit flex flex-row items-end h-fit ipadMin:-mt-20 ipadMin:ml-10 -mt-40 laptop:-mt-24 ml-6 rotate-12	">
            <div className="p-1 ipadMax:pt-20 mobile:pt-[80px] w-fit h-fit scale-[1.1] mobile:scale-[0.8] ipadMin:scale-[0.8]">
              <img
                className="w-fit"
                src={require("../../assets/defaultScreen/start-icon.png")}
                alt=""
              />
            </div>
            <div className="p-1 w-fit h-fit scale-[0.8] mobile:scale-[0.5]  mobile:-ml-12 ipadMin:scale-[0.5]  ipadMin:-ml-12 -ml-6">
              <img
                className="w-fit"
                src={require("../../assets/defaultScreen/start-icon.png")}
                alt=""
              />
            </div>
            <div className="w-fit p-1 h-fit scale-[0.5] mobile:scale-[0.3] ipadMin:scale-[0.3]  -ml-20">
              <img
                className="w-fit"
                src={require("../../assets/defaultScreen/start-icon.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default forwardRef(UpgradeYourExperience);
