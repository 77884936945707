import React from 'react'
import { useNavigate } from "react-router-dom";
export default function WhyChooseP2h() {
    const navigate = useNavigate();
  return (
<div className='w-full h-fit px-6 p-4'>
        <div className=' h-fit w-full bg-blue p-10 rounded-[60px] pb-0 flex flex-row justify-between  text-[#ffffff]'>
            <div className='flex h-fit w-[60%] flex-col gap-5 px-10 pb-10'>
            <div className='font-[700] text-[40px] small:text-[16px] medium:text-[30px] small:text-[24px]'>Why you should choose P2H?</div>
        
            <div className='flex w-full flex-col gap-5'>
                <div className='flex flex-row gap-5 w-fit'>
                    <img className='small:w-[14px] small:h-[14px]' src={require('../../assets/defaultScreen/topIndividuals/start_tick.png')} alt="" />
                    <div className='font-[400] text-[20px] small:text-[10px] '>Access to a global talent pool</div>
                </div>
                <div className='flex flex-row gap-5 w-fit'>
                    <img className='small:w-[14px] small:h-[14px]' src={require('../../assets/defaultScreen/topIndividuals/start_tick.png')} alt="" />
                    <div className='font-[400] text-[20px] small:text-[10px]'>Convenience finding work</div>
                </div>
                <div className='flex flex-row gap-5 w-fit mobile:text-[16px]'>
                    <img className='small:w-[14px] small:h-[14px]' src={require('../../assets/defaultScreen/topIndividuals/start_tick.png')} alt="" />
                    <div className='font-[400] text-[20px] small:text-[10px]'>Rating and review system</div>
                </div>
                <div className='flex flex-row gap-5 w-fit '>
                    <img className='small:w-[14px] small:h-[14px]' src={require('../../assets/defaultScreen/topIndividuals/start_tick.png')} alt="" />
                    <div className='font-[400] text-[20px] small:text-[10px]'>Networking opportunities</div>
                </div>
            
            </div>
           <div className='flex   flex-row items-center justify-center w-full h-fit'>
           <button onClick={()=>{navigate('/signup')}} className='hover:bg-[#000000] hover:text-[#FFFFFF] reltive [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33]  h-fit w-fit px-6 p-2 bg-[#E3EDF7] font-[700] text-[22px] text-[#352F36] rounded-[22px]'>Join now</button>
           </div>
            </div>
            <div className='mt-[-100px] h-full small:mt-[60%]  w-[40%] flex flex-row  justify-end items-end'>
                
                <img className=' h-[500px]' src={require('../../assets/defaultScreen/whyChooseLogo.png')} alt="" />
              
            </div>
        </div>
    </div>  
    )
}
