
import React, { useState } from "react";
import {  Card, IconButton } from "ui-neumorphism";

import { useNavigate } from "react-router-dom";
import "ui-neumorphism/dist/index.css";
import BackButton from "../components/Button/BackButton";
 
function RoleChanger() {
  const [role, setRole] = useState("");
  const navigate = useNavigate();
 
  const handleNext = () => {
    // Navigate to the next page with the selected role
    if (role) {
      navigate("/client", { state: { role } });
    }
  };
 
  return (

    <div className="w-full small:h-full h-screen bg-normal  font-Satoshi">

      <div className="absolute  w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
        <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
       
      </div>
     
      <BackButton />
     
 
    
 
    <div className="py-10 px-6 z-30 pt-10  w-full h-full flex flex-col gap-5 justify-center items-center">

        <h2 className="text-[40px] medium:text-[24px] font-[700] w-[100%] text-center ">
          Empowering Individuals, Inspiring Investors, Fueling Ideas
        </h2>
        <p className="w-full  medium:text-[24px]   h-fit leading-54 text-[34px] font-[400] pb-10 text-[#414B5A] text-center ">
          Discover Your Path: Choose Your Journey
        </p>
        <div className="w-[100%] relative h-full justify-center  flex flex-col items-center  gap-5  bg-black-with-image pb-10">
          <div className="w-full  grid grid-cols-4 gap-5  justify-center pb-10 ">
            <Card
              style={{borderRadius:"20px"}} className="min-w-[200px]  h-[200px]"
            >
              <div
                className={`w-[100%] flex flex-col h-full cursor-pointer hover:border hover:border-[#6F7AFC] items-center rounded-[20px] justify-center gap-5 text-[24px] font-[500] p-10  ${
                role === "company" ? "text-white bg-gradient-to-b from-[#6F7AFC] to-[#4554EE]" : ""
                }`}
                onClick={() => {
                  setRole("company");
                }}
              >
                <div className="w-[100%] h-fit flex flex-row gap-5  items-center justify-center font-Satoshi">
                  <IconButton
                    rounded
                    text={false}
                    color="var(--error)"
                    size="large"
                    className="flex items-center p-5"
                  >
                    <img
                      src={require(`../assets/individual.png`)}
                      className="w-[34px] h-[34px]"
                      alt="Individual"
                    />
                  </IconButton>
                  <IconButton
                    rounded
                    text={false}
                    color="var(--error)"
                    size="large"
                    className="p-5"
                  >
                    <img
                      src={require(`../assets/company.png`)}
                      className="w-[34px] h-[34px]"
                      alt="Individual 1"
                    />
                  </IconButton>
                </div>
                <p className="w-[70%] items-center text-center text-[24px] font-[500] font-Satoshi">
                  Individual / Company
                </p>
              </div>
            </Card>
            <Card
              style={{borderRadius:"20px"}} className="min-w-[200px] h-[200px]"
            >
              <div
                 
                 className={`w-[100%] cursor-pointer  hover:border hover:border-[#6F7AFC] h-full flex flex-col items-center rounded-[20px] justify-center gap-5 text-[24px] font-[500] p-10  ${
                  role === "Client" ? "text-white bg-gradient-to-b from-[#6F7AFC] to-[#4554EE]" : ""
                 }`}
                onClick={() => {
                setRole("Client");
                  // Additional functionality here
                }}
              >
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  size="large"
                >
                  <img
                    src={require(`../assets/client.png`)}
                    className="w-[34px] h-[34px]"
                  />
                </IconButton>
                <p className="w-[100%] items-center text-center text-[24px] font-[500] font-Satoshi">
                  Client
                </p>
              </div>
            </Card>
 
            <Card
              style={{borderRadius:"20px"}} className="min-w-[200px] h-[200px]"
            >
              <div
                className={`w-[100%] cursor-pointer hover:border hover:border-[#6F7AFC] h-full flex flex-col items-center rounded-[20px] justify-center gap-5 text-[24px] font-[500] p-10  ${
              role === "ideas" ? "text-white bg-gradient-to-b from-[#6F7AFC] to-[#4554EE]" : ""
                }`}
                onClick={() => {
                 setRole("ideas");
                  // Additional functionality here
                }}
              >
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  size="large"
                >
                  <img
                    src={require(`../assets/ideas.png`)}
                    className="w-[34px] h-[34px]"
                  />
                </IconButton>
                <p className="w-[100%] items-center text-center text-[24px] font-[500] font-Satoshi">
                  Ideas
                </p>
              </div>
            </Card>
 
            <Card
              style={{borderRadius:"20px"}} className="min-w-[200px] h-[200px]"
            >
               <div
                className={`w-[100%] cursor-pointer hover:border hover:border-[#6F7AFC] h-full flex flex-col items-center rounded-[20px] justify-center gap-5 text-[24px] font-[500] p-10  ${
                  role === "funding" ? "text-white bg-gradient-to-b from-[#6F7AFC] to-[#4554EE]" : ""
                }`}
                onClick={() => {
                  setRole("funding");
                  // Additional functionality here
                }}
              >
                <IconButton
                  rounded
                  text={false}
                  color="var(--error)"
                  size="large"
                >
                  <img
                    src={require(`../assets/funding.png`)}
                    className="w-[34px] h-[34px]"
                  />
                </IconButton>
                <p className="w-[100%] items-center text-center text-[24px] font-[500] font-Satoshi">
                  Funding
                </p>
              </div>
            </Card>
          </div>
          <button
            className={`w-fit px-12  rounded-[15px] shadow-[5px_5px_10px_#a6aabc,-5px_-5px_6px_#FAFBFF] text-center text-[22px] font-[700] font-Satoshi p-2  rounded ${
        role
                ? "bg-gradient-to-r from-indigo-800 to-sky-600 hover:scale-[1.02] transform transition-transform duration-400 ease-in-out hover:bg-gradient-to-r hover:from-[#FF913A] hover:to-[#E46E10]  text-[white]"
                : "bg-[#E3EDF7] cursor-not-allowed text-[#929CA8]"
            }`}
            onClick={handleNext}
          >
           Next
          </button>
        </div>
      </div>
   
    </div>
  );
}
 
export default RoleChanger;
 