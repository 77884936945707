import React, { useImperativeHandle, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function SuccessStories(props, ref) {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();
  const localRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (localRef.current) {
        localRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed here
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleIwantHire = () => {
    navigate("/browse-experts-view", {
      state: { from: window.location.pathname },
    });
  };
  const handleImAnIncubator = () => {
    navigate("/all-projects", {
      state: { from: window.location.pathname },
    });
  };
  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };
  return (
    <div
      data-id="findCompanies"
      ref={localRef}
      className="w-full h-fit px-10 py-5 flex flex-col gap-6"
    >
      <div className="font-[500] w-[75%] text-[40px] text-[#352F36]">
        Discover tailored solutions: for every need
      </div>
      <div
        className="scroll-container -ml-10 h-fit p-1 w-full flex"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-fit h-fit gap-10 flex flex-row items-center">
          <div className="ml-10 grid grid-cols-[60%_40%] gap-5 w-[900px] mobile:grid-cols-1 mobile:w-[350px] px-14 py-2 medium:w-[600px] laptop:w-[800px] scale-y-[1.1] mobile:scale-[1] h-full rounded-[20px] bg-blue-image text-white pl-4">
            <div className="col-span-1 w-[100%] flex flex-col gap-5 p-4 pl-2 py-6 rounded-[20px]">
              <div className="font-[700] text-[40px] mobile:text-[18px] ipadMin:text-[20px] ipadMax:text-[22px]">
                Invest In Innovation, Join The P2H Revolution!
              </div>
              <div className="font-[400] text-[20px] mobile:text-[14px] ipadMin:text-[16px] ipadMax:text-[18px]">
                Invest In Innovation, Join The P2H Revolution!
              </div>
              <div>
                <button
                  onClick={handleImAnIncubator}
                  className="bg-[#E3EDF7] hover:bg-[black] hover:text-[#FFFFFF] p-2 px-8 w-fit h-fit rounded-[15px] text-[#352F36] [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33]"
                >
                  I’m an incubator
                </button>
              </div>
            </div>
            <div className="h-full w-fit justify-center flex flex-row mobile:hidden items-end">
              <img
                className="h-[300px] w-full object-cover"
                src={require("../../assets/defaultScreen/tailoredimage.png")}
                alt=""
              />
            </div>
          </div>
          <div className="grid grid-cols-[60%_40%] gap-5 w-[900px] mobile:grid-cols-1 mobile:w-[350px] px-14 pt-4 pb-0 medium:w-[600px] laptop:w-[800px] h-full rounded-[20px] bg-orange-image text-white pl-4">
            <div className="col-span-1 flex flex-col gap-5 p-4 pl-12 py-6 rounded-[20px]">
              <div className="font-[700] text-[40px] mobile:text-[18px] ipadMin:text-[20px] ipadMax:text-[22px]">
                Find Your Perfect Match, Hire Top Talent on P2H!
              </div>
              <div className="font-[400] text-[20px] mobile:text-[14px] ipadMin:text-[16px] ipadMax:text-[18px]">
                Offering clients access to a diverse pool of skilled freelancers
                for their projects.
              </div>
              <div>
                <button
                  onClick={handleIwantHire}
                  className="bg-[#E3EDF7] hover:bg-[black] hover:text-[#FFFFFF] p-2 px-8 w-fit h-fit rounded-[15px] text-[#352F36] [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33]"
                >
                  I want to hire
                </button>
              </div>
            </div>
            <div className="h-full w-[100%] justify-center flex flex-row mobile:hidden items-end">
              <img
                className="h-[300px] w-full object-cover"
                src={require("../../assets/defaultScreen/tailoredwomenimage.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.forwardRef(SuccessStories);
