import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton } from 'ui-neumorphism'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton() {
    const navigate=useNavigate()
    const location=useLocation()
    const backHandler=()=>{
      if (location.pathname!=="/client-home-view") {
        console.log(navigate(-1));
      }
    }
  return (
<div  className='w-fit h-fit z-50 relative' onClick={backHandler}>
<IconButton rounded text={false}  style={{width:"24px",height:"24px"}} size="large" className='absolute top-4 left-6 mobile:left-2 px-4 py-2 bg-gray-200 text-gray-800 rounded shadow'>
    <ArrowBackIcon /> </IconButton> 
</div>    
)
}
