import React from 'react'
import PostNavHeader from '../Post-Jobs/util/PostNavHeader';
import { DescriptionView, ProfileHeader } from './ViewProfileTopRatedIndividuals';
import ProjectPortfolio from './ProjectPortfolio';
import ProfilesForYourRequirements from './ProfilesForYourRequirements';

export default function ProfileView() {
    return (
        <div className="flex flex-col h-screen w-full bg-normal">
          <PostNavHeader isBack={true} titleName="Sarah's profile" />
          <div className="px-10 py-8 flex w-full flex-col gap-5 overflow-auto">
            <ProfileHeader />
            <ProjectPortfolio />
            <ProfilesForYourRequirements />
          </div>
        </div>
      );
}
