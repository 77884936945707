import React, { useContext, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, Card, IconButton } from "ui-neumorphism";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { handleBase64Change } from "../../utils/constants";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import { ClientIdContext, TokenContext } from "../../App";
export default function HomeNavBar({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const logoutHandler = () => {
    localStorage.clear();
    updateClientId("");
    updateToken("");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/");
  };
  return (
    <div className="w-full h-[10vh] px-10 py-4  small:px-4 mobile:px-2 flex flex-row gap-10 justify-between ">
      <div className="text-[#051118] flex flex-row gap-40 w-fit items-center">
        <div className=" text-[42px] w-fit leading-[51.66px] text-left cursor-pointer hover:scale-[1.01]">
          P2H
        </div>
      </div>
      <div className="flex flex-row w-full pr-2 items-center justify-end mobile:gap-4 relative right-4 gap-10">
        <div className="flex flex-row medium:hidden items-center gap-10">
          <span
            onClick={() => alert("Pending with Categories page navigation")}
            className=" text-[18px] font-medium leading-[24.3px] text-left hover:underline cursor-pointer"
          >
            Categories
          </span>
          <span
            onClick={() => alert("Pending with Freelancers page navigation")}
            className=" text-[18px] font-medium leading-[24.3px] text-left hover:underline cursor-pointer"
          >
            Freelancers
          </span>
          <span
            onClick={() =>
              alert("Pending with  Offshore companies page navigation")
            }
            className=" text-[18px] font-medium leading-[24.3px] text-left hover:underline cursor-pointer"
          >
            Offshore companies
          </span>
          <span
            onClick={() => alert("Pending with Startups page navigation")}
            className=" text-[18px] font-medium leading-[24.3px] text-left hover:underline cursor-pointer"
          >
            Startups
          </span>
        </div>

        <div className="w-fit p-2 text-left flex flex-row gap-2  rounded-md items-center min-w-[150px] card focus:[box-shadow:2.7px_2.7px_5.5px_1px_#A6ABBD_inset] ">
          <SearchIcon
            size={30}
            className="p-[1px] font-[rgba(146, 156, 168, 1)]"
          />{" "}
          <input
            type="search"
            className="outline-none bg-[#E3EDF7] w-full"
            placeholder="Search "
          />
        </div>

        <div
          className="cursor-pointer hover:scale-[1.01]"
          onClick={() => navigate("/notification")}
        >
          <Card rounded width={44} height={44}>
            <IconButton
              onClick={() =>
                navigate("/notification", {
                  state: { pathName: location.pathname },
                })
              }
              rounded
              text={false}
              style={{ width: "44px", height: "44px" }}
              size="large"
              className="rounded "
            >
              <div className="h-full w-full grid place-items-center text-[#929CA8] hover:text-black cursor-pointer">
                <Badge content={0}>
                  <NotificationsActiveIcon />{" "}
                </Badge>
              </div>
            </IconButton>
          </Card>
        </div>
        <div
          className="cursor-pointer w-fit h-fit hover:scale-[1.01]"
          onClick={handleDropdownToggle}
        >
          <Card rounded width={44} height={44}>
            <IconButton
              rounded
              text={false}
              style={{ width: "44px", height: "44px" }}
              size="large"
              className="rounded "
            >
              <img
                className="w-full h-full rounded-full object-cover"
                src={
                  user.profileImage
                    ? handleBase64Change(user.profileImage)
                    : require("../../assets/noimage.png")
                }
                alt=""
              />
            </IconButton>
          </Card>
        </div>

        {isDropdownOpen && (
          <div className="absolute top-12 right-5 medium:top-12 largeScreen:top-20 z-50  bg-[#E3EDF7] w-[200px]  shadow-[8px_8px_7px_#a6aabc,-8px_-8px_7px_#FAFBFF] p-4 rounded-[15px] py-2">
            <p
              className="px-1 py-3  border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10"
              onClick={() => navigate("/edit-profile")}
            >
              Edit Profile
            </p>
            <p
              className="px-1 py-3 border-b border-[#414B5A] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
              onClick={() => navigate("/clientDashboard")}
            >
              Dashboard
            </p>
            <div className="medium:flex flex-col medium:visible hidden items-start gap-5 pt-2">
              <p className="px-1 pb-2  w-full border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10">
                Categories
              </p>
              <p className="px-1 pb-2  w-full  border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10">
                Freelancers
              </p>
              <p className="px-1  pb-2  w-full border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10">
                Offshore companies
              </p>
              <p className="px-1 pb-2  w-full border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10">
                Startups
              </p>
            </div>
            <p
              className="px-1 py-3 text-[red] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
              onClick={logoutHandler}
            >
              <ExitToAppIcon /> Sign Out
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
