import React, { useContext, useEffect, useState } from "react";
import PostNavHeader from "../util/PostNavHeader";
import ProgressBar from "../util/ProgressBar";
import { ClientIdContext, PostAJobContext, TokenContext } from "../../../App";
import TextAreaField from "../../inputs/TextAreaField";
import DocumentsUploadField from "../../inputs/DocumentsUploadField";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../Button/NextButton";
import PreviewButton from "../../Button/PreviewButton";
import axios from "axios";
import { apiUrl, token, clientId } from "../../../utils/constants";
import toast from "react-hot-toast";

export default function ProjectDescription() {
  const { token, updateToken } = useContext(TokenContext);
  const {
    projectDuration,
    setProjectDuration,
    progressStatus,
    projectTitle,
    setProjectTitle,
    selectedSKills,
    setSelectedSkills,
    setEstimateTime,
    timeValue,
    setTimeValue,
    experienceLevel,
    setExperienceLevel,
    isContract,
    setIsContract,
    needAcompanyToWork,
    setNeedAcompanyToWork,
    teamSize,
    setTeamSize,
    projectDescription,
    setProjectDescription,
    projectDocument,
    setProjectDocument,
    projectDocumentName,
    setProjectDocumentName,
    projectBudget,
    setProjectBudget,
    costOfWork,
    setCostOfWork,
    paymentType,
    setPaymentType,
    setprogressStatus,
  } = useContext(PostAJobContext);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/project-description") {
      setprogressStatus(3);
    }
  }, []);
  const documentClearHandler = () => {
    setProjectDocument(null);
    setProjectDocumentName(null);
  };
  useEffect(() => {
    if (
      location.pathname === "/project-description" &&
      projectDocument !== null
    ) {
      setprogressStatus(4);
    }
  }, [projectDocument]);
  const navigate = useNavigate();
  const previewHandler = () => {
    if (projectDocument !== null && projectDescription !== null) {
      navigate("/post-preview");
    } else {
      toast.error("Please fill the data");
    }
  };

  useEffect(() => {
    if (progressStatus < 2) {
      navigate("/project-duration");
    }
  });
  const successfullySubmittedClearData = () => {
    setProjectDuration(null);
    setProjectTitle(null);
    setEstimateTime(null);
    setSelectedSkills([]);
    setTimeValue(null);
    setExperienceLevel(null);
    setIsContract(null);
    setNeedAcompanyToWork(null);
    setTeamSize(null);
    setProjectBudget(null);
    setProjectDescription(null);
    setProjectDocument(null);
    setProjectDocumentName(null);
    setCostOfWork(null);
    setPaymentType(null);
    setprogressStatus(1);
    navigate("/project-duration");
  };

  const postAJObApi = () => {
    const formData = new FormData();
    formData.append("file", projectDocument);
    formData.append("userId", clientId);
    formData.append("projectType", projectDuration);
    formData.append("projectName", projectTitle);
    formData.append("projectDescription", projectDescription);
    // formData.append("companyName", projectDescription);
    formData.append(
      "isThisJobAContractToHire",
      isContract === "Yes" ? true : false
    );
    formData.append("skills", selectedSKills);
    formData.append("estimatedTime", timeValue);
    formData.append(
      "needACompanyToWork",
      needAcompanyToWork === "Yes" ? true : false
    );
    formData.append("companySize", teamSize);
    formData.append("experience", experienceLevel);

    if (paymentType === "Hourly") {
      formData.append("paymentType", "hourly");
      formData.append("costOfWorking", costOfWork);
    } else {
      formData.append("paymentType", "fixed");
      formData.append("projectBudget", projectBudget);
    }

    axios
      .post(`${apiUrl}/project/addProject`, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        successfullySubmittedClearData();
        navigate("/post-Success-message");
      })
      .catch((error) => {
        alert("Show error notification!");
      });
  };

  return (
    <div className="flex flex-col h-screen w-full bg-normal">
      <PostNavHeader isBack={true} />
      <div className="px-40 py-8 small:px-10 mobile:px-4 flex flex-col items-center gap-5 overflow-auto">
        <ProgressBar status={progressStatus} />
        <TextAreaField
          labelName={"Project description"}
          inputValue={projectDescription}
          setInputValue={setProjectDescription}
        />
        {projectDocument ? (
          <div className="flex flex-row small:flex-wrap small:items-center w-full large:w-[700px] gap-5 ">
            <div className=" [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] bg-[#2485FF] w-[280px] mobile:w-full min-w-fit h-[64px] text-white  rounded-[20px] px-[30px] py-[20px] flex flex-row gap-2 items-center justify-center">
              <div className="w-[23] h-[17]">
                {" "}
                <img
                  className=" "
                  src={require("../../../assets/navbar-logos/tick_amrk.png")}
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="font-[500] text-[18px]">Uploaded</div>
            </div>
            <div className="min-w-[296px] mobile:w-full w-fit rounded-[30px] h-[64px] py-[12px] px-[30px] [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset] flex flex-row gap-1 items-center justify-between">
              <div className="flex flex-row gap-3 items-center">
                <div className="w-[16.17px] h-[20px]">
                  <img
                    src={require("../../../assets/navbar-logos/document-icon.png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <div>{projectDocumentName}</div>
              </div>
              <div
                className="pl-1 text-[#414B5A] opacity-[50%] w-[12.02px] text-[28px] mobile:font-bold mobile:text-black mobile:opacity-[100%] cursor-pointer hover:opacity-[100%]"
                onClick={documentClearHandler}
              >
                &times;
              </div>
            </div>
          </div>
        ) : (
          <DocumentsUploadField
            document={projectDocument}
            setDocument={setProjectDocument}
            documentName={projectDocumentName}
            setDocumentName={setProjectDocumentName}
          />
        )}

        <div className="w-full large:w-[700px] grid place-items-center h-fit py-10">
          <div className="w-full flex flex-row  small:flex-col small:items-center justify-center gap-10">
            <PreviewButton previewHandler={previewHandler} />
            <NextButton
              bgActive={true}
              buttonName={"post"}
              nextHandler={postAJObApi}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
