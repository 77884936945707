import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Avatar, Badge, Card, IconButton } from "ui-neumorphism";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation, useNavigate } from "react-router-dom";
export default function UserHeader({redirectUrl,isProfile, isBack, titleName }) {
  const navigate=useNavigate()
const redirectHandler=()=>{
  localStorage.removeItem("redirectUrl")
  navigate(redirectUrl)
}
const logoutHandler=()=>{
  localStorage.clear()
  navigate("/login")
}
const location=useLocation()
  return (
    <div className="w-full h-[12vh] px-10 py-1 mb-2 bg-normal [box-shadow:5px_5px_10px_0px_#A6ABBD,_-2px_-2px_10px_0px_#FAFBFF] flex flex-row justify-between items-center">
      <div className="flex flex-row gap-5 items-center">
        {isBack && (
          <IconButton rounded text={false} onClick={redirectHandler}>
            <Icon path={mdiArrowLeft} size={1} />
          </IconButton>
        )}
        <div className="font-Satoshi text-[28px] font-bold leading-[37.8px] text-center text-[#352F36]">
          {titleName ? titleName : " Post A job"}
        </div>
      </div>
     {
        !isProfile && 
        <div className="flex flex-row items-center gap-2">
        <Card rounded width={44} height={44}>
          <IconButton onClick={()=>navigate("/notification",{state:{pathName:location.pathname}})}
            rounded
            text={false}
            style={{ width: "44px", height: "44px" }}
            size="large"
            className="rounded "
          >
            <div className="h-full w-full grid place-items-center text-[#929CA8] hover:text-black cursor-pointer">
              <Badge content={0}>
                <NotificationsActiveIcon />{" "}
              </Badge>
            </div>
          </IconButton>
        </Card>
        <div onClick={logoutHandler}>
          <Avatar
            bgColor="--white"
            size={80}
            src={require("../../assets/navbar-logos/user.png")}
          />
        </div>
      </div>
     }
    </div>
  );
}
