import React from 'react'
import {  IconButton } from 'ui-neumorphism'
import { useNavigate } from "react-router-dom";
import BackButton from "../components/Button/BackButton";
import NextButton from "../components/Button/NextButton";
function PasswordSuccess() {
    const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-[#E3EDF7]  font-Satoshi overflow-x-hidden p-4 pb-0">
    <div className="absolute w-[350px] h-[350px] ml-[-180px] pt-[40px] rounded-tr-[150px] rounded-br-[120%] shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]">
      <div className="w-[350px] h-[350px] ml-[-20px] mt-[-28px] rounded-tr-[150px] rounded-br-[120%]  shadow-[inset_0px_7px_19px_#00000040,inset_0px_-7px_1px_#FFFF]"></div>
    </div>
   <div className="-mt-2 ">
   <BackButton  />
   </div>
    <div className="  relative w-full  h-full flex flex-col justify-center  items-center">
    <div className="small:mt-[50%]  mt-[4%] z-50 w-[100%] h-full flex flex-col gap-7 justify-center items-center">
          <IconButton  style={{width:"20%" }} rounded text={false}  color='var(--error)' size='large' className='w-[20%] flex items-center p-10' >
   <img src={require(`../assets/passowrdscucess.png`)} className='w-[34px] h-[34px] ' />
</IconButton>
    <div className="w-[100%] text-center text-zinc-800 text-[45px] font-[700] font-Satoshi">New password has been created!</div>
    <div className="w-[100%]  justify-center items-center gap-2 inline-flex">
        <div className="w-[70%] mobile:w-[100%] medium:w-[90%] text-center flex text-gray-600 text-[30px] font-[400] font-Satoshi">You can now login with your newly created password, let’s explore the recommended campaign.</div>
    </div>
   
              <NextButton
       bgActive={true}
            buttonName=" Log In"
            nextHandler={()=>navigate('/login')}
          />
    </div>
    <div className="w-[100%] h-[38%] z-10  relative flex  justify-center">
          <div className="absolute bottom-0">
          <img
            src={require(`../assets/passwordsucees.png`)}
            className="relative h-[250px] "
          />
          </div>
        </div>
      </div>

</div>
  )
}

export default PasswordSuccess