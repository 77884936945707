import React, { useState } from "react";
import UserHeader from "./UserHeader";
import { IoIosSearch } from "react-icons/io";
import RequestForProposal from "./RequestForProposal";
import FilterStatus from "./FilterStatus";
import CompanyProfiles, { RecommendationCompanyProfiles } from "./CompanyProfiles";
import CreateRFP from "./CreateRFP";
import FlowForRequestForProposal from "./FlowForRequestForProposal";

export default function MyDashBoard() {
  const [activeScreen, setActiveScreen] = useState(0);
  const [isCreateRFP,setCreateRFP]=useState(false)
  const [isCreateRFPClicked,setRFPClicked]=useState(false)
  const [flowPlaced,setFlowPlaced]=useState(1)
  return (
    <div className="bg-normal w-full h-screen overflow-hidden">
     
      <UserHeader isProfile={true} isBack={true} titleName={"My Dashboard"} />
      <div className="h-[82%]  overflow-auto scrollbar px-10 py-4">
        <BannerDashboard setCreateRFP={setCreateRFP} isCreateRFP={isCreateRFP} isCreateRFPClicked={isCreateRFPClicked} />
        <div className="grid grid-cols-[70%_30%] gap-4 py-4 mx-4 ">
          <div className="flex flex-col gap-4">
          <DashboardActiveScreen
          activeScreen={activeScreen}
          setActiveScreen={setActiveScreen}
        />
        {
          !isCreateRFPClicked ?(
            isCreateRFP?
            <CreateRFP setRFPClicked={setRFPClicked} />
            :
            <RequestForProposal />
          )
          :
          <FlowForRequestForProposal flowPlaced={flowPlaced} setFlowPlaced={setFlowPlaced}/>
        }
          </div>
          <div className="flex flex-col gap-4">
           {
             !isCreateRFPClicked &&(
            isCreateRFP?
           <RecommendationCompanyProfiles />
            :
            <FilterStatus />)
           }
            <CompanyProfiles />
          </div>
        </div>
      </div>
    </div>
  );
}

export const BannerDashboard=({setCreateRFP,isCreateRFP,isCreateRFPClicked})=>{
  return (
    <div className="flex flex-row gap-10 w-fit items-center">
      <div className="min-w-[390px] px-10 pl-6 h-[100px] py-3  pb-0 shadow-[5px_5px_10px_#a6aabc,-5px_-5px_10px_#FAFBFF] rounded-[15px] flex flex-row gap-5 items-center justify-center">
        <img
          className="w-[125px] h-[84px]"
          src={require("../../assets/Rohitimage.png")} // Path to your Google logo image
        />
        <p className="flex w-fit flex-col gap-2 items-center">
          <p className="text-[30px] font-[700]">Rohit</p>
          <p className="text-[14px] text-[#414B5A] font-[500]">P2H Solutions</p>
        </p>
      </div>
      <div className="min-w-[300px] w-fit h-[70px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF] rounded-[20px] p-4 flex flex-row gap-2 items-center">
        <IoIosSearch className="opacity-[50%]  text-[#414B5A]" />
        <input
          type="text"
          className="h-full w-full bg-transparent outline-none focus:outline-none"
          placeholder="Search "
        />
      </div>
    {
      !isCreateRFP && !isCreateRFPClicked &&   <div className="flex flex-row gap-4 items-center">
      <button onClick={()=>setCreateRFP(true)} className="bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] text-[20px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] text-[#FFFFFF] w-[225px] h-[54px] px-[30px] py-[12px] gap-[6px] rounded-[14px] opacity-[0px]">
        + Create ( RFP )
      </button>
      <div className="rounded-full w-fit h-fit p-1 text-[20px] [box-shadow:5px_5px_10px_0px_#A6ABBD,_-5px_-5px_10px_0px_#FAFBFF40] text-[#FFFFFF]">
        <div className="w-[20px] h-[20px] rounded-full bg-[linear-gradient(97.39deg,_#0829A9_17.03%,_rgba(100,_233,_255,_0.6)_161.49%)] grid place-content-center text-[14px]">
          !
        </div>
      </div>
    </div>
    }
    </div>
  );
}

export const DashboardActiveScreen = ({ activeScreen, setActiveScreen }) => {
  const list = ["About", "Projects", "Financials", "News & Events"];
  return (
    <div className="flex flex-row justify-between  border-b-[5px]  border-[#d2d7e7]">
      {list.map((title, index) => (
        <div
          key={index}
          onClick={() => setActiveScreen(index)}
          className={`rounded-[2px] text-[#352F36] w-fit cursor-pointer hover:opacity-100 ${
            activeScreen === index
              ? "font-[700] border-b-[5px] border-[#6F7AFC] -mb-1"
              : "opacity-[50%] text-[18px] font-[500]"
          }`}
        >
          {title}
        </div>
      ))}
    </div>
  );
};
