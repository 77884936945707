import React, { useRef,useState } from "react";
import { useSwipeable } from "react-swipeable";
// Import custom CSS for any additional styling

export default function SuccessStories() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed here
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };
  return (
    <div className="w-full h-fit p-5 flex flex-col gap-6">
      
      <div className="font-[500] pl-5 w-[75%] text-[40px] text-[#352F36]">
      Discover the power of influence: P2H success stories
      </div>
      <div
        className="scroll-container h-fit p-1 w-full flex"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}

      >
        <div className="w-full h-fit gap-10 flex ml-[-30px] flex-row">
      
         
            <div className=" w-fit h-fit max-w-[1000px] rounded-[20px] bg-transparent flex flex-col gap-3 ">
              <div className="ml-10 flex flex-row gap-5 mobile:grid-cols-1 mobile:w-[350px] px-14 pt-4 pb-0 w-[980px] laptop:w-[950px]  mobile:scale-[1] h-full rounded-[20px] bg-orange-image text-white pl-4">
                <div className=" w-full flex flex-col gap-3  py-4 pr-0 pl-10 ">
                  <div className="w-full font-[700] text-[40px] leading-[50px]">
                    Unlock Your Influence Potential
                  </div>
                  <div className="font-[400] text-[20px] w-full">
                    Join influencers who have found success on P2H!
                  </div>
                  <div>
                    <button className="hover:bg-[black] hover:text-[#FFFFFF] cursor-pointer relative bg-[#E3EDF7] p-2 px-8 w-fit h-fit rounded-[15px] text-[#352F36] [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33]">
                      See more
                    </button>
                  </div>
                </div>
                <div className="w-full    flex flex-row items-center">
                  <img
                    className=" h-[404px] object-cover"
                    src={require("../../assets/defaultScreen/3d-portrait-woman 2.png")}
                    alt=""
                  />
                </div>
              </div>
              
                <div className="-mt-40 ml-10 z-50">
                  <img
                    className="w-[507px]"
                    src={require("../../assets/defaultScreen/influencer_logo.png")}
                    alt=""
                  />
                </div>
              
            </div>
         
      

            <div className=" w-fit h-fit max-w-[1000px] rounded-[20px] bg-transparent flex flex-col gap-3 ">
              <div className="ml-10 flex flex-row gap-5  mobile:grid-cols-1 mobile:w-[350px] px-14 pt-4 pb-0 w-[980px] laptop:w-[950px]  mobile:scale-[1] h-full rounded-[20px] bg-purple-image text-white pl-4">
                <div className=" w-full flex flex-col gap-3  py-4 pr-0 pl-10 ">
                  <div className="w-full font-[700] text-[40px] leading-[50px]">
                    Unlock Your Influence Potential
                  </div>
                  <div className="font-[400] text-[20px] w-full">
                    Join influencers who have found success on P2H!
                  </div>
                  <div>
                    <button className="bg-[#E3EDF7] hover:bg-[black] hover:text-[#FFFFFF] p-2 px-8 w-fit h-fit rounded-[15px] text-[#352F36] [box-shadow:5px_5px_10px_0px_#A6ABBD40,_-5px_-5px_10px_0px_#FAFBFF33]">
                      See more
                    </button>
                    <div className="pt-5 object-cover flex flex-row gap-2">
                  <div className="">
                  <img
                    className="w-[130px] h-[130px] rounded-[5px] rounded-tr-[30px] rounded-bl-[30px] "
                    src={require("../../assets/defaultScreen/suceesstorie2.jpg")}
                    alt=""
                  />
                  </div>
                  <div>
                  <img
                    className="w-[130px] h-[130px] pr-10 rounded-[15px]"
                    src={require("../../assets/defaultScreen/suceesstorie3.jpg")}
                    alt=""
                  />
                  </div>
                </div>
                  </div>
                </div>
                <div className="w-full  flex flex-row items-center">
                  <img
                    className=" h-[404px]  object-cover"
                    src={require("../../assets/defaultScreen/suceesstories.png")}
                    alt=""
                  />
                </div>
              </div>
              <div>

              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
