import { mdiArrowLeft } from "@mdi/js";
import axios from "axios";
import Icon from "@mdi/react";
import React, { useState, useEffect, useContext } from "react";
import { Avatar, Badge, Card, IconButton } from "ui-neumorphism";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation, useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Spinner, {
  apiUrl,
  handleBase64Change,
  hoverBg,
} from "../../../utils/constants";
import { ClientIdContext, TokenContext } from "../../../App";
export default function PostNavHeader({ isBack, titleName }) {
  const [clientInfo, setClientInfo] = useState({});
  const { clientId, updateClientId } = useContext(ClientIdContext);
  const { token, updateToken } = useContext(TokenContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(null);
  useEffect(() => {
    const path = localStorage.getItem("previousPath");
    setPreviousPath(path);
  }, []);
  useEffect(() => {
    axios
      .get(apiUrl + `/client/getById/${clientId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) {
          setClientInfo(res.data);
        }
      });
  }, []);
  // useEffect(() => {
  //   setPreviousPath(location.pathname);
  // }, [location]);
  const redirectHandler = () => {
    if (previousPath === "/login") {
      navigate("/client-home-view");
    } else if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  const logoutHandler = () => {
    localStorage.clear();
    navigate("/login");
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div>
      <div className="w-full h-[12vh] px-10 py-1 mb-2 bg-normal [box-shadow:5px_5px_10px_0px_#A6ABBD,_-2px_-2px_10px_0px_#FAFBFF] flex flex-row justify-between gap-10 mobile:px-2 mobile:gap-4 items-center">
        <div className="flex flex-row gap-5 items-center">
          {isBack && (
            <IconButton rounded text={false} onClick={redirectHandler}>
              <Icon path={mdiArrowLeft} size={1} />
            </IconButton>
          )}
          <div className="font-Satoshi text-[28px] mobile:text-[18px] small:text-[20px] font-bold leading-[37.8px] text-left w-fit text-[#352F36]">
            {titleName ? titleName : " Post A job"}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Card rounded width={44} height={44}>
            <IconButton
              onClick={() => navigate("/notification")}
              rounded
              text={false}
              style={{ width: "44px", height: "44px" }}
              size="large"
              className="rounded "
            >
              <div className="h-full w-full grid place-items-center text-[#929CA8] hover:text-black cursor-pointer">
                <Badge content={0}>
                  <NotificationsActiveIcon />{" "}
                </Badge>
              </div>
            </IconButton>
          </Card>
          <div onClick={handleDropdownToggle}>
            <Avatar
              className="w-[25px] h-[25px]"
              bgColor="--white"
              src={
                clientInfo.profileImage
                  ? handleBase64Change(clientInfo.profileImage)
                  : require("../../../assets/noimage.png")
              }
            />
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <div className="relative z-50 pl-2">
          <div className="absolute right-0 z-50  bg-[#E3EDF7] w-[300px]  shadow-[8px_8px_7px_#a6aabc,-8px_-8px_7px_#FAFBFF] p-4 rounded-[15px] py-2">
            <p
              className="px-1 py-3  border-b  border-[#414B5A] hover:scale-[1.02] cursor-pointer hover:bg-slate-600 hover:bg-opacity-10"
              onClick={() => navigate("/edit-profile")}
            >
              Edit Profile
            </p>
            <p
              className="px-1 py-3 border-b border-[#414B5A] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
              onClick={() => navigate("/clientDashboard")}
            >
              Dashboard
            </p>

            <p
              className="px-1 py-3 text-[red] cursor-pointer hover:scale-[1.02] hover:bg-slate-600 hover:bg-opacity-10"
              onClick={logoutHandler}
            >
              <ExitToAppIcon /> Sign Out
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
