import React from "react";

export default function RadioInput({ radioValue, setRadioValue, labelValue,disabled }) {
  const radioValueHandler = () => {
    if (radioValue === labelValue) {
      setRadioValue(null);
    } else {
      setRadioValue(labelValue);
    }
    
  };
  const radioUnHandler = () => {
    
  };
  return (
    <div  onClick={disabled?radioUnHandler:radioValueHandler} className={`${ radioValue === labelValue && " border-[3px] rounded-[20px] border-[#0624A6] border-opacity-[20%]"} p-2 h-full  w-full flex flex-row items-center gap-2 cursor-pointer`}>
      <div
        className={`w-[24px] h-[24px] rounded-full grid place-items-center cursor-pointer ${
          radioValue === labelValue
            ? "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF]"
            : "[box-shadow:2.5px_2.5px_5px_0px_#A6ABBD_inset,_-2.5px_-2.5px_5px_0px_#FAFBFF_inset]"
        }`}
       
      >
        <div
              className={`w-[15px] h-[15px]   ${
                radioValue === labelValue ? "border-[#0624A6] border-4" : "border-2 border-[#414B5A] opacity-[50%] "
          } rounded-full [box-shadow:2.5px_2.5px_5px_0px_#A6ABBD,_-2.5px_-2.5px_5px_0px_#FAFBFF]`}
        ></div>
      </div>
      <div className=" text-[18px] font-[500] leading-[24.3px] text-center capitalize text-[#352F36]">
        {" "}
        {labelValue}
      </div>
    </div>
  );
}
